import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
} from "Components/QualityAssurance";
// Hooks
import { QualityAssuranceObservationsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TableProps } from "Components/Programs/Table/types";
import { filtersInitialState } from "store/filters/initialState";
import { qualityAssuranceObservationsResetFiltersAction, setQualityAssuranceObservationsFiltersAction } from "store/filters/reducer.actions";
import {
  resetQualityAssuranceObservationsDirectoryOrderAction,
  updateColumnsVisibilityOfQualityAssuranceObservationsByStatus,
} from "store/directoriesOrders/reducer.actions";
import { fetchQualityAssuranceObservationsAction } from "store/qualityAssurance";
import { IfetchQualityAssuranceObservations } from "store/qualityAssurance/initialState";
import { ExternalReviewerHeader } from "Components/QualityAssurance/ExternalReviewerHeader";
import useRoles from "hooks/roles";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const QualityAssurance = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setQualityAssuranceObservationsFiltersAction());
  }, []);
  const { data } = useSelector<RootState, IfetchQualityAssuranceObservations>(
    (state) => state?.allQualityAssuranceObservations
  );
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);
  const auth = useSelector((state: RootState) => state.auth);
  const { isExternalReviewer } = useRoles();
  const { communityId, programId } = useParams();
  const { qa } = usePermissions();
  useLayoutEffect(() => {
    dispatch(
      updateColumnsVisibilityOfQualityAssuranceObservationsByStatus(
        qualityAssuranceObservationsFilter.status,
        isExternalReviewer()
      )
    );
  }, [qualityAssuranceObservationsFilter.status]);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = QualityAssuranceObservationsTable(data?.observations);

  useEffect(() => {
    return () => {
      // dispatch(qualityAssuranceObservationsResetFiltersAction());
      dispatch(resetQualityAssuranceObservationsDirectoryOrderAction());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchQualityAssuranceObservationsAction(communityId, programId));
    saveFiltersDataToLocalStorage(qualityAssuranceObservationsFilter);

  }, [qualityAssuranceObservationsFilter]);
  return (
    <>
      {isExternalReviewer() && <ExternalReviewerHeader auth={auth} />}
      <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
        <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
          <Header isExternalReviewer={isExternalReviewer()} />
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
            <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                  <Tabs />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                <Filters
                  columnOrder={columnOrder}
                  setColumnOrder={setColumnOrder}
                  columns={columnsConfig}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  setColumnsConfig={setColumnsConfig}
                />
              </div>
            </div>
          </div>

          <RednderView
            flexRender={flexRender}
            table={table}
            columnOrder={columnOrder}
            columns={columnsConfig}
            columnVisibility={columnVisibility}
            setSorting={setSorting}
            sorting={sorting}
            isExternalReviewer={isExternalReviewer()}
          />
        </div>
      </div>
    </>
  );
};
export default QualityAssurance;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
  isExternalReviewer,
}) => {
  const { data, stateIs } = useSelector<
    RootState,
    IfetchQualityAssuranceObservations
  >((state) => state?.allQualityAssuranceObservations);
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs === "Idle" && data?.observations?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
          isExternalReviewer={isExternalReviewer}
        />
      )}
      <Pagination />
    </div>
  );
};
