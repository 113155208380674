import React, { useEffect, useLayoutEffect, useState } from "react";
import FiltersList from "../FiltersList";
import HeaderBar from "./components/HeaderBar";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
// types
import { FiltersProps } from "./types";
import ObservationColumnModal from "view/components/Modals/Observations/ObservationsColumnModal";
import { IfetchObservations } from "store/observations/initialState";
import { isAnyObservationsFilterApplied } from "../AppliedFilters";
import { filtersInitialState } from "store/filters/initialState";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

export const Filters: React.FC<FiltersProps> = ({
  columnOrder,
  setColumnOrder,
  columns,
  columnVisibility,
  setColumnVisibility,
  setColumnsConfig,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCloseDiv, setIsCloseDiv] = useState(false);
  const { data } = useSelector<RootState, IfetchObservations>(
    (state) => state?.allObservations
  );

  const { observationsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // Handlers
  const handleToggleDropdown = () => {
    setIsCloseDiv((prevState) => !prevState);
  };

 


  useEffect(() => {
    const filtersData = getFiltersDataFromLocalStorage();
    setIsCloseDiv((pre: any) => {
      if (filtersData !== undefined) {
        return isAnyObservationsFilterApplied(filtersData.filters);
      } else {
        return isAnyObservationsFilterApplied(observationsFilter);
      }
    });
  }, []);

  // Initializing event listener
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <HeaderBar
          data={data}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleToggleDropdown={handleToggleDropdown}
          isCloseDiv={isCloseDiv}
        />
        <FiltersList isCloseDiv={isCloseDiv} />
      </div>
      {isOpen && (
        <ObservationColumnModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columns={columns.filter((item: any) => item.id !== "select")}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
      )}
    </>
  );
};
