import PlusIcon from "assets/icons/HeroIcons/PlusIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { sentenceCase } from "change-case";
import { Field, FieldArray, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import apiLibrary from "services/api";
import { Step } from "store/addReportStepper/initialState";
import { ISummaryReports } from "store/reportsSummary";
import { v4 as uuidv4 } from "uuid";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { IReportSettingsFormValues } from "../..";
import { MaterialUiSelect } from "../Select";
import _, { forEach } from "lodash";
import { Toasts } from "view/components/Toasts";
import { TailSpin } from "react-loader-spinner";

interface ReportSettingsProps extends FormikProps<IReportSettingsFormValues> {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}
interface IColumn {
  columnName: string;
  columnType: string;
  isSelected: boolean;
  type: string;
  inputType: string;
  columnId: string;
  columnTitle: string;
}

export const OtherQueryBuilder: React.FC<ReportSettingsProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const [availableColumns, setAvailableColumns] = useState<IColumn[]>([]);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);

  const handleClearAll = () => {
    setFieldValue("otherQueryFilter", []);
  };

  const getColumnsByReport = async () => {
    setIsColumnsLoading(true);
    try {
      const { data } = await apiLibrary.Reports.AddReportApis.getReportColumns(
        reportSummary.id
      );
      const availableColumns = data.availableColumns
        .filter((column: IColumn) => ["text", "number"].includes(column.columnType))
        .map((column: IColumn) => ({
          column: column.columnName,
          type: column.columnType,
          title: column.columnTitle,
          inputType: column.inputType ?? "text",
        }));

      setAvailableColumns(availableColumns);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(sentenceCase(errorMsg ?? ""));
    } finally {
      setIsColumnsLoading(false);
    }
  };

  useEffect(() => {
    getColumnsByReport();
    return () => {
      setAvailableColumns([]);
    };
  }, []);

  const filteredColumns = availableColumns.filter(
    (item: any) =>
      !values.otherQueryFilter.some((columnItem: any) => {
        return columnItem.column === item.column;
      })
  );

  if (isColumnsLoading) {
    return (
      <div className="flex items-center justify-center w-full loader">
        <TailSpin
          height="50"
          width="50"
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col items-start self-stretch justify-start w-full gap-4 bg-white rounded-lg">
      <div className="flex flex-col items-start self-stretch justify-start ">
        <div className="flex items-center justify-between w-full mt-2 mb-1">
          <p className="text-base text-textMid font-Overpass dark:text-textMain">
            Filter Observations
          </p>
          <button type="button" onClick={handleClearAll}>
            <p className="text-sm font-semibold cursor-pointer text-textLink">
              Clear All
            </p>
          </button>
        </div>
        <FieldArray name="otherQueryFilter">
          {({ push, remove }) => {
            return (
              <div className="w-full">
                {values.otherQueryFilter.map((rowKey, index) => {
                  const otherQueryFilterErrors: any = errors?.otherQueryFilter
                    ? errors.otherQueryFilter[index]
                    : {
                      column: null,
                      condition: null,
                      operator: null,
                      type: null,
                      value: null,
                    };

                  const otherQueryFilterTouched = otherQueryFilterErrors || {
                    column: false,
                    condition: false,
                    operator: false,
                    type: false,
                    value: false,
                  };

                  return (
                    <div className="flex w-full gap-2 items-start" key={index}>
                      {index > 0 ? (
                        <div className="w-[100px] flex-shrink-0 py-3">
                          <MaterialUiSelect
                            options={[
                              {
                                label: "and",
                                value: "and",
                              },
                              {
                                label: "or",
                                value: "or",
                              },
                            ]}
                            labelKey="label"
                            valueKey="value"
                            error={otherQueryFilterErrors?.operator}
                            initialValue={
                              values?.otherQueryFilter[index]?.operator
                            }
                            handleChange={(value: any) => {
                              setFieldValue(
                                `otherQueryFilter[${index}].operator`,
                                value.value
                              );
                            }}
                            width={100}
                            className="captitalize border-none"
                            styles={{ textTransform: 'capitalize' }}
                          />
                        </div>
                      ) : (
                        <div className="w-[100px] dark:text-textMain self-center flex-shrink-0">
                          <p className="mt-[5px] text-center uppercase">Where</p>
                        </div>
                      )}

                      <div className="w-full py-3">
                        <ColumnsDropdown
                          rowIndex={index}
                          values={values}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          otherQueryFilterErrors={otherQueryFilterErrors}
                          otherQueryFilterTouched={otherQueryFilterTouched}
                          availableColumns={availableColumns}
                        />
                      </div>

                      <div className="w-[400px] py-3">
                        <ConditionDropdown
                          rowIndex={index}
                          values={values}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          otherQueryFilterErrors={otherQueryFilterErrors}
                          otherQueryFilterTouched={otherQueryFilterTouched}
                        />
                      </div>
                      <div className="w-full flex justify-start items-start">
                        {values.otherQueryFilter[index].column === "status" ?
                          <div className="w-full py-3">
                          <RenderedDropdown
                            rowIndex={index}
                            className={""}
                            values={values}
                            setFieldValue={setFieldValue}
                            otherQueryFilterErrors={otherQueryFilterErrors}
                            otherQueryFilterTouched={otherQueryFilterTouched}
                            handleChange={handleChange}
                          />
                          </div>
                          :
                          <div className="w-full">
                            <RenderedInput
                              rowIndex={index}
                              className={""}
                              values={values}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              otherQueryFilterErrors={otherQueryFilterErrors}
                              otherQueryFilterTouched={otherQueryFilterTouched}
                              handleChange={handleChange}
                            />
                          </div>
                        }
                      </div>

                      <div className="w-[50px] cursor-pointer mt-3">
                        <TrashIcon onClick={() => remove(index)} />
                      </div>
                      {/* </div> */}
                    </div>
                  );
                })}
                <button
                  disabled={filteredColumns.length === 0}
                  type="button"
                  title={`${filteredColumns.length === 0 && "No More Colmuns To Add"
                    }`}
                  onClick={() => {
                    push({
                      column: filteredColumns[0].columnName,
                      columnId: filteredColumns[0]?.columnId,
                      condition: "==",
                      operator: filteredColumns.length === 0 ? "and" : "and",
                      type: filteredColumns[0].type,
                      value: "",
                    });
                  }}
                  className={`flex items-center mb-6 disabled:cursor-not-allowed mt-2 ${filteredColumns.length === 0 ? 'opacity-[0.5]' : ''}`}
                >
                  <PlusIcon fill="#005C89" />
                  <p className={`ml-2 text-sm font-semibold text-textLink `}>
                    Add Another
                  </p>
                </button>
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

const ColumnsDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  availableColumns,
}) => {
  return (
    <MaterialUiSelect
      width={"100%"}
      options={availableColumns}
      labelKey="title"
      valueKey="column"
      error={otherQueryFilterErrors?.column}
      initialValue={values?.otherQueryFilter[rowIndex]?.column}
      handleChange={(value: any) => {
        setFieldValue(`otherQueryFilter[${rowIndex}].column`, value.column);
        setFieldValue(`otherQueryFilter[${rowIndex}].type`, value.inputType);
      }}
      inputStyles={{ border: 'none' }}
      className="border-none"
    />
  );
};

const ConditionDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
}) => {
  const dropdownOptions = getDropdown(values.otherQueryFilter[rowIndex].type);
  return (
    <MaterialUiSelect
      options={dropdownOptions}
      labelKey="name"
      valueKey="value"
      error={otherQueryFilterErrors?.condition}
      initialValue={values?.otherQueryFilter[rowIndex]?.condition}
      handleChange={(value: any) => {
        setFieldValue(`otherQueryFilter[${rowIndex}].condition`, value.value);
      }}
      styles={{ textTransform: 'capitalize' }}
    />
  );
};

const RenderedInput: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const type = values.otherQueryFilter[rowIndex].type;
  return (
    <TextInput
      label=""
      type={type}
      // inputClassName="border-2"
      name={`otherQueryFilter[${rowIndex}].value`}
      setFieldValue={setFieldValue}
      value={values?.otherQueryFilter[rowIndex]?.value}
      error={otherQueryFilterErrors?.value}
      touched={otherQueryFilterTouched}
      handleChange={handleChange}
      handleBlur={handleBlur}
      inputClassName="rounded-[4px] "
    />
  );
};

const RenderedDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  return (
    <MaterialUiSelect
      options={[
        {
          label: "In Review",
          value: "in_review",
        },
        {
          label: "Complete",
          value: "completed",
        },
        {
          label: "Incomplete",
          value: "incompleted"
        },
        {
          label: "Archived",
          value: "archived"
        },
        {
          label: "Request To Edit",
          value: "request_to_edit"
        },
      ]}
      labelKey="label"
      valueKey="value"
      initialValue={values?.otherQueryFilter[rowIndex]?.value}
      error={otherQueryFilterErrors?.value}
      handleChange={(value: any) => {
        setFieldValue(`otherQueryFilter[${rowIndex}].value`, value.value);
      }}
      width="100%"
      className="captitalize border-none"
    />
  );
};

function getDropdown(type: string) {
  switch (type) {
    case "id":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "string":
    case "text":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];
    case "boolean":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];
    case "integer":
    case "float":
    case "number":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "array":
      return ["Equal To ==", "Not Equal to!=", "Between", "Not Between!="];
    case "decimal":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "binary":
    case "binary_id":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];
    case "date":
    case "datetime-local":
    case "time":
    case "utc_date":
    case "utc_datetime":
    case "naive_datetime":
    case "utc_datetime_usec":
    case "naive_datetime_usec":
    case "time_usec":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    default:
      return [];
  }
}
