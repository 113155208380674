import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { Toasts } from "view/components/Toasts";

import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import {
  TimeQueryBuilder,
  OtherQueryBuilder,
  SubmissionButtons,
} from "./components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ISummaryReports } from "store/reportsSummary";
// import CustomAccordion from "../../../CustomAccordian";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import CustomAccordion from "../CustomAccordian";
import { values } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";
import { formatTheDates } from "utils/formatTheDates";
import { Step } from "hooks/useReportsStepper";
import { checkIsAnythingUpdatedThisStep } from "../..";
import { sentenceCase } from "change-case";
import {
  convertDateAndTimeToUTC,
  getEndOfDayInLocalTimeZoneToUtc,
  getMidnightInLocalTimeZoneToUtc,
} from "utils/getMidnightOrLocalDayeEndTime";
import { TIME_FORMATS } from "constants/timeFormats";
import { formatTheTime } from "utils/formatTheTime";
interface ReportSettingsProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}
export interface IReportSettingsFormValues {
  dateFilter: {
    condition: any;
    operator: any;
    date: string;
    dateType: any;
  }[];
  otherQueryFilter: {
    column: any;
    condition: any;
    operator: any;
    type: any;
    value: any;
  }[];
  repeatedDateFilter: {
    date: any;
    period: any;
    periodDuration: number;
    periodType: any;
    dateType: any;
  };
}

export const Filtering: React.FC<ReportSettingsProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  setAnythingUpdatedThisStep,
}) => {
  const dispatch = useDispatch();
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const { reportsId } = useParams();
  const navigate = useNavigate();
  const [initialState, setinitialState] = useState<IReportSettingsFormValues>({
    dateFilter: [],
    otherQueryFilter: [],
    repeatedDateFilter: {
      date: reportSummary.repeatedDateFilter?.date || "",
      dateType: reportSummary?.repeatedDateFilter?.dateType
        ? {
            value: reportSummary?.repeatedDateFilter?.dateType,
            name: sentenceCase(
              reportSummary.repeatedDateFilter?.dateType ?? ""
            ),
          }
        : {
            value: "observer_date",
            name: "Observer Date",
          },
      period: reportSummary.repeatedDateFilter?.period || "",
      periodDuration: reportSummary.repeatedDateFilter?.periodDuration || 1,
      periodType: {
        value:
          reportSummary.repeatedDateFilter?.periodType &&
          reportSummary.repeatedDateFilter?.periodType === "was_during_the_past"
            ? "was_during_the_past"
            : "was_between_today_and",
        name:
          reportSummary.repeatedDateFilter?.periodType &&
          reportSummary.repeatedDateFilter?.periodType === "was_during_the_past"
            ? "Was During The Past"
            : "Was Between Today And",
      },
    },
  });

  const reportFilteringWithOutRepeatSchema = Yup.object().shape({
    dateFilter: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("Date is required"),
        dateType: Yup.mixed().required("Date type is required"),
      })
    ),

    otherQueryFilter: Yup.array().of(
      Yup.object().shape({
        column: Yup.mixed().required("Column is required"),
        condition: Yup.mixed().required("Condition is required"),
        operator: Yup.mixed().required("Operator is required"),
        value: Yup.mixed().required("Value is required"),
      })
    ),
  });

  const reportFilteringWithRepeatSchema = Yup.object().shape({
    otherQueryFilter: Yup.array().of(
      Yup.object().shape({
        column: Yup.mixed().required("Column is required"),
        condition: Yup.mixed().required("Condition is required"),
        operator: Yup.mixed().required("Operator is required"),
        value: Yup.mixed().required("Value is required"),
      })
    ),
    repeatedDateFilter: Yup.object().shape({
      period: Yup.string().when(
        "periodType",
        (periodType: any, schema: any) => {
          if (periodType[0].value === "was_between_today_and") {
            return schema;
          } else {
            return schema.required("Period is required");
          }
        }
      ),

      date: Yup.string().when("periodType", (periodType: any, schema: any) => {
        if (periodType[0].value !== "was_between_today_and") {
          return schema.notRequired();
        } else {
          return schema.required("Date Is Required");
        }
      }),
      dateType: Yup.object()
        .shape({
          value: Yup.string().required("Date Type Value is required"),
          name: Yup.string().required("Date Type Name is required"),
        })
        .required("Date Type is required"),
      periodType: Yup.object()
        .shape({
          value: Yup.string().required("Period Value is required"),
          name: Yup.string().required("Period Name is required"),
        })
        .required("Period Type is required"),
    }),
  });

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const someStepsNeedsReview = steps
      .filter((step) => step.step !== "filtering")
      .some((step) => step.needsReview);

    if (someStepsNeedsReview) {
      Toasts.error(
        "Your report could not be submitted as you have not confirmed all steps that were affected by your edits. Please review and save all steps that are highlighted in red before submitting the report"
      );
      return;
    }
    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.addReportFiltering(
          reportSummary.id,
          convertDataFormat(
            values,
            reportSummary.reportSetting.isRepeat,
            profileSettings.timezone
              ? profileSettings.timezone
              : currentTimezone
          )
        );

      navigate(`/reports/${reportSummary.id}/profile`);
    } catch (error: any) {
      console.log("error", error);
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };
  const { reports } = usePermissions();
  useEffect(() => {
    if (!reportSummary.reportSetting.isRepeat && !reportId) {
      setinitialState((pre: any) => {
        return {
          ...pre,
          dateFilter: [
            {
              condition: {
                name: "Equal To ==",
                value: "==",
              },
              operator: "and",
              date: "",
              dateType: {
                value: "observer_date",
                name: "Observer Date",
              },
            },
            {
              condition: {
                name: "Equal To ==",
                value: "==",
              },
              operator: "and",
              date: "",
              dateType: {
                value: "observer_date",
                name: "Observer Date",
              },
            },
          ],
        };
      });
    } else if (reportsId) {
      setinitialState((pre: any) => {
        return {
          ...pre,
          dateFilter: reportSummary.dateFilter.map((date, index) => {
            return {
              condition: date.condition,
              operator: date.operator,
              date: index === 0 ? date.startDate : date.endDate,
              startTime: date.startTime,
              endTime: date.endTime,
              dateType: {
                value: date.dateType,
                name: sentenceCase(date.dateType ?? ""),
              },
            };
          }),
          otherQueryFilter: reportSummary.filters,
        };
      });
    }

    // setinitialState(())
  }, [reportSummary.reportSetting.isRepeat]);
  return (
    <Formik
      initialValues={initialState}
      validationSchema={
        reportSummary.reportSetting.isRepeat
          ? reportFilteringWithRepeatSchema
          : reportFilteringWithOutRepeatSchema
      }
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(FormikProps) => {
        useEffect(() => {
          setAnythingUpdatedThisStep("filtering", FormikProps.dirty);
        }, [FormikProps.dirty]);
        return (
          <Form>
            <div className="mb-4">
              <CustomAccordion
                expandIcon={
                  <CheveronUpIcon height={16} width={16} fill="#2C3236" />
                }
                detailsClassName="mt-[-15px]"
                summaryClassName="YOUR_CLASS_NAME_HERE"
                heading="Time Query Builder"
              >
                <TimeQueryBuilder
                  activeStep={activeStep}
                  isLastStep={isLastStep}
                  steps={steps}
                  reportId={reportId}
                  reportSummary={reportSummary}
                  fetchReportsSummary={fetchReportsSummary}
                  moveToPreviousStep={moveToPreviousStep}
                  moveToNextStep={moveToNextStep}
                  setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
                  {...FormikProps}
                />
              </CustomAccordion>
            </div>
            {reports.canViewColumnsReports && (
              <CustomAccordion
                expandIcon={
                  <CheveronUpIcon height={16} width={16} fill="#2C3236" />
                }
                detailsClassName="mt-[-15px]"
                summaryClassName="YOUR_CLASS_NAME_HERE"
                heading="Other Queries"
              >
                <OtherQueryBuilder
                  activeStep={activeStep}
                  steps={steps}
                  isLastStep={isLastStep}
                  reportId={reportId}
                  reportSummary={reportSummary}
                  fetchReportsSummary={fetchReportsSummary}
                  moveToPreviousStep={moveToPreviousStep}
                  moveToNextStep={moveToNextStep}
                  setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
                  {...FormikProps}
                />
                <SubmissionButtons
                  isValid={FormikProps.isValid}
                  isSubmitting={FormikProps.isSubmitting}
                  handleGoBackBtn={() => moveToPreviousStep()}
                  values={FormikProps.values}
                />
              </CustomAccordion>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

// Helper function to format dates
function formatDate(date: string) {
  return formatTheDates(date, { utc: true });
}

function formatTime(
  date: string,
  time: string | undefined,
  timeZone: string,
  type: "start" | "end"
) {
  if (time) {
    return formatTheTime(
      convertDateAndTimeToUTC(date, time, timeZone),
      TIME_FORMATS.HOURS_MINUTES_SECONDS,
      { utc: false }
    );
  }
  return convertTheStartAndEndTime(date, timeZone, type);
}

function formatFilter(filter: any, index: number, timeZone: string) {
  const baseFormat = {
    condition: filter.condition,
    operator: filter.operator,
    dateType: filter.dateType.value,
  };

  if (index === 0) {
    return {
      ...baseFormat,
      start_date: formatDate(filter.date),
      start_time: formatTime(filter.date, filter.startTime, timeZone, "start"),
    };
  } else {
    return {
      ...baseFormat,
      end_date: formatDate(filter.date),
      end_time: formatTime(filter.date, filter.endTime, timeZone, "end"),
    };
  }
}

function convertDataFormat(
  inputData: any,
  isRepeate: boolean,
  timeZone: string
) {
  const dateFilter = inputData.dateFilter.map((filter: any, index: number) =>
    formatFilter(filter, index, timeZone)
  );

  const formatRepeatedDateFilter = (repeatedDateFilter: any) => {
    return {
      date: formatDate(repeatedDateFilter.date),
      time:
        repeatedDateFilter.periodType.value === "was_between_today_and"
          ? formatTime(
              repeatedDateFilter.date,
              repeatedDateFilter.time,
              timeZone,
              "end"
            )
          : null,

      period: repeatedDateFilter.period,
      dateType: repeatedDateFilter.dateType.value,
      period_duration: repeatedDateFilter.periodDuration,
      period_type: repeatedDateFilter.periodType.value,
    };
  };

  const otherQueryFilter = inputData.otherQueryFilter;
  const repeatedDateFilter = formatRepeatedDateFilter(
    inputData.repeatedDateFilter
  );

  return isRepeate
    ? {
        other_query_filter: otherQueryFilter,
        repeated_date_filter: repeatedDateFilter,
      }
    : { date_filter: dateFilter, other_query_filter: otherQueryFilter };
}

function convertTheStartAndEndTime(date: any, timeZone: string, type: string) {
  const startOfTheDay = getMidnightInLocalTimeZoneToUtc(date, timeZone);
  const endOfTheDay = getEndOfDayInLocalTimeZoneToUtc(date, timeZone);

  return formatTheTime(
    type === "start" ? startOfTheDay : endOfTheDay,
    TIME_FORMATS.HOURS_MINUTES_SECONDS,
    {
      utc: false,
    }
  );
}
