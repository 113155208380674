import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updatePointPickers,
} from "store/surveyJS";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";
import {
  AbleToSelectMultiple,
  ChooseFeatures,
  Description,
  MapCenterPoint,
  Required,
  SelectLayers,
  Title,
} from "./components";
import { panelsName } from "../../panelsTitle";

interface PointPickerSidebarInterface {
  heading?: string;
  blockMultiSelect?: boolean;
  variant?: string;
}

const PointPickerSidebar = ({
  heading,
  blockMultiSelect,
  variant,
}: PointPickerSidebarInterface) => {
  const {
    selectedQuestionElement,
    pointPickers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
  } = useSelector((state: RootState) => state.surveyJS);
  const [currentPointPicker, setCurrentPointPicker] = useState<any>({});

  const dispatch = useDispatch();

  const mapTypePanels = [
    panelsName.HARVEST,
    panelsName.GEOGRAPHICAL_INFORMATION,
  ];

  const selectedQuestionTypes = selectedQuestionElement?.isPanel
    ? selectedQuestionElement.name
    : selectedQuestionElement?.getType();

  // structuring data of default points according to dropdown component
  const defaultLayersSelectedOptions = structuringLayersData(
    currentPointPicker.selectedDefaultLayers,
    "options"
  );
  const defaultLayersOptions = removeMatchedEleFromTwoArrays(
    structuringLayersData(
      currentPointPicker.allGlobalCommunityLayers,
      "options"
    ),
    defaultLayersSelectedOptions,
    "label"
  );

  useEffect(() => {
    if (
      selectedQuestionElement &&
      !mapTypePanels.includes(selectedQuestionTypes)
    ) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentPointPicker]);

  // recognize and set current point picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;
    if (questionId) {
      const ele = pointPickers.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentPointPicker({ ...ele, title, description });
      } else if (
        selectedQuestionTypes === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        const pointpicker =
          variant === "retrieval"
            ? harvestPanelMapQuestions.retrievalPointPicker
            : harvestPanelMapQuestions.struckPointPicker;

        setCurrentPointPicker(pointpicker);
      } else if (
        selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        setCurrentPointPicker(geographicalPanelMapQuestions.pointPicker);
      }
    }
  }, [
    selectedQuestionElement,
    pointPickers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
  ]);

  // handlers
  const handleCoummunityPointOnSelect = (e: any) => {
    const selectedPointId = e.target.value;

    const isPointAlreadyAdded =
      currentPointPicker?.selectedPointsIdToHighlight?.find(
        (pointId: any) => pointId == selectedPointId
      );

    if (!isPointAlreadyAdded && currentPointPicker?.id) {
      // update current shape picker
      const updatedCurrentPointPicker = {
        ...currentPointPicker,
        selectedPointsIdToHighlight: [
          ...currentPointPicker.selectedPointsIdToHighlight,
          selectedPointId,
        ],
      };

      // if point picker belongs to harvest panel
      if (
        selectedQuestionTypes === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedHarvestPanelMapQuestions({
            ...harvestPanelMapQuestions,
            [variant === "retrieval"
              ? "retrievalPointPicker"
              : "struckPointPicker"]: updatedCurrentPointPicker,
          })
        );
      } else if (
        selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedGeographicalPanelMapQuestions({
            ...geographicalPanelMapQuestions,
            pointPicker: updatedCurrentPointPicker,
          })
        );

        return;
      }

      // update current element state into global elements
      updateElementByIdFromArray(
        pointPickers,
        updatedCurrentPointPicker,
        (updatedPointPickers: any) => {
          setCurrentPointPicker(updatedCurrentPointPicker);
          dispatch(updatePointPickers(updatedPointPickers));
        }
      );
    }
  };
  const handleClickOnRemovePoint = (id: any) => {
    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.selectedPointsIdToHighlight =
      updatedCurrentPointPicker.selectedPointsIdToHighlight?.filter(
        (layerId: any) => layerId != id
      );

    // if point picker belongs to harvest panel
    if (
      selectedQuestionTypes === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalPointPicker"
            : "struckPointPicker"]: updatedCurrentPointPicker,
        })
      );
    } else if (
      selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          pointPicker: updatedCurrentPointPicker,
        })
      );

      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.mapCenterPoint = {
      ...updatedCurrentPointPicker.mapCenterPoint,
      doFly: false,
      [name]: value,
    };

    // if point picker belongs to harvest panel
    if (
      selectedQuestionTypes === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalPointPicker"
            : "struckPointPicker"]: updatedCurrentPointPicker,
        })
      );
    } else if (
      selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          pointPicker: updatedCurrentPointPicker,
        })
      );

      return;
    }
    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentPointPicker = { ...currentPointPicker };

    // update current element
    updatedCurrentPointPicker.mapCenterPoint = {
      ...updatedCurrentPointPicker.mapCenterPoint,
      doFly: true,
    };

    // if point picker belongs to harvest panel
    if (
      selectedQuestionTypes === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalPointPicker"
            : "struckPointPicker"]: updatedCurrentPointPicker,
        })
      );
    } else if (
      selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          pointPicker: updatedCurrentPointPicker,
        })
      );

      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleAbleToSelectMultipleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.ableToCheckMultiple = isChecked;

    // if point picker belongs to harvest panel
    if (
      selectedQuestionTypes === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalPointPicker"
            : "struckPointPicker"]: updatedCurrentPointPicker,
        })
      );
    } else if (
      selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          pointPicker: updatedCurrentPointPicker,
        })
      );

      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleDefaultLayersOnSelection = (option: any) => {
    if (
      !JSON.stringify(currentPointPicker.selectedDefaultLayers).includes(
        option.label
      )
    ) {
      // update current element
      const updatedCurrentPointPicker = { ...currentPointPicker };

      updatedCurrentPointPicker.selectedDefaultLayers = [
        ...updatedCurrentPointPicker.selectedDefaultLayers,
        ...structuringLayersData(
          [option],
          "original",
          currentPointPicker.allGlobalCommunityLayers
        ),
      ];

      // if point picker belongs to harvest panel
      if (
        selectedQuestionTypes === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedHarvestPanelMapQuestions({
            ...harvestPanelMapQuestions,
            [variant === "retrieval"
              ? "retrievalPointPicker"
              : "struckPointPicker"]: updatedCurrentPointPicker,
          })
        );
      } else if (
        selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedGeographicalPanelMapQuestions({
            ...geographicalPanelMapQuestions,
            pointPicker: updatedCurrentPointPicker,
          })
        );

        return;
      }

      // update current element state into global elements
      updateElementByIdFromArray(
        pointPickers,
        updatedCurrentPointPicker,
        (updatedPointPickers: any) => {
          setCurrentPointPicker(updatedCurrentPointPicker);
          dispatch(updatePointPickers(updatedPointPickers));
        }
      );
    }
  };
  const handleDefaultLayersOnRemove = (option: any) => {
    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.selectedDefaultLayers =
      currentPointPicker.selectedDefaultLayers.filter(
        (l: any) => l.name !== option.label
      );

    // if point picker belongs to harvest panel
    if (
      selectedQuestionTypes === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalPointPicker"
            : "struckPointPicker"]: updatedCurrentPointPicker,
        })
      );
    } else if (
      selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          pointPicker: updatedCurrentPointPicker,
        })
      );

      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentPointPicker = { ...currentPointPicker };

    updatedCurrentPointPicker.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      pointPickers,
      updatedCurrentPointPicker,
      (updatedPointPickers: any) => {
        setCurrentPointPicker(updatedCurrentPointPicker);
        dispatch(updatePointPickers(updatedPointPickers));
      }
    );
  };

  return (
    <div className="flex flex-col pt-[40px] px-[32px] bg-[#F3F5F7] w-full gap-8">
      {heading && <h1 className="font-semibold">{heading}</h1>}

      {!mapTypePanels.includes(selectedQuestionTypes) && (
        <>
          <Title
            handleTitleOnChange={handleTitleOnChange}
            value={currentPointPicker.title}
          />
          <Description
            handleDescriptionOnChange={handleDescriptionOnChange}
            value={currentPointPicker.description}
          />
          <Required
            handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
            checked={currentPointPicker.isRequired}
          />
        </>
      )}
      <ChooseFeatures
        allCommunityMarkers={currentPointPicker?.allCommunityMarkers ?? []}
        handleCoummunityLayerOnSelect={handleCoummunityPointOnSelect}
        selectedPointsIdToHighlight={
          currentPointPicker?.selectedPointsIdToHighlight ?? []
        }
        handleClickOnRemoveLayer={handleClickOnRemovePoint}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentPointPicker.mapCenterPoint}
      />
      {!blockMultiSelect && (
        <AbleToSelectMultiple
          handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
          ableToCheckMultiple={currentPointPicker.ableToCheckMultiple}
        />
      )}
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      />
    </div>
  );
};

export { PointPickerSidebar };

function structuringLayersData(
  data: any,
  converter: "original" | "options",
  originalData?: any
) {
  let structureData = [];

  if (converter === "options" && data) {
    structureData = data.map((l: any) => ({ value: l.id, label: l.name }));
  } else if (converter === "original" && originalData) {
    structureData = originalData.filter((l: any) =>
      JSON.stringify(data).includes(l.id.toString())
    );
  }

  return structureData;
}
