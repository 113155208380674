import { TableBodyProps } from "../../types";
import { Cell } from "@tanstack/react-table";
import { Column, RowsActions } from "../index";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";

export const TableBody = ({
  table,
  columnVisibility,
  flexRender,
}: TableBodyProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const openProtocolProfile = (row: any) => {
    navigate(`/protocols/${row?.original?.protocol?.id}/profile`);
  };

  return (
    <tbody>
      {table.getRowModel().rows.map((row: any, index: number) => (
        <tr
          key={index}
          className="w-full cursor-pointer Close item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {row.getVisibleCells().map((cell: Cell<any, any>) => (
            <Column
              hoverEffect="item_hover_effect"
              flexRender={flexRender}
              formDetails={() => openProtocolProfile(row)}
              cell={cell}
              columnVisibility={columnVisibility}
            />
          ))}
          {/* <td className="sticky right-0 whitespace-nowrap dark:bg-bgtetriaryz-10 bg-bgWhite item_hover_effect dark:bg-secondaryLight">
            <RowsActions row={row} getNotificationsRecipientsActions={getNotificationsRecipientsActions} />
          </td> */}
        </tr>
      ))}
    </tbody>
  );
};
