import { useEffect, useMemo, useRef, useState } from "react";
import {
  useInitializeCustomControlForLayerListing,
  useInitializeMap,
  useResetMapOnRequest,
} from "./hooks";
import { useSelector } from "react-redux";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { RootState } from "store";
import {
  handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap,
  handlePaintingOfVectorRegionShapeLayersOnMap,
} from "./handlers";
import { useDispatch } from "react-redux";
import separateColorAndOpacity from "utils/separateColorAndOpacity";
import {
  setDrawShapeMode,
  updateChoosedLayersItems,
  updateDoRemoveAllDrawnShapesSignal,
  updateSelectedLayersItems,
} from "store/surveyJS";
import changeShapeMode from "./utils/changeShapMode";
import {
  __deleteAllLocalDrawShapes__,
  __paintShapeByGeoJsonOnMap__,
} from "./workerMehods";
import generateUniqueId from "../../SurveyJs/utils/generateUniqueId";
import { calculateZoomLevel, generatingLayerId } from "./utils";
import { setDrawShapeGeometry } from "store/surveyJS/reducer.actions";
import { IfetchSurveyJsFormDatails } from "store/formDetailsSurveyJsFields";
import { IFormDetails } from "store/formDetailsSurveyJsFields/initialState";
import { FETCH_SURVEYJS_FORM_DETAILS_SUCCESS } from "store/formDetailsSurveyJsFields/action-types";

const LAYER_DEFAULT_COLOR = "#FF0000";
const ZOOMED_LAYER_COLOR = "#FFA500";
const Map = () => {
  const mapContainer = useRef<any>(null);
  const [zoom, setZoom] = useState(3.6);

  const [clickedLayer, setClickedLayer] = useState<any>(null);

  const { geography } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );
  const { formDetails, stateIs, message } = useSelector<
    RootState,
    IfetchSurveyJsFormDatails
  >((state) => state.formSurveyJsDetails);

  const dispatch = useDispatch();

  const isEditMode = !!formDetails?.formFields;

  const {
    selectedLayersItems,
    choosedLayers,
    drawShapeMode,
    doRemoveAllDrawShapes,
  } = geography;

  // initialize the map on first rendering
  const { map, drawRef, isMapFUllyLoaded } = useInitializeMap(
    mapContainer,
    setZoom
  );
  // initialize the custom control for layers listing
  useInitializeCustomControlForLayerListing(map, selectedLayersItems);

  // reset the map on a request
  useResetMapOnRequest(map, drawRef, isMapFUllyLoaded);

  // remove the draw shapes
  useEffect(() => {
    if (
      doRemoveAllDrawShapes &&
      doRemoveAllDrawShapes?.remove &&
      map.current &&
      drawRef
    ) {
      if (doRemoveAllDrawShapes.variant === "local_draw") {
        __deleteAllLocalDrawShapes__(drawRef);
      } else {
        drawRef.deleteAll();
      }

      // dispatch(updateDrawShapeCoordinates(null));
      dispatch(updateDoRemoveAllDrawnShapesSignal(null));
    } else if (doRemoveAllDrawShapes?.remove) {
      dispatch(updateDoRemoveAllDrawnShapesSignal(null));
    }
  }, [doRemoveAllDrawShapes]);

  // handle on change of selected shape
  useEffect(() => {
    changeShapeMode(drawShapeMode ?? "", drawRef, zoom);

    // reset selected shape whenever user switch to other page
    return () => {
      dispatch(setDrawShapeMode(""));
    };
  }, [drawShapeMode]);

  // handle layers on click on the checkbox of individual layer and display it to map
  useEffect(() => {
    if (
      selectedLayersItems &&
      selectedLayersItems.length > 0 &&
      map.current &&
      isMapFUllyLoaded
    ) {
      selectedLayersItems.forEach((item: any) => {
        switch (true) {
          case item.draw && item.itemType == "vector":
            handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap(
              drawRef,
              item
            );
            break;

          case item.itemType === "vector" ||
            item.itemType === "region" ||
            item.itemType === "shape" ||
            item.itemType === "answer":
            const { sourceId, layerId } = generatingLayerId(
              item.itemType,
              item.item.id
            );

            handlePaintingOfVectorRegionShapeLayersOnMap(
              item,
              map.current,
              drawRef,
              item?.item?.color ?? LAYER_DEFAULT_COLOR,
              (geometryCollection: any) => {
                setClickedLayer(geometryCollection);
              }
            );

            if (
              item.status === false &&
              choosedLayers.find((l) => l.source === sourceId)
            ) {
              dispatch(
                updateChoosedLayersItems(
                  choosedLayers.filter(
                    (l) => l.source !== sourceId && l.layer.id === layerId
                  )
                )
              );
            }

            break;
        }
      });
    }
  }, [selectedLayersItems, map.current, isMapFUllyLoaded]);

  useEffect(() => {
    if (
      isEditMode &&
      formDetails.formAreaMode === "select" &&
      map.current &&
      isMapFUllyLoaded
    ) {
      if (
        formDetails?.formArea?.features?.length > 0 &&
        formDetails.formAreaMode === "select"
      ) {
        const item = {
          item: {
            mapData: formDetails?.formArea,
            id: generateUniqueNumberId(),
            isAnswer: true,
            color: LAYER_DEFAULT_COLOR,
          },

          clickable: true,
          itemType: "vector",
          status: true,
        };

        const { sourceId, layerId } = generatingLayerId(
          item.itemType,
          item.item.id
        );
        handlePaintingOfVectorRegionShapeLayersOnMap(
          item,
          map.current,
          drawRef,
          item.item.color ?? LAYER_DEFAULT_COLOR,
          (geometryCollection: any) => {
            setClickedLayer(geometryCollection);
          }
        );

        // item.item.mapData.features = item.item.mapData.features.map(
        //   (f: any) => {
        //     console.log("generateUniqueNumberId()", f);
        //     const uniqueId = generateUniqueId(item.item.id + ".");

        //     const layerId = uniqueId + ".layer";

        //     f.id = generateUniqueNumberId();

        //     return f;
        //   }
        // );

        // console.log(
        //   "...item.item.mapData.features",
        //   ...item.item.mapData.features.map((item: any) => {
        //     console.log("fddg", item);

        //     const updatedItem = { ...item };
        //     updatedItem.source = sourceId;
        //     updatedItem.layer.id = layerId;
        //     updatedItem.layer.source = sourceId;
        //     return item;
        //   })
        // );

        const updatedSelectedItems = item.item.mapData.features.map(
          (item: any) => {
            const updatedItem = { ...item };
            updatedItem.source = sourceId;
            updatedItem.layer.oldLayerId = item.layer.id;
            updatedItem.layer.oldSource = item.source;
            updatedItem.layer.id = layerId;
            updatedItem.layer.source = sourceId;
            return item;
          }
        );
        dispatch(updateChoosedLayersItems([...updatedSelectedItems]));

        dispatch(updateSelectedLayersItems([item]));
      }
    }
  }, [map.current, isMapFUllyLoaded]);

  // handle edit mode of select form area mode
  useEffect(() => {
    // if (isEditMode && formDetails.formAreaMode === "select") {
    //   const item = {
    //     item: {
    //       mapData: formDetails?.formArea,
    //       id: generateUniqueNumberId(),
    //       isAnswer: true,
    //       color:"#FFA500",
    //     },
    //     clickable: true,
    //     itemType: "vector",
    //     status: true,
    //   };
    //   handlePaintingOfVectorRegionShapeLayersOnMap(
    //     item,
    //     map.current,
    //     drawRef,
    //     item.item.color ?? LAYER_DEFAULT_COLOR,
    //     (geometryCollection: any) => {
    //       setClickedLayer(geometryCollection);
    //     }
    //   );
    //   // item.item.mapData.features = item.item.mapData.features.map((f: any) => {
    //   //   const uniqueId = generateUniqueId(item.item.id + ".");
    //   //   const layerId = uniqueId + ".layer";
    //   //   f.id = layerId;
    //   //   return f;
    //   // });
    //   dispatch(updateChoosedLayersItems([...item.item.mapData.features]));
    //   // dispatch(updateSelectedLayersItems([item]));
    // }
  }, []);

  // handle zoom level and fit bounds in edit mode of select form area mode
  useEffect(() => {
    if (
      map.current &&
      isMapFUllyLoaded &&
      isEditMode &&
      formDetails.formAreaMode === "select"
    ) {
      if (formDetails?.formArea?.features?.length > 0) {
        const zoomLevel = calculateZoomLevel(formDetails.formArea, map.current);

        if (zoomLevel) {
          map.current.fitBounds({
            ...zoomLevel.bounds,
            maxZoom: zoomLevel.maxZoom,
          });
        }
      }
    }
  }, [isMapFUllyLoaded]);

  // handle the edit mode of draw form area mode
  useEffect(() => {
    const geojson = formDetails?.formArea;
    const isDrawMode = formDetails?.formAreaMode === "draw";

    if (isMapFUllyLoaded && geojson && isDrawMode) {
      drawRef.set(geojson);
      const zoomLevel = calculateZoomLevel(geojson, map.current);

      if (zoomLevel) {
        map.current.fitBounds({
          ...zoomLevel.bounds,
          maxZoom: zoomLevel.maxZoom,
        });
      }

      dispatch(setDrawShapeGeometry(geojson.features));
    }
  }, [isMapFUllyLoaded]);

  // useEffect(() => {
  //   if (!clickedLayer) return;
  //   if (
  //     formDetails?.formArea?.features.some(
  //       (feature: any) => feature.id === clickedLayer.id
  //     ) &&
  //     clickedLayer.properties.isAnswer
  //   ) {
  //     const updatedFeatureCollection = {
  //       type: "FeatureCollection",
  //       features:
  //         formDetails?.formArea?.features.filter(
  //           (feature: any) => feature.id !== clickedLayer.id
  //         ) ?? [],
  //     };

  //     var source = map.current.getSource(clickedLayer.source);
  //     if (source) {
  //       map.current
  //         .getSource(clickedLayer.source)
  //         .setData(updatedFeatureCollection);
  //     }

  //     const updatedFormDetails: IFormDetails = { ...formDetails };
  //     updatedFormDetails.formArea = updatedFeatureCollection;

  //     dispatch({
  //       type: FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
  //       payload: updatedFormDetails,
  //     });
  //     updateChoosedLayersItems(
  //       choosedLayers.filter((layer: any) => layer.id !== clickedLayer.id)
  //     );
  //   }

  //   if (choosedLayers.some((feature) => feature.id === clickedLayer.id)) {
  //     map.current.setFeatureState(
  //       { source: clickedLayer?.source, id: clickedLayer?.id },
  //       { selected: false }
  //     );
  //     dispatch(
  //       updateChoosedLayersItems(
  //         choosedLayers.filter((layer: any) => layer.id !== clickedLayer.id)
  //       )
  //     );
  //   } else {
  //     dispatch(updateChoosedLayersItems([...choosedLayers, clickedLayer]));
  //   }

  //   setClickedLayer(null);
  // }, [clickedLayer]);

  const { featureExists, updatedFeatures } = useMemo(
    () => ({
      featureExists:
        clickedLayer &&
        formDetails?.formArea?.features?.some(
          (feature: any) => feature.id === clickedLayer.id
        ),
      updatedFeatures:
        clickedLayer &&
        formDetails?.formArea?.features?.filter(
          (feature: any) => feature.id !== clickedLayer.id
        ),
    }),
    [clickedLayer, formDetails]
  );

  useEffect(() => {
    if (!clickedLayer) return;

    const { id, source, properties } = clickedLayer || {};

    const isAnswer = properties?.isAnswer;

    if (featureExists && isAnswer) {
      const updatedFeatureCollection = {
        type: "FeatureCollection",
        features: updatedFeatures,
      };

      const sourceObject = map.current.getSource(source);
      if (sourceObject) {
        sourceObject.setData(updatedFeatureCollection);
      }

      const updatedFormDetails = {
        ...formDetails,
        formArea: updatedFeatureCollection,
      };

      dispatch({
        type: FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
        payload: updatedFormDetails,
      });

      dispatch(
        updateChoosedLayersItems(
          choosedLayers.filter((layer) => layer.id !== id)
        )
      );
      // updateChoosedLayersItems(
      //   updatedFeatures.map((feature: any) => ({ id: feature.id }))
      // );
    } else {
      // Check if layer is already chosen, and update accordingly
      const layerInChoosed = choosedLayers.some((layer) => layer.id === id);
      if (layerInChoosed) {
        map.current.setFeatureState({ source, id }, { selected: false });
        dispatch(
          updateChoosedLayersItems(
            choosedLayers.filter((layer) => layer.id !== id)
          )
        );
      } else {
        dispatch(updateChoosedLayersItems([...choosedLayers, clickedLayer]));
      }
    }

    setClickedLayer(null);
  }, [
    clickedLayer,
    // featureExists,
    // updatedFeatures,
    // choosedLayers,
    // map.current,
    // dispatch,
  ]);

  useEffect(() => {
    if (!isMapFUllyLoaded) return;

    if (choosedLayers.length > 0) {
      choosedLayers?.forEach((item: any) => {
        var source = map.current.getSource(item?.source);
        if (source) {
          map.current.setFeatureState(
            { source: item?.source, id: item?.id },
            { selected: true }
          );
        }
      });
    }
  }, [choosedLayers, isMapFUllyLoaded, selectedLayersItems]);
  // handle states of choosed layers

  // // handle the color of layer during selection in creating region and shape screens
  // useEffect(() => {
  //   if (!choosedLayers || !map.current || !isMapFUllyLoaded) return;

  //   const layers = map.current
  //     .getStyle()
  //     .layers.filter((l: any) => l.id?.includes("layer"));

  //   layers.forEach((l: any) => {
  //     const isLayerExist = map.current.getLayer(l?.id) && !l?.id?.includes("-border")
  //     const layerId = l.id?.split(".")[0];

  //     if (isLayerExist && choosedLayers.find((feature) => feature.id == l.id)) {
  //       map.current.setPaintProperty(
  //         l.id,
  //         "fill-color",
  //         ZOOMED_LAYER_COLOR
  //       );
  //       map.current.setPaintProperty(l.id, "fill-opacity", 1);
  //     }
  //     else if (isLayerExist) {
  //       const layer = selectedLayersItems.find((l: any) => l.item.id == layerId);

  //       const { color, opacity } = separateColorAndOpacity(layer?.item?.color ?? LAYER_DEFAULT_COLOR);

  //       map.current.setPaintProperty(
  //         l.id,
  //         "fill-color",
  //         color
  //       );
  //       map.current.setPaintProperty(l.id, "fill-opacity", opacity);
  //     }
  //   });

  // }, [choosedLayers, selectedLayersItems, isMapFUllyLoaded])

  return (
    <section className="h-[84vh] w-[100%]  relative geography-map ">
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
    </section>
  );
};

export default Map;

function generateUniqueNumberId() {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000);
  const uniqueId = timestamp * 1000 + randomNum;
  return uniqueId;
}

function removeFeature(map: any, id: number) {
  // let dataSource = map.getSource("example-source")._data;
  // let newFeatures = dataSource.features.filter(
  //   (feature: any) => feature.id !== id
  // );
  // map.getSource("example-source").setData({
  //   type: "FeatureCollection",
  //   features: newFeatures,
  // });
}
