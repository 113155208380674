import {
  GET_OBSERVATION_PROFILE,
  GET_OBSERVATION_REVIEWERS,
  RESET_OBSERVATION_PROFILE,
  START_LOADING_OBSERVATION,
  STOP_LOADING_OBSERVATION,
  SWITCH_TO_OBSERVER_TIME_ZONE,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import { Toasts } from "view/components/Toasts";
export const fetchObservationProfileAction =
  (observationId: any) => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_OBSERVATION });
    try {
      const { data } =
        await apiLibrary.Observations.getObservationProfile(observationId);

      dispatch(
        convertKeysToCamelCase({
          type: GET_OBSERVATION_PROFILE,
          payload: data,
        })
      );
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      window.location.href = "/observations/list";
    } finally {
      dispatch({ type: STOP_LOADING_OBSERVATION });
    }
  };

export const fetchObservationReviewersAction =
  (programId: any) => async (dispatch: Dispatch, getState: any) => {
    try {
      const queryParams = {
        id: programId,
        page: 1,
        limit: 1000,
      };

      const { data } = await apiLibrary.Managers.getAllManagers(queryParams);

      dispatch({
        type: GET_OBSERVATION_REVIEWERS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

export const resetObservationProfileAction = () => {
  return {
    type: RESET_OBSERVATION_PROFILE,
  };
};

export const switchToObserverTimeZoneAction = (swith: boolean) => {
  return {
    type: SWITCH_TO_OBSERVER_TIME_ZONE,
    payload: swith,
  };
};
