import React from "react";
import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { sentenceCase } from "change-case";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeNotificationsSummaryModalAction } from "store/modals/reducer.actions";
import Placeholderavatar from "assets/images/Placeholders/avatar-circle.png";
import usePermissions from "hooks/usePermissions";

interface IProps {
  recipients: any;
  recipientsCount: number;
  roles: any;
  programs: any;
  communities: any;
  id: any;
}

export const SummaryRecipients: React.FC<IProps> = ({
  recipients,
  recipientsCount,
  roles,
  programs,
  communities,
  id,
}) => {
  const handleIconClick = (event: any) => {
    event.stopPropagation();
  };
  const { reminders } = usePermissions();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (notificationId: any) => {
    navigate(`/notifications/${notificationId}/recipients`);
    dispatch(closeNotificationsSummaryModalAction());
  };
  return (
    <div className="py-2 border-b border-lineMid">
      <Accordion
        className="dark:bg-secondaryLight "
        style={{ boxShadow: "none" }}
        defaultExpanded
      >
        <AccordionSummary
          className="dark:bg-secondaryLight "
          expandIcon={<CheveronUpIcon height={16} width={16} fill="#2C3236" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="w-full" onClick={handleIconClick}>
            <div className="flex justify-between items-center w-full">
              <p className="text-secondaryMid dark:text-textMain ">
                {recipientsCount} Recipients
              </p>
              {reminders.canViewMembersReminders && (
                <button
                  onClick={() => handleClick(id)}
                  className="flex items-center"
                >
                  <p className="mr-6 text-textLink">See All</p>
                </button>
              )}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="mt-[-15px] dark:bg-secondaryLight">
          <Typography>
            <div>
              <div className="mt-1">
                <p className="text-textMid dark:text-caption  text-sm">
                  Filter by roles
                </p>
              </div>
              <div className="flex flex-wrap gap-[8px] py-2">
                {roles?.map((role: any) => {
                  return (
                    <p className="text-xs text-left bg-primaryExtraLight dark:bg-primary_40 dark:text-textMain break-all py-1 px-2 rounded-lg">
                      {sentenceCase(role?.name)}
                    </p>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="mt-2">
                <p className="text-textMid dark:text-caption  text-sm">
                  Filter by programs
                </p>
              </div>
              <div className="flex flex-wrap gap-[8px] py-2">
                {programs?.map((program: any) => {
                  return (
                    <p className="text-xs text-left bg-primaryExtraLight dark:text-textMain break-all py-1 px-2 rounded-lg">
                      {program?.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="mt-2">
                <p className="text-textMid dark:text-caption  text-sm">
                  Filter by communities
                </p>
              </div>
              <div className="flex flex-wrap gap-[8px] py-2">
                {communities?.map((community: any) => {
                  return (
                    <p className="text-xs text-left bg-primaryExtraLight dark:text-textMain break-all py-1 px-2 rounded-lg">
                      {community?.name}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="mt-4 overflow-y-scroll max-h-[400px]">
              {recipients?.map((recipient: any) => {
                return (
                  <div className="flex items-center py-2">
                    <img
                      src={recipient?.personImage?.thumb || Placeholderavatar}
                      alt=""
                      className="object-cover rounded-full h-[24px] w-[24px]"
                    />

                    <p className="text-sm ml-3 dark:text-caption ">
                      {recipient?.firstName} {recipient?.lastName}
                    </p>
                  </div>
                );
              })}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
