import {
  ADDED_QUESTION_STATE,
  DELETED_QUESTION_ELEMENT,
  SELECTED_QUESTION_ELEMENT,
  UPDATE_DRAW_POLYGON,
  UPDATE_DROP_PIN,
  UPDATE_POINT_PICKER,
  UPDATE_POINT_PICKERS,
  UPDATE_SHAPE_PICKER,
  UPDATE_SHAPE_PICKERS,
  UPDATE_DRAW_POLYGONS,
  UPDATE_DROP_PINS,
  UPDATE_ALL_COMMUNITY_LAYERS,
  UPDATE_CURRENT_QUESTION,
  UPDATE_ALL_COMMUNITY_POINTS,
  ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE,
  ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS_STATE,
  UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS,
  UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
  UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS,
  UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
  UPDATE_SURVEY_ACTIVE_TAB,
  UPDATE_GET_GPS_DATAS,
  DISPLAY_SURVEY_VIEW,
  DISPLAY_GEOGRAPHY_VIEW,
  UPDATE_SELECTED_LAYERS_ITEMS_DATA,
  UPDATE_CHOOSED_LAYERS_ITEMS_DATA,
  SET_FORM_JSON,
  SET_DRAW_SHAPE_MODE,
  UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL,
  SET_DRAW_SHAPE_GEOMETRY,
  DO_RESET_MAP,
  SET_DRAW_REFERENCE,
  SET_MAP,
  SET_FORM_ALL_SELECTED_DEFAULT_LAYERS,
  SET_FORM_ALL_SELECTED_SHAPES,
} from "./action-types";

import initialStateOfSurveyJS, {
  I_InitialStateOfSurveyJS,
} from "./initialState";

export const SurveyJSReducer = (
  state = initialStateOfSurveyJS,
  action: any
) => {
  switch (action.type) {
    case UPDATE_SHAPE_PICKER:
      return {
        ...state,
        shapePicker: action.payload,
      };

    case UPDATE_SHAPE_PICKERS:
      return {
        ...state,
        shapePickers: action.payload,
      };

    case UPDATE_POINT_PICKER:
      return {
        ...state,
        pointPicker: action.payload,
      };
    case UPDATE_POINT_PICKERS:
      return {
        ...state,
        pointPickers: action.payload,
      };

    case UPDATE_DRAW_POLYGON:
      return {
        ...state,
        drawPolygon: action.payload,
      };

    case UPDATE_DRAW_POLYGONS:
      return {
        ...state,
        drawPolygons: action.payload,
      };

    case UPDATE_DROP_PIN:
      return {
        ...state,
        dropPin: action.payload,
      };

    case UPDATE_DROP_PINS:
      return {
        ...state,
        dropPins: action.payload,
      };
    case UPDATE_GET_GPS_DATAS:
      return {
        ...state,
        getGpsDatas: action.payload,
      };

    case DELETED_QUESTION_ELEMENT:
      return {
        ...state,
        deletedQuestionElement: action.payload,
      };

    case SELECTED_QUESTION_ELEMENT:
      return {
        ...state,
        selectedQuestionElement: action.payload,
      };

    case ADDED_QUESTION_STATE:
      return {
        ...state,
        addedQuestionState: action.payload,
      };
    case UPDATE_ALL_COMMUNITY_LAYERS:
      return {
        ...state,
        allCommunityLayers: action.payload,
      };

    case UPDATE_ALL_COMMUNITY_POINTS:
      return {
        ...state,
        allCommunityPoints: action.payload,
      };

    case UPDATE_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      };

    case ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE:
      return {
        ...state,
        harvestPanelMapQuestions: action.payload,
      };

    case ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS_STATE:
      return {
        ...state,
        geographicalPanelMapQuestions: action.payload,
      };

    case UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS:
      return {
        ...state,
        allGlobalCommunityLayers: action.payload,
      };

    case UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS:
      return {
        ...state,
        isAllCommunityLayersFetched: action.payload,
      };

    case UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS:
      return {
        ...state,
        isAllCommunityPointsFetched: action.payload,
      };
    case UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS:
      return {
        ...state,
        isAllGlobalCommunityLayersFetched: action.payload,
      };
    case UPDATE_SURVEY_ACTIVE_TAB:
      return {
        ...state,
        surveyActiveTab: action.payload,
      };
    case DISPLAY_SURVEY_VIEW:
      return {
        ...state,
        displayView: "survey",
      };
    case DISPLAY_GEOGRAPHY_VIEW:
      return {
        ...state,
        displayView: "geography",
      };

    case UPDATE_SELECTED_LAYERS_ITEMS_DATA:
      return {
        ...state,
        geography: {
          ...state.geography,
          selectedLayersItems: action.payload,
        },
      };

    case UPDATE_CHOOSED_LAYERS_ITEMS_DATA:
      return {
        ...state,
        geography: {
          ...state.geography,
          choosedLayers: action.payload,
        },
      };

    case SET_FORM_JSON:
      return {
        ...state,
        formJson: action.payload,
      };

    case SET_DRAW_SHAPE_MODE:
      return {
        ...state,
        geography: {
          ...state.geography,
          drawShapeMode: action.payload,
        },
      };

    case UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL:
      return {
        ...state,
        geography: {
          ...state.geography,
          doRemoveAllDrawShapes: action.payload,
        },
      };

    case SET_DRAW_SHAPE_GEOMETRY:
      return {
        ...state,
        geography: {
          ...state.geography,
          drawShapeGeometry: action.payload,
        },
      };

    case DO_RESET_MAP:
      return {
        ...state,
        geography: {
          ...state.geography,
          doResetMap: action.payload,
        },
      };

    case SET_DRAW_REFERENCE:
      return {
        ...state,
        geography: {
          ...state.geography,
          drawRef: action.payload,
        },
      };

    case SET_MAP:
      return {
        ...state,
        geography: {
          ...state.geography,
          map: action.payload,
        },
      };

    case SET_FORM_ALL_SELECTED_SHAPES:
      return {
        ...state,
        formAllSelectedShapes: action.payload,
      };

    case SET_FORM_ALL_SELECTED_SHAPES:
      return {
        ...state,
        formAllSelectedDefaultLayers: action.payload,
      };

    default:
      return state;
  }
};
