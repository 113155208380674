import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import React from "react";
import {
 useNavigate,
} from 'react-router-dom';
interface BreadcrumbProps {
  items: string[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();
  return (
    <div className="relative flex items-center  align justify-start flex-grow-0 flex-shrink-0 gap-1">
     <button onClick={() => navigate(-1)} className="h-auto w-auto p-0 m-0 outline-none border-none hover:outline-none">
     <ArrowLeftIcon height={22} width={22} />
     </button>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-0.5 pb-1">
              <p className="flex-grow-1 truncuate h-5 overflow-hidden text-overflow-ellipsis flex-shrink-0 text-sm text-center text-secondaryMid dark:text-textMain max-w-3xl">
                {item}
              </p>
            </div>
            {index !== items.length - 1 && (
              <CheveronRightIcon height={22} width={22} />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>    
  );
};

export default Breadcrumb;