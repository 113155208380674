import MapboxDraw from "@mapbox/mapbox-gl-draw";

export interface I_InitialStateOfSurveyJS {
  shapePickers: {
    id: string;
    allCommunityLayers: any;
    selectedLayersIdToHighlight: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
  }[];
  pointPickers: {
    allCommunityMarkers: any;
    selectedPointsIdToHighlight: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
  }[];
  drawPolygons: {
    allCommunityLayers: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
    snapping: boolean;
  }[];
  dropPins: {
    allCommunityLayers: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
    snapping: boolean;
  }[];
  getGpsDatas: {
    mapCenterPoint: any;
    zoomLevel: number;
  }[];
  getGpsData?: {
    mapCenterPoint: any;
    zoomLevel: number;
  };
  shapePicker?: {
    allCommunityLayers?: any;
    selectedLayersIdToHighlight?: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
  };
  pointPicker?: {
    selectedPointsIdToHighlight?: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
  };
  drawPolygon?: {
    allCommunityLayers?: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
    snapping: boolean;
  };
  dropPin: {
    allCommunityLayers?: any;
    mapCenterPoint: any;
    zoomLevel: number;
    ableToCheckMultiple: boolean;
    selectedDefaultLayers: any;
    snapping: boolean;
  };
  deletedQuestionElement?: any;
  selectedQuestionElement?: any;
  addedQuestionState?: any;
  allCommunityLayers: any;
  allCommunityPoints: any[];
  currentQuestion?: any;
  harvestPanelMapQuestions: any;
  geographicalPanelMapQuestions: any;
  allGlobalCommunityLayers: any[];
  isAllCommunityLayersFetched: boolean;
  isAllCommunityPointsFetched: boolean;
  isAllGlobalCommunityLayersFetched: boolean;
  surveyActiveTab: string;
  displayView:"survey" | "geography";
  geography:{
    selectedLayersItems:any[];
    choosedLayers:any[];
    drawShapeMode?:"polygon" | "rectangle" | "circle";
    doRemoveAllDrawShapes?:{variant:"local_draw" | "global_draw",remove?:boolean};
    drawShapeGeometry?:JSON;
    doResetMap?:boolean;
    drawRef?:MapboxDraw;
    map?:mapboxgl.Map;
  },
  formJson:{
    json:JSON | null,
    panelJson:JSON | null,
    status?:"draft" | "published"
  },
  formAllSelectedShapes:any[],
  formAllSelectedDefaultLayers:any[]
}

const initialStateOfSurveyJS: I_InitialStateOfSurveyJS = {
  shapePickers: [],
  pointPickers: [],
  drawPolygons: [],
  dropPins: [],
  getGpsDatas: [],
  shapePicker: {
    zoomLevel: 2,
    mapCenterPoint: {
      longitude: 0,
      latitude: 0,
    },
    ableToCheckMultiple: false,
    selectedDefaultLayers: [],
  },
  pointPicker: {
    zoomLevel: 2,
    mapCenterPoint: {
      longitude: 0,
      latitude: 0,
    },
    ableToCheckMultiple: false,
    selectedDefaultLayers: [],
  },
  drawPolygon: {
    zoomLevel: 2,
    mapCenterPoint: {
      longitude: 0,
      latitude: 0,
    },
    ableToCheckMultiple: false,
    selectedDefaultLayers: [],
    snapping: false,
  },
  dropPin: {
    zoomLevel: 2,
    mapCenterPoint: {
      longitude: 0,
      latitude: 0,
    },
    ableToCheckMultiple: false,
    selectedDefaultLayers: [],
    snapping: false,
  },
  getGpsData: {
    zoomLevel: 2,
    mapCenterPoint: {
      longitude: 0,
      latitude: 0,
    },
  },
  deletedQuestionElement: null,
  selectedQuestionElement: null,
  allCommunityLayers: [],
  allCommunityPoints: [],
  harvestPanelMapQuestions: {},
  geographicalPanelMapQuestions: {},
  allGlobalCommunityLayers: [],
  isAllCommunityLayersFetched: false,
  isAllCommunityPointsFetched: false,
  isAllGlobalCommunityLayersFetched: false,
  surveyActiveTab: "designer",
  displayView:"survey",
  geography:{
    selectedLayersItems:[],
    choosedLayers:[],
  },
  formJson:{
    json:null,
    panelJson:null
  },
  formAllSelectedShapes:[],
  formAllSelectedDefaultLayers:[]
 };

export default initialStateOfSurveyJS;
