import { MenuItem, Select } from "@mui/material";
import DragDotsIcon from "assets/icons/HeroIcons/DragDotsIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";

const ChooseFeatures = ({
  allCommunityMarkers,
  handleCoummunityLayerOnSelect,
  selectedPointsIdToHighlight,
  handleClickOnRemoveLayer,
}: any) => {
  return (
    <div className="w-full">
      <h2 className="mb-2 text-secondaryMidLight">Choose Features</h2>
      {allCommunityMarkers && allCommunityMarkers.length > 0 && (
        <div className="bg">
          <Select
            className="w-full mb-2 bg-bgWhite"
            size="small"
            onChange={handleCoummunityLayerOnSelect}
            displayEmpty
            renderValue={() => "Choose location defined in system"}
          >
            <MenuItem disabled value="">
              Choose location defined in system
            </MenuItem>

            {removeMatchedEleFromTwoArrays(
              allCommunityMarkers,
              allCommunityMarkers.filter((l: any) =>
                selectedPointsIdToHighlight.find((sl: any) => sl == l.id)
              ),
              "id"
            ).map((l: any) => (
              <MenuItem key={l.id.toString()} value={l.id}>
                {l.name}
              </MenuItem>
            ))}
          </Select>
          {selectedPointsIdToHighlight && (
            <ul className="w-full divide-y divide-lineLightExtra">
              {selectedPointsIdToHighlight.map((id: any) => {
                const layer = allCommunityMarkers.find((l: any) => l.id == id);

                return (
                  <li
                    className="flex justify-between bg-bgWhite p-2 "
                    key={"selected#" + id}
                  >
                    {/* <img/> */}
                    <p className="capitalize dark:text-textMain">
                      {layer?.name}
                    </p>
                    <button onClick={() => handleClickOnRemoveLayer(id)}>
                      <TrashIcon fill="#6F747B" />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export { ChooseFeatures };
