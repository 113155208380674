import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeArchiveRestoreDeleteReportsModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { reportsStatusAction } from "store/filters";
import { reportsProfileStatusAction } from "store/filters/reducer.actions";
import { fetchReportsAction } from "store/reports";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface ArchiveRestoreDeleteReportsModalProps {}

export const ArchiveRestoreDeleteReportsModal: React.FC<
  ArchiveRestoreDeleteReportsModalProps
> = () => {
  const { archiveRestoreDeleteReportModal } = useSelector(
    (state: RootState) => state?.modals
  );
  const {reportsId} = useParams();
  
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeArchiveRestoreDeleteReportsModalAction());
  };
  const navigate = useNavigate();
  const reportId = archiveRestoreDeleteReportModal?.data?.reportsId || archiveRestoreDeleteReportModal?.data?.id;
  const reportStatus = archiveRestoreDeleteReportModal?.data?.status;
  const reportPath = archiveRestoreDeleteReportModal?.data?.path;
  const reportVersionId = archiveRestoreDeleteReportModal?.data?.versionId;

  const archiveRestoreDeleteClick = async () => {
    setIsLoading(true);

    try {
      if (reportStatus === "deleted" ) {
        const res = await apiLibrary.Reports.deleteReports(reportId);
        dispatch(fetchReportsAction());
        Toasts.success(res.message);
      } else if (reportStatus === "deletedProfile"){
        const res = await apiLibrary.Reports.deleteReportVersion(reportVersionId);
        dispatch(fetchReportsAction());
        Toasts.success(res.message);
      }
       else {
        const status = reportStatus === "archived" ? "restore" : "archived";
        const res = await apiLibrary.Reports.archiveOrRestoreReport(
          reportId,
          status
        );
        dispatch(fetchReportsAction());
        Toasts.success(res.message);
      }

      if(reportPath === "profile"){
        dispatch(reportsProfileStatusAction(""))
        // navigate(`/reports/list`);
      }else if(reportPath === "profilePage"){
        navigate('reports/list');
      }
      if(reportPath === "directory"){
        navigate(`/reports/${reportsId}/profile`)
      }
      if(reportPath === "directory"){
        navigate(`/reports/${reportsId}/profile`)
      }
      if(reportPath === "directory"){
        navigate(`/reports/${reportsId}/profile`)
      }
       dispatch(
        reportsStatusAction(
          reportStatus === "active" || reportStatus === "past"
            ? "archived"
            : "active"
        )
      );
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={archiveRestoreDeleteReportModal?.isOpen}
      onClose={handleClose}
   aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  {reportStatus === "active" || reportStatus === "past"
                    ? "Archive report"
                    : reportStatus === "archived"
                    ? "Restore report"
                    : reportStatus === "deleted" ? "Delete Report" : "Delete Processed Report"}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              {reportStatus === "active" || reportStatus === "past" ? (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to archive this report?
                </p>
              ) : reportStatus === "archived" ? (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to restore this report?
                </p>
              ) : reportStatus === "deleted" ? (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to delete this report? It means other
                  users won't be able to access it anymore.
                </p>
              ) : reportStatus === "deletedProfile" && (
                <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-textMain">
                  Are you sure you want to delete this? Anyone that had access will no longer have acess to it.
                </p>
              ) }
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text={
                  reportStatus === "active" || reportStatus === "past"
                    ? "Archive Report"
                    : reportStatus === "archived"
                    ? "Restore Report"
                    : reportStatus === "deleted" ? "Delete Report"
                    : reportStatus === "deletedProfile" && "Delete Report"
                }
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={archiveRestoreDeleteClick}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
