import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  closeformDetailsAction,
  openAddFormModalAction,
} from "store/modals/reducer.actions";
import PlaceholderProfileImage from "assets/images/Placeholders/imagePlaceholder.png";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import QuestionMarkCircleIcon from "assets/icons/HeroIcons/QuestionMarkCircleIcon";
import DocumentTextIcon from "assets/icons/HeroIcons/DocumentTextIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import PopOverMenu from "view/components/PopOverMenu";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import { IformDetails } from "store/formDetails";
import useActions from "hooks/useActions";
import Button from "view/components/Button";
import { useNavigate } from "react-router-dom";
import { resetDynamicForms } from "store/forms/reducer.actions";
import usePermissions from "hooks/usePermissions";
import useDocumentTitle from "hooks/useDocumentTitle";
import Head from "view/components/Head";
import { IfetchFormDatails } from "store/formDetails/initialState";
import { resetFormDetailsAction } from "store/formDetails/reducer.actions";
import { PaperClipIcon } from "assets/icons/HeroIcons";

export const FormDetails = () => {
  // Page Title
  const [showFullText, setShowFullText] = useState(false);
  const { formDetailsModal } = useSelector((state: RootState) => state.modals);
  const { formDetails, stateIs, message } = useSelector<
    RootState,
    IfetchFormDatails
  >((state) => state?.formDetails);
  const { dynamicForms } = usePermissions();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModal = () => {
    // setModalOpen(formDetailsModal?.isOpen);
  };
  const handleClose = () => {
    dispatch(closeformDetailsAction());
    dispatch(resetFormDetailsAction());
  };

  const { dynamicFormsActions } = useActions();

  const getDynamicFormsActionss = () => {
    const modalData = {
      name: formDetails.name,
      pictureId: formDetails.pictureId,
      program: formDetails.program,
      imageUrl: formDetails.picture.thumb,
      id: formDetails?.id,
      status: formDetails.status,
      description: formDetails.description,
      programStatus: formDetails.program.programStatus,
      allowAnonObs: formDetails.allowAnonObs,
      path: "profile",
    };
    return dynamicFormsActions(modalData);
  };
  const editFormOrUseAsTemplate = (
    editMode: any = false,
    templateMode: any = false
  ) => {
    const modalData = {
      name: formDetails.name,
      pictureId: formDetails.pictureId,
      program: formDetails.program,
      imageUrl: formDetails.picture.thumb,
      id: formDetails?.id,
      status: formDetails.status,
      description: formDetails.description,
      allowAnonObs: formDetails.allowAnonObs,
      path: "profile",
    };
    dispatch(openAddFormModalAction(modalData, editMode, templateMode));
  };
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const maxChars = 600;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Copy link for anonymous observations");


  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_OBSERVER_APP_URL}form/${formDetails?.dynamicFormToken}`); // Adjust the text as needed
    setTitle("Copied!");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000); // Tooltip disappears after 2 seconds
  };

  const handleHoverTooltip = () => {
    setOpen(true);
    setTitle("Copy link for anonymous observations")
  }

  const handleTooltipClose = () => setOpen(false);

  return (
    <SwipeableDrawer
      anchor="right"
      open={formDetailsModal?.isOpen}
      onClose={handleClose}
      onOpen={openModal}
    >
      <Box sx={{ width: 720 }} role="presentation" onKeyDown={handleClose}>
        <Head
          title={formDetailsModal?.isOpen ? `Forms ${formDetails?.name}` : ""}
        />
        <div
          className="flex flex-col items-start justify-start w-full h-full bg-white dark:bg-secondary"
          style={{
            boxShadow:
              "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
          }}
        >
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 py-4 pl-3 pr-6 bg-white">
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg">
              <button onClick={handleClose}>
                {" "}
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                    className="dark:fill-textMain fill-[#2C3236]"
                  />
                </svg>
              </button>
              <div className="flex items-center justify-start flex-grow">
                <div className="flex justify-start items-center text-secondaryMid flex-grow relative py-0.5">
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-textMain">
                    Form details
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6 px-3 py-4">
              <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                {formDetailsModal?.isOpen && (
                  <img
                    src={
                      formDetails.picture?.original
                        ? formDetails.picture?.original
                        : PlaceholderProfileImage
                    }
                    height={326}
                    width={326}
                    alt={formDetails.name}
                  />
                )}
              </div>
              <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
                <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5 pb-2">
                  <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0">
                    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                      <div className="relative flex flex-col items-start justify-center flex-grow py-1">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-textMid dark:text-textMain">
                          {formDetails.name}
                        </p>
                      </div>
                      <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
                        <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                          {formDetails?.allowAnonObs && formDetails?.dynamicFormToken && formDetails.status !== "draft" &&
                            <Tooltip
                              title={title}
                              open={open}
                              onClose={handleTooltipClose}
                              onMouseEnter={handleHoverTooltip}
                              onMouseLeave={handleTooltipClose}
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#005C89",
                                    "& .MuiTooltip-arrow": {
                                      color: "#005C89",
                                    },
                                  },
                                },
                              }}
                            >
                              <button
                                onClick={handleCopyLink}
                                className="flex items-center justify-start cursor-pointer gap-2 p-3"
                              >
                                <PaperClipIcon fill="#2C3236" />
                              </button>
                            </Tooltip>
                          }
                          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                            {dynamicForms.canEditDynamicForm && formDetails.program.programStatus !== "completed" && (
                              <button
                                onClick={() => editFormOrUseAsTemplate(true)}
                                className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2"
                              >
                                <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                                  <PencilAltIcon fill="#2C3236" />
                                </div>
                              </button>
                            )}
                          </div>
                          {getDynamicFormsActionss().some(
                            (action) => action.isVisible === true
                          ) ? (
                            <PopOverMenu icon={<DotsVerticalIcon />}>
                              <ActionDropDownMenu
                                actions={getDynamicFormsActionss()}
                              />
                            </PopOverMenu>
                          ) : (
                            <DotsVerticalIcon />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-textMid dark:text-caption">
                      Number of observations:
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-textMid dark:text-inputText">
                      {formDetails.observations}
                    </p>
                  </div>
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-textMid dark:text-caption">
                      Status:
                    </p>
                    <div className="flex items-start justify-start flex-grow-0 flex-shrink-0">
                      {formDetails.status && (
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 px-1.5 py-0.5 rounded bg-tertiary/[0.07]">
                          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 py-1">
                            <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-[#2a6d66] capitalize dark:text-inputText">
                              {formDetails.status}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-textMid dark:text-caption">
                      Date Updated:
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-textMid dark:text-inputText">
                      {moment(formDetails.dateUpdated).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left capitalize text-textMid dark:text-caption">
                      Date created:
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-textMid dark:text-inputText">
                      {moment(formDetails.dateAdded).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-[3px]">
                    <p className="flex-grow w-[660px] text-sm text-left text-textMid dark:text-caption">
                      {showFullText
                        ? formDetails.description
                        : formDetails.description.slice(0, maxChars)}
                    </p>
                  </div>
                  {formDetails.description.length > maxChars && (
                    <button
                      onClick={toggleText}
                      className="flex items-center pr-1 text-sm font-semibold underline text-secondaryMid"
                    >
                      {showFullText ? "See less" : "See more"}
                    </button>
                  )}
                </div>
              </div>

              {dynamicForms.canEditDynamicFormFields && (
                <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                  <Button
                    type="button"
                    text="Open Form Builder"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 dark:hover:border-primary dark:hover:text-primary dark:hover:bg-[transparent]"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                      navigate(`/forms/${formDetails.id}/edit`, {
                        state: { programId: formDetails.program.id },
                      });
                    }}
                  />
                </div>
              )}

              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                  <div className="flex flex-col items-center self-stretch justify-start flex-grow overflow-hidden bg-white border rounded-lg border-lineMid">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1.5 p-3">
                      <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 gap-2">
                        <svg
                          width={17}
                          height={16}
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.66669 2C2.93031 2 2.33335 2.59695 2.33335 3.33333V4.17838C1.93482 4.40892 1.66669 4.83981 1.66669 5.33333V10.6667C1.66669 11.1602 1.93482 11.5911 2.33335 11.8216V12.6667C2.33335 13.403 2.93031 14 3.66669 14H5.66669C6.40307 14 7.00002 13.403 7.00002 12.6667V11.8216C7.39855 11.5911 7.66669 11.1602 7.66669 10.6667V10H9.00002V10.6667C9.00002 11.1602 9.26815 11.5911 9.66669 11.8216V12.6667C9.66669 13.403 10.2636 14 11 14H13C13.7364 14 14.3334 13.403 14.3334 12.6667V11.8216C14.7319 11.5911 15 11.1602 15 10.6667V5.33333C15 4.83981 14.7319 4.40892 14.3334 4.17838V3.33333C14.3334 2.59695 13.7364 2 13 2H11C10.2636 2 9.66669 2.59695 9.66669 3.33333V4.17838C9.26815 4.40892 9.00002 4.83981 9.00002 5.33333V6H7.66669V5.33333C7.66669 4.83981 7.39855 4.40892 7.00002 4.17838V3.33333C7.00002 2.59695 6.40307 2 5.66669 2H3.66669ZM7.66669 7.33333V8.66667H9.00002V7.33333H7.66669ZM6.33335 5.33333L6.05721 5.05719C5.80716 4.80714 5.66669 4.468 5.66669 4.11438V3.33333H3.66669V4.11438C3.66669 4.468 3.52621 4.80714 3.27616 5.05719L3.00002 5.33333V10.6667H6.33335V5.33333ZM10.3334 10.6667H13.6667V5.33333L13.3905 5.05719C13.1405 4.80714 13 4.468 13 4.11438V3.33333H11V4.11438C11 4.468 10.8595 4.80714 10.6095 5.05719L10.3334 5.33333V10.6667ZM5.66669 12H3.66669V12.6667H5.66669V12ZM11 12H13V12.6667H11V12Z"
                            className="fill-secondaryMid dark:fill-caption"
                          />
                        </svg>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-inputText">
                          Observations
                        </p>
                      </div>
                      <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
                        <p className="flex-grow-0 flex-shrink-0 text-3xl text-center text-textMid dark:text-inputText">
                          {formDetails.observations}
                        </p>
                        {/* {formDetails.status === "published" && (
                          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-3xl">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.83335 2.66536C5.88784 2.66536 3.50002 5.05318 3.50002 7.9987C3.50002 10.9442 5.88784 13.332 8.83335 13.332C11.7789 13.332 14.1667 10.9442 14.1667 7.9987C14.1667 5.05318 11.7789 2.66536 8.83335 2.66536ZM2.16669 7.9987C2.16669 4.3168 5.15146 1.33203 8.83335 1.33203C12.5153 1.33203 15.5 4.3168 15.5 7.9987C15.5 11.6806 12.5153 14.6654 8.83335 14.6654C5.15146 14.6654 2.16669 11.6806 2.16669 7.9987ZM8.16669 6.94151L7.30476 7.80344C7.04441 8.06379 6.6223 8.06379 6.36195 7.80344C6.1016 7.54309 6.1016 7.12098 6.36195 6.86063L8.36195 4.86063C8.6223 4.60028 9.04441 4.60028 9.30476 4.86063L11.3048 6.86063C11.5651 7.12098 11.5651 7.54309 11.3048 7.80344C11.0444 8.06379 10.6223 8.06379 10.3619 7.80344L9.50002 6.94151L9.50002 10.6654C9.50002 11.0336 9.20154 11.332 8.83335 11.332C8.46516 11.332 8.16669 11.0336 8.16669 10.6654L8.16669 6.94151Z"
                                fill="#008170"
                              />
                            </svg>
                          </div>
                        )} */}
                      </div>
                      {/* {formDetails.status === "published" && (
                        <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow w-[190.67px] text-xs text-center text-textMid">
                            20% more than previous 30 days
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="flex flex-col items-center self-stretch justify-start flex-grow overflow-hidden bg-white border rounded-lg border-lineMid">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1.5 p-3">
                      <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 gap-2">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.99998 3.33203C3.99998 2.22746 4.89541 1.33203 5.99998 1.33203H9.99998C11.1045 1.33203 12 2.22746 12 3.33203V4.1125C12.7768 4.38706 13.3333 5.12789 13.3333 5.9987V6.77917C14.1101 7.05373 14.6666 7.79455 14.6666 8.66536V12.6654C14.6666 13.7699 13.7712 14.6654 12.6666 14.6654H3.33331C2.22874 14.6654 1.33331 13.7699 1.33331 12.6654V8.66536C1.33331 7.79455 1.88985 7.05373 2.66665 6.77917V5.9987C2.66665 5.12789 3.22318 4.38706 3.99998 4.1125V3.33203ZM5.33331 3.9987H10.6666V3.33203C10.6666 2.96384 10.3682 2.66536 9.99998 2.66536H5.99998C5.63179 2.66536 5.33331 2.96384 5.33331 3.33203V3.9987ZM3.99998 6.66536H12V5.9987C12 5.63051 11.7015 5.33203 11.3333 5.33203H4.66665C4.29846 5.33203 3.99998 5.63051 3.99998 5.9987V6.66536ZM3.33331 7.9987C2.96512 7.9987 2.66665 8.29718 2.66665 8.66536V12.6654C2.66665 13.0336 2.96512 13.332 3.33331 13.332H12.6666C13.0348 13.332 13.3333 13.0336 13.3333 12.6654V8.66536C13.3333 8.29718 13.0348 7.9987 12.6666 7.9987H3.33331Z"
                            className="fill-secondaryMid dark:fill-caption"
                          />
                        </svg>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-inputText">
                          Species
                        </p>
                      </div>
                      <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 dark:text-inputText">
                        <p className="flex-grow-0 flex-shrink-0 text-3xl text-center text-textMid dark:text-textMain">
                          {formDetails.species}
                        </p>
                        {/* {formDetails.status === "published" && (
                          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-3xl">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.83335 2.66536C5.88784 2.66536 3.50002 5.05318 3.50002 7.9987C3.50002 10.9442 5.88784 13.332 8.83335 13.332C11.7789 13.332 14.1667 10.9442 14.1667 7.9987C14.1667 5.05318 11.7789 2.66536 8.83335 2.66536ZM2.16669 7.9987C2.16669 4.3168 5.15146 1.33203 8.83335 1.33203C12.5153 1.33203 15.5 4.3168 15.5 7.9987C15.5 11.6806 12.5153 14.6654 8.83335 14.6654C5.15146 14.6654 2.16669 11.6806 2.16669 7.9987ZM8.16669 6.94151L7.30476 7.80344C7.04441 8.06379 6.6223 8.06379 6.36195 7.80344C6.1016 7.54309 6.1016 7.12098 6.36195 6.86063L8.36195 4.86063C8.6223 4.60028 9.04441 4.60028 9.30476 4.86063L11.3048 6.86063C11.5651 7.12098 11.5651 7.54309 11.3048 7.80344C11.0444 8.06379 10.6223 8.06379 10.3619 7.80344L9.50002 6.94151L9.50002 10.6654C9.50002 11.0336 9.20154 11.332 8.83335 11.332C8.46516 11.332 8.16669 11.0336 8.16669 10.6654L8.16669 6.94151Z"
                                fill="#008170"
                              />
                            </svg>
                          </div>
                        )} */}
                      </div>
                      {/* {formDetails.status === "published" && (
                        <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow w-[190.67px] text-xs text-center text-textMid">
                            20% more than previous 30 days
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="flex flex-col items-center self-stretch justify-start flex-grow overflow-hidden bg-white border rounded-lg border-lineMid">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-1.5 p-3">
                      <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 gap-2">
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-inputText">
                          Used as template
                        </p>
                      </div>
                      <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
                        <p className="flex-grow-0 flex-shrink-0 text-3xl text-center text-textMid dark:text-inputText">
                          {formDetails.templateCount}
                        </p>
                        {/* {formDetails.status === "published" && (
                          <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-3xl">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="relative flex-grow-0 flex-shrink-0 w-4 h-4"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.83335 2.66536C5.88784 2.66536 3.50002 5.05318 3.50002 7.9987C3.50002 10.9442 5.88784 13.332 8.83335 13.332C11.7789 13.332 14.1667 10.9442 14.1667 7.9987C14.1667 5.05318 11.7789 2.66536 8.83335 2.66536ZM2.16669 7.9987C2.16669 4.3168 5.15146 1.33203 8.83335 1.33203C12.5153 1.33203 15.5 4.3168 15.5 7.9987C15.5 11.6806 12.5153 14.6654 8.83335 14.6654C5.15146 14.6654 2.16669 11.6806 2.16669 7.9987ZM8.16669 6.94151L7.30476 7.80344C7.04441 8.06379 6.6223 8.06379 6.36195 7.80344C6.1016 7.54309 6.1016 7.12098 6.36195 6.86063L8.36195 4.86063C8.6223 4.60028 9.04441 4.60028 9.30476 4.86063L11.3048 6.86063C11.5651 7.12098 11.5651 7.54309 11.3048 7.80344C11.0444 8.06379 10.6223 8.06379 10.3619 7.80344L9.50002 6.94151L9.50002 10.6654C9.50002 11.0336 9.20154 11.332 8.83335 11.332C8.46516 11.332 8.16669 11.0336 8.16669 10.6654L8.16669 6.94151Z"
                                fill="#008170"
                              />
                            </svg>
                          </div>
                        )} */}
                      </div>
                      {/* {formDetails.status === "published" && (
                        <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow w-[190.67px] text-xs text-center text-textMid">
                            20% more than previous 30 days
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4 pt-[9px] pb-[7px]">
                    <div className="relative flex items-center justify-start flex-grow">
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-caption">
                        Connected program
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg">
                    <div className="relative flex-grow-0 flex-shrink-0 w-6 h-6 rounded-full">
                      {formDetails.program?.profileImage.thumb !== null ? (
                        <img
                          className="absolute w-6 h-6 rounded-full"
                          src={formDetails.program?.profileImage.thumb}
                          alt={formDetails.program?.name}
                        />
                      ) : (
                        <svg
                          width={25}
                          height={24}
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <circle cx="12.3335" cy={12} r={12} fill="#99BED0" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.3336 5.33203C10.4926 5.33203 9.00024 6.82442 9.00024 8.66536C9.00024 10.5063 10.4926 11.9987 12.3336 11.9987C14.1745 11.9987 15.6669 10.5063 15.6669 8.66536C15.6669 6.82442 14.1745 5.33203 12.3336 5.33203ZM10.3336 8.66536C10.3336 7.5608 11.229 6.66536 12.3336 6.66536C13.4381 6.66536 14.3336 7.56079 14.3336 8.66536C14.3336 9.76993 13.4381 10.6654 12.3336 10.6654C11.229 10.6654 10.3336 9.76993 10.3336 8.66536Z"
                            fill="white"
                          />
                          <path
                            d="M8.33358 17.9987C8.33358 15.7896 10.1244 13.9987 12.3336 13.9987C14.5427 13.9987 16.3336 15.7896 16.3336 17.9987C16.3336 18.3669 16.6321 18.6654 17.0002 18.6654C17.3684 18.6654 17.6669 18.3669 17.6669 17.9987C17.6669 15.0532 15.2791 12.6654 12.3336 12.6654C9.38806 12.6654 7.00024 15.0532 7.00024 17.9987C7.00024 18.3669 7.29872 18.6654 7.66691 18.6654C8.0351 18.6654 8.33358 18.3669 8.33358 17.9987Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="flex items-center justify-start flex-grow">
                      <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-inputText">
                        {formDetails.program?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4 pt-[9px] pb-[7px]">
                    <div className="relative flex items-center justify-start flex-grow">
                      <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-caption">
                        Creator
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg">
                    <div className="flex-grow-0 flex-shrink-0 w-6 h-6 rounded-full ">
                      {formDetails.createdBy?.personImage.thumb !== null ? (
                        <img
                          className="rounded-full "
                          src={formDetails.createdBy?.personImage.thumb}
                          alt={formDetails.createdBy.name}
                        />
                      ) : (
                        <svg
                          width={25}
                          height={24}
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <circle cx="12.3335" cy={12} r={12} fill="#99BED0" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.3336 5.33203C10.4926 5.33203 9.00024 6.82442 9.00024 8.66536C9.00024 10.5063 10.4926 11.9987 12.3336 11.9987C14.1745 11.9987 15.6669 10.5063 15.6669 8.66536C15.6669 6.82442 14.1745 5.33203 12.3336 5.33203ZM10.3336 8.66536C10.3336 7.5608 11.229 6.66536 12.3336 6.66536C13.4381 6.66536 14.3336 7.56079 14.3336 8.66536C14.3336 9.76993 13.4381 10.6654 12.3336 10.6654C11.229 10.6654 10.3336 9.76993 10.3336 8.66536Z"
                            fill="white"
                          />
                          <path
                            d="M8.33358 17.9987C8.33358 15.7896 10.1244 13.9987 12.3336 13.9987C14.5427 13.9987 16.3336 15.7896 16.3336 17.9987C16.3336 18.3669 16.6321 18.6654 17.0002 18.6654C17.3684 18.6654 17.6669 18.3669 17.6669 17.9987C17.6669 15.0532 15.2791 12.6654 12.3336 12.6654C9.38806 12.6654 7.00024 15.0532 7.00024 17.9987C7.00024 18.3669 7.29872 18.6654 7.66691 18.6654C8.0351 18.6654 8.33358 18.3669 8.33358 17.9987Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="flex items-center justify-start flex-grow">
                      <div className="relative flex flex-col items-start justify-center flex-grow">
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center capitalize text-textMid dark:text-inputText">
                          {formDetails.createdBy?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-4 pt-[9px] pb-[7px]">
                    <div className="relative flex items-center justify-start flex-grow capitalize dark:text-caption">
                      <p>Help info</p>
                    </div>
                  </div>

                  <Tooltip
                    placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                    // style={{p}}
                    title={`${formDetails.id === null
                      ? "No Protocol Attached With This Form"
                      : ""
                      }`}
                    arrow
                  >
                    <button
                      type="button"
                      disabled={formDetails.protocolId === null}
                      onClick={() => {
                        navigate(
                          `/protocols/${formDetails.protocolId}/profile`
                        );
                        handleClose();
                      }}
                      className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg disabled:cursor-not-allowed hover:bg-bgBluish_2"
                    >
                      <QuestionMarkCircleIcon
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="#2C3236"
                      />

                      <div className="flex items-center justify-start flex-grow">
                        <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-inputText">
                          Protocol{" "}
                          {formDetails.protocolId === null &&
                            "(No Protocol Attached)"}
                        </div>
                      </div>
                    </button>
                  </Tooltip>

                  <Tooltip
                    // placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                    // style={{p}}
                    title={`${formDetails.id === null ? "No Data Against This Id" : ""
                      }`}
                    arrow
                  >
                    <button
                      className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg hover:bg-bgBluish_2"
                      disabled={formDetails.program.id === null}
                      onClick={() => {
                        formDetails.id === null
                          ? ""
                          : navigate(
                            `/programs/${formDetails.program?.id}/dataAccessPolicy/${formDetails.id}`
                          );
                        handleClose();
                      }}
                    >
                      <DocumentTextIcon
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="#2C3236"
                      />

                      <div className="flex items-center justify-start flex-grow">
                        <div className="flex justify-start items-center flex-grow relative py-0.5 capitalize dark:text-inputText">
                          Program data access policy
                        </div>
                      </div>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </SwipeableDrawer>
  );
};
