import { useFormikContext } from "formik";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import CustomCheckbox from "view/components/CheckBox";
import { IReportSettingsFormValues } from "../..";
import InputField from "view/components/InputField";
import { TIME_FORMATS } from "constants/timeFormats";
import { formatTheTime } from "utils/formatTheTime";

export const DateTimePicker: React.FC = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<IReportSettingsFormValues>();

  const formatTime = (time: string) => {
    return moment(time, TIME_FORMATS.HOURS_MINUTES).format(
      TIME_FORMATS.FULL_TIME
    );
  };

  const currentDate = moment().format(DATE_FORMATS.DEFAULT);
  const isToday = moment(values.startRunDate).isSame(currentDate, "day");
  console.log("errors.startRunDate", errors.startRunDate);
  return (
    <>
      <div className="flex items-center w-full gap-4 mt-2">
        <div className="w-[360px] mt-[3px]">
          <InputField
            // height={38}
            label=""
            type="date"
            name="startRunDate"
            onChange={(values: any) => {
              setFieldValue("occuranceAccordingToMonth", {
                dayOfWeek: null,
                weekOfMonth: null,
                dayOfMonth: null,
                type: null,
              });
              setFieldValue("endOnDate", null);
              setFieldValue("startRunDate", values);
            }}
            onBlur={handleBlur}
            value={values.startRunDate}
            error={errors.startRunDate}
            touched={touched.startRunDate}
            className={"w-full py-[1px]"}
            inputClassName="uppercase"
            min={currentDate}
          />
        </div>

        <div className="w-[360px]">
          <TextInput
            height={38}
            label=""
            type="time"
            name="startRunTime"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const formattedTime = formatTheTime(
                event.target.value,
                TIME_FORMATS.FULL_TIME,
                { utc: false }
              );
              setFieldValue("startRunTime", formattedTime);
            }}
            handleBlur={handleBlur}
            value={values.startRunTime}
            error={errors.startRunTime}
            touched={touched.startRunTime}
            inputClassName="uppercase"
            // min={isToday && moment().format(TIME_FORMATS.HOURS_MINUTES)}
          />
        </div>
      </div>
      <div className="mt-2 mb-3">
        <CustomCheckbox
          name="isRepeat"
          checked={values.isRepeat}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue("isRepeat", event.target.checked);
          }}
          label={
            <p className="text-secondaryMid font-Overpass dark:text-textMain">
              Repeat
            </p>
          }
          labelPlacement="end"
        />
      </div>
    </>
  );
};
