import { useRef,useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useCreateMarkers, useInitializeMap } from "./hooks";
import { IProgram } from "store/programProfile/initialState";

const PrograpProfileMap = () => {
  const mapContainer = useRef<null | any>(null);

  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );

  useEffect(()=>{
    if(mapContainer.current){

    }
  },[mapContainer])

  useEffect(()=>{
    console.log("program profile updated: ",programProfile)
  },[programProfile])
  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(mapContainer);

  // creating markers for individual community
  useCreateMarkers(programProfile.observations, map, isMapFullyLoaded,programProfile.mapType);

  return (
    <section className="bg-primaryDark  h-full w-[100%] relative ">
      <div ref={mapContainer} className="w-full h-full"></div>
    </section>
  );
};

export { PrograpProfileMap };
