import PlusCircleIcon from "assets/icons/HeroIcons/PlusCircleIcon";
import React from "react";
import ListImagePlaceholder from "assets/placeholders/listImageIcon";
import Placeholderavatar from "assets/images/Placeholders/avatar-circle.png";

interface UserProps {
  userData: any;
  setSelectedUser: any;
  addAdmin: any;
  // handleClick?: any;
}

export const Users: React.FC<UserProps> = ({
  userData,
  setSelectedUser,
  addAdmin,
}) => {
  return (
    <div className="">
      <div className="flex justify-between items-center relative gap-4 px-3 py-2 hover:bg-primary/[0.2] rounded">
        <div
          // onClick={() => setSelectedUser(userData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
          <img
            src={
              userData?.profileImage?.file?.thumb
                ? userData?.profileImage?.file?.thumb
                : Placeholderavatar
            }
            alt=""
            height="32px"
            width="32px"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <p className="ml-3 break-words w-[540px] dark:text-textMain">{`${userData.name}`}</p>
        </div>
        <button type="button" onClick={() => addAdmin(userData)}>
          <PlusCircleIcon />
        </button>
      </div>
    </div>
  );
};
