import { Dispatch } from "redux";
import {
  ERROR_LOADING_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  RESET_DATA,
  START_LOADING_NOTIFICATIONS,
  STOP_LOADING_NOTIFICATIONS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchNotificationsAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_NOTIFICATIONS });
    // dispatch(loadingState(true))
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.notificationsFilter
      );
      const { data } =
        await apiLibrary.Notifications.getAllNotifications(queryString);
      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: data,
      });
      console.log(data,'data notifications')
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);

      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the notifications:", error);
      dispatch({ type: ERROR_LOADING_NOTIFICATIONS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_NOTIFICATIONS });
      // dispatch(loadingState(false))
    }
  };
