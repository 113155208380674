import useDateFormatter from "hooks/formatDateWithPattern";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "formName"
  ) {
    return (
      <>
        <div className="flex justify-start items-center self-stretch flex-grow relative gap-3 px-2 py-2.5 ">
          {/* <div className=""> */}
          {cellValue?.picture.thumb ? (
            <img
              src={cellValue?.picture.thumb}
              alt=""
              height="100"
              width="100"
              className="object-cover Img_user_Data"
              style={{ flexShrink: 0 }}
            />
          ) : (
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative w-[35px] h-[35px] rounded-full"
              preserveAspectRatio="xMidYMid meet"
            >
              <rect width={32} height={32} fill="#DCDEE0" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 9L14 15.5H9V16.5H14L9 23H23L18 16.5H23V15.5H18L23 9H9ZM18 15.5V16.5H14V15.5H18ZM11.0309 10L14.4924 14.5H17.5076L20.9691 10H11.0309ZM14.4924 17.5L11.0309 22H20.9691L17.5076 17.5H14.4924Z"
                fill="#202123"
                fill-opacity="0.25"
              />
            </svg>
          )}
          {/* </div> */}
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[182.67px] text-sm text-left text-textMid">
              {cellValue?.formName}
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    (columnDef.id === "createdBy" || columnDef.id === "archivedBy") &&
    cellValue.id !== null
  ) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 pr-2 py-3 ">
          {cellValue?.personImage?.thumb ? (
            <img
              src={cellValue?.personImage?.thumb}
              alt=""
              height="100"
              width="100"
              className="Img_user_Data"
              style={{ flexShrink: 0 }}
            />
          ) : (
            <svg
              width={25}
              height={24}
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative w-[35px] h-[35px] rounded-full"
              preserveAspectRatio="xMidYMid meet"
            >
              <circle cx="12.3335" cy={12} r={12} fill="#99BED0" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3336 5.33203C10.4926 5.33203 9.00024 6.82442 9.00024 8.66536C9.00024 10.5063 10.4926 11.9987 12.3336 11.9987C14.1745 11.9987 15.6669 10.5063 15.6669 8.66536C15.6669 6.82442 14.1745 5.33203 12.3336 5.33203ZM10.3336 8.66536C10.3336 7.5608 11.229 6.66536 12.3336 6.66536C13.4381 6.66536 14.3336 7.56079 14.3336 8.66536C14.3336 9.76993 13.4381 10.6654 12.3336 10.6654C11.229 10.6654 10.3336 9.76993 10.3336 8.66536Z"
                fill="white"
              />
              <path
                d="M8.33358 17.9987C8.33358 15.7896 10.1244 13.9987 12.3336 13.9987C14.5427 13.9987 16.3336 15.7896 16.3336 17.9987C16.3336 18.3669 16.6321 18.6654 17.0002 18.6654C17.3684 18.6654 17.6669 18.3669 17.6669 17.9987C17.6669 15.0532 15.2791 12.6654 12.3336 12.6654C9.38806 12.6654 7.00024 15.0532 7.00024 17.9987C7.00024 18.3669 7.29872 18.6654 7.66691 18.6654C8.0351 18.6654 8.33358 18.3669 8.33358 17.9987Z"
                fill="white"
              />
            </svg>
          )}
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className=" w-[182.67px] text-sm text-left text-textMid">
              {cellValue?.name}
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    cellValue !== null &&
    (columnDef.id === "dateAdded" ||
      columnDef.id === "dateUpdated" ||
      columnDef.id === "dateArchived")
  ) {
    return (
      <div className="relative flex items-center justify-start flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid dark:text-textMain">
          {formatDate(cellValue)}
        </p>
      </div>
    );
  } else if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    (columnDef.id === "programName" || columnDef.id === "protocol")
  ) {
    return (
      <div className="flex justify-start items-center self-stretch flex-grow gap-3 px-2 py-2.5 ">
        <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
          <p
            className={`self-stretch flex-grow-0 flex-shrink-0 w-[226.67px] text-sm text-left capitalize break-words ${
              columnDef.id === "protocol" ? "text-primary" : "text-textMid"
            }`}
          >
            {cellValue.name !== null && ellipsize(`${cellValue.name}`, 50)}
          </p>
        </div>
      </div>
    );
  } else if (typeof cellValue !== "object" && cellValue !== null) {
    return (
      <div className="relative flex items-center justify-center flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid dark:text-textMain">{cellValue}</p>
      </div>
    );
  } else {
    return null;
  }
};
