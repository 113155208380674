import mapboxgl from "mapbox-gl";
import separateColorAndOpacity from "utils/separateColorAndOpacity";

function __paintShapeByGeoJsonOnMap__(
  map: any,
  draw: any,
  sourceId: string,
  layerId: string,
  geoJson: any,
  defaultColor: any
) {
  if (!map || !sourceId || !layerId || !geoJson) {
    return;
  }
  // adding geojson source
  map.addSource(sourceId, {
    type: "geojson",
    data: geoJson,
  });

  const isGeoJsonHasLineString = geoJson && geoJson?.features?.find(
    (f: any) =>
      f.geometry.type === "LineString" || f.geometry.type === "MultiLineString"
  );

  // const {color,opacity} = separateColorAndOpacity(defaultColor);
 

  // // design and register the added geojson source layer
  map.addLayer({
    id: layerId,
    type: isGeoJsonHasLineString ? "line" : "fill",
    source: sourceId,
    layout: {},
    paint: {
      [isGeoJsonHasLineString ? "line-color" : "fill-color"]: defaultColor,
      [isGeoJsonHasLineString ? "line-opacity" : "fill-opacity"]: 0.6,
    },
  });

  if (!isGeoJsonHasLineString) {
    map.addLayer({
      id: `${layerId}-border`,
      type: "line",
      source: sourceId,
      layout: {},
      paint: {
        "line-color": "#000000", 
        "line-width": 1, 
      },
    });
  }
}

export { __paintShapeByGeoJsonOnMap__ };
