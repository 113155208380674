

import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
    fill?: string;
    isActive?: boolean;
}

export const ExpandAllIcon = ({
    fill = "#6F747B",
    isActive,
    ...restProps
}: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 7 10" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.12814 3.88749C-0.0427133 3.71663 -0.0427133 3.43962 0.12814 3.26877L3.19062 0.206294C3.27266 0.124248 3.38394 0.0781549 3.49997 0.0781549C3.616 0.0781544 3.72728 0.124248 3.80933 0.206294L6.87181 3.26877C7.04266 3.43962 7.04266 3.71663 6.87181 3.88748C6.70095 4.05834 6.42395 4.05834 6.25309 3.88748L3.49997 1.13437L0.746853 3.88749C0.576 4.05834 0.298993 4.05834 0.12814 3.88749Z" fill="#005C89" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.87186 6.11251C7.04271 6.28337 7.04271 6.56038 6.87186 6.73123L3.80938 9.79371C3.72734 9.87575 3.61606 9.92184 3.50003 9.92185C3.384 9.92185 3.27272 9.87575 3.19067 9.79371L0.128194 6.73123C-0.0426598 6.56038 -0.0426598 6.28337 0.128194 6.11252C0.299047 5.94166 0.576055 5.94166 0.746907 6.11252L3.50003 8.86563L6.25315 6.11251C6.424 5.94166 6.70101 5.94166 6.87186 6.11251Z" fill="#005C89" />
    </svg>

);
export default ExpandAllIcon;
