// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading } from "./components";

// Store Utils
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CreateSurveyBtn } from "./components/CreateSurveyBtn";
import { openAddSurveyFormModal } from "store/modals";
import usePermissions from "hooks/usePermissions";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { RootState } from "store";
import { useEffect, useState } from "react";
import apiLibrary from "services/api";
import { pollsAndSurveysResetFiltersAction } from "store/filters";
import { Toasts } from "view/components/Toasts";

// const generateDynamicBreadcrumbLinks = () => {
//   switch (true) {
//     default:
//       return [{ path: `/polls-and-surveys/list`, label: `Polls & Surveys` }];
//   }
// };

const generateDynamicBreadcrumbLinks = (
  programId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/polls-and-surveys`,
          label: `Polls & Surveys`,
        },
      ];

    default:
      return [{ path: `/polls-and-surveys/list`, label: `Polls & Surveys` }];
  }
};

export const Header = () => {
  const { surveyAndPolls } = usePermissions();

  const { pollsAndSurveysFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const navigate = useNavigate();

  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const { programId, communityId } = useParams<{
    programId: string;
    communityId: string;
  }>();
  const pollsAndSurveyLinks = generateDynamicBreadcrumbLinks(
    programId ?? "",

    BreadcrumbData?.name ?? "",
    pollsAndSurveysFilter.status
  );

  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    }

    return () => {
      dispatch(pollsAndSurveysResetFiltersAction());
    };
  }, [programId]);

  const handleCreateSurvey = () => {
    if (programId) {
      dispatch(
        openAddSurveyFormModal({
          description: "",
          name: "",
          programId: {
            label: BreadcrumbData?.name ?? null,
            value: BreadcrumbData?.id ?? null,
          },
        })
      );
    } else {
      dispatch(openAddSurveyFormModal(null));
    }
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={pollsAndSurveyLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            {/* <PageHeading name="Polls & Surveys" /> */}
            <PageHeading
              programId={BreadcrumbData.id ? BreadcrumbData.id : ""}
              name={BreadcrumbData?.name}
              parentName={
                programId ? "Program" : communityId ? "Community" : ""
              }
            />

            {surveyAndPolls.canCreateSurveyAndPolls && (
              <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
                <CreateSurveyBtn
                  handleCreateSurvey={handleCreateSurvey}
                  label={"Create Survey"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
