import React from "react";
import ISNLogo from "assets/logo/ISNLogo";

const FormHeader = () => {
  return (
    <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 gap-3 pt-[62px] pb-[60px] xl:pt-[72px] xl:pb-[70px] bg-white">
      <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 lg:w-[310px] xl:w-[330px] relative gap-3">
        <ISNLogo width={42} height={42}/>
        <p className="flex-grow text-lg font-bold text-primaryDark dark:text-primary">
          Indigenous Sentinels Network
        </p>
      </div>
    </div>
  );
};

export default FormHeader;
