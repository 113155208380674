import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DirectModeOverride,
  DragCircleMode,
  DrawRectangleMode,
  SimpleSelectMode,
  SnapLineMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from "Components/Geography/MapBox/lib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetMap,
  updateDrawShapeCoordinates,
  updateSelectedItemToZoom,
} from "store/geography";

import mapboxgl from "mapbox-gl";
import CustomControlForLayersListing from "Components/Geography/MapBox/features/CustomControlForLayersListing";
import customControlForSnapping from "Components/Geography/MapBox/features/CustomControlForSnapping";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useInitializeMap = (
  mapContainer: any,
  lng: number | null,
  lat: number | null,
  zoom: any,
  setZoom: any,
  communityCenterpoint: any
) => {
  const map = useRef<any>(null);
  const [drawRef, setDrawRef] = useState<any>(null);
  const [isMapFUllyLoaded, setIsMapFullyLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (map.current) return; // initialize map only once

    (async () => {
      if (!mapContainer.current) return;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_MAPBOX_STYLE_URL,
        center: [lng ?? -156.44636531857395, lat ?? 62.40265300551266],
        zoom: 2,
        pitch: 0,
        bearing: 0,
        renderWorldCopies: false,
        minZoom: 0.999,
        // @ts-ignore
        projection: {
          name: "mercator",
        },
      });

      mapContainer.current.style.width = "100%";
      // initializing all controls of drawing shapes
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes,
          draw_point: SnapPointMode,
          draw_polygon: SnapPolygonMode,
          draw_circle: CircleMode,
          direct_select: DirectModeOverride,
          drag_circle: DragCircleMode,
          simple_select: SimpleSelectMode,
          draw_line_string: SnapLineMode,
          draw_rectangle: DrawRectangleMode,
        },
        // Styling guides
        styles: SnapModeDrawStyles,
        userProperties: true,
        // Config snapping features
        // @ts-ignore
        snap: false,
        snapOptions: {
          snapPx: 15, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
        },
        guides: false,
      });

      setDrawRef(draw);

      map.current.addControl(draw);

      // adding zoom and navigation controls
      const nav = new mapboxgl.NavigationControl();
      map.current.addControl(nav, "bottom-right");

      // adding full screen control
      map.current.addControl(new mapboxgl.FullscreenControl());

      // adding custom control for layers listing
      const customLayerListingControl = new CustomControlForLayersListing();
      map.current.addControl(customLayerListingControl, "top-right");

      // adding custom control for snapping
      const customSnappingControl = new customControlForSnapping();
      map.current.addControl(customSnappingControl);

      map.current.on("load", () => setIsMapFullyLoaded(true));
      map.current.on("draw.create", updateGeoJsonData);
      map.current.on("draw.delete", updateGeoJsonData);
      map.current.on("draw.update", updateGeoJsonData);

      map.current.on("move", () => {
        // setLng(map.current.getCenter().lng );
        // setLat(map.current.getCenter().lat );
        setZoom(map.current.getZoom().toFixed(2));
      });

      // setting up and updating geojson
      function updateGeoJsonData() {
        const data = draw.getAll();

        if (data.features.length > 0) {
          // removing unnecessary shape items added by map
          const features = data.features.filter((f: any) => {
            if (
              f.id !== "VERTICAL_GUIDE" &&
              f.id !== "HORIZONTAL_GUIDE" &&
              !f.id.includes("feature")
            ) {
              return true;
            }
            return false;
          });

          if (features[0]?.geometry?.type === "Point") {
            dispatch(
              updateDrawShapeCoordinates(features[0].geometry.coordinates)
            );
          } else {
            dispatch(updateDrawShapeCoordinates(features));
          }
        }
      }
    })();

    return () => {
      //reset selected layers item to zoom on map
      dispatch(updateSelectedItemToZoom(null));
      // reset selected layers item to display on map
      dispatch(resetMap(true));
    };
  }, []);

  useEffect(() => {
    if (map.current && communityCenterpoint) {
      map.current.flyTo({
        center: communityCenterpoint
          ? communityCenterpoint
          : [-156.44636531857395, 62.40265300551266],
      });
    }
  }, [communityCenterpoint]);

  return { map, drawRef, isMapFUllyLoaded };
};

export { useInitializeMap };
