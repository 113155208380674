import parseId from "../utils/parseId";
import splitCamelCaseToString from "../utils/splitCamelCaseToString";
import { v4 as uuidv4 } from "uuid";

function convertJsonOfDropPin(question: any, data: any) {
  const {
    mapCenterPoint,
    selectedDefaultLayers,
    id,
    ableToCheckMultiple,
    enableSnapping,
    title,
    description,
    isRequired,
    zoomLevel
  } = data;

  const parseIdToNumber = (id: any) => Number(parseId(id, "-"));

  const selectedLayers = selectedDefaultLayers.map((layer: any) => ({
    ...layer,
    id: parseIdToNumber(layer.id),
  }));

  const structuredData = {
    id,
    name: question.name,
    title: title ?? splitCamelCaseToString(question.name),
    type: question.type,
    description,
    isRequired,
    center_point: [mapCenterPoint.longitude, mapCenterPoint.latitude],
    drop_multiple: ableToCheckMultiple,
    enable_snapping: enableSnapping,
    selected_layers: selectedLayers,
    elementId: uuidv4(),
    zoomLevel:zoomLevel,
    selected_layers_ids:selectedLayers.map((l:any)=>l.id)
  };

  return structuredData;
}

export { convertJsonOfDropPin };
