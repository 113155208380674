import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export const useInitializeMap = (mapContainer: any) => {
  const [map, setMap] = useState<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);

  useEffect(() => {
    if (map || !mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-145.6433003, 65.0710178],
      zoom: 2,
      minZoom: 0.999,
      renderWorldCopies: false,
      // @ts-ignore
      projection: {
        name: "mercator",
      },
    });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    initializeMap.addControl(nav, "bottom-right");

    initializeMap.addControl(new mapboxgl.FullscreenControl());

    // initializeMap.getCanvas().style.cursor = 'zoom-in';

    initializeMap.on("load", () => {
      setIsMapFullyLoaded(true);
    });

    setMap(initializeMap);

    return () => {
      setIsMapFullyLoaded(false);
    };
  }, []);

  return { map, isMapFullyLoaded };
};

function convertToFeatureCollection(geometryCollections: any) {
  const filteredCollections = geometryCollections.filter(
    (collection: any) => collection !== null
  );

  const features = filteredCollections.flatMap((collection: any) =>
    collection.geometries.map((geometry: any) => ({
      type: "Feature",
      geometry: {
        type: geometry.type,
        coordinates: geometry.coordinates,
      },
      properties: {},
    }))
  );

  const featureCollection = {
    type: "FeatureCollection",
    features: features,
  };

  return featureCollection;
}
