import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export const useInitializeMap = (mapContainer: any, communityLocation: any) => {
  const [map, setMap] = useState<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);

  useEffect(() => {
    if (map || !mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-156.44636531857395, 62.40265300551266],
      zoom: 2,
      renderWorldCopies: false,
      minZoom: 0.999,
      // @ts-ignore
      projection: {
        name: "mercator",
      },
    });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    initializeMap.addControl(nav, "bottom-right");

    initializeMap.addControl(new mapboxgl.FullscreenControl());

    initializeMap.on("load", () => setIsMapFullyLoaded(true));

    initializeMap.getCanvas().style.cursor = 'zoom-in';
  
    setMap(initializeMap);

    return () => {
      setIsMapFullyLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (map && communityLocation) {
      map.flyTo({
        center: communityLocation.coordinates
          ? communityLocation.coordinates
          : [-156.44636531857395, 62.40265300551266],
      });
    }
  }, [communityLocation]);

  return { map, isMapFullyLoaded };
};
