import { apiClient } from "../config";

async function getAllCommunities(search: any,page:number) {
  const res = await apiClient.get(`/admin/organizations/communities`, {
    params: {
      searchString: search,
     page:page
    },
  });
  return res.data;
}

export default getAllCommunities;
