// Head.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

type HeadProps = {
    title: string;
    description?: string;
};

const Head: React.FC<HeadProps> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{`${title} - ISN - Indigenous Sentinels Network`}</title>
            {/* <meta name="description" content={description} /> */}
        </Helmet>
    );
};

export default Head;
