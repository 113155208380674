import { useEffect, useRef, useState } from "react";

import {
  useDrawAllGlobalLayersOnMap,
  useDropPinsOnMap,
  useHandleControlsForEditMode,
  useInitializeCustomLayersListControl,
  useInitializeMap,
} from "../../hooks";

import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";

import mapboxgl from "mapbox-gl";

const Map = ({element,editMode,answer,eleName,setFieldValue,markers,setMarkers}:any) => {
  const mapContainer = useRef<any>(null);
  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const [isFeaturesUpdated, setIsFeauresUpdateed] = useState(false);
  const [draggedMarker, setDraggedMarker] = useState<any>();

  const customControlId = "droppin" + element.id;
  const customDeleteControlId = "droppin#delete" + element.id;
 
  // initializing the map
  const { map, isMapFullyLoaded, draw } = useInitializeMap(
    mapContainer,
    customControlId,
    customDeleteControlId,
    questionTypes.dropPin,
    editMode,
    element?.dropMultiple,
    element?.enableSnapping,
    setIsFeauresUpdateed
  );

  // initializing custom shapes dropdown to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    element.selectedLayers,
    customControlId
  );

  // draw all global layers
  useDrawAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    draw
  );

  // handling the drop pin and delete button
  useHandleControlsForEditMode(isMapFullyLoaded, editMode);

  // draw all answer points
  useDropPinsOnMap(
    answer,
    map,
    isMapFullyLoaded,
    editMode,
    draw,
    markers,
    setMarkers,
    setDraggedMarker
  );

  useEffect(() => {
    if (!isFeaturesUpdated) return;

    const geometryCollection = draw?.getAll();

    if (geometryCollection) {
      const data = geometryCollection.features
        ?.map((f: any) => {
          let id = f.id;

          if (id?.includes("draw")) {
            id = "draw#" + f.id.split("draw#").at(-1);
          }

          const name = answer?.find((ans: any) => ans.id === id)?.name ?? "";

          const mapData = {
            features: [f],
            type: "FeatureCollection",
          };

          return {
            type: "point",
            vectorLayers: [],
            legendFileId: null,
            name,
            geoFilePath: null,
            id,
            mapData,
          };
        })
        .filter((f: any) => !f.id.includes("global"));
      // converting the point into marker
      const droppedPin = data[0];

      if (droppedPin) {
        draw.delete([droppedPin.id]);

        const coordinates =
          droppedPin.mapData?.features[0]?.geometry?.coordinates;

        if (coordinates) {
          const marker = new mapboxgl.Marker({ draggable: editMode })
            .setLngLat(coordinates)
            .addTo(map);

          const data = { reference: marker, id: droppedPin.id };

          marker.on("dragend", () => setDraggedMarker(data));

          if (element?.dropMultiple) {
            setFieldValue(eleName, [...(answer ?? []), droppedPin]);
            setMarkers(() => [...markers, data]);
          } else {
            markers.forEach((marker: any) => {
              marker.reference.remove();
            });
            setMarkers(() => [data]);

            setFieldValue(eleName, [droppedPin]);
          }
        }
      }
    }

    setIsFeauresUpdateed(false);
  }, [isFeaturesUpdated]);

  useEffect(() => {
    if (!draggedMarker) return;

    const updatedAnswers = answer.map((ans: any) => {
      if (ans.id === draggedMarker.id) {
        const { lng, lat } = draggedMarker.reference.getLngLat();
        ans.mapData.features[0].geometry.coordinates = [lng, lat];
      }
      return ans;
    });

    setFieldValue(eleName, updatedAnswers);
  }, [draggedMarker]);

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
