import React, { ReactNode, ReactElement } from "react";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";

interface PopOverMenuProps {
  children: ReactNode;
  icon?: ReactNode;
  style?: any;
}

const PopOverMenu: React.FC<PopOverMenuProps> = ({ children, icon, style }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPopoverDirection = (anchorEl: HTMLElement | null) => {
    if (anchorEl) {
      const { top, bottom, left, right } = anchorEl.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (bottom > windowHeight && right > windowWidth) {
        return "top-end";
      } else if (bottom > windowHeight) {
        return "top";
      } else if (right > windowWidth) {
        return "right";
      }
    }
    return "bottom";
  };

  const open = Boolean(anchorEl);
  const id = open ? "three-dot-menu" : undefined;
  const popoverDirection = getPopoverDirection(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="more"
        style={{
          minHeight: "40px",
          minWidth: "40px",
        }}
        aria-describedby={id}
        onMouseDown={handleClick} 
      >
        {icon}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: popoverDirection === "top" ? "bottom" : "top",
          horizontal: popoverDirection === "right" ? "left" : "right",
        }}
        PaperProps={{
          style: {
            marginLeft: "10px",
            minWidth: "150px",
          },
        }}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as ReactElement<any>, {
              onClick: (event: React.MouseEvent<HTMLElement>) => {
                handleClose();
                event.nativeEvent.stopImmediatePropagation();
                if (child.props.onClick) {
                  handleClose();
                  child.props.onClick(event);
                }
              },
            });
          }
          return null;
        })}
      </Popover>
    </div>
  );
};

export default PopOverMenu;
