import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, PageHeading, AddProtocolBtn } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
// import {
//   communitiesOrganizationsAction,
//   communitiesResetFiltersAction,
// } from "store/filters/reducer.actions";
import { openAddEditDuplicateProtocolModalAction } from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import { protocolsProgramsAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

const generateDynamicLinks = (programId: any, name: any, label: any) => {
  switch (true) {
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/protocols`,
          label: `Protocols`,
        },
      ];

    default:
      return [
        { path: `/protocols/list`, label: `Protocols` },
        //   
      ];
  }
};

export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
    showAddButton: false,
  });
  const { protocolsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
 
  const {protocols} =usePermissions()
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programId } = useParams<{
    programId: string;
  }>();

  const dynamicLinks = generateDynamicLinks(
    programId ?? "",
    BreadcrumbData?.name ?? "",
    protocolsFilter.status
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/programs/list");
        });
    }
  }, [programId]);

  // Handlers

  // displaying models based on organizationId
  const handleAddProtocolModal = () => {
    // if (organizationId) {
    //   setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);
    // } else {
    //   dispatch(openCreateCommunityModalAction());
    // }
    dispatch(
      openAddEditDuplicateProtocolModalAction({
        title: "",
        description: "",
        programId: {
          value: programId ?? "",
          label: BreadcrumbData?.name ?? "",
        },
        formId: {
          value: "",
          label: "",
        },

        files: [],
        path: "",
      })
    );
  };
  // displaying PersonModal and hiding OptionsModal
  // const handleClickOnCreateNewBtn = () => {
  //   dispatch(openCreateCommunityModalAction());
  //   setIsShowExistingOptionsModal(false);
  // };
  // // displaying SearchCommunityModal and hiding OptionsModal
  // const handleClickOnAddExistingBtn = () => {
  //   dispatch(openAddExistingCommunityModalAction(organizationId));
  //   setIsShowExistingOptionsModal(false);
  // };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              organizationId={programId ? programId : ""}
              name={BreadcrumbData?.name}
              parentName={programId ? "Program" : ""}
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {BreadcrumbData?.showAddButton && protocols.canCreateProtocol && (
                <AddProtocolBtn
                  handleAddProtocolModal={handleAddProtocolModal}
                />
              )}
              {!programId && protocols.canCreateProtocol && (
                <AddProtocolBtn
                  handleAddProtocolModal={handleAddProtocolModal}
                />
              )}
              {/* <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                 handleAddDynamicFormModel={ handleAddDynamicFormModel}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
