import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import PopOverMenu from 'view/components/PopOverMenu';
import { Toasts } from 'view/components/Toasts';
import ImageCircleIcon from 'assets/icons/HeroIcons/ImageCircleIcon';
import DocumentCircleIcon from 'assets/icons/HeroIcons/DocumentCircleIcon';
import AudioCircleIcon from 'assets/icons/HeroIcons/AudioCircleIcon';
import PaperClipCircleIcon from 'assets/icons/HeroIcons/PaperClipCircleIcon';
import ActionDropDownMenu from './ActionDropDownMenu';

interface MessageAttachmentActionsProps {
  selectedContact: any | null;
  handleSubmit: (values: any, actions: any) => void;
  updateAttachments: (attachments: any) => void;
  setFileType: any;
  fileType: string;
  attachments: any;
}

const FILE_TYPE_ACTIONS = [
  {
    label: 'Photos & Videos',
    type: '.jpg,.jpeg,.png,.svg,.gif,.heic,.heif,.mp4,.mov,.webm',
    Icon: ImageCircleIcon,
  },
  {
    label: 'Document',
    type: '.pdf, .doc, .docx, .xls, .xlsx, .txt, .rtf, .csv, .zip, .geojson, .kml, .shp',
    Icon: DocumentCircleIcon,
  },
  {
    label: 'Audio',
    type: '.mp3, .wav',
    Icon: AudioCircleIcon,
  },
];
export const MessageAttachmentActions: React.FC<
  MessageAttachmentActionsProps
> = ({ updateAttachments, setFileType, fileType, attachments }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const chatDetails = useSelector<RootState, any>((state) => state.chatDetails);
  const [inputTrigger, setInputTrigger] = useState(0); // State to trigger useEffect

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files) {
      const invalidFiles: string[] = [];
      const validFiles: File[] = [];

      Array.from(files)?.forEach((file) => {
        const fileSizeMb = file.size / (1024 * 1024);
        if (fileSizeMb > 20) {
          invalidFiles.push(`${file.name} must be less than 20 MB!`);
        } else {
          validFiles.push(file);
        }
      });

      // Display all errors at once
      if (invalidFiles.length > 0) {
        invalidFiles?.forEach((error) => Toasts.error(error));
        return; // Optionally return if any files are invalid
      }

      // If all files are valid, update your attachments state
      updateAttachments(validFiles);
          }
  };

  useEffect(() => {
    if (fileInputRef.current && inputTrigger > 0) {
      fileInputRef.current.click();
          }
  }, [inputTrigger]); // Depend on inputTrigger to trigger the file input

  const triggerFileInput = (type: string) => {
    setFileType(type);
    setInputTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (attachments?.length < 1 && fileInputRef.current) {
      fileInputRef.current.value = ''; // Setting the value to an empty string to clear the file input
    }
  }, [attachments]);

  const messagesAttachmentActions = () =>
    FILE_TYPE_ACTIONS?.map(({ label, type, Icon }) => ({
      label,
      onClick: () => triggerFileInput(type),
      Icon,
      iconProps: {},
      divider: true,
      isVisible: true,
    }));

  return (
    <div className='mr-3'>
      <PopOverMenu
        icon={
          <PaperClipCircleIcon
            className={
              chatDetails?.isLoading ? 'pointer-events-none' : 'pointer'
            }
          />
        }
      >
        <ActionDropDownMenu actions={messagesAttachmentActions()} />
      </PopOverMenu>
      {fileType ? (
        <input
          type='file'
          accept={fileType}
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          multiple
        />
      ) : null}
    </div>
  );
};
