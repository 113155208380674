import React, { useState } from "react";
/************ Assets ************/
import { CheveronDownIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 200,
    },
  },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={24}
      width={24}
      onClick={onClick}
      className={` ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ cursor: "pointer" }}
    />
  );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 8px;
  },
`;

interface ReviewersDropdownProps {
  placeholder: any;
  value?: any;
  data?: any[];
  handleChange?: any;
  isInternalReviewer: any;
}

export const ReviewersDropdown: React.FC<ReviewersDropdownProps> = ({
  placeholder,
  value,
  data,
  handleChange,
  isInternalReviewer,
}) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <FormControl className="w-full">
      <MaterialUiSelect
        className={`w-full !font-Overpass  text-textMid text-xs dark:bg-[transparent]`}
        displayEmpty
        value={value}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disabled={isInternalReviewer}
        renderValue={() => {
          return <p>{placeholder}</p>;
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={CustomIcon}
        open={open}
        onClick={handleToggle}
        input={<CustomOutlinedInput />}
        classes={{
          root: 'dark:bg-secondaryLight',
        }}
      >
        {data && data?.length > 0 ? (
          data?.map((option: any) => (
            <MenuItem
              key={typeof option === "object" ? option?.id : option}
              value={typeof option === "object" ? option?.id : option}
              className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:!text-inputText w-full"
            >
              {typeof option === "object" ? option?.name : option}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            disabled
            className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-inputText "
          >
            No data
          </MenuItem>
        )}

        {/* {data?.map((item) => {
          if (typeof item === "object") {
            return (
              <MenuItem key={item.id} value={item} className="menu_items dark:bg-secondaryLight dark:text-textMain">
                {item.name}
              </MenuItem>
            );
          } else {
            return <></>; // Handle other data types if needed
          }
        })} */}
      </MaterialUiSelect>
    </FormControl>

    // <FormControl className="">
    //   <MaterialUiSelect
    //     className={`w-full !font-Overpass selectremove_active ${
    //       !value ? "!text-textLightExtra" : "!text-textNormal"
    //     }`}
    //     // displayEmpty
    //     value={value}
    //     onChange={handleChange}
    //     onClose={() => setOpen(false)}
    //     onOpen={() => setOpen(true)}
    //     renderValue={(selected) => {
    //       if (selected === "") {
    //         return (
    //           <p>
    //             {placeholder ||
    //               (data && data.length > 0 ? data[0].name || data[0] : "")}
    //           </p>
    //         );
    //       }

    //       const selectedItems = Array.isArray(selected)
    //         ? selected.map((item) =>
    //             typeof item === "string" ? item : item?.name || ""
    //           )
    //         : typeof selected === "string"
    //         ? [selected]
    //         : [selected?.name || ""];

    //       return selectedItems.join(", ");
    //     }}
    //     MenuProps={MenuProps}
    //     inputProps={{ "aria-label": "Without label" }}
    //     IconComponent={CustomIcon}
    //     open={open}
    //     onClick={handleToggle}
    //     input={<CustomOutlinedInput />}
    //   >
    //     {data && data?.length > 0 ? (
    //       data?.map((option: any) => (
    //         <MenuItem
    //           key={typeof option === "object" ? option?.id : option}
    //           value={typeof option === "object" ? option?.id : option}
    //           className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-caption "
    //         >
    //           {typeof option === "object" ? option?.name : option}
    //         </MenuItem>
    //       ))
    //     ) : (
    //       <MenuItem
    //         disabled
    //         className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-caption "
    //       >
    //         No data
    //       </MenuItem>
    //     )}
    //   </MaterialUiSelect>
    // </FormControl>
  );
};

export default ReviewersDropdown;
