import getAllObservations from "./getAllObservations";
import getAllObservationsActivities from "./getAllObservationsActivities";
import editObservationActivity from "./editObservationActivity";
import getObservationProfile from "./getObservationProfile";
import changeObservationStatus from "./changeObservationStatus";
import updateObservationsFields from "./updateObservationsFields"
import updateReviewer from "./updateReviewer"
import updateObservation from "./updateObservation"
import getReviewers from "./getReviewers"
import seenObservationsActivites from "./seenObservationsActivites";
import getImportInfo from "./getImportInfo";
import getExifData from "./getExifData";

const Observations = {
  getAllObservations,
  getAllObservationsActivities,
  editObservationActivity,
  getObservationProfile,
  changeObservationStatus,
  updateObservationsFields,
  updateReviewer,
  updateObservation,
  getReviewers,
  seenObservationsActivites,  
  getImportInfo,
  getExifData
};

export default Observations;
