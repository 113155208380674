import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { FilesListingCard, ReadMore } from "Components/ProtocolProfile";
import { useSelector } from "react-redux";
import { RootState } from "store";

import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import { IProtocol } from "store/protocolProfile/initialState";

import { ProtocolDetails } from "Components/ProtocolProfile/ProtocolDetails";
import { Header } from "Components/ProtocolProfile/Header";
import {
  fetchProtocolProfileAction,
  resetProtocolProfileAction,
} from "store/protocolProfile/reducer.actions";
import { openAddEditDuplicateProtocolModalAction } from "store/modals/reducer.actions";
import Head from "view/components/Head";
import useRoles from "hooks/roles";

export const ProtocolProfile = () => {
  const { protocolId } = useParams();
  const protocolProfile = useSelector<RootState, IProtocol>(
    (state) => state?.protocolProfile
  );
  const { isExternalReviewer } = useRoles();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  useEffect(() => {
    dispatch(fetchProtocolProfileAction(protocolId));
    return () => {
      dispatch(resetProtocolProfileAction());
    };
  }, []);
  const editProtocolHandle = () => {
    const protocolInitialState = {
      id: protocolProfile.protocolId,
      title: protocolProfile.protocolTitle,
      description: protocolProfile.protocolDescription,
      programId: {
        value: protocolProfile.program ? protocolProfile.program?.id : "",
        label: protocolProfile.program ? protocolProfile.program?.name : "",
      },
      formId: {
        value: protocolProfile.form ? protocolProfile.form?.id : "",
        label: protocolProfile.form ? protocolProfile.form?.name : "",
      },
      notify: false,
      files: protocolProfile.files?.length > 0 ? protocolProfile.files : [],
      path: "profilePage",
    };
    dispatch(
      openAddEditDuplicateProtocolModalAction(protocolInitialState, true)
    );
  };

  const dynamicProtocolLinks = [
    {
      path: !isExternalReviewer() ? `/protocols/list` : ``,
      label: `Protocols`,
    },
    {
      path: `/protocols/${protocolProfile?.protocolId}/profile`,
      label: protocolProfile?.protocolTitle,
    },
  ];

  return (
    <div className="w-full cursor-default pt-5">
      <Head title={`Protocols ${protocolProfile?.protocolTitle}`} />
      <div className="mb-3 ">
        <DirectoryBreadCrumb links={dynamicProtocolLinks} />
      </div>
      <div>
        <Header
          protocolTitle={protocolProfile?.protocolTitle}
          editProtocolHandle={editProtocolHandle}
          programStatus={protocolProfile?.program?.programStatus}
        />
      </div>
      <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 py-10">
        <div className="w-full pb-6 rounded-lg bg-bgWhite dark:bg-secondaryLight ">
          <div>
            <ProtocolDetails />
          </div>
          <div>
            <ReadMore />
          </div>
          <div>
            <FilesListingCard />
          </div>
        </div>
      </div>
    </div>
  );
};
