export interface IProgram {
  code: string;
  canAddAdmin: boolean;
  startTime: string;
  canEditProgramDetail:boolean;
  communities: [
    {
      date: string;
      id: number;
      image: {
        original: string;
        thumb: string;
      };
      membersCount: number;
      name: string;
      regions: [
        {
          location: {
            coordinates: [number, number];
            type: string;
          };
          regionName: string;
          shape: string;
        },
      ];
    },
  ];
  communitiesCount: number;
  coverImage: string;
  coverImageId: number;
  description: string;
  endDate: string;
  mapType: string;
  files: [
    {
      id: number;
      image: {
        original: string;
        thumb: string;
      };
      name: string;
      type: string;
      fileSize: string;
    },
  ];
  filesCount: number;
  formsCount: number;
  id: number;
  isPublic: boolean;
  location: string[];
  membersCount: number;
  name: string;
  observationsCount: number;
  organizations: [
    {
      date: string;
      id: number;
      image: {
        original: string;
        thumb: string;
      };
      name: string;
    },
  ];
  organizationsCount: number;
  profileImage: string;
  profileImageId: number;
  programAdmins: [
    {
      id: number;
      name: string;
      profileImage: {
        file: {
          original: string;
          thumb: string;
        };
        id: number;
        path: string;
      };
      observations_count: number;
    },
  ];
  requireQa: boolean;
  speciesObservedCount: number;
  startDate: string;
  status: string;
  endTime: string;
  isLoading: boolean;
  dapId: number;
  programTags: any[];
  observations: any[];
  contactInfo: ContactInfo[];
  pausedSince: string;
  openAccess: boolean;
  message: string;
  protocol: {
    protocolId: number;
    title: string | null;
  };
}

interface ContactInfo {
  description: string;
  emails: [
    {
      email: string;
      title: string;
    },
  ];
  links: [
    {
      links: string;
      title: string;
    },
  ];
  organizationId: number;
  phones: [
    {
      phoneNumber: string;
      title: string;
    },
  ];
}

export const initialState: IProgram = {
  code: " ",
  canEditProgramDetail:false,
  canAddAdmin: false,
  startTime: "",
  endTime: "",
  communities: [
    {
      date: " ",
      id: 0,
      image: {
        original: " ",
        thumb: " ",
      },
      membersCount: 0,
      name: " ",
      regions: [
        {
          location: {
            coordinates: [0, 0],
            type: " ",
          },
          regionName: " ",
          shape: " ",
        },
      ],
    },
  ],
  communitiesCount: 0,
  coverImage: " ",
  coverImageId: 0,
  description: " ",
  endDate: "",
  mapType: "",
  files: [
    {
      id: 0,
      image: {
        original: " ",
        thumb: " ",
      },
      name: " ",
      type: " ",
      fileSize: " ",
    },
  ],
  filesCount: 0,
  formsCount: 0,
  id: 0,
  isPublic: true,
  location: [],
  membersCount: 0,
  name: " ",
  observationsCount: 0,
  organizations: [
    {
      date: " ",
      id: 0,
      image: {
        original: " ",
        thumb: " ",
      },
      name: " ",
    },
  ],
  organizationsCount: 0,
  profileImage: " ",
  profileImageId: 0,
  programAdmins: [
    {
      id: 0,
      name: " ",
      profileImage: {
        file: {
          original: " ",
          thumb: " ",
        },
        id: 0,
        path: " ",
      },
      observations_count: 0,
    },
  ],
  requireQa: false,
  speciesObservedCount: 0,
  startDate: " ",
  status: " ",
  isLoading: false,
  dapId: 0,
  programTags: [],
  observations: [],
  contactInfo: [],
  pausedSince: " ",
  openAccess: false,
  protocol: {
    protocolId: 0,
    title: "",
  },
  message: "",
};
