import { Box, IconButton } from "@mui/material";
import WidgetIcon from "./WidgetIcon";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { redirectionFromDashboardAction } from "store/filters";
import { useDispatch } from "react-redux";
import CustomToolTip from "view/components/ToolTip";
import { InformationCircleIcon } from "assets/icons/HeroIcons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useState } from "react";

interface WidgetTitles {
  [key: string]: string;
}

const widgetTitles: WidgetTitles = {
  Forms: "Total number of forms available",
  Communities: "Total number of participating communities",
  Observations: "Total number of observations made",
  Species: "Total number of species observed",
  Users: "Total number of active users",
  Programs: "Total number of active programs",
};

const Widget = ({
  title,
  count,
  redirectLink,
}: {
  title: string;
  count: number;
  redirectLink: string;
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { configurationDashboardModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const navigate = useNavigate();
  const redirectToLink = () => {
    dispatch(redirectionFromDashboardAction(title.toLowerCase()));
    setTimeout(() => {
      if (title.toLowerCase() === "species") {
        const state = { speciesObserved: true };
        navigate(redirectLink, { state });
      } else {
        navigate(redirectLink);
      }
    }, 200);
  };

  return (
    <div
      onClick={redirectToLink}
      className="px-3 py-4 pb-6 rounded-lg shadow cursor-pointer bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight"
    >
      {configurationDashboardModal.isOpen ? (
        <CustomToolTip title={widgetTitles[title] || ""} showTooltip={showTooltip} toggleTooltip={toggleTooltip}>
          <p className="text-textMid text-sm font-medium dark:text-textMain font-Overpass capitalize leading-[18px]" onClick={toggleTooltip}>
            {title}
          </p>
        </CustomToolTip>
      ) : (
        <div className="flex items-center">
          <p className="text-textMid text-sm font-medium dark:text-textMain font-Overpass capitalize leading-[18px] mr-2">
            {title}
          </p>
          <CustomToolTip title={widgetTitles[title] || ""} showTooltip={showTooltip} toggleTooltip={toggleTooltip}>
            <IconButton onClick={toggleTooltip}>
              <InformationCircleIcon className="w-5 h-5 fill-[#475569]" />
            </IconButton>
          </CustomToolTip>
        </div>
      )}

      <div className="flex items-center justify-between mt-7">
        <p className="text-2xl xl:text-3xl font-medium text-textMid dark:text-caption font-Overpass">
          {count}
        </p>
        <Box
          sx={{
            filter: "drop-shadow(0px 0px 10px rgba(178, 178, 178, 0.22))",
          }}
        >
          <WidgetIcon widgetName={title} />
        </Box>
      </div>
    </div>
  );
};

export default Widget;
