import { MenuItem, Select } from "@mui/material";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";

const ChooseFeatures = ({
  allCommunityLayers,
  handleCoummunityLayerOnSelect,
  selectedLayersIdToHighlight,
  handleClickOnRemoveLayer,
}: any) => {

   const layers = allCommunityLayers?.filter((l:any)=>{
    if(!selectedLayersIdToHighlight?.includes(l.id)){
      return true;
    }
    return false;
   });

  return (
    <div className="w-full">
      <h2 className="mb-2 text-secondaryMidLight">Choose Features</h2>
        <div className="bg">
          <Select
            className="w-full mb-2 bg-bgWhite"
            size="small"
            onChange={handleCoummunityLayerOnSelect}
            displayEmpty
            renderValue={() => "Choose location defined in system"}
          >
            <MenuItem disabled value="">
              Choose location defined in system
            </MenuItem>

            {
            // removeMatchedEleFromTwoArrays(
            //   allCommunityLayers,
            //   allCommunityLayers.filter((l:any)=> selectedLayersIdToHighlight.find((sl:any)=>sl== l.id)),
            //   "id"
            // )
            layers?.map((l: any) => (
              <MenuItem key={l.id.toString()} value={l.id}>
                {l.name}
              </MenuItem>
            ))}
          </Select>
          {selectedLayersIdToHighlight && (
            <ul className="w-full divide-y divide-lineLightExtra">
              {selectedLayersIdToHighlight?.map((id: any) => {
                const layer = allCommunityLayers?.find((l: any) => l.id == id);

                return (
                  <li
                    className="flex justify-between bg-bgWhite p-2 "
                    key={"selected#" + id}
                  >
                    <p className="capitalize dark:text-textMain">{layer?.name}</p>
                    <button onClick={() => handleClickOnRemoveLayer(id)}>
                      <TrashIcon fill="#6F747B" />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
    </div>
  );
};

export { ChooseFeatures };

function structuringLayersData(
  data: any,
  converter: "original" | "options",
  originalData?: any
) {
  let structureData = [];

  if (converter === "options" && data) {
    structureData = data.map((l: any) => ({ value: l.id, label: l.name }));
  } else if (converter === "original" && originalData) {
    structureData = originalData.filter((l: any) =>
      JSON.stringify(data).includes(l.id.toString())
    );
  }

  return structureData;
}
