export interface ICommunity {
  QAQCCount: number;
  address: string;
  status: string;
  adminsCount: number;
  backgroundImage: null | string;
  communityAdmins: CommunityAdmin[];
  communityRegions: null | any[];
  formsCount: number;
  hikeInQaqcCount: number;
  hikeInAdminsCount: number;
  hikeInFormsCount: number;
  hikeInMembersCount: number;
  hikeInObjectsCount: number;
  hikeInObservationsCount: number;
  hikeInProgramsCount: number;
  hikeInRegionsCount: number;
  hikeInSpeciesCount: number;
  id: number;
  location: {
    coordinates: [number, number];
    crs: {
      properties: {
        name: string;
      };
      type: string;
    };
    type: string;
  };
  membersCount: number;
  missionStatement: string;
  name: string;
  objectsCount: number;
  observationsCount: number;
  organizations: string[];
  profileImage: null | string;
  programsCount: number;
  recentActiveMembers: any[];
  regionsCount: number;
  speciesCount: number;
  type: string;
  profileImageId: number | null;
  backgroundImageId: number | null;
  communityTags: any[] | null;
  contactInfo: ContactInfo[];
  openAccess: boolean;
  observations: Observation[];
}

interface CommunityAdmin {
  id: number;
  name: string;
  profileImage: {
    file: null | string;
    id: null | string;
    path: null | string;
  };
}

interface Observation {
  id: number;
  locations: Location[];
  name: string;
}

interface Location {
  geometries: Geometry[];
  type: string;
}

interface Geometry {
  coordinates: number[];
  type: string;
}

interface ContactInfo {
  description: string;
  emails: [
    {
      email: string;
      title: string;
    },
  ];
  links: [
    {
      links: string;
      title: string;
    },
  ];
  organizationId: number;
  phones: [
    {
      phoneNumber: string;
      title: string;
    },
  ];
}

export const initialState: ICommunity = {
  status: "",
  QAQCCount: 0,
  address: "",
  adminsCount: 0,
  backgroundImage: null,
  communityAdmins: [],
  communityRegions: null,
  formsCount: 0,
  hikeInQaqcCount: 0,
  hikeInAdminsCount: 0,
  hikeInFormsCount: 0,
  hikeInMembersCount: 0,
  hikeInObjectsCount: 0,
  hikeInObservationsCount: 0,
  hikeInProgramsCount: 0,
  hikeInRegionsCount: 0,
  hikeInSpeciesCount: 0,
  observations: [],
  id: 0,
  location: {
    coordinates: [0, 0],
    crs: {
      properties: {
        name: "",
      },
      type: "",
    },
    type: "",
  },
  membersCount: 0,
  missionStatement: "",
  name: "",
  objectsCount: 0,
  observationsCount: 0,
  organizations: [],
  profileImage: null,
  programsCount: 0,
  recentActiveMembers: [],
  regionsCount: 0,
  speciesCount: 0,
  type: "",
  profileImageId: null,
  backgroundImageId: null,
  communityTags: [],
  contactInfo: [],
  openAccess: false,
};
