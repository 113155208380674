import DirectoryBreadCrumb from "view/components/Breadcrumbs/DirectoryBreadCrumb";
import Head from "view/components/Head";
import { IformDetails } from "store/formDetails";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchFormDatails } from "store/formDetails/initialState";

const generateDynamicUserLinks = (formDetails: IformDetails) => {
    return [
        { path: `/forms/list`, label: `Forms` },
        { path: `/forms/${formDetails.id}/edit`, label: `Edit` },
        { path: ``, label: formDetails?.name },
    ];
};

const HeaderBar = () => {

    const { formDetails } = useSelector<
        RootState,
        IfetchFormDatails
    >((state) => state.formSurveyJsDetails);

    const dynamicUsersLinks = generateDynamicUserLinks(formDetails);

    return (
        <div className="w-full pt-4 pb-6">
            <Head title="Geography" />
            <DirectoryBreadCrumb links={dynamicUsersLinks} />
            <div className="flex flex-col justify-start items-start flex-grow py-0.5">
                <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                    <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
                        Indicate Form Area
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HeaderBar;
