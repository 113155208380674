import { apiClient } from "../config";

async function getAllUserWhichCanBeAddAsAdmin(
  page: number,
  searchString?: any
) {
  const params = {
    searchString: searchString,
    page: page,
  };

  const response = await apiClient.get("/admin/communities/user-admins", {
    params,
  });

  return response.data;
}

export default getAllUserWhichCanBeAddAsAdmin;
