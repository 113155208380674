import useDateFormatter from "hooks/formatDateWithPattern";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import WarningIcon from "assets/icons/HeroIcons/WarningIcon";
import FormsDropdown from "../FormsDropdown";
import { useState } from "react";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "protocolName"
  ) {
    return (
      <div className="flex justify-start items-center self-stretch flex-grow relative gap-3 px-2 py-2.5 group">
        <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 group">
          <p className="self-stretch flex-grow-0 flex-shrink-0 w-[182.67px] text-sm text-left text-textMid group-hover:text-primaryDark">
            {cellValue?.name}
          </p>
        </div>
      </div>
    );
  } else if (
    ["createdBy", "archivedBy", "programName", "updatedBy"].includes(
      columnDef.id
    ) &&
    cellValue !== null &&
    cellValue !== undefined
  ) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 py-3 pr-2">
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid group-hover:text-primaryDark w-[180px] break-all">
              {cellValue?.name}
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    columnDef.id === "formName" &&
    cellValue !== null &&
    cellValue !== undefined
  ) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 py-3 pr-2">
          {cellValue.length ? (
            <img
              src={
                cellValue[0]?.image?.thumb
                  ? cellValue[0].image?.thumb
                  : cellValue?.image?.thumb || PlaceholderAvatar
              }
              alt=""
              height="100"
              width="100"
              className="mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
          ) : (
            ""
          )}
          <div className="flex flex-col justify-start items-start">
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-lefttext-textMid group-hover:text-primaryDark w-[180px] break-all py-2">
              {cellValue.length ? (
                <div>
                  <span>{cellValue[0]?.name} </span>
                  {cellValue.length > 1 && (
                    <button
                      className="py-1 px-1.5 text-[12px] bg-primaryExtraLight text-primary font-medium rounded hover:underline transition font-Overpass"
                      onClick={handleClick}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      +{cellValue.length - 1}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="w-[300px]">
                <FormsDropdown
                  forms={cellValue}
                  id={cellValue?.id}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                />
              </div>
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    cellValue !== null &&
    ["dateCreated", "dateUpdated", "dateArchived"].includes(columnDef.id)
  ) {
    return (
      <div className="relative flex items-center justify-center flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid group-hover:text-primaryDark">
          {formatDate(cellValue)}
        </p>
      </div>
    );
  } else if (typeof cellValue !== "object" && cellValue !== null) {
    return (
      <div className="relative flex items-center justify-center flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid group-hover:text-primaryDark">
          {cellValue}
        </p>
      </div>
    );
  } else if (columnDef.id === "programName" && cellValue === null) {
    return (
      <div className="flex items-center gap-6 pl-3">
        <div>
          <WarningIcon />
        </div>{" "}
        <p className="text-sm text-accent_2 whitespace-nowrap">
          No Program Selected
        </p>
      </div>
    );
  } else if (columnDef.id === "formName" && cellValue === null) {
    return (
      <div className="flex items-center gap-6 pl-3">
        <div>
          <WarningIcon />
        </div>{" "}
        <p className="text-sm text-accent_2 whitespace-nowrap">
          No Form Selected
        </p>
      </div>
    );
  } else {
    return null;
  }
};
