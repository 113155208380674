import { HARVEST_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";
import { HARVEST_QUESTIONS_NAME } from "../panelsQuestionsName";
import questionTypes from "../questionTypes";
import { v4 as uuidv4 } from "uuid";

import convertQuestionObj, {
  convertTheChoicesOfQuestions,
} from "../utils/convertQuestionObj";

function convertJsonOfHarvestPanel(ele: any, globalStoreData: any, panel: any) {
  const harvestPanel = globalStoreData.find((d: any) => d?.id === ele?._id);

  const newTemplateELements = panel.elements
    .filter((item: any) => {
      if (item.type === "html") {
        return false;
      } else if (
        ele.showRetrievalLocation === false &&
        item.name === HARVEST_QUESTIONS_NAME.RetrievalLocation
      ) {
        return false;
      } else if (
        ele.showValuedFeatures === false &&
        item.name === HARVEST_QUESTIONS_NAME.ValuedFeautres
      ) {
        return false;
      } else if (
        ele.showStuckAndLostLocationRegion === false &&
        item.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion
      ) {
        return false;
      } else if (
        ele.showStruckAndLostLocationPoint === false &&
        item.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint
      ) {
        return false;
      }
      return true;
    })
    .map((e: any) => {
      if (
        e.type === questionTypes.shapePicker &&
        e.name === HARVEST_QUESTIONS_NAME.RetrievalLocation
      ) {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: harvestPanel?.retrievalShapePicker?.id },
            [harvestPanel?.retrievalShapePicker]
          ),
        };
      } else if (
        e.type === questionTypes.shapePicker &&
        e.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion
      ) {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: harvestPanel?.struckShapePicker?.id },
            [harvestPanel?.struckShapePicker]
          ),
        };
      } else if (
        e.type === questionTypes.pointPicker &&
        e.name === HARVEST_QUESTIONS_NAME.ValuedFeautres
      ) {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: harvestPanel?.retrievalPointPicker?.id },
            [harvestPanel?.retrievalPointPicker]
          ),
        };
      } else if (
        e.type === questionTypes.pointPicker &&
        e.name === HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint
      ) {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: harvestPanel?.struckPointPicker?.id },
            [harvestPanel?.struckPointPicker]
          ),
        };
      } else if (
        [
          questionTypes.radio,
          questionTypes.checkbox,
          questionTypes.dropdown,
          questionTypes.tagbox,
        ].indexOf(e.type)
      ) {
        return convertTheChoicesOfQuestions(e);
      }

      return e;
    });
  panel.elements = newTemplateELements?.map((element: any) => {
    return {
      ...element,
      elementId: uuidv4(),
    };
  });

  console.log("harvest panel", { ...ele, ...panel });

  return {
    ...ele,
    ...panel,
  };
}

export { convertJsonOfHarvestPanel };
