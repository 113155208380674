import { __changeColorOfZoomedShapeLayer__ } from "../../../mapWorkerMehods";
import { useEffect } from "react";

const useCustomizeColorOfHigilightedLayer = (
  map: any,
  isMapFullyLoaded: any,
  currentQuestionEle: any,
  LAYER_SELECTED_COLOR: string,
  LAYER_DEFAULT_COLOR: string
) => {
  useEffect(() => {
    if (
      !map ||
      !isMapFullyLoaded ||
      !currentQuestionEle ||
      !currentQuestionEle.allCommunityLayers
    )
      return;

    currentQuestionEle.allCommunityLayers.forEach((layer: any) => {
      const item = { item: layer, itemType: "shapepicker", status: true };

      const isItemHighlighted =
        currentQuestionEle.selectedLayersIdToHighlight.find(
          (layerId: any) => layerId == item.item.id
        );

      const isGeojsonUrl =  item.item.geoFilePath ? true : false;

      const layerColor = isGeojsonUrl ? "purple" : isItemHighlighted ? LAYER_SELECTED_COLOR : LAYER_DEFAULT_COLOR;
        
      // change the color of layer on the behalf of selection or unselection
      if(!isGeojsonUrl){
        __changeColorOfZoomedShapeLayer__(item, map, layerColor);
      }
      
    });
  }, [currentQuestionEle?.selectedLayersIdToHighlight,isMapFullyLoaded]);
  return null;
};

export { useCustomizeColorOfHigilightedLayer };
