import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeReinviteUserModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

import Avatarimg from "assets/images/Placeholders/avatar-circle.png";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "view/components/Button";
import { usersStatusAction } from "store/filters/reducer.actions";
import { fetchUserProfileAction } from "store/userProfile/reducer.actions";
import Roles from "hooks/roles";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface ReInviteUserModalProps {}

export const ReInviteUserModal: React.FC<ReInviteUserModalProps> = () => {
  const { reinviteUserModal } = useSelector((state: RootState) => state.modals);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { isMyProfile } = Roles();
  const { users } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeReinviteUserModalAction());
  };

  const createPassword = async () => {
    dispatch(setLoadingProgress(getRandomProgress()));
    setIsLoading(true);
    try {
      if (
        reinviteUserModal.data.contactPreference === "email" &&
        users.canInviteUsers
      ) {
        const res = await apiLibrary.userProfile.inviteUser({
          email: reinviteUserModal.data?.email,
          reInvite: true,
          appName: "isn_admin",
        });
        Toasts.success(res.message);
        handleClose();
      } else {
        if (users.canInviteUsers) {
          const res = await apiLibrary.userProfile.inviteUser({
            phoneNumber: reinviteUserModal.data?.phoneNumber,

            reInvite: true,
            appName: "isn_admin",
          });
          Toasts.success(res.message);
          handleClose();
        }
      }
      if (reinviteUserModal.data.path === "directory") {
        dispatch(usersStatusAction("re-Invited"));
      } else if (reinviteUserModal.data.path === "userProfile" && users.canViewUsersProfile) {
        dispatch(
          fetchUserProfileAction(
            reinviteUserModal.data.personId,
            isMyProfile(reinviteUserModal.data.personId)
          )
        );
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  return (
    <Modal
      open={reinviteUserModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                  Re-Invite
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
              <p className="flex-grow w-[432px] text-sm text-left text-secondaryMid">
                <span className="flex-grow w-[432px] text-sm text-left text-secondaryMid dark:text-caption">
                  You are going to Re-Invite this user to the system. They will
                  receive an invitation via their preferred contact (Email or
                  Phone).{" "}
                </span>
                <br />
                <span className="flex-grow w-[432px] text-sm text-left mt-7 text-secondaryMid dark:text-caption">
                  If the contact information is not correct, please, edit it in
                  the profile.
                </span>
              </p>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex-grow-0 flex-shrink-0 rounded-full w-14 h-14">
                  <img
                    alt={`${reinviteUserModal?.data?.firstName} ${reinviteUserModal?.data?.lastName}`}
                    className="object-cover w-full h-full rounded-full"
                    src={
                      reinviteUserModal?.data?.imageUrl
                        ? reinviteUserModal?.data?.imageUrl
                        : Avatarimg
                    }
                  />
                </div>
                <div className="flex flex-col items-start justify-start flex-grow">
                  <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
                    <div className="flex items-center justify-start flex-grow rounded-lg">
                      <div className="flex items-center justify-start flex-grow">
                        <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                            <p className="flex-grow w-[360px] text-[15px] text-left text-secondary dark:text-caption">
                              {`${reinviteUserModal?.data?.firstName} ${reinviteUserModal?.data?.lastName}`}
                            </p>
                          </div>
                          <p className="self-stretch flex-grow-0 flex-shrink-0 w-[360px] text-sm text-left dark:text-caption">
                            Preferred Contact:{" "}
                            {reinviteUserModal?.data?.email
                              ? reinviteUserModal?.data?.email
                              : reinviteUserModal?.data?.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex flex-col items-start justify-start flex-grow">
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 rounded-lg">
                    <div className="flex items-center justify-start flex-grow">
                      <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize w-52 dark:text-caption">
                          Email
                        </p>
                        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow w-52 text-[15px] text-left text-secondary dark:text-caption">
                            {reinviteUserModal?.data?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start flex-grow">
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 rounded-lg">
                    <div className="flex items-center justify-start flex-grow">
                      <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left capitalize w-52 dark:text-caption">
                          Phone
                        </p>
                        <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                          <p className="flex-grow w-52 text-[15px] text-left text-secondary dark:text-caption">
                            {reinviteUserModal?.data?.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={isLoading}
                type="button"
                text="Send Invitation"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={createPassword}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
