import React, { useState } from "react";
import { DurationFilter } from "Components/Dashboard/FiltersList/Components/Duration";
import { Link } from "react-router-dom";
import CustomToolTip from "view/components/ToolTip";
import { InformationCircleIcon } from "assets/icons/HeroIcons";
import { widgetTitles } from "Components/Dashboard/widgetTitles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IconButton } from "@mui/material";

const DashboardCardHeading = (props: {
  text: string;
  widgetId?: any;
  viewAllLInk?: string;
  showChip?: boolean;
  chipLabel?: number | string;
  durationFilter?: boolean;
  toggleDuration?: (props: any) => void;
  handleClick?: any;
}) => {
  const { configurationDashboardModal } = useSelector((state: RootState) => state.modals);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <div className="flex items-center justify-start dark:bg-  mb-4">
      {configurationDashboardModal.isOpen ? (
        <CustomToolTip title={widgetTitles[props.widgetId] || ""} showTooltip={showTooltip} toggleTooltip={toggleTooltip}>
          <p className="text-left text-gray-700 text-[18px] font-Overpass dark:text-textMain font-semibold leading-none break-words mr-2 cursor-default" onClick={toggleTooltip}>
            {props.text}
          </p>
        </CustomToolTip>
      ) : (
        <>
          <p className="text-left text-gray-700 text-[18px] font-Overpass dark:text-textMain font-semibold leading-none break-words mr-2 cursor-default">
            {props.text}
          </p>

          <CustomToolTip title={widgetTitles[props?.widgetId] || ""} showTooltip={showTooltip} toggleTooltip={toggleTooltip} >
            <IconButton onClick={toggleTooltip}>
              <InformationCircleIcon className="w-5 h-5 fill-[#475569]" />
            </IconButton>
          </CustomToolTip>
        </>
      )}

      {props.showChip && (
        <div className="w-[39px] h-5 rounded-[30px] ml-2 p-0 py-0.5  bg-textLink dark:bg-primary_40  flex items-center justify-center">
          <p className="font-['DM Sans'] text-sm font-bold leading-none dark:text-textMain text-textWhite">
            {props.chipLabel}
          </p>
        </div>
      )}
      {props.durationFilter && props.toggleDuration && (
        <DurationFilter toggleDuration={props.toggleDuration} />
      )}

      {props?.text === "Active Users" ? (
        <p
          onClick={props?.handleClick}
          className="inline-block ml-auto text-sm font-normal text-right capitalize cursor-pointer text-textLink font-Overpass"
        >
          View All
        </p>
      ) : (
        props?.viewAllLInk && (
          <Link
            to={props?.viewAllLInk}
            className="inline-block ml-auto text-sm font-normal text-right capitalize text-textLink font-Overpass"
          >
            View All
          </Link>
        )
      )}
    </div>
  );
};

export default DashboardCardHeading;
