import React from "react";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import { useNavigate } from "react-router-dom";
import ellipsize from "ellipsize";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import useDateFormatter from "hooks/formatDateWithPattern";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  function getFileType(type: string): string {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      return "image";
    } else if (type === "pdf") {
      return "pdf";
    } else if (type.match(/(mp4|mp3|mpeg|avi|mkv|wmv|flv|mov)$/)) {
      return "video";
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      return "document";
    } else if (type.match(/(txt|csv)$/)) {
      return "text";
    } else if (type.match(/(zip|rar|7z)$/)) {
      return "archive";
    } else {
      return "unknown";
    }
  }

  function getThumbnailSource(file: any, type: any) {
    if (type === null) return null;
    const fileType = getFileType(type);
    switch (fileType) {
      case "video":
        return videoThumbnailImg;
      case "image":
        return file;
      case "pdf":
        return pdfThumbnailImg;
      default:
        return null;
    }
  }

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "fileName"
  ) {
    return (
      <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 pr-2 py-3 ">
        {getThumbnailSource(cellValue.path, cellValue.fileType) ? (
          <img
            src={getThumbnailSource(cellValue.path, cellValue.fileType)}
            alt=""
            height="100"
            width="100"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
        ) : (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
            preserveAspectRatio="xMidYMid meet"
          >
            <circle cx={12} cy={12} r={12} fill="#99BED0" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5887 7.24829L8.30968 11.6417C7.00794 12.9435 7.0079 15.054 8.30964 16.3557C9.61139 17.6575 11.7219 17.6575 13.0237 16.3557L13.0242 16.3552L17.1958 12.1934C17.4565 11.9334 17.8786 11.9339 18.1386 12.1945C18.3987 12.4552 18.3982 12.8773 18.1375 13.1373L13.9665 17.2986C13.9664 17.2987 13.9666 17.2985 13.9665 17.2986C12.144 19.1207 9.18918 19.1209 7.36683 17.2986C5.54547 15.4772 5.54439 12.5248 7.3636 10.7021L11.6429 6.30832C12.9447 5.00657 15.0553 5.00661 16.357 6.30836C17.6577 7.60902 17.6588 9.71713 16.3603 11.0191L12.0809 15.413C11.2999 16.194 10.0335 16.194 9.25245 15.4129C8.47141 14.6319 8.4714 13.3656 9.25245 12.5845L13.643 8.19398C13.9033 7.93363 14.3254 7.93363 14.5858 8.19398C14.8461 8.45433 14.8461 8.87644 14.5858 9.13679L10.1953 13.5273C9.93491 13.7877 9.93491 14.2098 10.1953 14.4701C10.4547 14.7296 10.8749 14.7305 11.1355 14.4727L15.4142 10.0796C16.1952 9.29851 16.1953 8.03222 15.4142 7.25117C14.6341 6.47109 13.37 6.47012 12.5887 7.24829Z"
              fill="white"
            />
          </svg>
        )}

        <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid hover:text-primaryDark dark:text-textMain dark:hover:text-primary">
            {cellValue.fileName}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateUpdated") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {formatDate(cellValue)}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
