import { PauseCircleIcon, PlayCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
// Import the AudioVoiceRecorder component; make sure the import path matches your package
import { AudioRecorder } from 'react-audio-voice-recorder';
import apiLibrary from 'services/api';

interface AudioRecorderProps {
    audioSrc: string;
    editMode: boolean;
    error?: any;
    label?: string;
    required?: boolean;
    deleteAudio: any;
    setAudio: any;
    panel?: any;
    element?: any;
}

interface AudioData {
    url: string;
    blob: Blob;
    chunks: BlobPart[];
    duration: number;
}

const AudioRecorderComponent: React.FC<AudioRecorderProps> = ({ audioSrc, editMode, error, label, required, deleteAudio, setAudio, panel, element }) => {
    const [audioData, setAudioData] = useState<AudioData | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const audioRef = useRef<any>(null);

    // const handleAudioStop = (data: AudioData) => {
    //     setAudioData(data);
    // }

    // const handleAudioUpload = (file: Blob) => {
    //     console.log(file);
    // }

    // const handleReset = () => {
    //     if (audioData) {
    //         const newBlob = new Blob([audioData.url], { type: 'audio/wav' });
    //         setAudioData({ ...audioData, blob: newBlob });
    //     }
    // }

    // Set state to false once audio finishes
    useEffect(() => {
        const audio = audioRef.current;

        const setPlayStateToEnd = () => setIsPlaying(false);

        if (audio) {
            audio.addEventListener('ended', setPlayStateToEnd);
        }

        return () => {
            if (audio) {
                audio.removeEventListener('ended', setPlayStateToEnd);
            }
        };
    }, []);

    // Upload audio file, get audio path and set to formik
    const addAudioElement = async (blob: any) => {
        // Create a File object from the Blob with additional properties
        const audioFile = new File([blob], 'audio.mp3', {
            type: 'audio/mpeg', // Set the MIME type for MP3 files
            lastModified: Date.now(),
        });

        // Call the API to upload the audio file
        try {
            const res = await apiLibrary.file.fileUpload(audioFile, false, 'public');
            setAudio(res.data?.path);
            // Display the API response
            console.log("API Response:", res.data);
        } catch (error: any) {
            console.error("Error uploading file:", error);
        }
    };

    // Change audio status play/pause on buttons
    const togglePlayback = () => {
        if (audioRef?.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className='p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight' id={`${element?.name}${panel?.sectionId}`}>
            <p className={`w-full pb-1 text-md text-left capitalize font-Overpass ${editMode ? "text-primary dark:text-caption font-medium " : "text-primary dark:text-caption font-regular "
                }`}>
                {label} {required && <span>*</span>}
            </p>



            {editMode ? (
                <div>
                    <div className='audio-wrapper flex items-center gap-5'>
                        <AudioRecorder
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }}
                            downloadOnSavePress={false}
                            downloadFileExtension="mp3"
                            showVisualizer={true}
                        />

                        {<div className={`flex items-center gap-2 ${!audioSrc ? "opacity-50 pointer-events-none" : ""}`}>
                            <audio id="player" ref={audioRef} src={audioSrc} className='' />
                            <button onClick={deleteAudio} type='button'>
                                {<TrashIcon width={26} className='stroke-accent_1Dark' />}
                            </button>
                        </div>}
                    </div>

                    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                        {error && (
                            <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                                {error}
                            </p>
                        )}
                    </div>
                </div>

            ) : audioSrc ?
                <audio src={audioSrc} controls />
                :
                <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
                    (No Selection)
                </p>
            }
        </div>
    );
};

export default AudioRecorderComponent;
