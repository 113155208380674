import React, { useEffect, useMemo, useState } from "react";
import { SingleValue } from "react-select";
import Placeholder from "assets/images/Placeholders/programPlaceholderAvatar.png";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";
import { fetchImportsFileDataAction } from "store/importsViewData";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { InputAdornment, List, ListItem, Popover } from "@mui/material";
import usePermissions from "hooks/usePermissions";

interface OptionType {
  value: string;
  label: string;
  image: string;
}

interface ObserversDropdownProps {
  observers: any[];
  id: number;
  handleClose: () => void;
  anchorEl: any;
}

const ObserversDropdown: React.FC<ObserversDropdownProps> = ({
  observers,
  id,
  handleClose,
  anchorEl,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const { importId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { imports } = usePermissions();
  const updateObserver = async (personId: string, id: number) => {
    try {
      const body = {
        personId: Number(personId),
      };
      const { message } = await apiLibrary.Imports.updateObserver(id, body);
      Toasts.success(message);
      dispatch(fetchImportsFileDataAction(Number(importId)));
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message ??
        error?.message ??
        "Error fetching reviewers";
      Toasts.error(errorMsg);
    }
  };

  useEffect(() => {
    if (selectedOption?.value && imports.canEditObserverImports) {
      updateObserver(selectedOption.value, id);
    }
  }, [selectedOption]);

  const formattedOptions = useMemo(() => {
    const options = observers?.map((observer) => {
      return {
        value: observer.id,
        label: observer.name,
        image: observer.image?.thumb,
      };
    });

    return options;
  }, [observers]);

  const handleSelect = (option: SingleValue<OptionType>) => {
    setSelectedOption(option as OptionType);
  };

  const filteredOptions = useMemo(() => {
    const options = formattedOptions?.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return options;
  }, [searchTerm, formattedOptions]);

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        id="observer-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "40vh",
            overflowY: "auto",
            width: "250px",
            marginTop: "30px",
          },
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="search"
          type="text"
          fullWidth
          placeholder="Search By Member Name"
          value={searchTerm}
          className="text-sm font-Overpass dark:placeolder:text-caption"
          onChange={(e) => {
            e.stopPropagation(); // Prevent the event from propagating to the menu
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => {
            // Prevents the menu from closing when pressing "Enter"
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
          sx={{
            "& .MuiInputBase-root": {
              fontSize: "14px",
              fontFamily: "Overpass",
            },

            "& .MuiInputBase-input": {
              padding: "10px 0px",
              fontSize: "14px",
              height: "auto",
              fontFamily: "Overpass",
              color: "#00000",
              boxShadow: "none",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              margin: "0px 10px",
              border: "1px solid #BDC0C4",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="ml-2">
                <MagnifyingGlassIcon className="w-6 h-6 stroke-textLightExtra" />
              </InputAdornment>
            ),
            classes: {
              input: "MuiInputBase-input", // Ensure this class name matches your theme's class name for input
            },
          }}
        />
        <List component="nav" aria-label="search results">
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option, index) => {
              const img =
                option?.image && option?.image.includes("https")
                  ? option.image
                  : Placeholder;
              return (
                <div
                  onClick={() => handleSelect(option)}
                  className={`px-4 py-2 flex items-center gap-2 cursor-pointer capitalize text-[15px] text-secondaryMid font-Overpass hover:bg-primaryLight hover:text-primary dark:text-caption dark:hover:text-secondary`}
                >
                  <div className="w-8 h-8 mr-2 overflow-hidden shrink-0">
                    <img
                      src={img}
                      alt={option?.label}
                      className="object-cover w-full h-full rounded-full shrink-0"
                    />
                  </div>
                  {option?.label}
                </div>
              );
            })
          ) : (
            <ListItem className="font-Overpass text-sm dark:text-caption !justify-center">
              No Options Found
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
};

export default ObserversDropdown;
