import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updatePointPickers,
} from "store/surveyJS";
import { panelsName } from "../../../panelsTitle";
import { HARVEST_QUESTIONS_NAME } from "../../../panelsQuestionsName";

const useHandleClickOnPoint = (
  clickedPoint: any,
  setClickedPoint: any,
  currentQuestionEle: any,
  questionName: any
) => {
  const {
    pointPickers,
    selectedQuestionElement,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
    surveyActiveTab
  } = useSelector((state: RootState) => state.surveyJS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyActiveTab === "test") {
      return;
    };

    if (clickedPoint) {
      const alreadyExistedPointIndex =
        currentQuestionEle.selectedPointsIdToHighlight.findIndex(
          (p: any) => p === clickedPoint
        );

      if (alreadyExistedPointIndex < 0) {
        const updatedSelectedPoints = [
          ...currentQuestionEle.selectedPointsIdToHighlight,
          clickedPoint,
        ];
        const updatedQuestion = {
          ...currentQuestionEle,
          selectedPointsIdToHighlight: updatedSelectedPoints,
        };

        const selectedQuestionTypes = selectedQuestionElement?.isPanel ? selectedQuestionElement.name : selectedQuestionElement?.getType()

        if (selectedQuestionTypes === panelsName.HARVEST) {
          if (questionName === HARVEST_QUESTIONS_NAME.ValuedFeautres) {
            dispatch(
              updateAddedHarvestPanelMapQuestions({
                ...harvestPanelMapQuestions,
                retrievalPointPicker: updatedQuestion,
              })
            );
          }
          else if (questionName === HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint) {
            dispatch(
              updateAddedHarvestPanelMapQuestions({
                ...harvestPanelMapQuestions,
                struckPointPicker: updatedQuestion,
              })
            );
          }
        } else if (
          selectedQuestionTypes ===
          panelsName.GEOGRAPHICAL_INFORMATION
        ) {
          dispatch(
            updateAddedGeographicalPanelMapQuestions({
              ...geographicalPanelMapQuestions,
              pointPicker: updatedQuestion
            })
          );
        } else {
          const currentQuestionIndex = pointPickers.findIndex(
            (s: any) => s.id === updatedQuestion.id
          );

          const updatedAllPointPickers = [...pointPickers];
          updatedAllPointPickers[currentQuestionIndex] = updatedQuestion;

          dispatch(updatePointPickers(updatedAllPointPickers));
        }
      }

      // reset current clicked marker to null
      setClickedPoint(null);
    }
  }, [clickedPoint]);

  return null;
};

export { useHandleClickOnPoint };
