function removeMatchedEleFromTwoArrays(
    arr1: any[],
    arr2: any[],
    compareBy: string
  ): any[] {
    return arr1.filter((ele1: any) => {
      return !arr2.find((ele2) => ele1[compareBy] == ele2[compareBy]);
    });
  }

  
  export default removeMatchedEleFromTwoArrays;