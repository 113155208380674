import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateDropPin, updateDropPins } from "store/surveyJS";

import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import {
  AbleToSelectMultiple,
  Description,
  MapCenterPoint,
  Required,
  SelectLayers,
  Snapping,
  Title,
} from "./components";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";
import { MapZoomLevel } from "./components/MapZoomLevel";

const DropPinSidebar = () => {
  const { selectedQuestionElement, dropPins } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const [currentDropPin, setCurrentDropPin] = useState<any>({});

  const dispatch = useDispatch();

  // structuring data of default points according to dropdown component
  const defaultLayersSelectedOptions = structuringLayersData(
    currentDropPin.selectedDefaultLayers,
    "options"
  );
  const defaultLayersOptions = removeMatchedEleFromTwoArrays(
    structuringLayersData(currentDropPin.allGlobalCommunityLayers, "options"),
    defaultLayersSelectedOptions,
    "label"
  );

  useEffect(() => {
    if (selectedQuestionElement) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentDropPin]);

  // recognize and set current point picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;
    if (questionId) {
      const ele = dropPins.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentDropPin({ ...ele, title, description });
      }
    }
  }, [selectedQuestionElement, dropPins]);

  // handlers
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    if (name === "zoomLevel") {
      updatedCurrentDropPin.zoomLevel = parseFloat(value);
    } else {
      updatedCurrentDropPin.mapCenterPoint = {
        ...updatedCurrentDropPin.mapCenterPoint,
        doFly: false,
        [name]: value,
      };
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentDropPin = { ...currentDropPin };

    // update current element
    updatedCurrentDropPin.mapCenterPoint = {
      ...updatedCurrentDropPin.mapCenterPoint,
      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleClickOnZoomLevelBtn = () => {
    const updatedCurrentDropPin = { ...currentDropPin };

    if (isNaN(updatedCurrentDropPin.zoomLevel)) {
      return;
    }
    // update current element
    updatedCurrentDropPin.mapCenterPoint = {
      ...updatedCurrentDropPin.mapCenterPoint,
      ...updatedCurrentDropPin.zoomLevel,

      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };

  const handleAbleToSelectMultipleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.ableToCheckMultiple = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleEnableSnappingCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.enableSnapping = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleDefaultLayersOnSelection = (option: any) => {
    if (
      !JSON.stringify(currentDropPin.selectedDefaultLayers).includes(
        option.label
      )
    ) {
      // update current element
      const updatedCurrentDropPin = { ...currentDropPin };

      updatedCurrentDropPin.selectedDefaultLayers = [
        ...updatedCurrentDropPin.selectedDefaultLayers,
        ...structuringLayersData(
          [option],
          "original",
          currentDropPin.allGlobalCommunityLayers
        ),
      ];

      // update current element state into global elements
      updateElementByIdFromArray(
        dropPins,
        updatedCurrentDropPin,
        (updatedDropPins: any) => {
          setCurrentDropPin(updatedCurrentDropPin);
          dispatch(updateDropPins(updatedDropPins));
        }
      );
    }
  };
  const handleDefaultLayersOnRemove = (option: any) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.selectedDefaultLayers =
      currentDropPin.selectedDefaultLayers.filter(
        (l: any) => l.name !== option.label
      );

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDropPin = { ...currentDropPin };

    updatedCurrentDropPin.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      dropPins,
      updatedCurrentDropPin,
      (updatedDropPins: any) => {
        setCurrentDropPin(updatedCurrentDropPin);
        dispatch(updateDropPins(updatedDropPins));
      }
    );
  };
  return (
    <div className="flex flex-col py-[40px] px-[32px] bg-[#F3F5F7]  w-full h-full gap-8">
      <Title
        handleTitleOnChange={handleTitleOnChange}
        value={currentDropPin.title}
      />
      <Description
        handleDescriptionOnChange={handleDescriptionOnChange}
        value={currentDropPin.description}
      />
      <Required
        handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
        checked={currentDropPin.isRequired}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentDropPin.mapCenterPoint}
      />
      <MapZoomLevel
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnZoomLevelBtn={handleClickOnZoomLevelBtn}
        zoomLevel={currentDropPin.zoomLevel}
      />
      <div>
        <AbleToSelectMultiple
          handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
          ableToCheckMultiple={currentDropPin.ableToCheckMultiple}
        />
        <Snapping
          enableSnapping={currentDropPin.enableSnapping}
          handleCheckboxOnChange={handleEnableSnappingCheckboxOnChange}
        />
      </div>
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      />
    </div>
  );
};

export { DropPinSidebar };

function structuringLayersData(
  data: any,
  converter: "original" | "options",
  originalData?: any
) {
  let structureData = [];

  if (converter === "options" && data) {
    structureData = data.map((l: any) => ({ value: l.id, label: l.name }));
  } else if (converter === "original" && originalData) {
    structureData = originalData.filter((l: any) =>
      JSON.stringify(data).includes(l.id.toString())
    );
  }

  return structureData;
}
