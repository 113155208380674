import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export const useInitializeMap = (mapContainer: any) => {
  const [map, setMap] = useState<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);

  useEffect(() => {
    if (map || !mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-145.6433003, 65.0710178],
      zoom: 2,
      renderWorldCopies: false,
      minZoom: 0.999,
      //@ts-ignore
      projection: {
        name: "mercator",
      },
      // dragRotate: false,
    });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    initializeMap.addControl(nav, "bottom-right");
    initializeMap.addControl(new mapboxgl.FullscreenControl());

    initializeMap.on("load", () => setIsMapFullyLoaded(true));
    // initializeMap.on("zoom", (value: any) => console.log(value));

    // const bounds: any = initializeMap.getMaxBounds();
    // const restrictMapBounds = () => {
    //   const currentCenter = initializeMap.getCenter();
    //   console.log(currentCenter,'currentCentercurrentCenter');

    //   let newLng = currentCenter.lng;
    //   let newLat = currentCenter.lat;

    //   if (currentCenter.lng > bounds.getEast()) {
    //     console.log(bounds.getEast(),'bounds.getEast()');

    //     newLng = bounds.getEast();
    //   } else if (currentCenter.lng < bounds.getWest()) {
    //     console.log(bounds.getWest(),'bounds.getWest()bounds.getWest()');

    //     newLng = bounds.getWest();
    //   }

    //   if (currentCenter.lat > bounds.getNorth()) {
    //     newLat = bounds.getNorth();
    //   } else if (currentCenter.lat < bounds.getSouth()) {
    //     newLat = bounds.getSouth();
    //   }

    //   if (newLng !== currentCenter.lng || newLat !== currentCenter.lat) {
    //     initializeMap.setCenter([newLng, newLat]);
    //   }
    // };

    // initializeMap.on("move", restrictMapBounds);
    setMap(initializeMap);

    return () => {
      setIsMapFullyLoaded(false);
    };
  }, []);

  return { map, isMapFullyLoaded };
};
