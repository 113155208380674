import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { useSelector } from "react-redux";
import { RootState } from "store";
import changeShapeMode from "../../utils/changeShapMode";
import { useDispatch } from "react-redux";
import {
  removeAllDrawShapes,
  removeMarkerOfPickOnMapScreen,
  setSelectedPointLayerToPointSelectForm,
  setSelectedRegionLayerToRegionSelectForm,
  setSelectedShapeLayerToShapeSelectForm,
  updateDrawShapeCoordinates,
  updateSelectedItemToZoom,
  updateSelectedLayersItems,
  updateSelectedShape,
} from "store/geography";

import {
  handleCreationOfMarkerOnMap,
  handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap,
  handlePaintingOfVectorRegionShapeLayersOnMap,
} from "./handlers";
import {
  calculateZoomLevel,
  generatingLayerId,
  getCenterCoordinatesForPointLayers,
  getCenterCoordinatesForRasterLayer,
  getCenterCoordinatesForRegionLayers,
  getCenterCoordinatesForShapeLayers,
  getCenterCoordinatesForVectorLayers,
} from "./utils";
import {
  __changeColorOfMarker__,
  __changeColorOfPoint__,
  __changeColorOfZoomedShapeLayer__,
  __deleteAllLocalDrawShapes__,
  __resetColorOfSelectedLayersItems__,
  __resetColorOfSelectedMarkers__,
  __resetColorOfSelectedPoints__,
} from "./workerMehods";
import { handlePaintingOfRasterLayersOnMap } from "./handlers/handlePaintingOfRasterLayersOnMap";
import {
  useInitializeCustomControlForLayerListing,
  useInitializeCustomControlForSnapping,
  useInitializeMap,
  useMapMoveOnCoordinatesChange,
  useRequestForUserLocation,
  useResetMapOnRequest,
  useResetMapOnScreenChange,
} from "./hooks";
import { screens } from "store/geography/initialState";
import separateColorAndOpacity from "utils/separateColorAndOpacity";
import { geometryCollection } from "turf";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const LAYER_DEFAULT_COLOR = "red";
const ZOOMED_LAYER_COLOR ="#FFA500";

export function DefaultMap() {
  const mapContainer = useRef<any>(null);
  const markerRef = useRef<any>(null);

  const {
    currentSelectedShape,
    shapeCoordinates,
    currentLayerToDisplayOnMap,
    selectedLayersItems,
    selectedItemToZoom,
    doRemoveAllDrawShapes,
    selectedRegionLayerForRegionSelectForm,
    selectedShapeLayerForShapeSelectForm,
    isRemoveMarkerOfPickOnMapScreen,
    activeSidebarScreen,
    communityCenterpoint,
  } = useSelector((state: RootState) => state.geography);

  const dispatch = useDispatch();

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [zoom, setZoom] = useState(3.6);

  const [selectedMarkers, setSelectedMarkers] = useState<any>([]);
  const [popups, setPopups] = useState<any>([]);
  const [clickedPointForSelectPointForm, setClickedPointForSelectPointForm] =
    useState<any>();
  const [selectedLayerId, setSelectedLayerId] = useState<any>(null);

  const [newPopup, setNewPopup] = useState<any>();

  const [selectedFeatures, setSelectedFeatures] = useState<any>([]);

  // initialize the map on first rendering
  const { map, drawRef, isMapFUllyLoaded } = useInitializeMap(
    mapContainer,
    lat,
    lng,
    zoom,
    setZoom,
    communityCenterpoint
  );

  // initialize the custom control for layers listing
  useInitializeCustomControlForLayerListing(map, selectedLayersItems);

  // initialize the custom control for snapping
  useInitializeCustomControlForSnapping(map, drawRef);

  // get user permission to access his current location
  // useRequestForUserLocation(map, lng, lat, setLng, setLat);

  // move to user location if he allows
  useMapMoveOnCoordinatesChange(map, lng, lat);

  // if we are not on the all layers listing screen then removing the selected layers items from map and also reseting its storage
  useResetMapOnScreenChange(map, drawRef);

  // reset the map on a request
  useResetMapOnRequest(map, drawRef, selectedMarkers, setSelectedMarkers);

  // remove the draw shapes
  useEffect(() => {
    if (
      doRemoveAllDrawShapes &&
      doRemoveAllDrawShapes.remove &&
      map.current &&
      drawRef
    ) {
      if (doRemoveAllDrawShapes.variant === "local_draw") {
        __deleteAllLocalDrawShapes__(drawRef);
      } else {
        drawRef.deleteAll();
      }

      dispatch(updateDrawShapeCoordinates(null));
      dispatch(removeAllDrawShapes(false));
    } else if (doRemoveAllDrawShapes) {
      dispatch(removeAllDrawShapes(false));
    }
  }, [doRemoveAllDrawShapes]);

  // handle on change of selected shape
  useEffect(() => {
    changeShapeMode(currentSelectedShape, drawRef, zoom);

    // reset selected shape whenever user switch to other page
    return () => {
      dispatch(updateSelectedShape(""));
    };
  }, [currentSelectedShape]);

  // handle on selecting point layer
  useEffect(() => {
    if (
      shapeCoordinates &&
      currentLayerToDisplayOnMap?.type === "pick_on_map"
    ) {
      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker({ draggable: true })
          .setLngLat(shapeCoordinates)
          .addTo(map.current);

        markerRef.current.on("dragend", function () {
          const lngLat = markerRef.current.getLngLat();
          dispatch(updateDrawShapeCoordinates([lngLat.lng, lngLat.lat]));
        });

        map.current.flyTo({ center: shapeCoordinates });

        // remove dropped point
        dispatch(removeAllDrawShapes({ remove: true, variant: "local_draw" }));
      } else {
        // updating marker cooordinates on map whenever its change manually from user input fields
        const Lng = shapeCoordinates[0] ?? lng;
        const Lat = shapeCoordinates[1] ?? lat;

        setLng(Lng);
        setLat(Lat);

        markerRef.current.setLngLat([Lng, Lat]);
      }
    }
  }, [shapeCoordinates]);

  // remove marker of pick on map screen on request
  useEffect(() => {
    if (markerRef.current && isRemoveMarkerOfPickOnMapScreen) {
      markerRef.current.remove();
      markerRef.current = null;

      dispatch(removeMarkerOfPickOnMapScreen(false));
    } else if (isRemoveMarkerOfPickOnMapScreen) {
      dispatch(removeMarkerOfPickOnMapScreen(false));
    }
  }, [isRemoveMarkerOfPickOnMapScreen]);

  // handle creation of marker on point layer and handle the preview mode of raster layer
  useEffect(() => {
    if (
      markerRef.current
      // &&
      // currentLayerToDisplayOnMap?.type !== "pick_on_map"
    ) {
      // removing it from map if the current screen is not the point layer screen
      markerRef.current.remove();
      markerRef.current = null;
    }

    // handle preview mode of raster
    if (currentLayerToDisplayOnMap?.type === "raster" && map.current) {
      const item: any = {
        item: currentLayerToDisplayOnMap,
        itemType: "raster",
      };

      item.status = false;
      dispatch(updateSelectedLayersItems([item]));

      item.status = true;
      handlePaintingOfRasterLayersOnMap(item, map.current);

      const centerCoordinates = getCenterCoordinatesForRasterLayer(
        item,
        map.current
      );

      map.current.flyTo({
        center: centerCoordinates ?? [lng, lat],
      });
    }
  }, [currentLayerToDisplayOnMap]);

  // handle layers on click on the checkbox of individual layer and display it to map
  useEffect(() => {
    if (
      selectedLayersItems &&
      selectedLayersItems.length > 0 &&
      map.current &&
      map.current.isStyleLoaded()
    ) {
      selectedLayersItems.forEach((item: any) => {
        switch (true) {
          case item.draw && item.itemType == "vector":
            handleDrawingOfVectorLayerDuringCreatingOtherLayersOnMap(
              drawRef,
              item
            );
            break;
          case item.itemType === "raster":
            handlePaintingOfRasterLayersOnMap(
              item,
              map.current,
              (clickedItem: any) => { }
            );
            // rasterItems.push(item);
            break;
            
          case item.itemType === "vector" ||
            item.itemType === "region" ||
            item.itemType === "shape":
            if (item.item.shapeType === "point") {
              handleCreationOfMarkerOnMap(
                item,
                map.current,
                popups,
                setPopups,
                setNewPopup,
                activeSidebarScreen, // if screen is all layers then showing the popup
                (event: any, layerId: any) => {
                  // dispatch(setSelectedPointLayerToPointSelectForm(event.lngLat));
                  // setClickedPointForSelectPointForm({ event, layerId });
                }
              );
            } else {
              handlePaintingOfVectorRegionShapeLayersOnMap(
                item,
                map.current,
                drawRef,
                LAYER_DEFAULT_COLOR,

                (geometryCollection: any) => {
                  if (item.clickable) {
                    setSelectedLayerId({ geometryCollection, itemType: item.itemType, itemId: item.item.id });

                  }
                },
                popups,
                setPopups,
                setNewPopup,
                selectedFeatures,
                setSelectedFeatures,
              );
            }
            if (item.clickable && item.status === false) {
              if (
                item.itemType === "region" &&
                selectedRegionLayerForRegionSelectForm?.features[0]?.id?.includes(
                  item.item.id
                )
              ) {
                dispatch(setSelectedRegionLayerToRegionSelectForm(null));
              } else if (
                item.itemType === "shape" &&
                selectedShapeLayerForShapeSelectForm?.features[0]?.id?.includes(
                  item.item.id
                )
              ) {
                dispatch(setSelectedShapeLayerToShapeSelectForm(null));
              }
            }
            break;
          case item.itemType === "point":
            handleCreationOfMarkerOnMap(
              item,
              map.current,
              popups,
              setPopups,
              setNewPopup,
              activeSidebarScreen, // if screen is all layers then showing the popup
              (event: any, layerId: any) => {
                dispatch(setSelectedPointLayerToPointSelectForm(event.lngLat));
                setClickedPointForSelectPointForm({ event, layerId });
              }
            );
            break;
        }
      });
    }
  }, [selectedLayersItems, map.current, activeSidebarScreen]);

  // handle the color of layer during selection in creating region and shape screens
  useEffect(() => {
    if (!selectedLayerId || !map.current || !map.current?.getStyle()) return;

    const { geometryCollection: geojson, itemId, itemType } = selectedLayerId;

    const selectedFeatureId = geojson.features[0]?.id;

    const { sourceId } = generatingLayerId(itemType, itemId);

    // if clicked feature already selected then unselect it
    const isLayerAlreadyAdded = selectedFeatures.find((l: any) => l.layerId === selectedFeatureId);

    // change the color of clicked feature
    map.current.setFeatureState(
      { source: sourceId, id: selectedFeatureId },
      { selected: !isLayerAlreadyAdded }
    );

    if (isLayerAlreadyAdded) {
      setSelectedFeatures((prev: any) => prev.filter((l: any) => l.layerId !== selectedFeatureId))
    }
    else {
      if (selectedFeatures.length > 0) {

        // unselect other features
        selectedFeatures.forEach((f: any) => {
          map.current.setFeatureState(
            { source: f.sourceId, id: f.layerId },
            { selected: false }
          );
        });

      };

      // remove other selected features and add current selected feature
      setSelectedFeatures((prev: any) => [{ layerId: selectedFeatureId, sourceId }]);
    };

    // update global state for region and shape geojson
    if (itemType === "region") {
      dispatch(
        setSelectedRegionLayerToRegionSelectForm(
          isLayerAlreadyAdded ? null : geojson
        )
      );
    }
    else if (itemType === "shape") {
      dispatch(
        setSelectedShapeLayerToShapeSelectForm(
          isLayerAlreadyAdded ? null : geojson
        )
      );
    }


    // const layers = map.current
    //   .getStyle()
    //   .layers.filter((l: any) => l.id?.includes("layer"));

    // layers.forEach((l: any) => {
    //   const isLayerExist = map.current.getLayer(l?.id) && !l?.id?.includes("-border")
    //   const layerId = l.id?.split(".")[0];

    //   if (isLayerExist && selectedLayerId == l.id) {
    //     map.current.setPaintProperty(
    //       l.id,
    //       "fill-color",
    //       ZOOMED_LAYER_COLOR
    //     );
    //     map.current.setPaintProperty(l.id, "fill-opacity", 0.6);
    //   }
    //   else if (isLayerExist) {
    //     const layer = selectedLayersItems.find((l: any) => l.item.id == layerId);

    //     const { color, opacity } = separateColorAndOpacity(layer?.item?.color ?? LAYER_DEFAULT_COLOR);

    //     map.current.setPaintProperty(
    //       l.id,
    //       "fill-color",
    //       color
    //     );
    //     map.current.setPaintProperty(l.id, "fill-opacity", opacity);
    //   }
    // });

  }, [selectedLayerId])

  // display popup of points whenever click on it
  useEffect(() => {
    if (
      !newPopup ||
      newPopup.isAlreadyAdded ||
      activeSidebarScreen !== screens.ALL_LAYERS
    )
      return;

    const { coordinates, html, id } = newPopup;

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map.current);

    setPopups((prev: any) => [...popups, { id, item: popup }]);
  }, [newPopup]);

  // handle clicked on marker for select point creation
  useEffect(() => {
    if (
      clickedPointForSelectPointForm &&
      map.current &&
      activeSidebarScreen === screens.ADD_POINTS_LAYER
    ) {
      const { event, layerId } = clickedPointForSelectPointForm;

      const layers = map.current.getStyle().layers;

      for (let index = layers.length - 1; index > 0; index--) {
        const pointLayer = layers[index];

        if (pointLayer.id.includes("point#")) {
          map.current.setLayoutProperty(
            pointLayer.id,
            "icon-image",
            "marker-red"
          );
        } else {
          break;
        }
      }

      map.current.setLayoutProperty(layerId, "icon-image", "marker-orange");

      // console.log("point event on click",event)

      // __resetColorOfSelectedLayersItems__(
      //   selectedLayersItems,
      //   LAYER_DEFAULT_COLOR,
      //   map.current,
      //   "marker-red"
      // );
      // __changeColorOfPoint__(layerItem, "marker-orange", map.current);
      // const updatedMarkers = selectedMarkers.map((markerData: any) => {
      //   let color = LAYER_DEFAULT_COLOR;

      //   if (markerData.id === clickedPointForSelectPointForm.id) {
      //     color = ZOOMED_LAYER_COLOR;
      //   }

      //   return __changeColorOfMarker__(
      //     map.current,
      //     markerData,
      //     color,
      //     mapboxgl,
      //     (marker: any) => {
      //       dispatch(
      //         setSelectedPointLayerToPointSelectForm(marker.marker._lngLat)
      //       );

      //       setClickedPointForSelectPointForm(marker);
      //     }
      //   );
      // });

      // setSelectedMarkers((prev: any) => updatedMarkers);
    }
  }, [clickedPointForSelectPointForm]);

  // reset states
  useEffect(() => {
    if (map.current) {
      map.current.off();
    };

    if (popups.length > 0) setPopups([]);
    if (selectedFeatures.length > 0) setSelectedFeatures([]);
    if (selectedLayerId) setSelectedLayerId(null);

  }, [activeSidebarScreen]);

  // remove marker data from global state if its not existed in the local state for select point creation
  useEffect(() => {
    if (clickedPointForSelectPointForm) {
      if (
        !selectedMarkers.find(
          (m: any) => m.id == clickedPointForSelectPointForm.id
        )
      ) {
        dispatch(setSelectedPointLayerToPointSelectForm(null));
        setClickedPointForSelectPointForm(undefined);
      }
    }
  }, [selectedMarkers]);

  // handle zoom to selection action of layer item
  useEffect(() => {
    if (selectedItemToZoom && map.current && map.current.isStyleLoaded()) {
      let centerCoordinates;
      let zoomLevel: any = zoom;

      switch (selectedItemToZoom.itemType) {
        case "raster":
          centerCoordinates = getCenterCoordinatesForRasterLayer(
            selectedItemToZoom,
            map.current
          );
          // zoomLevel = calculateZoomLevel(selectedItemToZoom, map.current);
          break;

        case "point":
          centerCoordinates =
            getCenterCoordinatesForPointLayers(selectedItemToZoom);
          // zoomLevel = calculateZoomLevel(selectedItemToZoom, map.current);
          break;

        case "region":
          centerCoordinates =
            getCenterCoordinatesForRegionLayers(selectedItemToZoom);
          zoomLevel = calculateZoomLevel(selectedItemToZoom, map.current);
          break;

        case "shape":
          centerCoordinates =
            getCenterCoordinatesForShapeLayers(selectedItemToZoom);
          zoomLevel = calculateZoomLevel(selectedItemToZoom, map.current);
          break;

        case "vector":
          centerCoordinates =
            getCenterCoordinatesForVectorLayers(selectedItemToZoom);
          zoomLevel = calculateZoomLevel(selectedItemToZoom, map.current);
      }

      if (selectedItemToZoom.itemType === "point") {
        // go to specific shapes coordinates
        map.current.flyTo({
          center: centerCoordinates ?? [lng, lat],
          zoom: zoomLevel,
        });

        // // reset all selected points color
        // __resetColorOfSelectedLayersItems__(
        //   selectedLayersItems,
        //   LAYER_DEFAULT_COLOR,
        //   map.current,
        //   "marker-red"
        // );

        // // change selected point color to zoomed color
        // __changeColorOfPoint__(
        //   selectedItemToZoom,
        //   "marker-orange",
        //   map.current
        // );

        return;
      }

      // change the color of zoomed layer item //

      // reset all selected shape layers color
      // __resetColorOfSelectedLayersItems__(
      //   selectedLayersItems,
      //   LAYER_DEFAULT_COLOR,
      //   map.current,
      //   "marker-red"
      // );

      // reset all selected markers color
      // const updatedSelectedMarkers = __resetColorOfSelectedMarkers__(
      //   selectedMarkers,
      //   map.current,
      //   LAYER_DEFAULT_COLOR,
      //   mapboxgl
      // );

      // setSelectedMarkers((prev: any) => updatedSelectedMarkers);

      // change the color of zoomed marker
      // if (selectedItemToZoom.itemType === "point") {
      //   const markerIndex: number = selectedMarkers.filter(
      //     (m: any) => m.id === selectedItemToZoom.item.id
      //   );

      //   const selectedItemID = selectedItemToZoom.item.id;

      //   // Map over the selectedMarkers to update the relevant marker
      //   const updatedSelectedMarkers = selectedMarkers.map((marker: any) => {
      //     if (marker.id === selectedItemID) {
      //       // Change color of the marker that matches the condition

      //       const updatedMarker = __changeColorOfMarker__(
      //         map.current,
      //         marker,
      //         ZOOMED_LAYER_COLOR,
      //         mapboxgl
      //       );

      //       updatedMarker.marker
      //         .getElement()
      //         .addEventListener("click", (e: any) => {
      //           e.stopPropagation();

      //           // removing existing popups
      //           const existingPopups =
      //             document.querySelectorAll(".mapboxgl-popup");
      //           existingPopups?.forEach((popup) => popup?.remove());

      //           // creating new popup
      //           const coordinates = marker.marker.getLngLat();

      //           const newPopup = new mapboxgl.Popup({ closeOnClick: true })
      //             .setLngLat(coordinates)
      //             .setHTML(
      //               `<div
      //           class="flex justify-center items-start rounded-lg bg-bgWhite font-Overpass w-[256px]"
      //         >
      //           <div class="flex flex-col justify-start items-start gap-1 p-1 w-full">
      //             <div
      //               class="flex justify-start items-start relative pr-3"
      //             >
      //               <p class=" text-sm font-medium text-left capitalize text-[#202123]">
      //                 ${selectedItemToZoom.item.name}
      //               </p>
      //             </div>
      //             ${
      //               selectedItemToZoom.item.description &&
      //               `<div
      //             class="flex justify-start items-center relative gap-2 pb-0.5"
      //           >
      //             <p class="text-xs text-left text-secondaryMid">
      //              ${selectedItemToZoom.item.description}
      //             </p>
      //           </div>`
      //             }
      //             <div class="flex justify-start items-center gap-3">
      //               <div
      //                 class="flex justify-start items-center relative gap-2 pr-1"
      //               >
      //                 <p class=" text-xs text-left text-secondaryMid">Latitude: ${
      //                   coordinates[1]
      //                 }</p>
      //                 <p class=" text-xs text-left text-secondaryMid">Longitude: ${
      //                   coordinates[0]
      //                 }</p>
      //               </div>
      //             </div>
      //           </div>
      //         </div>`
      //             )
      //             .addTo(map.current);

      //           const updatedPopups = popups?.filter(
      //             (p: any) => p.id !== selectedItemToZoom.item.id
      //           );
      //           updatedPopups.push({
      //             id: selectedItemToZoom.item.id,
      //             item: newPopup,
      //           });
      //           setPopups((prev: any) => [...updatedPopups]);
      //         });

      //       return updatedMarker;
      //     }
      //     return marker; // Return the marker unchanged if it doesn't match the condition
      //   });

      //   // Set the updated markers
      //   setSelectedMarkers((prev: any) => updatedSelectedMarkers);
      //   return;
      // }

      if (selectedItemToZoom.itemType === "raster") {
        return;
      }

      // change the color of zoomed shape layer item
      // __changeColorOfZoomedShapeLayer__(
      //   selectedItemToZoom,
      //   map.current,
      //   ZOOMED_LAYER_COLOR
      // );

      if (zoomLevel) {
        map.current.fitBounds({
          ...zoomLevel.bounds,
          maxZoom: zoomLevel.maxZoom,
        });
      }
    }
  }, [selectedItemToZoom]);

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100%",
        height: "100%",
      }}
    ></div>
  );
}
