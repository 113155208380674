import React, { useEffect, useRef, useState } from 'react';
import PersonPlaceholder from 'assets/images/Placeholders/OrganizationPlaceholder.png';
import TextInputField from 'view/components/InputField';
import useDateFormatter from 'hooks/formatDateWithPattern';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IChatDetails } from 'store/messagesDetail/initialState';
import {
  appendNewChatDetails,
  fetchChatDetailAction,
  resetChatDetails,
  sendAttachment,
  sendMessageText,
} from 'store/messagesDetail/reducer.actions';
import moment from 'moment';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import apiLibrary from 'services/api';
import PaperAirplaneRightButtonIcon from 'assets/icons/HeroIcons/PaperAirplaneRightButtonIcon';
import TextHighlighter from 'Components/MessageDetails/TextHighlighter';
import { messagesFilterReset, messagesPaginationAction } from 'store/filters';
import { filtersInitialState } from 'store/filters/initialState';
import { appendNewMessageInThreads } from 'store/messages/reducer.actions';
import { TailSpin } from 'react-loader-spinner';
import usePermissions from 'hooks/usePermissions';
import { MessageAttachmentActions } from 'Components/MessageDetails/MessageAttachmentActions';
import MediaThumbnails from 'Components/MessageDetails/MediaThumbnails';
import AttachmentPreview from 'Components/MessageDetails/AttachmentsPreview';
import { Toasts } from 'view/components/Toasts';
interface FormValues {
  message: string;
  fileId: string;
}

interface MessageDetailsProps {
  selectedContact: any | null;
  renderPlaceholder: (type: string) => any;
  reciever: any;
  chatSearchValue: any;
}

const MessageDetails: React.FC<MessageDetailsProps> = ({
  selectedContact,
  renderPlaceholder,
  chatSearchValue,
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );
  const [isInputFocused, setIsInputFocused] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading } = useSelector<RootState, IChatDetails>(
    (state) => state.chatDetails
  );
  const { conversations } = usePermissions();
  const { formatDate } = useDateFormatter();
  const auth = useSelector((state: RootState) => state.auth);

  const { messageDetailFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const [doFetchData, setDoFetchData] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [fileType, setFileType] = useState<string>('');

  const validationSchema = Yup.object().shape({
    // message: Yup.string().required('Please write a message'),
  });

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      // Set the initial scroll position to the bottom
      chatContainerRef.current.scrollTop =
        chatContainerRef.current?.scrollHeight + 100;
    }
  };

  // checkSelectedContact();
  useEffect(() => {
    if (chatContainerRef.current && data?.chats.length <= 20) {
      scrollToBottom();
    }
  }, [selectedContact?.id, data?.chats]);

  // Handling scrolling
  useEffect(() => {
    if (doFetchData) {
      if (data.totalPages === messageDetailFilter.page) {
        dispatch(fetchChatDetailAction(selectedContact?.id));
        dispatch(messagesPaginationAction(messageDetailFilter.page + 1));
      }
      setDoFetchData(false);
    }
  }, [doFetchData]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const isScrolledToTop = chatContainerRef.current.scrollTop === 0;
      if (isScrolledToTop) {
        setDoFetchData(true);
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll);
      }
      dispatch(messagesFilterReset());
      dispatch(resetChatDetails());
    };
  }, []);

  // Function to remove an attachment by index
  const handleRemoveAttachment = (fileIndex: number) => {
    // Filter out the attachment at the specified index
    const newAttachments = attachments.filter(
      (_: File, index: number) => index !== fileIndex
    );
    // Update the state with the new list of attachments
    setAttachments(newAttachments);
  };

  const updateAttachments = (attachments: any) => {
    setAttachments(attachments);
  };

  const handleSubmit = async (
    values: any,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: any },
    fileIds: string[] = []
  ) => {
    setSubmitting(true);
    const currentTime = moment().tz(profileSettings.timezone);

    try {
      // Send the main message text if it exists
      if (values?.message) {
        const messageResponse = await sendMessageText(
          values.message,
          selectedContact?.id
        );
        dispatchNewMessage(messageResponse, true, currentTime); // Dispatch with text
      }

      // Send attachments
      if (fileIds.length > 0) {
        for (const fileId of fileIds) {
          const attachmentResponse = await sendAttachment(
            fileId,
            selectedContact?.id
          );
          dispatchNewMessage(attachmentResponse, false, currentTime); // Dispatch without text
        }
      }
      scrollToBottom();
      setIsInputFocused(false);
      resetForm();
    } catch (error) {
      console.error('Error while sending message and/or attachments:', error);
    } finally {
      setSubmitting(false);
      setAttachments([]); // Clear attachments after processing
    }
  };

  function dispatchNewMessage(
    data: any,
    includeText: boolean,
    currentTime: any
  ): void {
    const updatedMessage: any = {
      messageBy: 'sender',
      messageDate: currentTime.format('YYYY-MM-DDTHH:mm:ss'),
      messageSendTime: currentTime.format('HH:mm'),
      messageText: includeText ? data.data.message : '',
      fileSize: data.data.fileSize,
      fileName: data.data.fileName,
      file: data.data.file,
      userId: auth.id,
      messageType: data.data.messageType,
      fileId: data.data.fileId,
    };
    dispatch(appendNewChatDetails(updatedMessage));
    dispatch(
      appendNewMessageInThreads({
        message: includeText ? data.data.message : '',
        file: data.data.file,
        fileName: data.data.fileName,
        messageType: data.data.messageType,
        conversationId: selectedContact?.id,
        lastMessageAt: currentTime.format('YYYY-MM-DDTHH:mm:ss'),
      })
    );
  }

  const handleFormSubmit = async (values: any, formikBag: any) => {
    formikBag.setSubmitting(true);

    try {
      // Get the files from state or form values
      const files = attachments; // Assuming `attachments` is your state variable

      // Get file IDs from multiple file uploads; returns empty array if no files
      const fileIds =
        files.length > 0 ? await handleMultipleFileUploads(files) : [];

      // Now submit the message with the file IDs
      await handleSubmit(values, formikBag, fileIds);
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handleMultipleFileUploads = async (
    files: File[]
  ): Promise<string[]> => {
    try {
      // Map each file to the upload function and execute all promises concurrently
      const uploadPromises = files.map((file) => handleFileUpload(file));
      const fileIds = await Promise.all(uploadPromises);
      return fileIds;
    } catch (error) {
      // Handle errors from any file upload failure
      console.error('Error uploading one or more files:', error);
      throw error; // Re-throw to handle it in the submission function
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      const response = await apiLibrary.file.fileUpload(file);
      return response.data.id;
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return (
    <div className='flex flex-col justify-between h-full relative overflow-hidden mt-4'>
      <div
        className={`overflow-y-auto flex-1 items-center justify-center ${
          isLoading && 'flex'
        }`}
        ref={chatContainerRef}
      >
        {data?.chats?.length > 0 && !isLoading ? (
          data.chats?.map((message: any, index) => (
            <div key={index}>
              {message.dateChanged && (
                <div className='flex justify-center'>
                  <p className='py-1 px-3 bg-primary_8 dark:bg-bgtetriary rounded-[40px] text-sm text-secondaryMid font-Overpass dark:text-caption'>
                    {moment(message?.messageDate).format('DD/MM/YYYY')}
                  </p>
                </div>
              )}
              {message.userId === auth.id ? (
                <div className='flex flex-row-reverse'>
                  <div className='flex flex-row-reverse items-center justify-between gap-2 p-4 max-w-max'>
                    <div className=''>
                      <div className='rounded-full w-7 h-7'>
                        <img
                          src={
                            message.userId === data.sender?.userId
                              ? data.sender?.personImage ?? PersonPlaceholder
                              : data.receiver?.personImage ?? PersonPlaceholder
                          }
                          alt='User Image'
                          className='w-full h-full rounded-full w-7 h-7'
                        />
                      </div>
                    </div>

                    {!message?.file && (
                      <div className='flex items-center space-x-2 rounded'>
                        <div className='flex-1 rounded'>
                          <p className='flex p-2 break-all rounded text-secondaryMidLight bg-bgChatMessage dark:bg-primary_8 font-Overpass dark:text-textMain'>
                            <span className='flex-1'>
                              <TextHighlighter
                                text={message?.messageText}
                                highlight={chatSearchValue}
                              />
                            </span>
                            <span className='flex items-end px-2 mt-1 text-xs text-secondaryLight dark:text-caption'>
                              {message?.messageSendTime}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    {message?.file && (
                      <MediaThumbnails message={message} chats={data?.chats} />
                    )}
                  </div>
                </div>
              ) : (
                <div className='flex items-center gap-2 p-4'>
                  <div className='rounded-full w-7 h-7'>
                    <img
                      src={
                        message.userId === data.receiver?.userId
                          ? data.receiver?.personImage ?? PersonPlaceholder
                          : data.sender?.personImage ?? PersonPlaceholder
                      }
                      alt='User Image'
                      className='w-full h-full rounded-full'
                    />
                  </div>

                  {!message?.file && (
                    <div className='items-center flex-1 space-x-2 rounded'>
                      <div className='flex-1 rounded'>
                        <p className='flex p-2 rounded text-secondaryMidLight max-w-max bg-bgWhite dark:bg-bgtetriary font-Overpass dark:text-textMain'>
                          <span className='flex-1'>
                            <TextHighlighter
                              text={message.messageText}
                              highlight={chatSearchValue}
                            />
                          </span>
                          <span className='flex items-end px-2 pt-2 text-xs text-secondaryLight'>
                            {message?.messageSendTime}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {message?.file && (
                    <MediaThumbnails message={message} chats={data?.chats} />
                  )}
                </div>
              )}
            </div>
          ))
        ) : isLoading ? (
          <div className='loader'>
            <TailSpin
              height='50'
              width='50'
              color='#005C89'
              ariaLabel='tail-spin-loading'
              radius='2'
              wrapperStyle={{}}
              wrapperClass='tailspin-loader'
              visible={true}
            />
          </div>
        ) : (
          renderPlaceholder('No Messages')
        )}
      </div>
      {conversations.canCreateMessageConversations && (
        <div className='comment'>
          <Formik
            initialValues={{ message: '', fileId: null }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form>
                <div className='relative flex items-center w-full px-4 py-2 bg-bgWhite dark:bg-bgtetriary'>
                  <MessageAttachmentActions
                    selectedContact={selectedContact}
                    updateAttachments={updateAttachments}
                    handleSubmit={handleSubmit}
                    setFileType={setFileType}
                    fileType={fileType}
                    attachments={attachments}
                  />

                  <div className='w-full relative overflow-x-auto'>
                    <TextInputField
                      type='text'
                      name='message'
                      placeholder='Write a message'
                      className={`text-sm px-1 focus:outline-none dark:bg-bgtetriary bg-brandHoverLight border-none`}
                      inputClassName={`border-none bg-brandHoverLight py-1`}
                      value={formikProps.values.message}
                      onChange={(e: any) => {
                        formikProps.handleChange(e);
                        if (e.target.value.length > 0) {
                          setIsInputFocused(true);
                        } else {
                          setIsInputFocused(false);
                        }
                      }}
                      disabled={formikProps.isSubmitting}
                    />

                    {attachments.length > 0 ? (
                      <div className='flex w-full items-center gap-2 py-4 overflow-x-auto'>
                        {attachments?.map((attachment: any, index: number) => (
                          <AttachmentPreview
                            attachment={attachment}
                            onRemove={() => handleRemoveAttachment(index)}
                            disabled={formikProps.isSubmitting}
                          />
                        ))}
                      </div>
                    ) : null}

                    {isInputFocused || attachments.length ? (
                      formikProps.isSubmitting ? (
                        <div className='loader absolute right-7 top-2'>
                          <TailSpin
                            height='25'
                            width='25'
                            color='#005C89'
                            ariaLabel='tail-spin-loading'
                            radius='2'
                            wrapperStyle={{}}
                            wrapperClass='tailspin-loader'
                            visible={true}
                          />
                        </div>
                      ) : (
                        <button
                          type='submit'
                          className={`absolute right-7 top-2 focus:outline-none ${formikProps.isSubmitting ? 'opacity-30' : 'opacity-100'}`}
                          disabled={formikProps.isSubmitting}
                        >
                          <PaperAirplaneRightButtonIcon
                            width={25}
                            height={25}
                          />
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default MessageDetails;
