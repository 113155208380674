import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { sentenceCase } from "change-case";

type MaterialUiSelectProps = {
  options: any[];
  valueKey: string;
  labelKey: string;
  initialValue: string;
  handleChange: (value: any) => void;
  error: any;
  height?: number;
  width?: any;
  className?: string;
  styles?: any;
  inputStyles?: any;
};

export function MaterialUiSelect({
  options = [],
  valueKey,
  labelKey,
  initialValue,
  handleChange,
  error = null,
  height = 40,
  width = 340,
  className,
  inputStyles,
  styles
}: MaterialUiSelectProps) {
  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    if (initialValue !== undefined) {
      const initialOption = options.find(
        (option) => option[valueKey] === initialValue
      );
      setSelectedOption(initialOption || null);
    }
  }, [initialValue, options, valueKey]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedObj = options.find(
      (option) => option[valueKey].toString() === selectedValue
    );
    handleChange(selectedObj);
    setSelectedOption(selectedObj || null);
  };

  return (
    <div className="w-full">
      <FormControl sx={{ m: 0, width: width, ...styles }}>
        <Select
          displayEmpty
          value={selectedOption ? selectedOption[valueKey]?.toString() : ""}
          onChange={handleSelect}
          className={`border dark:border-lineLight ${className}`}
          input={<OutlinedInput />}
          IconComponent={CustomIcon}
          renderValue={(selected) => {
            return selectedOption ? (
              <p className="dark:text-textMain">{selectedOption[labelKey]}</p>
            ) : (
              <em className="dark:text-textMain">Select an option</em>
            );
          }}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: height,
            "& .MuiOutlinedInput-root": {
              ...inputStyles,
              border: "2px solid #ccc",
              borderRadius: "4px",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#ccc",
            },
            "& .MuiSelect-select": {
              height: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <MenuItem disabled value="">
            <em>Select an option</em>
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem
                className={`dark:bg-secondaryLight ${selectedOption &&
                  selectedOption[valueKey]?.toString() ===
                  option[valueKey]?.toString()
                  ? "dark:text-primary"
                  : "dark:text-textMain"
                  } dark:hover:text-primary`}
                key={option[valueKey]}
                value={option[valueKey]?.toString()}
              >
                {sentenceCase(option[labelKey] ?? "")}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <FormHelperText
            style={{
              color: "#BF200B",
            }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

function CustomIcon(props: any) {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={16}
      width={16}
      onClick={onClick}
      className={`-0 -0 w-6 h-6 relative ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
}
