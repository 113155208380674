import { Formik } from "formik";
import * as Yup from "yup";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import CustomCheckbox from "view/components/CheckBox";
import splitString from "utils/splitString";
// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import {
  openAllLayersListingScreen,
  removeAllDrawShapes,
  resetMap,
  updateSelectedLayersItems,
  updateSelectedShape,
} from "store/geography";
import { useDispatch } from "react-redux";
import RectangleIcon from "assets/icons/MapIcons/RectangleIcon";
import CircleIcon from "assets/icons/MapIcons/CircleIcon";
import PolygonIcon from "assets/icons/MapIcons/PolygonIcon";
import { shapeTypes } from "store/geography/initialState";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { showSnapingControl } from "store/geography/reducer.actions";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import {
  EntityType,
  useFetchGlobalAndCommunityVectorLayers,
} from "Components/Geography/SideBar/hooks/useFetchGlobalAndCommunityVectorLayers";
import InfiniteScroll from "Components/Geography/InfiniteScroll";
import { TailSpin } from "react-loader-spinner";
import ReferenceVectorLayersList from "../../ReferenceVectorLayersList";
import usePermissions from "hooks/usePermissions";

const initialValues = {
  description: "",
  mapData: {},
  type: "region",
  vectorLayers: [],
  name: "",
  color: "#FF0000",
};
const validationSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, "255 max characters")
    .required("Description is required"),
  vectorLayers: Yup.array(),
  name: Yup.string().required("Name is required"),
  mapData: Yup.object(),
  color: Yup.string().required("Color is required"),
});

interface DrawRegionInterface {
  height: string;
}

export const DrawRegion = ({ height }: DrawRegionInterface) => {
  const [page, setPage] = useState({
    vector: 1,
    publicVector: 1,
  });
  const [entityType, setEntityType] = useState<EntityType>(null);
  const [currentOpenToggle, setCurrentOpenToggle] = useState<
    "vector" | "publicVector" | null
  >(null);
  const { communities } = usePermissions();
  const {
    communityLayersLoading,
    communityVectorLayers,
    globalLayersLoading,
    globalVectorLayers,
  } = useFetchGlobalAndCommunityVectorLayers({
    vectorLayerType: "polygon",
    entityType,
    currentOpenToggle,
    setCurrentOpenToggle,
    page,
  });

  const [doActiveShape, SetDoActiveShape] = useState();
  const { communityId } = useParams();

  const { shapeCoordinates } = useSelector(
    (state: RootState) => state.geography
  );
  const dispatch = useDispatch();

  const shapes = [
    {
      name: shapeTypes.POLYGON,
      Icon: <PolygonIcon />,
    },
    {
      name: shapeTypes.CIRCLE,
      Icon: <CircleIcon />,
    },
    {
      name: shapeTypes.RECTANGLE,
      Icon: <RectangleIcon />,
    },
  ];

  useEffect(() => {
    // reset selected layers
    dispatch(resetMap(true));
    return () => {
      dispatch(showSnapingControl(false));
    };
  }, []);

  // Handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    const payload = { ...values };

    delete payload.vectorLayers;

    if (!shapeCoordinates) {
      setFieldError("mapData", "Shape is required");
      return;
    }
    if (!willColorBeVisibleOnMap(values.color)) {
      setFieldError("color", "Please select a color that is easily visible");
      return;
    }

    payload.mapData = { type: "FeatureCollection", features: shapeCoordinates };

    try {
      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, payload);
        Toasts.success(`Region layer created successfully`);
        // navigate to all layers screen
        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(true);
    }
  };
  const handleClickOnShape = (name: any) => {
    dispatch(removeAllDrawShapes({ remove: true, variant: "local_draw" }));

    SetDoActiveShape(name);
    dispatch(updateSelectedShape(name));
  };
  const handleSelectVectorLayer = (
    e: any,
    item: any,
    values: any,
    setFieldValue: any
  ) => {
    const layerId = e.target.value;
    const isLayerIdExist = values.vectorLayers.find(
      (layer: any) => layer === parseInt(layerId)
    );

    setFieldValue(
      "vectorLayers",
      isLayerIdExist
        ? values.vectorLayers.filter(
            (layer: any) => layer !== parseInt(layerId)
          )
        : [...values.vectorLayers, parseInt(layerId)]
    );
  };
  const handleClickOnTogglerBtn = (type: EntityType) => {
    if (currentOpenToggle !== type) setCurrentOpenToggle(type);
  };
  const handleScrollEnd = (type: EntityType) => {
    const totalPages =
      type === "vector"
        ? communityVectorLayers.totalPages
        : globalVectorLayers.totalPages;
    if (page && type && totalPages >= page[type] + 1) {
      setPage((prev: any) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));

      if (entityType !== type) {
        setEntityType(type);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit} className="">
          <div className="overflow-y-auto" style={{ height }}>
            <h1 className="mb-2 dark:text-textMain">Draw On The Map</h1>
            <div className="flex justify-between w-full mt-1">
              {shapes.map((el, index) => {
                return (
                  <button
                    type="button"
                    key={index.toString()}
                    className={`border border-lineMid dark:border-lineLight w-[32%] rounded-lg p-2 flex flex-col justify-center items-center capitalize ${
                      doActiveShape === el.name ? "bg-[#005C8924]" : " "
                    }`}
                    onClick={() => handleClickOnShape(el.name)}
                  >
                    <p>{el.Icon}</p>
                    <p
                      className={`mt-2 text-sm font-extralight ${
                        doActiveShape === el.name
                          ? "text-primaryDark font-medium dark:text-textMain"
                          : "dark:text-textMain"
                      }`}
                    >
                      {el.name}
                    </p>
                  </button>
                );
              })}
            </div>
            {errors.mapData && (
              <p className="flex-grow pt-1 text-xs text-left text-accent_1Dark">
                Shape is required
              </p>
            )}
            <p className="text-[15px] text-textMidLight italic my-4 dark:text-textMain ">
              Turn on the vector layers to see them on the map. Click the
              snapping icon to enabel snapping for this layer.
            </p>
            <div>
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={globalVectorLayers.vector}
                heading={"Snapping to Global layers"}
                message="Create vector layers first from the Geography"
                loading={globalLayersLoading}
                type="publicVector"
                newItemType="vector"
                layerRenderingMode="draw"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "publicVector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={communityVectorLayers.vector}
                heading={"Snapping to Community layers"}
                message="Create vector layers first from the Listing section"
                loading={communityLayersLoading}
                type="vector"
                newItemType="vector"
                layerRenderingMode="draw"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "vector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />

              {errors.vectorLayers && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.vectorLayers}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start justify-start ">
              <TextInput
                label="Region Name*"
                type="text"
                placeholder="Region name"
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched?.name}
              />
            </div>
            <div className="w-full mb-4">
              <TextInput
                label="Description*"
                type="text"
                rows={3}
                fieldAs="textarea"
                placeholder="Description"
                name="description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
                error={errors?.description}
                touched={touched?.description}
              />

              {!errors?.description && (
                <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                  255 max characters
                </p>
              )}
            </div>
            <div className="w-full mb-4 mt-4">
              <div>
                <p
                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                    touched && errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : ""
                  }`}
                >
                  Select Color
                </p>
                <ColorPicker
                  handlePicker={(color: string) =>
                    setFieldValue("color", color)
                  }
                  color={values.color}
                />
              </div>
              {errors?.color && (
                <p
                  className={`flex-grow text-xs text-left   ${
                    errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : "text-textMidLight dark:text-textMain"
                  } `}
                >
                  {errors.color}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between gap-2 pb-2">
            <Button
              disabled={isSubmitting}
              type="reset"
              text="Cancel"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.t%]"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
              onClick={() => dispatch(openAllLayersListingScreen())}
            />
            <Button
              type="submit"
              text="Save"
              disabled={
                isSubmitting|| !communities.canCreateGeographyCommunities
              }
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.5%]"
              height="13"
              fontStyle="font-semibold"
              variant="filled"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
