import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { identifyFileType } from "utils/IdentifyFileType";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";

// File Upload Interface props
interface FileUploadProps {
  handleOnDropFiles: (files: any) => void;
  handleDeleteFile: (id: any) => void;
  handleUpdateFile: (file: any, id: any) => void;
  files: any;
  acceptedFileTypes: any;
  onFileDrop: (files: any) => void;
  isSubmitting?: boolean;
  editMode?: boolean;
  duplicateMode?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  handleOnDropFiles,
  handleDeleteFile,
  handleUpdateFile,
  files,
  acceptedFileTypes,
  onFileDrop,
  isSubmitting,
  editMode,
  duplicateMode,
}) => {
  return (
    <>
      <div
        className={`w-full flex flex-col justify-start items-start gap-1 ${isSubmitting ? "pointer-events-none" : ""
          } `}
      >
        <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes}>
          {({ getRootProps, getInputProps }) => (
            <div
              className={`w-full flex flex-col justify-center items-center px-4 py-2 rounded-lg bg-bgWhite  border border-lineDark border-dashed dark:bg-secondaryLight  dark:border-lineLight`}
            >
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="w-full">
                    <div className="ml-[150px]">
                      <CloudUploadIcon />
                    </div>
                    <p className="dark:text-textMain">Or drag and drop your files here or click in this area</p>
                  </div>
                </div>
              </section>
            </div>
          )}
        </Dropzone>
        <p className="text-sm text-secondaryLight dark:text-textMain">File uploads are limited to a maximum of 50 files. Supported file formats include PDF, JPEG, JPG, MP4, and MP3. For optimal performance on all devices, we recommend using files that are 25 MB or less.</p>
        {files.length > 0 && (
          <div className="flex flex-wrap justify-start gap-3 w-full mt-1">
            {files.map((f: any, index: number) => (
              <FileBox
                img={f}
                editMode={editMode}
                duplicateMode={duplicateMode}
                key={index.toString()}
                handleDeleteFile={handleDeleteFile}
                handleUpdateFile={handleUpdateFile}
                acceptedFileTypes={acceptedFileTypes}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const FileBox = ({
  editMode,
  duplicateMode,
  img,
  handleDeleteFile,
  handleUpdateFile,
  acceptedFileTypes,
}: any) => {
  const [thumbnail, setThumbnail] = useState(img?.path);

  const fileType =
    (editMode || duplicateMode) && img?.file
      ? identifyFileType(img?.type)
      : identifyFileType(img.type.split("/")[1]);
  useEffect(() => {
    console.log(fileType,'checking the file type');
    
    let newThumbnail: any;
    switch (fileType) {
      case "video":
        newThumbnail = videoThumbnailImg;
        break;
      case "mpeg":
        newThumbnail = videoThumbnailImg;
        break;
      case "image":
        newThumbnail =
          (editMode || duplicateMode) && img?.file
            ? img?.file?.thumb
            : URL.createObjectURL(img);
        break;
      case "pdf":
        newThumbnail = pdfThumbnailImg;
        break;
      default:
        newThumbnail = null;
    }
    setThumbnail(newThumbnail);

    // Cleanup function for object URLs
    return () => {
      if (newThumbnail && newThumbnail.startsWith("blob:")) {
        URL.revokeObjectURL(newThumbnail);
      }
    };
  }, [img, editMode, duplicateMode, videoThumbnailImg, pdfThumbnailImg]);

  return (
    <div className="flex flex-col justify-start items-end gap-1 w-[146px] h-[150px] mr-2">
      <div>
        <button
          className="gap-1"
          type="button"
          onClick={() => handleDeleteFile(img?.id ? img?.id : img?.path)}
        >
          <TrashIcon />
        </button>
      </div>
      <div className="gap-1 p-1 border border-dashed rounded-lg bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-full">
          <Dropzone
            onDrop={(acceptedFiles) =>
              handleUpdateFile(acceptedFiles[0], img?.path)
            }
            multiple={false}
            accept={acceptedFileTypes}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="absolute items-center rounded-full right-1 bg-bgWhite w-7 h-7 dark:bg-secondaryLight">
                  <CameraIcon className="ml-0.5 mt-0.5 justify-start items-center w-6 h-6" />
                </div>
              </div>
            )}
          </Dropzone>
          <div className="">
            <img
              src={thumbnail}
              alt=""
              className={`w-[138px] h-[80px] mx-auto ${fileType == "pdf" ? "object-contain" : "object-cover"
                }`}
            />
          </div>
          <div className="relative w-full" style={{ wordBreak: "break-word" }}>
            <p className="w-full text-center break-words dark:text-textMain " >
              {editMode || duplicateMode ? img.name : img?.path}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
