import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import Placeholderavatar from "assets/images/Placeholders/avatar-circle.png";

interface AdminProps {
  userData: any;
  unAssignAdmin: any;
  setSelectedUser: any;
}
const Admins: React.FC<AdminProps> = ({
  userData,
  unAssignAdmin,
  setSelectedUser,

}) => {
  return (
    <div>
      <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">
        {" "}
        <div
          onClick={() => setSelectedUser(userData)}
          className="flex items-center flex-grow w-full cursor-pointer"
        >
          <div className="relative flex items-center justify-between cursor-pointer">
            <img
              src={
                userData?.personImage?.thumb
                  ? userData?.personImage?.thumb
                  : Placeholderavatar
              }
              alt=""
              height="32px"
              width="32px"
              className="object-cover mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
            <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${userData.name} `}</p>{" "}
          </div>
        </div>
        <button type="button" className="flex-shrink-0">
          <XCloseSmallControlIcon onClick={() => unAssignAdmin(userData)} />
        </button>
      </div>
    </div>
  );
};

export default Admins;

// import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
// import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
// import ListImagePlaceholder from "assets/placeholders/listImageIcon";

// interface AdminProps {
//   userData: any;
//   unAssignAdmin: any;
// }
// const Admins: React.FC<AdminProps> = ({ userData, unAssignAdmin }) => {
//   return (
//       <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">//       <div
//         onClick={() => unAssignAdmin(userData)}
//         className="flex items-center flex-grow w-full cursor-pointer"
//       >
//         <div
//           onClick={() => unAssignAdmin(userData)}
//           className="relative flex items-center justify-between cursor-pointer"
//         >
//           {userData.personImage.file !== null ? (
//             <img
//               className="w-8 h-8 rounded-full"
//               src={userData?.personImage.file}
//               alt=""
//             />
//           ) : (
//             <ListImagePlaceholder />
//           )}
//           <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${userData.name}`}</p>{" "}
//         </div>
//       </div>
//       <button type="button" className="flex-shrink-0">
//         <XCloseSmallControlIcon onClick={() => unAssignAdmin(userData)} />
//       </button>
//     </div>
//   );
// };

// export default Admins;
