import React from "react";
import "./AllAnalytics.scss";
import { useDispatch } from "react-redux";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import {
  reportsAnalyticsActivityAction,
  reportsAnalyticsResetFiltersAction,
} from "store/filters";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

interface AllAnalyticsProps {}
export const AllAnalytics: React.FC<AllAnalyticsProps> = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const analyticsActions = () => {
    return [
      {
        label: "All",
        onClick: () => dispatch(reportsAnalyticsResetFiltersAction()),
        divider: false,
        isVisible: true,
      },
      {
        label: "Viewed",
        onClick: () => dispatch(reportsAnalyticsActivityAction("viewed")),
        divider: false,
        isVisible: true,
      },
      {
        label: "Download",
        onClick: () => dispatch(reportsAnalyticsActivityAction("download")),
        divider: false,
        isVisible: true,
      },
    ];
  };

  return (
    <div className="bg-bgWhite">
      <ActionDropDownMenu actions={analyticsActions()} />
    </div>
  );
};
