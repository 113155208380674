import  { useEffect, useRef, useState } from "react";

import {
  useDrawAllGlobalLayersOnMap,
  useDrawShapesOnMap,
  useHandleControlsForEditMode,
  useInitializeCustomLayersListControl,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
} from "../../hooks";


// const LAYER_DEFAULT_COLOR = "red";
// const LAYER_SELECTED_COLOR ="#FFA500";


const Map = ({ editMode, element, answer, setFieldValue, eleName,setDraw }: any) => {
  const mapContainer = useRef<any>(null);

  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const customControlId = "drawpolygon" + element.id;
  const customDeleteControlId = "drawpolygon#delete" + element.id;

  const [IsFeauresUpdateed, setIsFeauresUpdateed] = useState(false);

  // initializing the map
  const { map, isMapFullyLoaded, draw } = useInitializeMap(
    mapContainer,
    customControlId,
    customDeleteControlId,
    element?.type,
    editMode,
    element?.drawMultiple,
    element?.enableSnapping,
    setIsFeauresUpdateed
  );

  // initializing custom shapes dropdown to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    element.selectedLayers,
    customControlId
  );

  // painting the all global layers on the map
  // usePaintAllGlobalLayersOnMap(
  //   selectedGlobalLayers,
  //   map,
  //   isMapFullyLoaded,
  //   LAYER_DEFAULT_COLOR,
  //   draw
  // );

  // draw all global layers
  useDrawAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    draw
  );

  // handling the draw polygon and delete button
  useHandleControlsForEditMode(isMapFullyLoaded, editMode);

  // drawing shapes on the map
  useDrawShapesOnMap(draw, answer, editMode);

  useEffect(() => {
    if (!IsFeauresUpdateed) return;

    const geometryCollection = draw?.getAll();

    if (geometryCollection) {
      const data = geometryCollection.features
        ?.map((f: any) => {
          let id = f.id;

          if (id?.includes("draw")) {
            id = "draw#" + f.id.split("draw#").at(-1);
          }

          const name = answer?.find((ans: any) => ans.id === id)?.name ?? "";

          const mapData = {
            features: [f],
            type: "FeatureCollection",
          };

          return {
            type: "region",
            vectorLayers: [],
            legendFileId: null,
            name,
            geoFilePath: null,
            id,
            mapData,
          };
        })
        .filter((f: any) => !f.id.includes("global"));

      setFieldValue(eleName, data);
    }

    setIsFeauresUpdateed(false);
  }, [IsFeauresUpdateed]);

  useEffect(()=>{
    if(isMapFullyLoaded && draw){
        setDraw(draw)
    }
  },[isMapFullyLoaded])


  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
