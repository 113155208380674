import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateShapePickers,
} from "store/surveyJS";

import {
  AbleToSelectMultiple,
  ChooseFeatures,
  Description,
  MapCenterPoint,
  Required,
  SelectLayers,
  Title,
} from "./components";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";
import { panelsName } from "../../panelsTitle";

interface ShapePickerSidebarInterface {
  heading?: string;
  variant?: string;
}

const ShapePickerSidebar = ({
  heading,
  variant,
}: ShapePickerSidebarInterface) => {
  const {
    selectedQuestionElement,
    shapePickers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
    formAllSelectedShapes
  } = useSelector((state: RootState) => state.surveyJS);

  const dispatch = useDispatch();
  const [currentShapePicker, setCurrentShapePicker] = useState<any>({});

  const mapTypePanels = [
    panelsName.HARVEST,
    panelsName.GEOGRAPHICAL_INFORMATION,
  ];

  // structuring data of default layers according to dropdown component
  const defaultLayersSelectedOptions = structuringLayersData(
    currentShapePicker.selectedDefaultLayers,
    "options"
  );
  const defaultLayersOptions = removeMatchedEleFromTwoArrays(
    structuringLayersData(
      currentShapePicker.allGlobalCommunityLayers,
      "options"
    ),
    defaultLayersSelectedOptions,
    "label"
  );

  useEffect(() => {
    if (
      selectedQuestionElement &&
      !mapTypePanels.includes(selectedQuestionElement?.getType())
    ) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentShapePicker]);

  // recognize and set current shape picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;

    if (questionId) {
      let ele = shapePickers.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentShapePicker({ ...ele, title, description });
      } else if (
        selectedQuestionElement?.name === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        const shapepicker =
          variant === "retrieval"
            ? harvestPanelMapQuestions.retrievalShapePicker
            : harvestPanelMapQuestions.struckShapePicker;

        setCurrentShapePicker(shapepicker);
      } else if (
        selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        const shapepicker =
          variant === "region"
            ? geographicalPanelMapQuestions.regionTypeShapePicker
            : geographicalPanelMapQuestions.viewTypeShapePicker;

        setCurrentShapePicker(shapepicker);
      }
    }
  }, [
    selectedQuestionElement,
    shapePickers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
  ]);

  useEffect(()=>{
    console.log("All selected shapes",formAllSelectedShapes)
  },[formAllSelectedShapes])

  // handlers
  const handleCoummunityLayerOnSelect = (e: any) => {
    const selectedLayerId = e.target.value;
  
    const isLayerAlreadyAdded =
      currentShapePicker?.selectedLayersIdToHighlight?.find(
        (layerId: any) => layerId == selectedLayerId
      );

    if (!isLayerAlreadyAdded && currentShapePicker?.id) {
      // update current shape picker
      const updatedCurrentShapePicker = {
        ...currentShapePicker,
        selectedLayersIdToHighlight: [
          ...currentShapePicker.selectedLayersIdToHighlight,
          selectedLayerId,
        ],
      };

      // if shape picker belongs to harvest panel
      if (
        selectedQuestionElement?.name === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedHarvestPanelMapQuestions({
            ...harvestPanelMapQuestions,
            [variant === "retrieval"
              ? "retrievalShapePicker"
              : "struckShapePicker"]: updatedCurrentShapePicker,
          })
        );

        return;
      } else if (
        selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedGeographicalPanelMapQuestions({
            ...geographicalPanelMapQuestions,
            [variant === "region"
              ? "regionTypeShapePicker"
              : "viewTypeShapePicker"]: updatedCurrentShapePicker,
          })
        );

        return;
      }

      // update current element state into global elements
      updateElementByIdFromArray(
        shapePickers,
        updatedCurrentShapePicker,
        (updatedShapePickers: any) => {
          setCurrentShapePicker(updatedCurrentShapePicker);
          dispatch(updateShapePickers(updatedShapePickers));
        }
      );
    }
  };
  const handleClickOnRemoveLayer = (id: any) => {
    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.selectedLayersIdToHighlight =
      updatedCurrentShapePicker.selectedLayersIdToHighlight?.filter(
        (layerId: any) => layerId != id
      );

    // if shape picker belongs to harvest panel
    if (
      selectedQuestionElement?.name === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalShapePicker"
            : "struckShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    } else if (
      selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          [variant === "region"
            ? "regionTypeShapePicker"
            : "viewTypeShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    }
    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.mapCenterPoint = {
      ...updatedCurrentShapePicker.mapCenterPoint,
      doFly: false,
      [name]: value,
    };

    // if shape picker belongs to harvest panel
    if (
      selectedQuestionElement?.name === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalShapePicker"
            : "struckShapePicker"]: updatedCurrentShapePicker,
        })
      );
      return;
    } else if (
      selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          [variant === "region"
            ? "regionTypeShapePicker"
            : "viewTypeShapePicker"]: updatedCurrentShapePicker,
        })
      );
      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentShapePicker = { ...currentShapePicker };

    // update current element
    updatedCurrentShapePicker.mapCenterPoint = {
      ...updatedCurrentShapePicker.mapCenterPoint,
      doFly: true,
    };

    // if shape picker belongs to harvest panel
    if (
      selectedQuestionElement?.name === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalShapePicker"
            : "struckShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    } else if (
      selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          [variant === "region"
            ? "regionTypeShapePicker"
            : "viewTypeShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    }
    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleAbleToSelectMultipleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.ableToCheckMultiple = isChecked;

    // if shape picker belongs to harvest panel
    if (
      selectedQuestionElement?.name === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalShapePicker"
            : "struckShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    } else if (
      selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          [variant === "region"
            ? "regionTypeShapePicker"
            : "viewTypeShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    }
    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleDefaultLayersOnSelection = (option: any) => {
    if (
      !JSON.stringify(currentShapePicker.selectedDefaultLayers).includes(
        option.label
      )
    ) {
      // update current element
      const updatedCurrentShapePicker = { ...currentShapePicker };

      updatedCurrentShapePicker.selectedDefaultLayers = [
        ...updatedCurrentShapePicker.selectedDefaultLayers,
        ...structuringLayersData(
          [option],
          "original",
          currentShapePicker.allGlobalCommunityLayers
        ),
      ];

      // if shape picker belongs to harvest panel
      if (
        selectedQuestionElement?.name === panelsName.HARVEST &&
        harvestPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedHarvestPanelMapQuestions({
            ...harvestPanelMapQuestions,
            [variant === "retrieval"
              ? "retrievalShapePicker"
              : "struckShapePicker"]: updatedCurrentShapePicker,
          })
        );

        return;
      } else if (
        selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
        geographicalPanelMapQuestions?.id
      ) {
        dispatch(
          updateAddedGeographicalPanelMapQuestions({
            ...geographicalPanelMapQuestions,
            [variant === "region"
              ? "regionTypeShapePicker"
              : "viewTypeShapePicker"]: updatedCurrentShapePicker,
          })
        );

        return;
      }
      // update current element state into global elements
      updateElementByIdFromArray(
        shapePickers,
        updatedCurrentShapePicker,
        (updatedShapePickers: any) => {
          setCurrentShapePicker(updatedCurrentShapePicker);
          dispatch(updateShapePickers(updatedShapePickers));
        }
      );
    }
  };
  const handleDefaultLayersOnRemove = (option: any) => {
    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.selectedDefaultLayers =
      currentShapePicker.selectedDefaultLayers.filter(
        (l: any) => l.name !== option.label
      );

    // if shape picker belongs to harvest panel
    if (
      selectedQuestionElement?.name === panelsName.HARVEST &&
      harvestPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedHarvestPanelMapQuestions({
          ...harvestPanelMapQuestions,
          [variant === "retrieval"
            ? "retrievalShapePicker"
            : "struckShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    } else if (
      selectedQuestionElement?.name === panelsName.GEOGRAPHICAL_INFORMATION &&
      geographicalPanelMapQuestions?.id
    ) {
      dispatch(
        updateAddedGeographicalPanelMapQuestions({
          ...geographicalPanelMapQuestions,
          [variant === "region"
            ? "regionTypeShapePicker"
            : "viewTypeShapePicker"]: updatedCurrentShapePicker,
        })
      );

      return;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentShapePicker = { ...currentShapePicker };

    updatedCurrentShapePicker.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      shapePickers,
      updatedCurrentShapePicker,
      (updatedShapePickers: any) => {
        setCurrentShapePicker(updatedCurrentShapePicker);
        dispatch(updateShapePickers(updatedShapePickers));
      }
    );
  };

  return (
    <div className="flex flex-col pt-[40px] px-[32px] bg-[#F3F5F7]  w-full gap-8">
      {heading && <h1 className="font-semibold">{heading}</h1>}
      {!mapTypePanels.includes(
        selectedQuestionElement.isPanel
          ? selectedQuestionElement.name
          : selectedQuestionElement?.getType()
      ) && (
        <>
          <Title
            handleTitleOnChange={handleTitleOnChange}
            value={currentShapePicker.title}
          />
          <Description
            handleDescriptionOnChange={handleDescriptionOnChange}
            value={currentShapePicker.description}
          />
          <Required
            handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
            checked={currentShapePicker.isRequired}
          />
        </>
      )}
      <ChooseFeatures
        allCommunityLayers={currentShapePicker.allCommunityLayers}
        handleCoummunityLayerOnSelect={handleCoummunityLayerOnSelect}
        selectedLayersIdToHighlight={
          currentShapePicker.selectedLayersIdToHighlight
        }
        handleClickOnRemoveLayer={handleClickOnRemoveLayer}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentShapePicker.mapCenterPoint}
      />
      <AbleToSelectMultiple
        handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
        ableToCheckMultiple={currentShapePicker.ableToCheckMultiple}
      />
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      />
    </div>
  );
};

export { ShapePickerSidebar };

function structuringLayersData(
  data: any,
  converter: "original" | "options",
  originalData?: any
) {
  let structureData = [];

  if (converter === "options" && data) {
    structureData = data.map((l: any) => ({ value: l.id, label: l.name }));
  } else if (converter === "original" && originalData) {
    structureData = originalData.filter((l: any) =>
      JSON.stringify(data).includes(l.id.toString())
    );
  }

  return structureData;
}
