import React from "react";
// Components
import { Filters, Header, ReportCard } from "Components/RunReport";

export const RunReport = () => {
  return (
    <div className="flex flex-col justify-start items-start w-full pt-4 pb-20">
      <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-6 mb-10">
              <Filters />
            </div>
            <ReportCard />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RunReport;
