import { TableBodyProps } from "../../types";
import { Cell } from "@tanstack/react-table";
import { Column } from "../index";
import { useDispatch } from "react-redux";
import {
  openDeleteFileModalAction,
  openImageLightBoxModalAction,
  openVideoLightBoxModalAction,
} from "store/modals/reducer.actions";
import DownloadIcon from "assets/icons/HeroIcons/DownloadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { useParams } from "react-router-dom";
import axios from "axios";
import usePermissions from "hooks/usePermissions";
// import { download } from "react-file-download";
export const TableBody = ({
  table,
  columnVisibility,
  flexRender,
}: TableBodyProps) => {
  const dispatch = useDispatch();
  const { programId } = useParams<{
    programId: string;
  }>();
  const { programs } = usePermissions();

  function getFileType(filePath: string, type: any) {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      dispatch(
        openImageLightBoxModalAction({
          width: 1080,
          height: 1620,
          filePath: filePath,
        })
      );
    } else if (type === "pdf") {
      window.open(filePath, "_blank");
    } else if (type === "mp3" || type === "mpeg") {
      window.open(filePath, "_blank");
    } else if (type.match(/(mp4|avi|mkv|wmv|flv|mov)$/)) {
      dispatch(
        openVideoLightBoxModalAction({
          filePath: filePath,
          fileType: type,
        })
      );
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      // Handle document files
    } else if (type.match(/(txt|csv)$/)) {
      // Handle text files
    } else if (type.match(/(zip|rar|7z)$/)) {
      // Handle archive files
    } else {
      return "unknown";
    }
  }

  const PreviewFiles = (file: any) => {
    getFileType(file.fileName.path, file?.fileType);
  };

  const handleDownload = async (url: any, fileName: any) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName; // Specify the desired file name

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Remove the link element
      document.body.removeChild(link);
    } catch (error: any) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <tbody>
      {table?.getRowModel()?.rows?.map((row: any, index: number) => (
        <tr
          key={index}
          className="cursor-pointer  Close w-full item_data border-y dark:border-lineLight dark:bg-secondaryLight dark:hover:bg-primaryExtraLight group"
          style={{ height: "66px" }}
        >
          {row
            ?.getVisibleCells()
            ?.map((cell: Cell<any, any>) => (
              <Column
                previewFiles={() => PreviewFiles(row?.original)}
                flexRender={flexRender}
                cell={cell}
                columnVisibility={columnVisibility}
              />
            ))}
          <td className="w-[90px]">
            <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
              <button
                onClick={() =>
                  handleDownload(
                    row?.original?.fileName?.path,
                    row?.original?.fileName?.fileName
                  )
                }
                className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
              >
                <DownloadIcon fill="#2C3236" width={24} height={24} />
              </button>
              {programs.canDeleteEntity && (
                <button
                  onClick={() =>
                    dispatch(
                      openDeleteFileModalAction({
                        programId,
                        id: row?.original.fileName.id,
                      })
                    )
                  }
                  className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
                >
                  <TrashIcon fill="#2C3236" width={24} height={24} />
                </button>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
