import { apiClient } from "../config";
async function getExternalReviewers(searchQuery?: string) {
  const params = {
    searchString: searchQuery,
    limit: 100,
  };

  const response = await apiClient.get(`/admin/qa/external_reviewers`, {
    params,
  });
  return response.data;
}
export default getExternalReviewers;
