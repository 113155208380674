import { SearchableDropdown } from "../../../index";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";

// types
type OptionType = {
  value: string;
  label: string;
};
type SelectLayersProps = {
  handleSelectorOnChange: (option: OptionType) => void;
  handleLayerOnRemove: (option: OptionType) => void;
  selectedOptions: any;
  options: any;
};
type SelectedDefaultLayersProps = {
  selectedOptions: OptionType[];
  onRemoveSelection: (option: OptionType) => void;
};
type LayerItemProps = {
  option: OptionType;
  onDelete: () => void;
};

const SelectLayers = ({
  handleSelectorOnChange,
  handleLayerOnRemove,
  selectedOptions,
  options,
}: SelectLayersProps) => {
  return (
    <div>
      <h2 className="mb-2 text-secondaryMidLight">Select Layers </h2>
      <SearchableDropdown
        options={options}
        onAddSelection={handleSelectorOnChange}
      />
      <SelectedDefaultLayers
        selectedOptions={selectedOptions}
        onRemoveSelection={handleLayerOnRemove}
      />
    </div>
  );
};

const SelectedDefaultLayers = ({
  selectedOptions,
  onRemoveSelection,
}: SelectedDefaultLayersProps) => {
  return (
    <div className="bg-bgWhite shadow overflow-hidden mt-2">
      <ul className="divide-y divide-lineLightExtra">
        {selectedOptions.map((option, index) => (
          <LayerItem
            key={index}
            option={option}
            onDelete={() => onRemoveSelection(option)}
          />
        ))}
      </ul>
    </div>
  );
};

const LayerItem = ({ option, onDelete }: LayerItemProps) => {
  return (
    <li className="flex items-center justify-between p-2 hover:bg-gray-100">
      <div className="flex items-center">
        <span className="font-medium text-gray-700">{option.label}</span>
      </div>
      <button onClick={onDelete} className="text-gray-400 hover:text-gray-600">
        <TrashIcon fill="#6F747B" />
      </button>
    </li>
  );
};

export { SelectLayers };
