import axios from "axios";
import { baseURL } from "config";
import apiClient from "services/apiClient";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getAuthToken = () => cookies.get("token") || "";
const authToken = getAuthToken();
interface IQueryParams {
  id: number;
}

async function updateObservation(queryParams: IQueryParams, body: any) {
  const res = await apiClient.put(
    `${baseURL}/admin/observations`,
    {
      dynamicFormId: body.dynamicForm.id,
      id: body.id,
      pages: [
        {
          elements: body.sections,
        },
      ],
      status: body.status,
    },
    {
      params: queryParams,
    }
  );

  return res.data;

  // const res = await axios.put(
  //   `${baseURL}/admin/observations`,
  //   {
  //     dynamicFormId: body.dynamicForm.id,
  //     id: body.id,
  //     pages: [
  //       {
  //         elements: body.sections,
  //       },
  //     ],
  //     status: body.status,
  //   },

  //   {
  //     headers: {
  //       Authorization: `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhcHAiOm51bGwsImF1ZCI6ImFwaSIsImV4cCI6MTcwNjU1OTI4OCwiaWF0IjoxNzA2NTE2MDg4LCJpc3MiOiJhcGkiLCJqdGkiOiIyNDUxZTc4Mi02NzA5LTQxM2YtOWZlZC1jMDAwYWQ5ZWZmMjUiLCJuYmYiOjE3MDY1MTYwODcsInN1YiI6IjF8aXNuX2FkbWlufHRlbmFudDEiLCJ0ZW5hbnQiOiJ0ZW5hbnQxIiwidHlwIjoiYWNjZXNzIn0.EZFZrfQuQvljwhsO279Ic1K1KeDG7jBLJHZzfJUahc5tL9DehzKY8XnylUaf9QyOoeSkZOLQuDQX96yOOnu2NA`,
  //     },

  //     params: queryParams,
  //   }
  // );

  // return res.data;
}

export default updateObservation;
