import questionTypes from "../questionTypes";
import convertQuestionObj from "../utils/convertQuestionObj";
import { v4 as uuidv4 } from "uuid";

function convertJsonOfGeographicalPanel(
  ele: any,
  globalStoreData: any,
  panel: any
) {
  const geographicalPanel = globalStoreData.find((d: any) => d?.id === ele._id);
  console.log("panel", panel);
  const newTemplateELements = panel.elements
    .filter((item: any) => {
      if (item.type === "html") {
        return false;
      }
      // else if(ele.showRegion == false && item.name === "Region"){
      //   return false;
      // }
      // else if(ele.showView == false && item.name === "View"){
      //   return false;
      // }
      // else if(ele.showVantagePoint == false && item.name === "VantagePoint"){
      //   return false;
      // };
      return true;
    })
    .map((e: any) => {
      if (e.type === questionTypes.shapePicker && e.name === "Region") {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: geographicalPanel?.regionTypeShapePicker?.id },
            [geographicalPanel?.regionTypeShapePicker]
          ),
        };
      } else if (e.type === questionTypes.shapePicker && e.name === "View") {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: geographicalPanel?.viewTypeShapePicker?.id },
            [geographicalPanel?.viewTypeShapePicker]
          ),
        };
      } else if (e.type === questionTypes.pointPicker) {
        return {
          ...e,
          ...convertQuestionObj(
            { ...e, _id: geographicalPanel?.pointPicker?.id },
            [geographicalPanel?.pointPicker]
          ),
        };
      }
      return e;
    });
  panel.elements = newTemplateELements?.map((element: any) => {
    return {
      ...element,
      elementId: uuidv4(),
    };
  });

  return {
    ...ele,
    ...panel,
  };
}

export { convertJsonOfGeographicalPanel };
