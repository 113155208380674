export interface IChat {
  messageBy: string;
  messageDate: string;
  messageSendTime: string;
  messageText: string;
}

export interface IChatUser {
  firstName: string;
  lastName: string;
  personImage: string;
  userId: number;
}

export interface IChatDetails {
  data: {
    chats: IChat[];
    receiver: IChatUser;
    sender: IChatUser;
    totalItems: number;
    totalPages: number;
  };
  isLoading: boolean;
}

export interface MessageFormValues {
  message: string;
  fileId: string | null;
}

const initialState: IChatDetails = {
  data: {
    chats: [],
    receiver: {
      firstName: "",
      lastName: "",
      personImage: "",
      userId: 0,
    },
    sender: {
      firstName: "",
      lastName: "",
      personImage: "",
      userId: 0,
    },
    totalItems: 0,
    totalPages: 3,
  },
  isLoading: false,
};

export default initialState;
