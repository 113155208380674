import React, { useEffect, useState } from "react";
// Store Utils
import { useDispatch } from "react-redux";
import { qualityAssuranceObservationsStatusAction } from "store/filters/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";
import { updateColumnsVisibilityOfQualityAssuranceObservationsByStatus } from "store/directoriesOrders/reducer.actions";
import { IAuthResponseData } from "store/auth/initialState";
import useRoles from "hooks/roles";

export const Tabs = () => {
  const { isExternalReviewer, isInternalReviewer } = useRoles();

  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const dispatch = useDispatch();

  const TabsData = [
    { title: "Assigned to me", value: "assigned_to_me", isVisible: true },
    { title: "Assigned to others", value: "assigned_to_others", isVisible: !isExternalReviewer() && isInternalReviewer() },
    { title: "Unassigned", value: "unassigned", isVisible: !isExternalReviewer() && isInternalReviewer() },
    { title: "Complete", value: "completed", isVisible: true },
    { title: "Incomplete", value: "incompleted", isVisible: !isExternalReviewer() },
    { title: "Archived", value: "archived", isVisible: !isExternalReviewer() },
  ];
  //handlers
  const handleLink = (linkName: string) => {
    dispatch(qualityAssuranceObservationsStatusAction(linkName));
  };

  return (
    <>
      {TabsData?.map((item: any, index: number) => {
        if (item.isVisible) {
          return (
            <div
              key={index}
              className={`group flex cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 px-4 py-3 ${qualityAssuranceObservationsFilter.status === item.value
                ? "border-b-2 border-primaryMid "
                : "border-lineMid border-b-2  hover:border-lineDarkExtra"
                }`}
              onClick={() => handleLink(item.value)}
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1.5 pt-1.5 pb-2 ">
                <p className={`flex-grow-0 flex-shrink-0 text-sm font-semibold text-center uppercase text-secondary group-hover:dark:text-textMain ${qualityAssuranceObservationsFilter.status === item.value
                  ? " dark:text-textMain "
                  : "dark:text-caption "
                  }`}>
                  {sentenceCase(item.title)}
                </p>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
