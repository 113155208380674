import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import { Link, useNavigate } from "react-router-dom";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import { sentenceCase } from "change-case";
import useRoles from "hooks/roles";
import usePermissions from "hooks/usePermissions";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const navigate = useNavigate();
  const { isUserInternalReviewer } = useRoles();
  const { communities } = usePermissions();
  const navigateToCommunityProfile = (communityName: any) => {
    if (communityName?.accessibleForSuperAdmin) {
      navigate(`/community/${communityName.id}/profile`);
    }
    return;
  };
  const { formatDate } = useDateFormatter();
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "communityName"
  ) {
    return (
      <Link
        to={
          cellValue.accessibleForSuperAdmin &&
          communities.canViewViewCommunities &&
          !isUserInternalReviewer()
            ? `/community/${cellValue.id}/profile`
            : ""
        }
      >
        <div className="flex justify-start items-center relative w-[230px] group">
          <img
            src={
              cellValue?.profileImage
                ? cellValue?.profileImage
                : PlaceholderAvatar
            }
            alt=""
            height="100"
            width="100"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: "wrap" }}
            className=" flex flex-col w-full justify-start items-start"
          >
            <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark py-2">
              {`${cellValue?.name}`}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (
    (cellValue !== null && columnDef.id === "organizations") ||
    columnDef.id === "communityAdmins"
  ) {
    const formattedValue = ellipsize(`${cellValue}`, 50);
    const commaSeparatedValue = formattedValue.split(",").join(", ");
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-[8.5px] ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {commaSeparatedValue}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "type") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {sentenceCase(ellipsize(`${cellValue}`, 50))}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {/* {sentenceCase(ellipsize(`${cellValue}`, 50))} */}
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
