import { useEffect, useState } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "Components/Geography/MapBox/components/DefaultMap/handlers";
import { handlePaintingOfRasterLayersOnMap } from "Components/Geography/MapBox/components/DefaultMap/handlers/handlePaintingOfRasterLayersOnMap";

const usePaintAllGlobalLayersOnMap = (
  layers: any,
  map: any,
  isMapFullyLoaded: boolean,
  LAYER_DEFAULT_COLOR: string,
  draw?: any,
) => {
  useEffect(() => {
    if (layers && map && isMapFullyLoaded) {
      layers.forEach((layer: any) => {
        const item = {
          item: layer,
          itemType: layer.type,
          status: layer.status,
        };

        // paint layers on map
        if (item.itemType === "raster") {
          handlePaintingOfRasterLayersOnMap(item, map);
        } else if (item.itemType === "vector") {
          handlePaintingOfVectorRegionShapeLayersOnMap(
            item,
            map,
            draw,
            LAYER_DEFAULT_COLOR
          );
        }
      });
    }
  }, [map, isMapFullyLoaded, layers]);

  return null;
};

export { usePaintAllGlobalLayersOnMap };
