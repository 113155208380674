export interface IObservationsActivities {
  addedBy: {
    id: number;
    image: {
      file: {
        large: string;
        original: string;
        thumb: string;
      };
      id: number;
      path: string;
    };
    name: string;
  };
  id: number;
  message: string;
  messageDate: any;
  messageDetails: {
    flag: string;
    sectionName: string;
    status: string;
  };
  messageTime: string;
}

export interface IobservationsActivitiesData {
  activities: IObservationsActivities[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchObservationsActivities {
  data: IobservationsActivitiesData;
  message: string;
  isLoading: boolean;
}

const initialObservationsActivitiesData: IfetchObservationsActivities = {
  data: {
    activities: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  isLoading: false
};

export default initialObservationsActivitiesData;
