import React, { useEffect, useState } from "react";
import { Map, RegionFilterFieldsArray } from "./components";
interface ChooseFromExistingBoundaryProps {
  selectedOption: any;
  reportId?: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleBlur: any;
  isSubmitting: boolean;
  reportSummary: any;
}

export const ChooseFromExistingBoundary: React.FC<
  ChooseFromExistingBoundaryProps
> = ({
  selectedOption,
  reportId,
  errors,
  values,
  touched,
  handleBlur,
  setFieldValue,
  isSubmitting,
  reportSummary,
}) => {
  const [selectedShapeRegions, setSelectedShapeRegions] = useState<any[]>([]);
  const updateSelectedShapeRegions = (newRegion: any) => {
    setSelectedShapeRegions((pre: any[]) => {
      const updatedRegions = [...pre, newRegion];

      const updatedRegionFilter = values.regionFilter.map((item: any) => ({
        ...item,
        regions: updatedRegions,
      }));

      setFieldValue("regionFilter", updatedRegionFilter);
      return updatedRegions;
    });
  };

  useEffect(() => {
    if (values.regionFilter.length === 0) {
      setSelectedShapeRegions([]);
    } else if (values.regionFilter.length > 0) {
      setSelectedShapeRegions(values.regionFilter[0].regions);
    }
  }, [values.regionFilter]);

  return (
    <>
      <Map
        selectedOption={selectedOption}
        selectedGeographies={selectedShapeRegions}
        updateSelectedShapeRegions={updateSelectedShapeRegions}
        reportId={reportId}
        reportSummary={reportSummary}
      />

      <RegionFilterFieldsArray
        reportId={reportId}
        errors={errors}
        touched={touched}
        values={values}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
        selectedShapeRegions={selectedShapeRegions}
      />
    </>
  );
};

export default ChooseFromExistingBoundary;
