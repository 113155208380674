import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeCreateCommunityModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { useNavigate } from "react-router-dom";
import { AddCommunity } from "./components/AddCommunity";
import { AddAdmin } from "./components/AddAdmin";
import { AddOrganization } from "./components/AddOrganization";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // width: "35%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#ffff",
};

interface CreateCommunityModalProps {}

export const CreateCommunityModal: React.FC<CreateCommunityModalProps> = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Community Details", "Add Admins", "Add Organizations "];
  const { createCommunityModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch = useDispatch();
  const { communities } = usePermissions();

  // Store the validation schema for each step separately
  const createCommunityValidationSchema = [
    Yup.object().shape({
      address: Yup.string().required("Address is required"),
      backgroundImageId: Yup.number(),
      location: Yup.object().shape({
        lat: Yup.string(),
        long: Yup.string(),
      }),
      missionStatement: Yup.string().max(6000, "6000 Max Characters"),
      name: Yup.string()
        .required("Community Name is required")
        .max(255, "255 Max Characters"),
      profileImageId: Yup.string(),
      type: Yup.string().required("Type is required"),
    }),
    Yup.object().shape({
      adminIds: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number().required("Admin ID is required"),
          })
        )
        .required("Admin IDs are required"),
    }),
    Yup.object().shape({
      organizationIds: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number().required("Organization ID is required"),
          })
        )
        .required("Organization IDs are required"),
    }),
  ];

  const currentValidationSchema = createCommunityValidationSchema[activeStep];

  const handleClose = () => {
    dispatch(closeCreateCommunityModalAction());
    setActiveStep(0);
  };

  const initialValues = {
    address: "",
    adminIds: [],
    backgroundImageId: "",
    location: {
      lat: "",
      long: "",
    },
    missionStatement: "",
    name: "",
    organizationIds: [],
    profileImageId: "",
    profileImage: "",
    type: "Open",
  };
  const isLastStep = activeStep === steps.length - 1;

  async function _submitForm(values: any, actions: any) {
    setIsLoading(true);
    try {
      const {
        backgroundImageId,
        profileImageId,
        name,
        address,
        location,
        missionStatement,
        organizationIds,
        adminIds,
        type,
      } = values;
      const res = await apiLibrary.Communities.createCommunity({
        backgroundImageId,
        profileImageId,
        name,
        address,
        location,
        missionStatement,
        adminIds: adminIds.map((admin: any) => {
          return admin.id;
        }),
        organizationIds: organizationIds.map((organization: any) => {
          return organization.id;
        }),
        type: type.toLowerCase(),
      });
      Toasts.success(res.message);
      handleClose();
      if (
        communities.canViewViewCommunities &&
        communities.canViewSideBarCommunities
      ) {
        navigate(`/community/${res?.data?.id}/profile`);
      } 
      setActiveStep(0);
    } catch (error: any) {
      // Handle any errors that occur during the API call
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("Error creating community:", error);
      // You can also set form errors if needed
      // actions.setErrors({ apiError: "Failed to create community" });
    } finally {
      setIsLoading(false);
    }
  }

  function _handleSubmit(
    values: any,
    actions: FormikHelpers<any> | any // Use FormikHelpers<any> or 'any' for compatibility
  ) {
    if (isLastStep) {
      _submitForm(values, actions);
    }
    if (activeStep === 1 && !communities.canViewOrganizationsCommunities) {
      _submitForm(values, actions);
    } else {
      setActiveStep((pre: any) => {
        if (pre === 2) {
          return pre;
        } else {
          return activeStep + 1;
        }
      });
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _renderStepContent(step: number, formikProps: FormikProps<any>) {
    switch (step) {
      case 0:
        return (
          <AddCommunity
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 1:
        return (
          <AddAdmin
            {...formikProps}
            handleClose={handleClose}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      case 2:
        return (
          <AddOrganization
            {...formikProps}
            handleClose={handleClose}
            {...formikProps}
            isLastStep={isLastStep}
            activeStep={activeStep}
            isFormLoading={isLoading}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <Modal
      open={createCommunityModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
          validateOnChange={false}
        >
          {(formikProps) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 bg-bgWhite rounded-lg dark:bg-secondaryLight"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  {_renderStepContent(activeStep, formikProps)}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
