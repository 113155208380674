import { CheveronDownIcon } from "assets/icons/HeroIcons";
import React, { useEffect, useState } from "react";
import CustomCheckbox from "view/components/CheckBox";
import UserAvatarIcon from "assets/icons/HeroIcons/UserAvatarIcon";
import userPlaceholder from "assets/images/Placeholders/avatar-circle.png";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { SearchBar } from "./SearchBar";
import { AddRoles } from "./AddRoles";
import { AddPrograms } from "./AddPrograms";
import { AddCommunities } from "./AddCommunities";
import Placeholderavatar from "assets/images/Placeholders/avatar-circle.png";
import { reminders } from "utils/dummyDataNotificationsRecipients";
import usePermissions from "hooks/usePermissions";
import { AddApp } from "./AddApp";

interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  step: number;
}

export const InputFields: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  step,
}) => {
  // USERS STATE
  const [usersData, setUsersData] = useState<any[]>([]);
  const [searchedUsersList, setSearchedUsersList] = useState<any[]>([]);
  const [searchString, isSearchString] = useState("");
  const [totalMembers, setTotalMembers] = useState(0);
  const { reminders } = usePermissions();

  const handleMembersSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    isSearchString(searchValue);
    if (searchString !== "") {
      const items = usersData.filter((entity: any) =>
        entity.name.toLowerCase().includes(searchString.toLowerCase())
      );

      setSearchedUsersList(items);
    } else {
      setSearchedUsersList([]);
    }
  };

  const handleMembersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (checked) {
      setFieldValue("targetPerson.personIds", [
        ...values.targetPerson.personIds,
        Number(value),
      ]);
    } else {
      setFieldValue(
        "targetPerson.personIds",
        values.targetPerson.personIds.filter(
          (item: any) => item !== Number(value)
        )
      );
    }
    setFieldTouched("targetPerson.personIds", false);
  };

  const handleSelectAllMembers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const allPersonIds = usersData?.map((item: any) => item.personId);
      setFieldValue("targetPerson.personIds", allPersonIds);
    } else {
      setFieldValue("targetPerson.personIds", []);
    }
  };

  const getRolesProgramsCommunitiesValues = (data: any[]) => {
    const values = data?.map((item: any) => item.value);
    return values;
  };

  useEffect(() => {
    if (values.targetPerson.appName === "") return;
    fetchUsers();
  }, [
    values.targetPerson.appName,
    values.targetPerson.communityIds,
    values.targetPerson.programIds,
    values.targetPerson.roles,
    step,
  ]);

  const fetchUsers = async () => {
    const queryParams = {
      communityIds: getRolesProgramsCommunitiesValues(
        values.targetPerson.communityIds
      ),
      programIds: getRolesProgramsCommunitiesValues(
        values.targetPerson.programIds
      ),
      roleIds: getRolesProgramsCommunitiesValues(values.targetPerson.roles),
      appName: values.targetPerson.appName,
      limit: 30000,
    };
    try {
      const { data } =
        await apiLibrary.Notifications.getAllTargetMembers(queryParams);
      //   setFieldValue("targetPerson.personIds", data.members);
      setUsersData(data?.members);
      setTotalMembers(data?.totalItems);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const existingUsersList = searchString !== "" ? searchedUsersList : usersData;

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="mb-2">
        <p className="text-sm text-textMidLight font-Overpass dark:text-textMain">
          How would you like to target your notification? You can fitler your
          audience by roles, programs and communities or select members
          manually.
        </p>
      </div>
      <div className="w-full py-1">
        <AddApp
          handleAddAppsOnChange={(value: any) => {
            setFieldValue("targetPerson.appName", value);
          }}
          errors={errors}
          values={values}
        />
        <AddRoles
          handleAddRolesOnChange={(options: any) => {
            setFieldValue("targetPerson.roles", options);
          }}
          values={values}
        />

        <AddPrograms
          handleAddProgramsOnChange={(options: any) => {
            setFieldValue("targetPerson.programIds", options);
          }}
          values={values}
        />

        <AddCommunities
          handleAddCommunitiesOnChange={(options: any) => {
            setFieldValue("targetPerson.communityIds", options);
          }}
          values={values}
        />
      </div>
      {reminders.canViewTargetMembersReminders && (
        <div className="w-full mt-2">
          <div className="w-full">
            <SearchBar
              handleMembersSearch={handleMembersSearch}
              searchString={searchString}
            />
          </div>
          <div>
            <div>
              <CustomCheckbox
                name="members"
                disabled={values.targetPerson.appName === ""}
                checked={
                  existingUsersList.length > 0 &&
                  values?.targetPerson?.personIds.length ===
                    existingUsersList.length
                }
                indeterminate={
                  values?.targetPerson?.personIds?.length > 0 &&
                  values?.targetPerson?.personIds?.length !==
                    existingUsersList?.length
                }
                onChange={handleSelectAllMembers}
                label={
                  <p className="text-secondaryMid font-Overpass dark:text-textMain mb-[-3px]">
                    Selected {values?.targetPerson?.personIds?.length} of{" "}
                    {totalMembers} members
                  </p>
                }
                labelPlacement="end"
              />
            </div>
            <div className="flex items-center">
              <p className="dark:text-textMain">Selected </p>
              <CheveronDownIcon
                height={16}
                width={16}
                fill="#2C3236"
                className="ml-3"
              />
            </div>

            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched?.targetPerson?.personIds &&
                errors?.targetPerson?.personIds && (
                  <p className="flex-grow text-xs text-left text-accent_1Dark">
                    {errors?.targetPerson?.personIds}
                  </p>
                )}
            </div>
            <div>
              <div className="flex flex-col max-h-[400px] overflow-x-hidden overflow-y-auto">
                {existingUsersList?.map((item: any, key: number) => {
                  return (
                    <CustomCheckbox
                      name="values.targetPerson.personIds"
                      checked={values?.targetPerson?.personIds?.includes(
                        item.personId
                      )}
                      disabled={values.targetPerson.appName === ""}
                      onChange={handleMembersChange}
                      value={item.personId}
                      label={
                        <div className="flex items-center">
                          {item?.personImage?.thumb ? (
                            <>
                              <img
                                src={item?.personImage?.thumb}
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_user_Data h-[28px] w-[28px]"
                                style={{ flexShrink: 0 }}
                              />
                            </>
                          ) : (
                            <div>
                              <img
                                src={userPlaceholder}
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_user_Data h-[28px] w-[28px]"
                                style={{ flexShrink: 0 }}
                              />
                            </div>
                          )}
                          <p className="ml-3 break-words break-all text-secondaryMid font-Overpass dark:text-textMain">
                            {item.name}
                          </p>
                        </div>
                      }
                      labelPlacement="end"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
