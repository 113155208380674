import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeManageConnectionProtocolModal } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import ManageConnectionModalForm from "./ManageConnectionModalForm";
import { Toasts } from "view/components/Toasts";
import { fetchProtocolsAction } from "store/protocols";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

// Program Interface
interface ProgramItem {
  value: string;
  label: string;
}

interface FormItem {
  value: string;
  label: string;
}

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */

const ManageConnectionProtocolModal = () => {
  const { manageConnectioProtocolModal } = useSelector(
    (state: RootState) => state.modals
  );

  const [programList, setProgramList] = useState<ProgramItem[]>([]);
  const [formList, setFormsList] = useState<FormItem[]>([]);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programId } = useParams();
  const { protocols } = usePermissions();

  // Fetch Protocol Profile and Programs on component mount
  useEffect(() => {
    fetchProtocolPrograms();
    if (manageConnectioProtocolModal.isOpen) {
      if (manageConnectioProtocolModal.data.programId.value !== "") {
        console.log("hellooo", manageConnectioProtocolModal.data.programId);
        fetchProtocolFormsByPrograms(
          manageConnectioProtocolModal.data.programId
        );
      }
    }
  }, [manageConnectioProtocolModal.isOpen]);

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const formValues = {
        ...values,
        id: manageConnectioProtocolModal.data.id,
      };

      if (values.programId) {
        formValues.programId = values.programId.value;
      }

      if (values.formId) {
        formValues.formId = values.formId.value;
      }

      if (formValues.programId) {
        let disconnectSuccessful = true;
        if (
          manageConnectioProtocolModal?.data.programId?.value &&
          formValues.programId !==
            manageConnectioProtocolModal?.data.programId?.value &&
          protocols.canDisconnectProgramForm
        ) {
          try {
            const { data, message } =
              await apiLibrary.Protocols.disconnectProtocol(
                manageConnectioProtocolModal?.data.id
              );
          } catch (error: any) {
            disconnectSuccessful = false;
          }
        }

        if (disconnectSuccessful && protocols.canConnectProgramForm) {
          try {
            const { data, message } =
              await apiLibrary.Protocols.connectProtocol(
                manageConnectioProtocolModal?.data.id,
                formValues
              );
            Toasts.success(message);
            dispatch(fetchProtocolsAction(programId));
          } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error.message;
            Toasts.error(errorMsg);
          }
        } else {
          Toasts.error("Disconnect failed, connection not attempted.");
        }
        handleClose();
      } else {
        if (!protocols.canDisconnectProgramForm) {
          return;
        }
        const { data, message } = await apiLibrary.Protocols.disconnectProtocol(
          manageConnectioProtocolModal.data.id
        );
        Toasts.success(message);
        dispatch(fetchProtocolsAction(programId));
        handleClose();
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      dispatch(fetchProtocolsAction(programId));
      setSubmitting(false);
    }
  };

  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeManageConnectionProtocolModal());
  };

  const fetchProtocolPrograms = useCallback(
    async (searchQuery?: string) => {
      try {
        const res = await apiLibrary.Protocols.getAllPrograms(searchQuery);
        const programs = res.data?.programs?.map((program: any) => {
          return {
            value: program.id,
            label: program.name,
          };
        });
        setProgramList(programs);
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching programs:", error);
      }
    },
    [programList]
  );

  // get forms by program
  const fetchProtocolFormsByPrograms = useCallback(
    async (programId: any, searchString?: string) => {
      try {
        const res = await apiLibrary.Protocols.getFormsByPrograms(
          programId.value ? programId.value : programId,
          searchString
        );
        const forms = res.data?.forms?.map((form: any) => {
          return {
            value: form.formId,
            label: form.formName,
          };
        });
        setFormsList(forms);
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching forms:", error);
      }
    },
    [formList]
  );

  const handleProgramSearch = (query: string) => {
    fetchProtocolPrograms(query);
  };

  return (
    <Modal
      open={manageConnectioProtocolModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={manageConnectioProtocolModal?.data}
          validationSchema={""}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <ManageConnectionModalForm
                {...formikProps}
                handleClose={handleClose}
                programList={programList}
                handleProgramSearch={handleProgramSearch}
                fetchProtocolFormsByPrograms={fetchProtocolFormsByPrograms}
                setFormsList={setFormsList}
                formsList={formList}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ManageConnectionProtocolModal;
