import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProtocol } from "store/protocolProfile/initialState";
import AttachFilePlaceholder from "assets/images/Placeholders/imagePlaceholder.png";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";

import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import WarningIcon from "assets/icons/HeroIcons/WarningIcon";
import useDateFormatter from "hooks/formatDateWithPattern";

export const ProtocolDetails = () => {
  const protocolProfile = useSelector<RootState, IProtocol>(
    (state) => state?.protocolProfile
  );
  const { formatDate } = useDateFormatter();
  return (
    <div className="px-8 pt-6">
      <div>
        <p className="font-semibold text-[18px] text-secondaryMid px-4 capitalize dark:text-textMain">
          Protocol details
        </p>
      </div>

      <div className="flex gap-4 px-4 mt-8">
        {protocolProfile?.form && (
          <div className="rounded-full w-8 h-7">
            <img
              src={protocolProfile.form.image?.thumb || PlaceholderAvatar}
              alt={protocolProfile.form.name}
              className="rounded-full w-7 h-7"
            />
          </div>
        )}
        <div>
          <p className="break-all dark:text-textMain">
            {protocolProfile?.form ? (
              protocolProfile?.form?.name
            ) : (
              <div className="flex items-center gap-4">
                <div>
                  <WarningIcon />
                </div>{" "}
                <p className="text-accent_2 capitalize">No form selected</p>
              </div>
            )}
          </p>
        </div>
      </div>

      <div className="flex gap-4 px-4 mt-4">
        {protocolProfile?.program && (
          <div className="rounded-full w-8 h-7">
            <img
              src={protocolProfile?.program?.image?.thumb || PlaceholderAvatar}
              alt={protocolProfile?.form?.name}
              className="rounded-full w-7 h-7 ml-[2px]"
            />
          </div>
        )}
        <div className="w-full">
          <p className="break-all capitalize dark:text-textMain">
            {protocolProfile?.program ? (
              protocolProfile?.program?.name
            ) : (
              <div className="flex items-center gap-4">
                <div>
                  <WarningIcon />
                </div>{" "}
                <p className="text-accent_2 capitalize">No program selected</p>
              </div>
            )}
          </p>
        </div>
      </div>
      <div className="pl-6 pt-6">
        <div className="py-1">
          <p className=" text-secondaryMid capitalize mr-2 dark:text-caption inline">
            Created by:
          </p>
          <p className="text-secondaryMid capitalize inline dark:text-textMain ">
            {protocolProfile?.createdBy?.name}
          </p>
        </div>

        <div className="py-1">
          <p className="text-secondaryMid capitalize mr-2 dark:text-caption inline">Edited :</p>
          <p className="text-secondaryMid capitalize inline dark:text-textMain ">
            {formatDate(protocolProfile?.edited?.date)}
          </p>
        </div>
      </div>
    </div>
  );
};
