import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import {
  OptionsModal,
  AddPersonBtn,
  PageHeading,
  ManageMembers,
} from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import {
  usersCommunitiesAction,
  usersOpenAccessAction,
  usersProgramsAction,
  usersResetFiltersAction,
} from "store/filters/reducer.actions";
import {
  openAddExistingMemberstocommunityModalAction,
  openAddPersonModalAction,
} from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import { Statuses } from "Components/ViewImportsData/Statuses";
import { profile } from "console";

const generateDynamicBreadcrumbLinks = (
  communityId: any,
  programId: any,
  organizationId: any,
  name: any,
  label: any,
  state: any = null
) => {
  function processTitle(kpiState: any = null) {
    if (communityId || programId || organizationId) {
      if (kpiState && kpiState.isAdminRole) {
        return `Admins`;
      } else {
        return `Members`;
      }
    } else {
      return "People";
    }
  }

  switch (true) {
    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/users`,
          label: processTitle(state),
        },
      ];
    case Boolean(programId):
      return [
        { path: `/programs/list`, label: `Programs` },
        {
          path: `/programs/${programId}/profile`,
          label: name,
        },
        {
          path: `/programs/${programId}/users`,
          label: processTitle(state),
        },
      ];
    case Boolean(organizationId):
      return [
        { path: `/organizations/list`, label: `Organizations` },
        {
          path: `/organizations/${organizationId}/profile`,
          label: name,
        },
        {
          path: `/organizations/${organizationId}/users`,
          label: processTitle(state),
        },
      ];

    default:
      return [{ path: `/users/list`, label: `People` }];
  }
};
interface IProps {
  getOpenAccessData?: (data?: any) => void;
}
export const Header: React.FC<IProps> = ({ getOpenAccessData }) => {
  const [BreadcrumbData, setBreadcrumbData] = useState<any>({
    name: "",
    id: "",
    showAddButton: false,
    openAccess: false,
  });

  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { usersFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const location = useLocation();
  const state = location.state;

  const { programs, users, profile } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();
  const navigate = useNavigate();
  const { communities } = usePermissions();
  const dynamicCommunityLinks = generateDynamicBreadcrumbLinks(
    communityId ?? "",
    programId ?? "",
    organizationId ?? "",
    BreadcrumbData?.name ?? "",
    usersFilter.status,
    state
  );

  useEffect(() => {
    if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
          dispatch(usersOpenAccessAction(data.openAccess));
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate(-1);
          // navigate("/community/list");
        });
    } else if (programId) {
      getProgramDetails(programId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
            showAddButton: data.status !== "completed",
          });
          // getOpenAccessData(data);
          dispatch(usersOpenAccessAction(data.openAccess));
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate(-1);
          // navigate("/programs/list");
        });
      // getOpenAccessData(BreadcrumbData);
    } else if (organizationId) {
      getOrganizationDetails(organizationId)
        .then((data) => {
          setBreadcrumbData({
            ...data,
          });
          dispatch(usersOpenAccessAction(data.openAccess));
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate(-1);
          // navigate("/organizations/list");
        });
    }
    return () => {
      dispatch(usersResetFiltersAction());
    };
  }, []);

  // Handlers

  // displaying models based on communityId
  const handleAddPersonalModel = () => {
    if (communityId) {
      setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);
    } else {
      dispatch(openAddPersonModalAction());
    }
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewBtn = () => {
    dispatch(openAddPersonModalAction());
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    dispatch(
      openAddExistingMemberstocommunityModalAction({
        communityId: communityId && parseInt(communityId),
      })
    );
    setIsShowExistingOptionsModal(false);
  };
  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className=" flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCommunityLinks} />
          <div className="flex justify-between items-start self-stretch  flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              communityId={
                communityId
                  ? communityId
                  : programId
                    ? programId
                    : (organizationId as string)
              }
              name={BreadcrumbData?.name}
              parentName={
                communityId
                  ? "Communities"
                  : programId
                    ? "Programs"
                    : organizationId
                      ? "Organizations"
                      : ""
              }
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {programId && programs.canViewCommunities && programs.canCreateMembers &&
                BreadcrumbData?.showAddButton &&
                !BreadcrumbData.openAccess && (
                  <ManageMembers BreadcrumbData={BreadcrumbData} />
                )}
              {communityId &&
              !communities.canCreateMemberCommunities &&
              !communities.canCreateMembersCommunities ? (
                <></>
              ) : (
                users.canCreatePerson &&
                !programId &&
                !organizationId &&
                !state?.isAdminRole &&
                !BreadcrumbData.openAccess && (
                  <AddPersonBtn
                    handleAddPersonalModel={handleAddPersonalModel}
                  />
                )
              )}

              {!communities.canCreateMemberCommunities &&
              !communities.canCreateMembersCommunities ? (
                <></>
              ) : (
                <OptionsModal
                  isOpen={IsShowExistingOptionsModal}
                  handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                  handleAddPersonalModel={handleAddPersonalModel}
                  handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// controllers
async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getProgramDetails(programId: string) {
  try {
    const { data } = await apiLibrary.Programs.getProgramProfile(
      programId ? programId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getOrganizationDetails(organizationId: string) {
  try {
    const { data } =
      await apiLibrary.OrganizationDirectory.getOrganizationProfile(
        organizationId ? organizationId : ""
      );
    return data;
  } catch (error: any) {
    throw error;
  }
}
