import React from "react";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate, FormatDateTime } = useDateFormatter();

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "createdBy"
  ) {
    return (
      <div className="flex justify-start w-[250px] items-center relative">
        <img
          src={cellValue?.image?.file?.thumb || PlaceholderAvatar}
          alt=""
          height="100"
          width="100"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className="flex flex-col w-full justify-start items-start py-2"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "reportName") {
    return (
      <div className="flex flex-col w-full justify-start items-start min-w-[150px] max-w-[350px] ">
        <p className="text-sm text-left break-words  text-textMid group-hover:text-primaryDark">
          {cellValue?.reportName}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "formsIncluded") {
    const formName = ellipsize(
      cellValue.map((item: any) => item.name).join(", "),
      60
    );
    return (
      <div
        className={`relative flex w-[250px] items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark `}>{formName}</p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "recurrenceSettings") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark `}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && (columnDef.id === "lastRun" || columnDef.id === "nextRun")) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-2`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && (columnDef.id === "dateAdded" || columnDef.id === "dateUpdated")) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {FormatDateTime(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
