import React, { useMemo, useState } from "react";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import RolesDropdown from "../RolesDropdown";
import { SelectChangeEvent } from "@mui/material";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { fetchManagerAction } from "store/managers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
  personId,
  programId,
}) => {
  const { formatDate } = useDateFormatter();
  const { programs, users } = usePermissions();
  const navigate = useNavigate();
  const handleNavigate = (id: any) => {
    if (users.canViewUsersProfile) {
      navigate(`/profile/${id}/person`);
    }
  };

  if (
    ["reviewer"].includes(columnDef.id) &&
    cellValue !== null &&
    cellValue !== undefined
  ) {
    return (
      <Link
        to={
          users.canViewUsersProfile
            ? `/profile/${cellValue?.personId}/person`
            : ""
        }
        className={`${users.canViewUsersProfile ? "cursor-pointer" : "cursor-default"}`}
      >
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 py-3 pr-2 cursor-pointer">
          <img
            src={
              cellValue?.image?.file?.thumb
                ? cellValue?.image?.file?.thumb
                : PlaceholderAvatar
            }
            alt=""
            height="100"
            width="100"
            className="mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid group-hover:text-primaryDark w-[180px] break-all">
              {cellValue?.firstName} {cellValue?.lastName}
            </p>
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMidLight group-hover:text-primaryDark w-[180px] break-all">
              {cellValue?.email}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (
    ["community"].includes(columnDef.id) &&
    cellValue !== null &&
    cellValue !== undefined
  ) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 py-3 pr-2">
          <img
            src={
              cellValue?.image?.file?.thumb
                ? cellValue?.image?.file?.thumb
                : PlaceholderAvatar
            }
            alt=""
            height="100"
            width="100"
            className="mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid w-[180px] break-all">
              {cellValue?.name}
            </p>
          </div>
        </div>
      </>
    );
  } else if (
    cellValue !== null &&
    [
      "endOfAccess",
      "lastActive",
      "reviewerSince",
      "dataEndViewerSince",
    ].includes(columnDef.id)
  ) {
    return (
      <div className="relative flex items-start flex-grow px-3 py-1">
        <p className="text-sm text-left text-textMid dark:text-textMain">
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (columnDef.id === "role") {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    // Roles List
    const roles = [
      {
        name: "Program Admin",
        value: "program_admin",
      },
      {
        name: "Internal Reviewer",
        value: "internal_reviewer",
      },
      {
        name: "Data-end viewer",
        value: "internal_data_end_viewer",
      },
    ];

    const handleRoleChange = async (event: SelectChangeEvent<any>) => {
      const {
        target: { value },
      } = event;

      const payload = {
        roles: event.target.value,
      };
      if (programId && personId && programs.canEditManager) {
        try {
          const res = await apiLibrary.Managers.updateManagerRole(
            programId,
            personId,
            payload
          );
          Toasts.success(res.message);
          dispatch(fetchManagerAction(programId));
        } catch (error: any) {
          const errorMsg = error?.response?.data?.message ?? error?.message;
          Toasts.error(errorMsg);
        }
      }
    };

    const value =
      Array.isArray(cellValue) && cellValue.length > 0 ? cellValue : [];

    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 w-[250px]`}
      >
        <RolesDropdown
          data={roles}
          value={value}
          handleChange={handleRoleChange}
        />
      </div>
    );
  } else if (
    typeof cellValue !== "object" &&
    cellValue !== null &&
    columnDef.id === "status"
  ) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 rounded-md  ${cellValue === "active" ? "bg-primaryExtraLight" : "bg-brandHoverLight"}`}
      >
        <p
          className={`text-sm text-left ${cellValue === "active" ? "text-primaryMid" : "text-secondaryLight"}`}
        >
          {ellipsize(`${cellValue}`, 70)}
        </p>
      </div>
    );
  } else if (typeof cellValue !== "object" && cellValue !== null) {
    return (
      <div className={`relative flex items-start flex-grow py-1 px-3 `}>
        <p className={`text-sm text-left text-textMid`}>
          {ellipsize(`${cellValue}`, 70)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
