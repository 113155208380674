import React, { useState } from "react";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import Status from "view/components/Statuses";
import useDateFormatter from "hooks/formatDateWithPattern";
import Avatarimg from "assets/images/Placeholders/avatar-circle.png";

interface ProfileModalProps {
  setSelectedUser: any;
  selectedUser: any;
  addAdmin: any;
  unAssignAdmin: any;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({
  setSelectedUser,
  selectedUser,
  addAdmin,
  unAssignAdmin,
}) => {
  const { formatDate, timeFromNowInTimeZone } = useDateFormatter();

  return (
    <div className="max-h-[60vh] overflow-y-auto w-full">
      <div className="relative p-5 rounded bg-bgWhite dark:bg-secondaryLight">
        <div className="relative">
          <div className="py-2">
            <div className="flex-grow-0 flex-shrink-0 w-28 h-28">
              <div className="absolute top-0 left-0 w-28 h-28 ">
                <img
                  className="absolute top-0 left-0 rounded-full w-28 h-28"
                  src={
                    selectedUser?.personImage
                      ? selectedUser?.personImage?.original
                      : Avatarimg
                  }
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5">
            <div className="flex flex-col items-start justify-center w-full ">
              <p className="w-full break-words text-lg font-semibold text-left text-secondaryMid">
                <p>{`${selectedUser.firstName} ${selectedUser.lastName}`}</p>
              </p>
            </div>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  {selectedUser.programs}
                </p>
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  programs
                </p>
              </div>
              <DotCentralIcon />
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  {selectedUser.forms}
                </p>
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  forms
                </p>
              </div>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-secondaryMid">
                Status:
              </p>
              <Status status={selectedUser.status} />
            </div>
            <div className="flex gap-2 py-[3px]">
              <p className="text-sm font-semibold text-left text-secondaryMid">
                Last Active:
              </p>
              <div className="flex gap-1">
                <div className="flex">
                  <p className="text-sm text-center text-secondaryMid">
                    {timeFromNowInTimeZone(selectedUser.lastActive)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 py-[3px]">
              <p className="text-sm font-semibold text-secondaryMid">
                Member since:
              </p>
              <p className="text-sm text-left text-secondaryMid">
                {formatDate(selectedUser.memberSince)}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center self-stretch relative gap-4 pt-3 pb-[11px] rounded-lg">
          <div className="flex-grow h-px bg-lineMid" />
        </div>
        <div className="flex flex-col gap-2 pb-2">
          <div className="w-full">
            <p className="text-base font-semibold text-left text-secondaryMid">
              Member of the Communities
            </p>
          </div>
          <div className="flex flex-wrap gap-2">
            {selectedUser?.relatedCommunities?.map((community: any) => {
              return (
                <div className="w-9 h-9 rounded-full left-[-1px] top-[-1px]">
                  <img
                  className="w-9 h-9 rounded-full left-[-1px] top-[-1px]"
                  src={community?.profileImage?.file?.original}
                  alt={community.name}       
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-start items-center self-stretch relative gap-4 pt-3 pb-[11px] rounded-lg">
          <div className="flex-grow h-px bg-lineMid" />
        </div>
        <div className="gap-2 pb-2">
          <div className="w-full">
            <p className="text-base font-semibold text-left text-secondaryMid">
              Do you want to {selectedUser.isAdmin ? "unassign" : "add"} this
              user as an admin?
            </p>
          </div>
          <div className="flex gap-6 pt-4">
            <button
              onClick={() => setSelectedUser()}
              className="font-semibold text-primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                if (selectedUser.isAdmin) {
                  unAssignAdmin(selectedUser);
                } else {
                  addAdmin(selectedUser);
                }
              }}
              className="font-semibold text-primary"
            >
              {selectedUser.isAdmin ? "Unassign" : " Assign as an admin"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
