import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DirectModeOverride,
  DragCircleMode,
  DrawRectangleMode,
  SimpleSelectMode,
  SnapLineMode,
  SnapModeDrawStyles,
  SnapPointMode,
  SnapPolygonMode,
} from "Components/Geography/MapBox/lib";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetMap,
  updateDrawShapeCoordinates,
  updateSelectedItemToZoom,
} from "store/geography";
import customMarkerImage1 from "../../../../../../../../../../assets/icons/custom-marker-1.png";
import customMarkerImage2 from "../../../../../../../../../../assets/icons/custom-marker-2.png";
import mapboxgl from "mapbox-gl";
import CustomShapesDrawControls from "../features/CustomShapesDrawControls";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

const useInitializeMap = (
  mapContainer: any,
  lng: number | null,
  lat: number | null,
  zoom: any,
  setZoom: any
) => {
  const map = useRef<any>(null);
  const drawRef = useRef<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (map.current) return; // initialize map only once

    (async () => {
      if (!mapContainer.current) return;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_MAPBOX_STYLE_URL,
        center: [lng ?? -145.6433003, lat ?? 65.0710178],
        zoom: 2,
        renderWorldCopies: false,
        minZoom: 0.999,
        // @ts-ignore
        projection: {
          name: "mercator",
        },
      });

      mapContainer.current.style.width = "100%";
      // initializing all controls of drawing shapes
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes,
          draw_point: SnapPointMode,
          draw_polygon: SnapPolygonMode,
          draw_circle: CircleMode,
          direct_select: DirectModeOverride,
          drag_circle: DragCircleMode,
          simple_select: SimpleSelectMode,
          draw_line_string: SnapLineMode,
          draw_rectangle: DrawRectangleMode,
        },
        // Styling guides
        styles: SnapModeDrawStyles,
        userProperties: true,
        // Config snapping features
        // @ts-ignore
        snap: true,
        snapOptions: {
          snapPx: 15, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
        },
        guides: false,
      });

      map.current.addControl(drawRef.current);

      // adding zoom and rotation controls to the map.
      const nav = new mapboxgl.NavigationControl({
        visualizePitch: true,
      });
      map.current.addControl(nav, "bottom-right");

      // adding full screen control
      map.current.addControl(new mapboxgl.FullscreenControl());

      // adding custom shapes draw controls
      const customControl = new CustomShapesDrawControls();
      map.current.addControl(customControl, "top-right");

      map.current.on("draw.create", updateGeoJsonData);
      map.current.on("draw.delete", updateGeoJsonData);
      map.current.on("draw.update", updateGeoJsonData);

      map.current.on("move", () => {
        // setLng(map.current.getCenter().lng );
        // setLat(map.current.getCenter().lat );
        setZoom(map.current.getZoom().toFixed(2));
      });

      map.current.on("load", () => setIsMapFullyLoaded(true));

      map.current.loadImage(customMarkerImage1, (error: any, image: any) => {
        if (error) throw error;
        if (!map.current.hasImage("custom-marker-1")) {
          map.current.addImage("custom-marker-1", image);
        }
      });

      map.current.loadImage(customMarkerImage2, (error: any, image: any) => {
        if (error) throw error;
        if (!map.current.hasImage("custom-marker-2")) {
          map.current.addImage("custom-marker-2", image);
        }
      });

      // setting up and updating geojson
      function updateGeoJsonData() {
        const data = drawRef.current.getAll();

        if (data.features.length > 0) {
          // removing unnecessary shape items added by map
          const features = data.features.filter((f: any) => {
            if (f.id !== "VERTICAL_GUIDE" || f.id !== "HORIZONTAL_GUIDE") {
              return true;
            }
            return false;
          });

          dispatch(updateDrawShapeCoordinates(features));
        }
      }
    })();

    return () => {
      //reset selected layers item to zoom on map
      dispatch(updateSelectedItemToZoom(null));
      // reset selected layers item to display on map
      dispatch(resetMap(true));
    };
  }, []);

  return { map, drawRef, isMapFullyLoaded };
};

export { useInitializeMap };
