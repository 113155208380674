import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

interface Props {
  vectorLayerType: string;
  entityType: EntityType;
  currentOpenToggle:EntityType;
  setCurrentOpenToggle:Dispatch<SetStateAction<EntityType>>;
  page:Page;
}

export type EntityType = null | "vector" | "publicVector";
export type Page = {vector:number,publicVector:number}

const DATA_LIMIT = 10;

const useFetchGlobalAndCommunityVectorLayers = ({
  vectorLayerType,
  entityType,
  currentOpenToggle,
  setCurrentOpenToggle,
  page
}: Props) => {
  const [communityLayersLoading, setCommunityLayersLoading] = useState(false);
  const [globalLayersLoading,setGlobalLayersLoading] = useState(false);
  const [communityVectorLayers, setCommunityVectorLayers] = useState({
    vector: [],
    totalPages: 1,
  });
  const [globalVectorLayers, setGlobaVectorLayers] = useState({
    vector: [],
    totalPages: 1,
  });

  const { communityId,formId } = useParams();

  useEffect(() => {

    const params  = (type:string) => ({entityType:type,limit:DATA_LIMIT,page:1})
    // fetching community layers
    if (formId) {
      setCommunityLayersLoading(true);
      apiLibrary.geography
        .getGlobalVectorLayers(vectorLayerType, {...params("vector"),dynamicFormId:formId})
        .then(({ data }: any) => {
          setCommunityVectorLayers(data);

          if (data.vector.length > 0) {
            if (!currentOpenToggle) setCurrentOpenToggle("vector");
          }
        })
        .finally(() => setCommunityLayersLoading(false));
    };

    // fetching global layers
    setGlobalLayersLoading(true);

    apiLibrary.geography
      .getGlobalVectorLayers(vectorLayerType, params("publicVector"))
      .then(({ data }: any) => {
        setGlobaVectorLayers(data);

        if (data.vector.length > 0) {
          if (!currentOpenToggle) setCurrentOpenToggle("publicVector");
        }
      })
      .finally(() => setGlobalLayersLoading(false));
  }, []);

  useEffect(() => {
    if (!entityType) return;

    if (
      (entityType === "vector" &&
        communityVectorLayers?.totalPages < page?.vector) ||
      (entityType === "publicVector" &&
        globalVectorLayers?.totalPages < page?.publicVector)
    ) {
      return;
    }

    const params = {
      limit: DATA_LIMIT,
      page: page[entityType ?? ""],
    };

    const fetchLayers =
      entityType === "vector" && formId
        ? apiLibrary.geography.getGlobalVectorLayers(
            vectorLayerType,
            {...params,dynamicFormId:formId}
          )
        : apiLibrary.geography.getGlobalVectorLayers(vectorLayerType, params);

    entityType === "vector"
      ? setCommunityLayersLoading(true)
      : setGlobalLayersLoading(true);

    fetchLayers
      .then(({ data }) => {
        if (entityType === "vector") {
            setCommunityVectorLayers((prevLayers:any) => ({
              ...prevLayers,
              vector: [...prevLayers.vector, ...data.vector],
            }));
          } 
          else {
            setGlobaVectorLayers((prevLayers:any) => ({
              ...prevLayers,
              vector: [...prevLayers.vector, ...data.vector],
            }));
          }
      })
      .catch((err) => {
        Toasts.error(err.message);
      })
      .finally(() => {
        entityType === "vector"
          ? setCommunityLayersLoading(false)
          : setGlobalLayersLoading(false);
      });
  }, [page]);


  return {
    communityLayersLoading,
    globalLayersLoading,
    communityVectorLayers,
    globalVectorLayers
  };
};

export{ useFetchGlobalAndCommunityVectorLayers};
