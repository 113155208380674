import ActionDropDownMenu from "Components/ActionDropDownMenu";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import LocationMarkerIcon from "assets/icons/HeroIcons/LocationMarkerIcon";
import MapMirrorIcon from "assets/icons/HeroIcons/MapMirrorIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import React, { useRef } from "react";
import PopOverMenu from "view/components/PopOverMenu";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import useActions from "hooks/useActions";
import { openEditCommunityModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { SeeMoreText } from "../SeeMoreText";
import usePermissions from "hooks/usePermissions";
import Restricted from "view/components/Restricted";
import Head from "view/components/Head";

export const ProfileActions = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const dispatch = useDispatch();
  const { communityActions } = useActions();
  const { communities } = usePermissions();
  const editCommunity = () => {
    const initialValues = {
      communityId: communityProfile.id,
      address: communityProfile?.address,

      backgroundImage: communityProfile?.backgroundImage,
      location: {
        long: communityProfile?.location?.coordinates[0],
        lat: communityProfile?.location?.coordinates[1],
      },
      missionStatement: communityProfile?.missionStatement,
      name: communityProfile?.name,
      profileImage: communityProfile?.profileImage,
      type: communityProfile?.type,
      profileImageId: communityProfile?.profileImageId,
      backgroundImageId: communityProfile?.backgroundImageId,
      openAccess: communityProfile?.openAccess
    };
    dispatch(openEditCommunityModalAction(initialValues));
  };
  const getCommunityActions = () => {
    const modalData = {
      status: communityProfile.status,
      id: communityProfile.id,
      accessibleForSuperAdmin: true,
      name: communityProfile.name,
      communityAdmins: communityProfile?.communityAdmins?.map((admin: any) => {
        const person = {
          ...admin,
          personImage: admin?.profileImage?.file || admin?.personImage,
        };
        return person;
      }),
      profileImage: communityProfile?.profileImage,
      path: "profilePage",
      contactInfo: communityProfile?.contactInfo,
      openAccess: communityProfile?.openAccess
    };

    return communityActions(modalData);
  };

  return (
    <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-3">
      <Head title={`Communities ${communityProfile.name}`} />
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        <div className="flex flex-col justify-start items-start flex-grow py-0.5">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            <p className="flex-grow text-[28px] font-bold text-left break-all text-secondaryMid dark:text-textMain">
              {communityProfile.name}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-1">
          {communities.canEditCommunities &&(
            <button
              onClick={() => editCommunity()}
              className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
            >
              <PencilAltIcon />
            </button>
          )}
          <PopOverMenu icon={<DotsVerticalIcon />}>
            <ActionDropDownMenu actions={getCommunityActions()} />
          </PopOverMenu>
        </div>
      </div>
      {communityProfile?.address !== null ? (
        <>
          <div className="w-full flex justify-start items-center relative gap-2 py-[3px]">
            <LocationMarkerIcon className="w-[16px] h-[16px]" />
            <p className="flex-grow text-sm italic text-left break-all dark:text-textMain">
              {communityProfile?.address}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="w-11/12 py-1">
        <SeeMoreText text={communityProfile?.organizations?.join(", ")} />
      </div>
    </div>
  );
};
