import React, { useLayoutEffect } from "react";
import { Header } from "Components/Dashboard/Header";
import Content from "Components/Dashboard/Content";
import { Box, Grid } from "@mui/material";

import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import FiltersList from "Components/Dashboard/FiltersList";
import EmptyDashboardIcon from "Components/Dashboard/Content/components/imgs/EmptyDashboardIcon";
import { TailSpin } from "react-loader-spinner";
import { dashboardResetFiltersAction } from "store/filters";
import usePermissions from "hooks/usePermissions";
import { setDashboardFiltersAction } from "store/filters/reducer.actions";

const Dashboard = () => {
  const { configuration } = useSelector<RootState, IDashboardWidgets>(
    (state) => state.dashboard
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setDashboardFiltersAction());
  }, []);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchDashboardConfiguration());

    // return () => {
    //   dispatch(dashboardResetFiltersAction());
    // };
  }, []);
  const { dashboard } = usePermissions();
  return (
    <div className="flex flex-col items-start justify-start w-full h-full pt-4 pb-20 dashboard-widgets">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full h-full gap-3">
        <Header />
        {configuration.stateIs === "Idle" && configuration.data.length === 0 ? (
          <div className="flex items-center justify-center w-full h-full">
            <EmptyDashboardIcon />
          </div>
        ) : configuration.stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <>
            <FiltersList />
            <Box width={"100%"}>
              <Grid container spacing={2}>
                {configuration.data?.map((item: any) => {
                  const { _id, grid, component, hasAccess } = Content({
                    widgetName: item._id as string,
                    dashboardPermissions: dashboard,
                  });
                  if (!hasAccess) return <></>;
                  return (
                    <Grid
                      className=""
                      key={item.widgetName}
                      data-id={item.widgetName}
                      item
                      xs={grid}
                    >
                      {component}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
