import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import NotificationNoData from "../imgs/NotificationNoData";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
export default function ReportsNotifications() {
  const {
    dashboard: { reportNotifications },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchReportNotifications());
  }, [dashboardFilter]);
  return (
    <Box height={"100%"}>
      <DashboardCardHeading
        text="Reports Notifications"
        widgetId="report_notifications"
        viewAllLInk={"custom-notifications/list"}
      />

      {reportNotifications.stateIs == "Idle" &&
      reportNotifications.data.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <NotificationNoData />
        </div>
      ) : reportNotifications.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <List sx={{ width: "100%", py: 0 }}>
          {reportNotifications.data.map((item, index) => {
            return (
              <Box key={index} mb={1.25}>
                <Link to={"/custom-notifications/list"}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      p: 0,
                      mb: 1.5,
                    }}
                  >
                    {/* <p className="w-full mr-1 overflow-hidden text-sm font-normal leading-5 text-secondaryMid font-Overpass overflow-ellipsis line-clamp-2">
                      {item.description}
                    </p> */}

<p
                    className="w-full mr-1 overflow-hidden text-sm font-normal leading-5 custom-html-content text-textNeutralGray dark:text-caption font-Overpass overflow-ellipsis line-clamp-2"
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />
                    <p className="ml-auto min-w-fit text-textNeutralGray text-sm font-normal font-['Inter'] text-right leading-4">
                      {item.duration}
                    </p>
                  </ListItem>
                </Link>
                {index + 1 < reportNotifications.data.length && (
                  <Divider
                    variant="inset"
                    component="li"
                    sx={{ width: "calc(100% + 32px)", m: 0, ml: "-16px" }}
                  />
                )}
              </Box>
            );
          })}
        </List>
      )}
    </Box>
  );
}
