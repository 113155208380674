import React, { useEffect, useState } from "react";
import apiClient from "services/apiClient";
import { RadioBtn } from "Components/RadioBtn";
import mapToDropdownValues from "utils/mapDropdownValues";
import { conditionalityForObservations } from "utils/conditionalityForObservations";

interface RadioButtonGroupProps {
  label: string;
  error?: any;
  name: string;
  editMode?: boolean;
  value?: any;
  data: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  required?: boolean;
  choicesByUrl?: {
    url: string;
    valueName: string;
    titleName: string;
  };
  visibleIf?: any;
  enableIf?: any;
  setValueIf?: any;
  setValueExpression?: any;
  requiredIf?: any;
  answers?: any;
  dataPlaceholders?: any;
  columns?: number;
  panel?: any;
  element?: any;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  required,
  choicesByUrl = null,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  dataPlaceholders,
  columns = 1,
  panel,
  element
}) => {
  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }
  const [option, setOption] = useState([]);

  const getRadioOptions = async (choicesByUrl: any) => {
    try {
      const { data } = await apiClient.get(choicesByUrl.url);
      const dropdownOptions = mapToDropdownValues(
        data,
        choicesByUrl.valueName,
        choicesByUrl.titleName,
        choicesByUrl.valueName,
        choicesByUrl.titleName
      );
      setOption(dropdownOptions);
    } catch (error: any) {
      return [];
    }
  };

  useEffect(() => {
    if (choicesByUrl !== null && editMode) {
      getRadioOptions(choicesByUrl);
    }
  }, [editMode]);

  return (
    <div className="flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight p-4" id={`${element?.name}${panel?.sectionId}`}>
      <div className="relative flex flex-col py-2">
        <p
          className={`w-full pb-1 text-md text-left capitalize font-Overpass ${editMode
            ? "text-primary dark:text-caption font-medium "
            : "text-primary dark:text-caption font-regular "
            }`}
        >
          {label || name} {required && <span>*</span>}
        </p>
        {editMode ? (
          <>
            <div
              className={`grid justify-start w-full focus:outline-none gap-1.5 px-3 py-2 rounded dark:bg-secondaryLight`}
              style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
            >
              {choicesByUrl !== null ? (
                <RenderRadioButtonGroupFromApi
                  items={option}
                  value={value}
                  handleChange={handleChange}
                  required={required}
                  choicesByUrl={choicesByUrl}
                  isEnabled={isEnabled}
                />
              ) : (
                <RenderSimpleRadioButtonGroup
                  items={data}
                  value={value}
                  handleChange={handleChange}
                  required={required}
                  isEnabled={isEnabled}
                  dataPlaceholders={dataPlaceholders}
                />
              )}
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              {value ?
                <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal font-Overpass text-textAnswer dark:text-textMain ">
                  {value && typeof value === "object"
                    ? choicesByUrl !== null
                      ? value[choicesByUrl.titleName]
                      : value?.text
                    : typeof value === "boolean" ? value === true ? data[0] : data[1] : value}
                </p>
                :
                <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
                  (No Selection)
                </p>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default RadioButtonGroup;

interface IChoiceByUrl {
  url: string;
  valueName: string;
  titleName: string;
}

interface ICheckboxItem {
  [key: string]: any;
}

interface RenderSimpleRadioButtonGroupProps {
  items: ICheckboxItem[];
  value: string | ICheckboxItem;
  handleChange: any;
  required: any;
  isEnabled: boolean;
  dataPlaceholders?: any[];
}

const RenderSimpleRadioButtonGroup: React.FC<
  RenderSimpleRadioButtonGroupProps
> = ({ items, value, handleChange, isEnabled, required, dataPlaceholders }) => {

  const getItemBoolOption = (item: any) => {
    return item === items[0] ? true : false;
  }

  return (
    <>
      {items &&
        items.length > 0 &&
        items.map((item: any, index) => {
          const itemLabel = getItemLabel(item, null);
          // We are checking based on LabelTrue and LabelFalse in case of boolean, items[0] is labelTrue value
          const itemValue = typeof value === "boolean" ? getItemBoolOption(item) : getItemValue(item, null);
          return (
            <RadioBtn
              className="pl-3 font-Overpass"
              value={item}
              disabled={isEnabled}
              checked={getItemValue(value, null)?.value == itemValue?.value}
              handleChange={() => {
                handleChange(typeof item === "boolean" ? getItemBoolOption(item) : getItemValue(item, null));
              }}
              labelContent={
                <span className="text-sm font-normal flex flex-col text-gray-800 font-overpass dark:text-caption">
                  {itemLabel}
                  {dataPlaceholders && <span className="text-textMidLight">{dataPlaceholders[index]}</span>}
                </span>
              }
            />
          );
        })}
    </>
  );
};

interface RenderRadioButtonGroupFromApiProps {
  items: ICheckboxItem[];
  value: string | ICheckboxItem;
  handleChange: any;
  required: any;
  choicesByUrl: IChoiceByUrl;
  isEnabled: any;
}

const RenderRadioButtonGroupFromApi: React.FC<
  RenderRadioButtonGroupFromApiProps
> = ({ items, value, handleChange, required, choicesByUrl, isEnabled }) => {
  return (
    <>
      {items.map((item: ICheckboxItem, index) => {
        const itemLabel =
          typeof item === "object" ? item[choicesByUrl?.titleName] : item;
        const isChecked =
          value !== null &&
          (typeof item === "object" && typeof value === "object"
            ? item[choicesByUrl.valueName] === value[choicesByUrl.valueName]
            : item === value);
        return (
          <RadioBtn
            value=""
            disabled={isEnabled}
            handleChange={() => {
              handleChange(item);
            }}
            className="pl-3 font-Overpass disabled:cursor-not-allowed"
            checked={isChecked}
            labelContent={
              <span className="text-sm font-normal text-gray-800 font-overpass dark:text-caption">
                {itemLabel}
              </span>
            }
          />
        );
      })}
    </>
  );
};

function getItemLabel(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.titleName] : item?.text;
  }
  return item;
}
function getItemValue(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.valueName] : item;
  }
  return item;
}
