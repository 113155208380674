import React from "react";
import placeholderImg from "assets/images/Placeholders/PlaceholderAvatar.png";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IOrganization } from "store/organizationProfile/initialState";
import { Link } from "react-router-dom";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";

export const CommunitiesListingCard = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );

  return (
    <div className="flex flex-col w-full rounded-lg shadow-md lg:p-4 xl:p-6 bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight hover:shadow-xl">
      <div className="flex justify-between w-full mb-1">
        <p className="text-base font-semibold dark:text-textMain">{`${organizationProfile?.noOfCommunities} ${
          organizationProfile.noOfCommunities > 1 ? "Communities" : "Community"
        }`}</p>
        <Link
          to={`/organizations/${organizationProfile?.id}/communities`}
          className="flex-grow-0 flex-shrink-0 "
        >
          <p className="text-sm font-semibold text-primary dark:text-primaryMidLight hover:text-primaryMid">
            Manage All
          </p>
        </Link>
      </div>

      {organizationProfile?.organizationCommunities
        .slice(0, 5)
        ?.map((communities: any, index: number) => {
          return (
            <div key={index} className="flex mt-4">
              <div className="w-8">
                <div className="w-8 h-8 mt-2 rounded-full">
                  <img
                    src={
                      communities?.profileImage?.file?.thumb
                        ? communities?.profileImage?.file?.thumb
                        : placeholderImg
                    }
                    alt={communities?.name}
                    className="w-full rounded-full"
                  />
                </div>
              </div>
              <div className="ml-4">
                <p className="text-[15px] break-all dark:text-textMain">
                  {communities?.name}
                </p>
                <div className="flex items-center">
                  <span className="text-xs dark:text-caption ">{`${communities?.noOfMembers} ${
                    communities.noOfMembers > 1 ? "member" : "members"
                  } `}</span>
                  <DotCentralIcon />
                  <span className="text-xs dark:text-caption ">
                    Joined {moment(communities?.date).format("DD MMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
