// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, GoToProfileBtn } from "./components";

// Store Utils
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { sentenceCase } from "change-case";
import { useParams } from "react-router-dom";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import { useEffect, useState } from "react";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import apiLibrary from "services/api";
import { filtersInitialState } from "store/filters/initialState";
import { Toasts } from "view/components/Toasts";

const generateDynamicReportsLinks = (reportsId: any, label: string) => {
  return [
    { path: `/reports/list`, label: `Reports` },
    { path: `/reports/${reportsId}/profile`, label: label ?? "" },
    // { path: `/reports/${reportsId}/profile`, label: `Report profile` },
    { path: `/reports/${reportsId}/run-report`, label: `Run report` },
  ];
};

export const Header = () => {
  const { data } = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );

  const { reportProfileFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const [breadcrumbData, setBreadcrumbData] = useState({
    name: "",
    id: "",
  });

  const navigate = useNavigate();
  const { reportsId } = useParams<any>();
  const dynamicReportsLinks = generateDynamicReportsLinks(
    reportsId,
    breadcrumbData?.name
  );

  const handleGoToProfile = () => {
    navigate(`/reports/${reportsId}/profile`);
  };

  useEffect(() => {

    const fetchData = async (
      id: string,
      getDetailsFn: (id: string, reportProfileFilter: any) => Promise<any>,
      reportProfileFilter: any
    ) => {
      try {
        const data = await getDetailsFn(id, reportProfileFilter);
        setBreadcrumbData({
          name: data?.reportName,
          id: data?.id
        });
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errMsg);
      }
    };

    if (reportsId) {
      fetchData(reportsId, getReportDetails, reportProfileFilter);
    }
  }, [reportsId]);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicReportsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <GoToProfileBtn handleGoToProfile={handleGoToProfile} />
          </div>
        </div>
      </div>
    </div>
  );
};


async function getReportDetails(reportId: string, reportProfileFilter: any) {
  try {
    const queryString = generateQueryStringForUsers(reportProfileFilter);
    const { data } = await apiLibrary.Reports.getAllProfileReports(
      queryString, reportId
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}