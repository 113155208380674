import { initialState } from "./initialState";
import * as actionTypes from "./action-types";
import { getFiltersDataFromLocalStorage } from "utils/cacheOrRetriveFiltersDate";

const Filters = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.DASHBOARD_DATE_PERIOD: {
      const { from, to } = action.payload;
      return {
        ...state,
        dashboardFilter: {
          ...state.dashboardFilter,
          datePeriod: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.DASHBOARD_FORMS: {
      const { formIds } = state.dashboardFilter;

      // Check if the program with the same id exists
      const existingFormIndex = formIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the program exists, remove it
        updatedForms = [
          ...formIds.value.slice(0, existingFormIndex),
          ...formIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedForms = [...formIds.value, action.payload];
      }

      return {
        ...state,
        dashboardFilter: {
          ...state.dashboardFilter,

          formIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.DASHBOARD_ORGANIZATIONS: {
      const { organizationIds } = state.dashboardFilter;

      // Check if the community with the same id exists
      const existingOrganizationIndex = organizationIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedDashboardOrganizations;

      if (existingOrganizationIndex !== -1) {
        // If the community exists, remove it
        updatedDashboardOrganizations = [
          ...organizationIds.value.slice(0, existingOrganizationIndex),
          ...organizationIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the community doesn't exist, add it
        updatedDashboardOrganizations = [
          ...organizationIds.value,
          action.payload,
        ];
      }

      return {
        ...state,
        dashboardFilter: {
          ...state.dashboardFilter,

          organizationIds: {
            value: updatedDashboardOrganizations,
            applied: updatedDashboardOrganizations.length > 0,
          },
        },
      };
    }

    case actionTypes.DASHBOARD_COMMUNITIES: {
      const { communityIds } = state.dashboardFilter;

      // Check if the community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        dashboardFilter: {
          ...state.dashboardFilter,

          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.DASHBOARD_PROGRAMS: {
      const { programIds } = state.dashboardFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        dashboardFilter: {
          ...state.dashboardFilter,

          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.DASHBOARD_RESET_FILTERS: {
      return {
        ...state,
        dashboardFilter: {
          ...initialState.dashboardFilter,
        },
      };
    }

    case actionTypes.SET_DASHBOARD_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          dashboardFilter: { ...filtersData.filters },
        };
      } else {
        return {
          ...state,
          dashboardFilter: initialState.dashboardFilter,
        };
      }
    }

    case actionTypes.USERS_SORTING: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }
    case actionTypes.USERS_SEARCH: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }
    case actionTypes.USERS_LAST_ACTIVE: {
      const { from, to } = action.payload;
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          lastActive: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.USERS_MEMBER_SINCE: {
      const { from, to } = action.payload;
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          memberSince: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.USERS_OPEN_ACCESS: {
      const data = action.payload;
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          entityOpenAccess: data,
        },
      };
    }
    case actionTypes.USERS_FORMS_OBSERVATIONS: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.USERS_COMMUNITIES: {
      const { communityIds } = state.usersFilter;

      // Check if the community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.USERS_PROGRAMS: {
      const { programIds } = state.usersFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.USERS_STATUS: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.USERS_PAGINATION: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.USERS_ITEMS_LIMIT: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.USERS_RESET_EXCEPT_COMMUNITY_FILTERS: {
      const { communityIds } = state.usersFilter;
      return {
        ...state,
        usersFilter: {
          ...initialState.usersFilter,
          status: "all",
          communityIds,
          page: 1,
        },
      };
    }

    case actionTypes.USERS_RESET_EXCEPT_PROGRAM_FILTERS: {
      const { programIds } = state.usersFilter;
      return {
        ...state,
        usersFilter: {
          ...initialState.usersFilter,
          status: "all",
          programIds,
          page: 1,
        },
      };
    }

    case actionTypes.USERS_RESET_FILTERS: {
      return {
        ...state,
        usersFilter: {
          ...initialState.usersFilter,
        },
      };
    }

    case actionTypes.USERS_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          formIds: action.payload.formIds,
          programIds: action.payload.programIds,
          organizationIds: action.payload.organizationIds,
          communityIds: action.payload.communityIds,
        },
      };
    }

    case actionTypes.SET_USERS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          usersFilter: {
            ...filtersData.filters,
            status: initialState.usersFilter.status,
          },
        };
      } else {
        return {
          ...state,
          usersFilter: initialState.usersFilter,
        };
      }
    }

    // COMMUNITIES DIRECTORY FILTERS REDUCERS
    case actionTypes.COMMUNITIES_SORTING: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.COMMUNITIES_SEARCH: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.COMMUNITIES_STATUS: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.COMMUNITIES_PAGINATION: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.COMMUNITIES_ITEMS_LIMIT: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.COMMUNITIES_ORGANIZATIONS: {
      const { organizationIds } = state.communitiesFilter;

      // Check if the Organization with the same id exists
      const existingOrganizationIndex = organizationIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedOrganizations;

      if (existingOrganizationIndex !== -1) {
        // If the Organization exists, remove it
        updatedOrganizations = [
          ...organizationIds.value.slice(0, existingOrganizationIndex),
          ...organizationIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the Organization doesn't exist, add it
        updatedOrganizations = [...organizationIds.value, action.payload];
      }

      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          organizationIds: {
            value: updatedOrganizations,
            applied: updatedOrganizations.length > 0,
          },
        },
      };
    }
    case actionTypes.COMMUNITIES_PROGRAM: {
      const { programIds } = state.communitiesFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }
    case actionTypes.COMMUNITIES_RESET_EXCEPT_ORGANIZATION_FILTERS: {
      const { organizationIds } = state.communitiesFilter;
      return {
        ...state,
        communitiesFilter: {
          ...initialState.communitiesFilter,
          status: "active",
          organizationIds,
          page: 1,
        },
      };
    }
    case actionTypes.COMMUNITIES_RESET_EXCEPT_PROGRAM_FILTERS: {
      const { programIds } = state.communitiesFilter;
      return {
        ...state,
        communitiesFilter: {
          ...initialState.communitiesFilter,
          status: "active",
          programIds,
          page: 1,
        },
      };
    }

    case actionTypes.COMMUNITIES_RESET_FILTERS: {
      return {
        ...state,
        communitiesFilter: {
          ...initialState.communitiesFilter,
          viewType: state.communitiesFilter.viewType,
        },
      };
    }

    case actionTypes.COMMUNITIES_MEMBERS: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.COMMUNITIES_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.COMMUNITIES_TYPE: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          type: { value: action.payload, applied: action.payload !== "" },
        },
      };
    }
    case actionTypes.COMMUNITIES_VIEW_TYPE: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          viewType: action.payload,
        },
      };
    }
    case actionTypes.COMMUNITIES_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          programIds: action.payload.programIds,
          organizationIds: action.payload.organizationIds,
          formIds: action.payload.formIds,
        },
      };
    }

    case actionTypes.COMMUNITIES_TAGS: {
      const { tagIds } = state.communitiesFilter;

      // Check if the communities with the same id exists
      const existingcommunitiesIndex = tagIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedTags;

      if (existingcommunitiesIndex !== -1) {
        // If the communities exists, remove it
        updatedTags = [
          ...tagIds.value.slice(0, existingcommunitiesIndex),
          ...tagIds.value.slice(existingcommunitiesIndex + 1),
        ];
      } else {
        // If the communities doesn't exist, add it
        updatedTags = [...tagIds.value, action.payload];
      }

      return {
        ...state,
        communitiesFilter: {
          ...state.communitiesFilter,
          page: 1,
          tagIds: {
            value: updatedTags,
            applied: updatedTags.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_COMMUNITIES_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          communitiesFilter: {
            ...filtersData.filters,
            status: initialState.communitiesFilter.status,
          },
        };
      } else {
        return {
          ...state,
          communitiesFilter: initialState.communitiesFilter,
        };
      }
    }

    // Organizations Filters

    case actionTypes.ORGANIZATIONS_SORTING: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.ORGANIZATIONS_SEARCH: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.ORGANIZATIONS_STATUS: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.ORGANIZATIONS_PAGINATION: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.ORGANIZATIONS_ITEMS_LIMIT: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.ORGANIZATIONS_COMMUNITIES: {
      const { communityIds } = state.organizationsFilter;

      // Check if the Organization with the same id exists
      const existingOrganizationIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingOrganizationIndex !== -1) {
        // If the Organization exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingOrganizationIndex),
          ...communityIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the Organization doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }
    case actionTypes.ORGANIZATIONS_PROGRAM: {
      const { programIds } = state.organizationsFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }
    case actionTypes.ORGANIZATIONS_RESET_EXCEPT_PROGRAM_FILTERS: {
      const { programIds } = state.organizationsFilter;
      return {
        ...state,
        organizationsFilter: {
          ...initialState.organizationsFilter,
          status: "active",
          programIds,
          page: 1,
        },
      };
    }

    case actionTypes.ORGANIZATIONS_TAGS: {
      const { tagIds } = state.organizationsFilter;

      // Check if the Organization with the same id exists
      const existingOrganizationIndex = tagIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedTags;

      if (existingOrganizationIndex !== -1) {
        // If the Organization exists, remove it
        updatedTags = [
          ...tagIds.value.slice(0, existingOrganizationIndex),
          ...tagIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the Organization doesn't exist, add it
        updatedTags = [...tagIds.value, action.payload];
      }

      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          tagIds: {
            value: updatedTags,
            applied: updatedTags.length > 0,
          },
        },
      };
    }
    case actionTypes.ORGANIZATIONS_RESET_EXCEPT_COMMUNITY_FILTERS: {
      const { communityIds } = state.organizationsFilter;
      return {
        ...state,
        organizationsFilter: {
          ...initialState.organizationsFilter,
          status: "active",
          communityIds,
          page: 1,
        },
      };
    }

    case actionTypes.ORGANIZATIONS_RESET_FILTERS: {
      return {
        ...state,
        organizationsFilter: {
          ...initialState.organizationsFilter,
        },
      };
    }

    case actionTypes.ORGANIZATIONS_MEMBERS: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.ORGANIZATIONS_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.ORGANIZATIONS_TYPE: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          page: 1,
          type: { value: action.payload, applied: action.payload !== "" },
        },
      };
    }
    case actionTypes.ORGANIZATIONS_VIEW_TYPE: {
      return {
        ...state,
        organizationsFilter: {
          ...state.organizationsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SET_ORGANIZATIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          organizationsFilter: {
            ...filtersData.filters,
            status: initialState.organizationsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          organizationsFilter: initialState.organizationsFilter,
        };
      }
    }

    // Dynamic forms filter

    case actionTypes.DYNAMIC_FORMS_SORTING: {
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_SEARCH: {
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.DYNAMIC_FORMS_STATUS: {
      const { dateUpdated, archivedByIds, dateArchived } =
        initialState.dynamicFormsFilter;

      if (action.payload === "archived") {
        return {
          ...state,
          dynamicFormsFilter: {
            ...state.dynamicFormsFilter,
            page: 1,
            dateUpdated,
            status: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dynamicFormsFilter: {
            ...state.dynamicFormsFilter,
            page: 1,
            dateArchived,
            archivedByIds,
            status: action.payload,
          },
        };
      }
    }
    case actionTypes.DYNAMIC_FORMS_PAGINATION: {
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.DYNAMIC_FORMS_ITEMS_LIMIT: {
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_CREATED_BY: {
      const { createdByIds } = state.dynamicFormsFilter;

      // Check if the createdByIds with the same id exists
      const existingCreatedByIdsIndex = createdByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCreatedByIds;

      if (existingCreatedByIdsIndex !== -1) {
        // If the createdByIds exists, remove it
        updatedCreatedByIds = [
          ...createdByIds.value.slice(0, existingCreatedByIdsIndex),
          ...createdByIds.value.slice(existingCreatedByIdsIndex + 1),
        ];
      } else {
        // If the createdByIds doesn't exist, add it
        updatedCreatedByIds = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedByIds,
            applied: updatedCreatedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_ARCHIVED_BY: {
      const { archivedByIds } = state.dynamicFormsFilter;

      // Check if the archivedByIds with the same id exists
      const existingArchivedByIdsIndex = archivedByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedArchivedByIds;

      if (existingArchivedByIdsIndex !== -1) {
        // If the archivedByIds exists, remove it
        updatedArchivedByIds = [
          ...archivedByIds.value.slice(0, existingArchivedByIdsIndex),
          ...archivedByIds.value.slice(existingArchivedByIdsIndex + 1),
        ];
      } else {
        // If the archivedByIds doesn't exist, add it
        updatedArchivedByIds = [...archivedByIds.value, action.payload];
      }

      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          archivedByIds: {
            value: updatedArchivedByIds,
            applied: updatedArchivedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_PROGRAMS: {
      const { programIds } = state.dynamicFormsFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }
    case actionTypes.DYNAMIC_FORMS_PROTOCOLS: {
      const { protocolIds } = state.dynamicFormsFilter;

      // Check if the program with the same id exists
      const existingProtocolIndex = protocolIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedProtocols;

      if (existingProtocolIndex !== -1) {
        // If the program exists, remove it
        updatedProtocols = [
          ...protocolIds.value.slice(0, existingProtocolIndex),
          ...protocolIds.value.slice(existingProtocolIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedProtocols = [...protocolIds.value, action.payload];
      }

      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          protocolIds: {
            value: updatedProtocols,
            applied: updatedProtocols.length > 0,
          },
        },
      };
    }
    case actionTypes.DYNAMIC_FORMS_RESET_FILTERS: {
      // const {status} = state.dynamicFormsFilter
      return {
        ...state,
        dynamicFormsFilter: {
          ...initialState.dynamicFormsFilter,
          status: initialState.dynamicFormsFilter.status,
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_OBSERVASTIONS: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_DATE_CREATED_UPDATED_ARCHIVED: {
      const { from, to, name } = action.payload;
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_RESET_EXCEPT_PROGRAMS_FILTERS: {
      const { programIds } = state.dynamicFormsFilter;
      return {
        ...state,
        dynamicFormsFilter: {
          ...initialState.dynamicFormsFilter,
          programIds,
          page: 1,
        },
      };
    }
    case actionTypes.FORMS_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          programIds: action.payload.programIds,
          organizationIds: action.payload.organizationIds,
          communityIds: action.payload.communityIds,
        },
      };
    }

    case actionTypes.DYNAMIC_FORMS_COMMUNITY: {
      const { communityIds } = state.dynamicFormsFilter;

      // Check if the community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        dynamicFormsFilter: {
          ...state.dynamicFormsFilter,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_DYNAMIC_FORMS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          dynamicFormsFilter: {
            ...filtersData.filters,
            status: initialState.dynamicFormsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          dynamicFormsFilter: initialState.dynamicFormsFilter,
        };
      }
    }

    // programs filters Reducer

    case actionTypes.PROGRAMS_SORTING: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.PROGRAMS_SEARCH: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.PROGRAMS_STATUS: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.PROGRAMS_PAGINATION: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.PROGRAMS_ITEMS_LIMIT: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.PROGRAMS_ORGANIZATIONS: {
      const { organizationIds } = state.programsFilter;

      // Check if the Organization with the same id exists
      const existingOrganizationIndex = organizationIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedOrganizations;

      if (existingOrganizationIndex !== -1) {
        // If the Organization exists, remove it
        updatedOrganizations = [
          ...organizationIds.value.slice(0, existingOrganizationIndex),
          ...organizationIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the Organization doesn't exist, add it
        updatedOrganizations = [...organizationIds.value, action.payload];
      }

      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          organizationIds: {
            value: updatedOrganizations,
            applied: updatedOrganizations.length > 0,
          },
        },
      };
    }

    case actionTypes.PROGRAMS_RESET_EXCEPT_ORGANIZATION_FILTERS: {
      const { organizationIds } = state.programsFilter;
      return {
        ...state,
        programsFilter: {
          ...initialState.programsFilter,
          status: "active",
          organizationIds,
          page: 1,
        },
      };
    }

    case actionTypes.PROGRAMS_RESET_EXCEPT_COMMUNITY_FILTERS: {
      const { communityIds } = state.programsFilter;
      return {
        ...state,
        programsFilter: {
          ...initialState.programsFilter,
          status: "active",
          communityIds,
          page: 1,
        },
      };
    }

    case actionTypes.PROGRAMS_RESET_FILTERS: {
      return {
        ...state,
        programsFilter: {
          ...initialState.programsFilter,
        },
      };
    }

    case actionTypes.PROGRAMS_MEMBERS_OBSERVATIONS_FORMS: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.PROGRAMS_TIMEFRAME: {
      const { from, to } = action.payload;
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          date: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.PROGRAMS_VIEW_TYPE: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.PROGRAMS_COMMUNITY: {
      const { communityIds } = state.programsFilter;

      // Check if the Community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the Community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.PROGRAMS_TAGS: {
      const { tagIds } = state.programsFilter;

      // Check if the Programs with the same id exists
      const existingProgramsIndex = tagIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedTags;

      if (existingProgramsIndex !== -1) {
        // If the Programs exists, remove it
        updatedTags = [
          ...tagIds.value.slice(0, existingProgramsIndex),
          ...tagIds.value.slice(existingProgramsIndex + 1),
        ];
      } else {
        // If the Programs doesn't exist, add it
        updatedTags = [...tagIds.value, action.payload];
      }

      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          page: 1,
          tagIds: {
            value: updatedTags,
            applied: updatedTags.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_PROGRAMS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          programsFilter: {
            ...filtersData.filters,
            status: initialState.programsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          programsFilter: initialState.programsFilter,
        };
      }
    }

    case actionTypes.PROGRAMS_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        programsFilter: {
          ...state.programsFilter,
          formIds: action.payload.formIds,
          organizationIds: action.payload.organizationIds,
          communityIds: action.payload.communityIds,
        },
      };
    }
    ///  Protocols

    case actionTypes.PROTOCOLS_SORTING: {
      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.PROTOCOLS_SEARCH: {
      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.PROTOCOLS_STATUS: {
      const { created, archivedByIds, updated, archived } =
        initialState.protocolsFilter;

      if (action.payload === "archived") {
        return {
          ...state,
          protocolsFilter: {
            ...state.protocolsFilter,
            page: 1,
            updated,
            status: action.payload,
          },
        };
      } else {
        return {
          ...state,
          protocolsFilter: {
            ...state.protocolsFilter,
            page: 1,
            archived,
            archivedByIds,
            status: action.payload,
          },
        };
      }
    }
    case actionTypes.PROTOCOLS_PAGINATION: {
      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.PROTOCOLS_ITEMS_LIMIT: {
      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.PROTOCOLS_CREATED_BY: {
      const { createdByIds } = state.protocolsFilter;

      // Check if the createdByIds with the same id exists
      const existingCreatedByIdsIndex = createdByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCreatedByIds;

      if (existingCreatedByIdsIndex !== -1) {
        // If the createdByIds exists, remove it
        updatedCreatedByIds = [
          ...createdByIds.value.slice(0, existingCreatedByIdsIndex),
          ...createdByIds.value.slice(existingCreatedByIdsIndex + 1),
        ];
      } else {
        // If the createdByIds doesn't exist, add it
        updatedCreatedByIds = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedByIds,
            applied: updatedCreatedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.PROTOCOLS_ARCHIVED_BY: {
      const { archivedByIds } = state.protocolsFilter;

      // Check if the archivedByIds with the same id exists
      const existingArchivedByIdsIndex = archivedByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedArchivedByIds;

      if (existingArchivedByIdsIndex !== -1) {
        // If the archivedByIds exists, remove it
        updatedArchivedByIds = [
          ...archivedByIds.value.slice(0, existingArchivedByIdsIndex),
          ...archivedByIds.value.slice(existingArchivedByIdsIndex + 1),
        ];
      } else {
        // If the archivedByIds doesn't exist, add it
        updatedArchivedByIds = [...archivedByIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          archivedByIds: {
            value: updatedArchivedByIds,
            applied: updatedArchivedByIds.length > 0,
          },
        },
      };
    }
    case actionTypes.PROTOCOLS_DELETED_BY: {
      const { deletedByIds } = state.protocolsFilter;

      // Check if the deletedByIds with the same id exists
      const existingDeletedByIdsIndex = deletedByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedDeletedByIds;

      if (existingDeletedByIdsIndex !== -1) {
        // If the deletedByIds exists, remove it
        updatedDeletedByIds = [
          ...deletedByIds.value.slice(0, existingDeletedByIdsIndex),
          ...deletedByIds.value.slice(existingDeletedByIdsIndex + 1),
        ];
      } else {
        // If the deletedByIds doesn't exist, add it
        updatedDeletedByIds = [...deletedByIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          deletedByIds: {
            value: updatedDeletedByIds,
            applied: updatedDeletedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.PROTOCOLS_PROGRAM: {
      const { programIds } = state.protocolsFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.PROTOCOLS_UPDATED_BY: {
      const { updatedByIds } = state.protocolsFilter;

      // Check if the program with the same id exists
      const existingUpdatedByIdIndex = updatedByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedUpdatedByIds;

      if (existingUpdatedByIdIndex !== -1) {
        // If the program exists, remove it
        updatedUpdatedByIds = [
          ...updatedByIds.value.slice(0, existingUpdatedByIdIndex),
          ...updatedByIds.value.slice(existingUpdatedByIdIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedUpdatedByIds = [...updatedByIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          updatedByIds: {
            value: updatedUpdatedByIds,
            applied: updatedUpdatedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.PROTOCOLS_FORM: {
      const { formIds } = state.protocolsFilter;

      // Check if the program with the same id exists
      const existingFormIndex = formIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the Form exists, remove it
        updatedForms = [
          ...formIds.value.slice(0, existingFormIndex),
          ...formIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the Form doesn't exist, add it
        updatedForms = [...formIds.value, action.payload];
      }

      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          formIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.PROTOCOLS_RESET_FILTERS: {
      return {
        ...state,
        protocolsFilter: {
          ...initialState.protocolsFilter,
        },
      };
    }

    case actionTypes.PROTOCOLS_DATE_CREATED_UPDATED_ARCHIVED_DELETED: {
      const { from, to, name } = action.payload;
      return {
        ...state,
        protocolsFilter: {
          ...state.protocolsFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.PROTOCOLS_RESET_EXCEPT_PROGRAM_FILTERS: {
      const { programIds } = state.protocolsFilter;
      return {
        ...state,
        protocolsFilter: {
          ...initialState.protocolsFilter,
          programIds,
          page: 1,
        },
      };
    }
    case actionTypes.PROTOCOLS_RESET_EXCEPT_FORM_FILTERS: {
      const { formIds } = state.protocolsFilter;
      return {
        ...state,
        protocolsFilter: {
          ...initialState.protocolsFilter,
          formIds,
          page: 1,
        },
      };
    }

    case actionTypes.SET_PROTOCOLS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          protocolsFilter: {
            ...filtersData.filters,
            status: initialState.protocolsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          protocolsFilter: initialState.protocolsFilter,
        };
      }
    }

    // observations filters Reducer

    case actionTypes.OBSERVATIONS_SORTING: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.OBSERVATIONS_SEARCH: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.OBSERVATIONS_STATUS: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.OBSERVATIONS_PAGINATION: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.OBSERVATIONS_ITEMS_LIMIT: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.OBSERVATIONS_OBSERVER: {
      const { observerIds } = state.observationsFilter;
      // Check if the Observer with the same id exists
      const existingObserverIndex = observerIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedObservers;

      if (existingObserverIndex !== -1) {
        // If the Observer exists, remove it
        updatedObservers = [
          ...observerIds.value.slice(0, existingObserverIndex),
          ...observerIds.value.slice(existingObserverIndex + 1),
        ];
      } else {
        // If the Observer doesn't exist, add it
        updatedObservers = [...observerIds.value, action.payload];
      }
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          observerIds: {
            value: updatedObservers,
            applied: updatedObservers.length > 0,
          },
        },
      };
    }

    case actionTypes.OBSERVATIONS_PROGRAMS: {
      const { programIds } = state.observationsFilter;
      // Check if the Program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the Program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the Program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.OBSERVATIONS_COMMUNITY: {
      const { communityIds } = state.observationsFilter;

      // Check if the Community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the Community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.OBSERVATIONS_FORMS: {
      const { formIds } = state.observationsFilter;

      // Check if the Form with the same id exists
      const existingFormIndex = formIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the Form exists, remove it
        updatedForms = [
          ...formIds.value.slice(0, existingFormIndex),
          ...formIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the Form doesn't exist, add it
        updatedForms = [...formIds.value, action.payload];
      }

      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          formIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.OBSERVATIONS_RESET_EXCEPT_PROGRAM_FILTERS: {
      const { programIds } = state.observationsFilter;
      return {
        ...state,
        observationsFilter: {
          ...initialState.observationsFilter,
          status: "all",
          programIds,
          page: 1,
        },
      };
    }

    case actionTypes.OBSERVATIONS_RESET_EXCEPT_COMMUNITY_FILTERS: {
      const { communityIds } = state.observationsFilter;
      return {
        ...state,
        observationsFilter: {
          ...initialState.observationsFilter,
          status: "all",
          communityIds,
          page: 1,
        },
      };
    }

    case actionTypes.OBSERVATIONS_RESET_FILTERS: {
      return {
        ...state,
        observationsFilter: {
          ...initialState.observationsFilter,
        },
      };
    }

    case actionTypes.OBSERVATIONS_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.OBSERVATIONS_VIEW_TYPE: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.OBSERVATIONS_SPECIES: {
      const { specieIds } = state.observationsFilter;
      // Check if the Species with the same id exists
      const existingSpecieIndex = specieIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedSpecies;

      if (existingSpecieIndex !== -1) {
        // If the Species exists, remove it
        updatedSpecies = [
          ...specieIds.value.slice(0, existingSpecieIndex),
          ...specieIds.value.slice(existingSpecieIndex + 1),
        ];
      } else {
        // If the Species doesn't exist, add it
        updatedSpecies = [...specieIds.value, action.payload];
      }

      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          page: 1,
          specieIds: {
            value: updatedSpecies,
            applied: updatedSpecies.length > 0,
          },
        },
      };
    }

    case actionTypes.OBSERVATIONS_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        observationsFilter: {
          ...state.observationsFilter,
          formIds: action.payload.formIds,
          programIds: action.payload.programIds,
          organizationIds: action.payload.organizationIds,
          communityIds: action.payload.communityIds,
          // speciesObserved: true,
        },
      };
    }

    case actionTypes.SET_OBSERVATIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          observationsFilter: {
            ...filtersData.filters,
            status: initialState.observationsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          observationsFilter: initialState.observationsFilter,
        };
      }
    }

    // Messages Filters Reducer
    case actionTypes.MESSAGES_SEARCH: {
      return {
        ...state,
        messageDetailFilter: {
          ...state.messageDetailFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.MESSAGES_PAGINATION: {
      return {
        ...state,
        messageDetailFilter: {
          ...state.messageDetailFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.MESSAGES_ITEMS_LIMIT: {
      return {
        ...state,
        messageDetailFilter: {
          ...state.messageDetailFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.MESSAGES_STATUS: {
      return {
        ...state,
        messageStatusFilter: {
          ...state.messageStatusFilter,
          archived: action.payload,
        },
      };
    }
    case actionTypes.MESSAGES_RESET_FILTER: {
      return {
        ...state,
        messageDetailFilter: {
          searchString: "",
          searchBy: "",
          page: 1,
          limit: 20,
        },
      };
    }

    case actionTypes.MESSAGES_CONTACT_PERSON_SEARCH: {
      return {
        ...state,
        messageContactPersonSearchFilter: {
          ...state.messageContactPersonSearchFilter,
          searchString: action.payload.searchString,
        },
      };
    }

    case actionTypes.MESSAGES_NEW_CHAT: {
      return {
        ...state,
        messageNewChatFilter: {
          ...state.messageNewChatFilter,
          newChat: action.payload,
        },
      };
    }

    case actionTypes.MESSAGES_APP_ID: {
      return {
        ...state,
        messageAppIdFilter: {
          ...state.messageAppIdFilter,
          appId: action.payload,
        },
      };
    }


    // Observation activities
    case actionTypes.ACTIVITIES_PAGINATION: {
      return {
        ...state,
        observationActivityFilter: {
          ...state.observationActivityFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.ACTIVITIES_ITEMS_LIMIT: {
      return {
        ...state,
        observationActivityFilter: {
          ...state.observationActivityFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.ACTIVITY_TYPE: {
      return {
        ...state,
        observationActivityFilter: {
          ...state.observationActivityFilter,
          activityType: action.payload,
        },
      };
    }
    case actionTypes.ACTIVITIES_RESET_FILTER: {
      return {
        ...state,
        observationActivityFilter: {
          activityType: "",
          page: 1,
          limit: 20,
        },
      };
    }

    // Quality Assurance observations filters Reducer

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_SORTING: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_SEARCH: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_STATUS: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_PAGINATION: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_ITEMS_LIMIT: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_FILTER_STATUS: {
      const { filterStatus } = state.qualityAssuranceObservationsFilter;
      // Check if the Program with the same id exists
      const existingStatusIndex = filterStatus.value.findIndex(
        (item) => item === action.payload
      );

      let updatedStatus;

      if (existingStatusIndex !== -1) {
        // If the Status exists, remove it
        updatedStatus = [
          ...filterStatus.value.slice(0, existingStatusIndex),
          ...filterStatus.value.slice(existingStatusIndex + 1),
        ];
      } else {
        // If the Status doesn't exist, add it
        updatedStatus = [...filterStatus.value, action.payload];
      }

      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          filterStatus: {
            value: updatedStatus,
            applied: updatedStatus.length > 0,
          },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_OBSERVER: {
      const { observerIds } = state.qualityAssuranceObservationsFilter;
      // Check if the Observer with the same id exists
      const existingObserverIndex = observerIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedObservers;

      if (existingObserverIndex !== -1) {
        // If the Observer exists, remove it
        updatedObservers = [
          ...observerIds.value.slice(0, existingObserverIndex),
          ...observerIds.value.slice(existingObserverIndex + 1),
        ];
      } else {
        // If the Observer doesn't exist, add it
        updatedObservers = [...observerIds.value, action.payload];
      }
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          observerIds: {
            value: updatedObservers,
            applied: updatedObservers.length > 0,
          },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_PROGRAMS: {
      const { programIds } = state.qualityAssuranceObservationsFilter;
      // Check if the Program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the Program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the Program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_COMMUNITY: {
      const { communityIds } = state.qualityAssuranceObservationsFilter;

      // Check if the Community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the Community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_REVIEWER: {
      const { reviewerIds } = state.qualityAssuranceObservationsFilter;

      // Check if the Form with the same id exists
      const existingFormIndex = reviewerIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the Form exists, remove it
        updatedForms = [
          ...reviewerIds.value.slice(0, existingFormIndex),
          ...reviewerIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the Form doesn't exist, add it
        updatedForms = [...reviewerIds.value, action.payload];
      }

      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          reviewerIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_RESET_FILTERS: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...initialState.qualityAssuranceObservationsFilter,
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_DATE_UPDATED: {
      const { from, to } = action.payload;
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          page: 1,
          dateUpdated: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.QUALITY_ASSURANCE_OBSERVATIONS_VIEW_TYPE: {
      return {
        ...state,
        qualityAssuranceObservationsFilter: {
          ...state.qualityAssuranceObservationsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SET_QUALITY_ASSURANCE_OBSERVATIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          qualityAssuranceObservationsFilter: {
            ...filtersData.filters,
            status: initialState.qualityAssuranceObservationsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          qualityAssuranceObservationsFilter:
            initialState.qualityAssuranceObservationsFilter,
        };
      }
    }

    case actionTypes.MANAGER_TYPE: {
      return {
        ...state,
        managerFilter: {
          ...state.managerFilter,
          page: 1,
          type: action.payload,
        },
      };
    }
    case actionTypes.MANAGER_SORTING: {
      return {
        ...state,
        managerFilter: {
          ...state.managerFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }
    case actionTypes.MANAGER_SEARCH: {
      return {
        ...state,
        managerFilter: {
          ...state.managerFilter,
          page: 1,
          searchString: payload.searchString,
        },
      };
    }

    case actionTypes.MANAGER_PAGINATION: {
      return {
        ...state,
        managerFilter: {
          ...state.managerFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.MANAGER_ITEMS_LIMIT: {
      return {
        ...state,
        managerFilter: {
          ...state.managerFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.MANAGER_FILTER_RESET: {
      return {
        ...state,
        managerFilter: {
          ...initialState.managerFilter,
        },
      };
    }

    case actionTypes.SET_MANAGER_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          managerFilter: {
            ...filtersData.filters,
            type: state.managerFilter.type,
          },
        };
      } else {
        return {
          ...state,
          managerFilter: initialState.managerFilter,
        };
      }
    }

    // CATEGORIES DIRECTORY FILTERS REDUCERS
    case actionTypes.CATEGORIES_SORTING: {
      return {
        ...state,
        categoriesFilter: {
          ...state.categoriesFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.CATEGORIES_SEARCH: {
      return {
        ...state,
        categoriesFilter: {
          ...state.categoriesFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.CATEGORIES_STATUS: {
      return {
        ...state,
        categoriesFilter: {
          ...state.categoriesFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.CATEGORIES_PAGINATION: {
      return {
        ...state,
        categoriesFilter: {
          ...state.categoriesFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.CATEGORIES_ITEMS_LIMIT: {
      return {
        ...state,
        categoriesFilter: {
          ...state.categoriesFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.CATEGORIES_RESET_FILTERS: {
      return {
        ...state,
        categoriesFilter: {
          ...initialState.categoriesFilter,
        },
      };
    }

    case actionTypes.SET_CATEGORIES_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          categoriesFilter: {
            ...filtersData.filters,
            status: initialState.categoriesFilter.status,
          },
        };
      } else {
        return {
          ...state,
          categoriesFilter: initialState.categoriesFilter,
        };
      }
    }

    // BEHAVIORS DIRECTORY FILTERS REDUCERS
    case actionTypes.BEHAVIORS_SORTING: {
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.BEHAVIORS_SEARCH: {
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.BEHAVIORS_STATUS: {
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.BEHAVIORS_PAGINATION: {
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.BEHAVIORS_ITEMS_LIMIT: {
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.BEHAVIORS_RESET_FILTERS: {
      return {
        ...state,
        behaviorsFilter: {
          ...initialState.behaviorsFilter,
        },
      };
    }

    case actionTypes.BEHAVIORS_TYPES: {
      const { typeIds } = state.behaviorsFilter;
      // Check if the Type with the same id exists
      const existingTypeIndex = typeIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedTypes;
      if (existingTypeIndex !== -1) {
        // If the Type exists, remove it
        updatedTypes = [
          ...typeIds.value.slice(0, existingTypeIndex),
          ...typeIds.value.slice(existingTypeIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedTypes = [...typeIds.value, action.payload];
      }
      return {
        ...state,
        behaviorsFilter: {
          ...state.behaviorsFilter,
          page: 1,
          typeIds: {
            value: updatedTypes,
            applied: updatedTypes.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_BEHAVIORS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          behaviorsFilter: {
            ...filtersData.filters,
            status: initialState.behaviorsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          behaviorsFilter: initialState.behaviorsFilter,
        };
      }
    }

    // CONDITIONS DIRECTORY FILTERS REDUCERS
    case actionTypes.CONDITIONS_SORTING: {
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.CONDITIONS_SEARCH: {
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.CONDITIONS_STATUS: {
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.CONDITIONS_PAGINATION: {
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.CONDITIONS_ITEMS_LIMIT: {
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.CONDITIONS_RESET_FILTERS: {
      return {
        ...state,
        conditionsFilter: {
          ...initialState.conditionsFilter,
        },
      };
    }

    case actionTypes.CONDITIONS_TYPES: {
      const { typeIds } = state.conditionsFilter;
      // Check if the Type with the same id exists
      const existingTypeIndex = typeIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedTypes;
      if (existingTypeIndex !== -1) {
        // If the Type exists, remove it
        updatedTypes = [
          ...typeIds.value.slice(0, existingTypeIndex),
          ...typeIds.value.slice(existingTypeIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedTypes = [...typeIds.value, action.payload];
      }
      return {
        ...state,
        conditionsFilter: {
          ...state.conditionsFilter,
          page: 1,
          typeIds: {
            value: updatedTypes,
            applied: updatedTypes.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_CONDITIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          conditionsFilter: {
            ...filtersData.filters,
            status: initialState.conditionsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          conditionsFilter: initialState.conditionsFilter,
        };
      }
    }

    // TYPES DIRECTORY FILTERS REDUCERS
    case actionTypes.TYPES_SORTING: {
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.TYPES_SEARCH: {
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.TYPES_STATUS: {
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.TYPES_PAGINATION: {
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.TYPES_ITEMS_LIMIT: {
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.TYPES_RESET_FILTERS: {
      return {
        ...state,
        typesFilter: {
          ...initialState.typesFilter,
        },
      };
    }

    case actionTypes.TYPES_CATEGORIES: {
      const { categoryIds } = state.typesFilter;
      // Check if the Category with the same id exists
      const existingCategoryIndex = categoryIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCategories;

      if (existingCategoryIndex !== -1) {
        // If the Category exists, remove it
        updatedCategories = [
          ...categoryIds.value.slice(0, existingCategoryIndex),
          ...categoryIds.value.slice(existingCategoryIndex + 1),
        ];
      } else {
        // If the Category doesn't exist, add it
        updatedCategories = [...categoryIds.value, action.payload];
      }
      return {
        ...state,
        typesFilter: {
          ...state.typesFilter,
          page: 1,
          categoryIds: {
            value: updatedCategories,
            applied: updatedCategories.length > 0,
          },
        },
      };
    }

    case actionTypes.SET_TAGS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          typesFilter: {
            ...filtersData.filters,
            status: initialState.typesFilter.status,
          },
        };
      } else {
        return {
          ...state,
          typesFilter: initialState.typesFilter,
        };
      }
    }

    // ADMIN_TRACKING DIRECTORY FILTERS REDUCERS
    case actionTypes.ADMIN_TRACKING_SORTING: {
      return {
        ...state,
        adminTrackingFilter: {
          ...state.adminTrackingFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.ADMIN_TRACKING_SEARCH: {
      return {
        ...state,
        adminTrackingFilter: {
          ...state.adminTrackingFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.ADMIN_TRACKING_STATUS: {
      return {
        ...state,
        adminTrackingFilter: {
          ...state.adminTrackingFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.ADMIN_TRACKING_PAGINATION: {
      return {
        ...state,
        adminTrackingFilter: {
          ...state.adminTrackingFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.ADMIN_TRACKING_ITEMS_LIMIT: {
      return {
        ...state,
        adminTrackingFilter: {
          ...state.adminTrackingFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.ADMIN_TRACKING_RESET_FILTERS: {
      return {
        ...state,
        adminTrackingFilter: {
          ...initialState.adminTrackingFilter,
        },
      };
    }

    case actionTypes.SET_ADMIN_TRACKING_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          adminTrackingFilter: {
            ...filtersData.filters,
            status: initialState.adminTrackingFilter.status,
          },
        };
      } else {
        return {
          ...state,
          adminTrackingFilter: initialState.adminTrackingFilter,
        };
      }
    }

    // Reports filter Reducer

    case actionTypes.REPORTS_SORTING: {
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.REPORTS_SEARCH: {
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.REPORTS_STATUS: {
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_PAGINATION: {
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_ITEMS_LIMIT: {
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.REPORTS_RESET_FILTERS: {
      return {
        ...state,
        reportsFilter: {
          ...initialState.reportsFilter,
        },
      };
    }

    case actionTypes.REPORTS_PROGRAMS: {
      const { programIds } = state.reportsFilter;
      // Check if the Program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the Program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the Program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_COMMUNITY: {
      const { communityIds } = state.reportsFilter;

      // Check if the Community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the Community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_ORGANIZATION: {
      const { organizationIds } = state.reportsFilter;

      // Check if the Organization with the same id exists
      const existingOrganizationIndex = organizationIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedOrganizations;

      if (existingOrganizationIndex !== -1) {
        // If the Organization exists, remove it
        updatedOrganizations = [
          ...organizationIds.value.slice(0, existingOrganizationIndex),
          ...organizationIds.value.slice(existingOrganizationIndex + 1),
        ];
      } else {
        // If the Organization doesn't exist, add it
        updatedOrganizations = [...organizationIds.value, action.payload];
      }

      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          organizationIds: {
            value: updatedOrganizations,
            applied: updatedOrganizations.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_FORMS: {
      const { formIds } = state.reportsFilter;
      // Check if the Form with the same id exists
      const existingFormIndex = formIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the Form exists, remove it
        updatedForms = [
          ...formIds.value.slice(0, existingFormIndex),
          ...formIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the Form doesn't exist, add it
        updatedForms = [...formIds.value, action.payload];
      }
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          formIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_CREATED_BY: {
      const { createdByIds } = state.reportsFilter;

      // Check if the Created By with the same id exists
      const existingCreatedByIndex = createdByIds.value.findIndex(
        (item: any) => item?.id === action.payload.id
      );

      let updatedCreatedBy;

      if (existingCreatedByIndex !== -1) {
        // If the Created By exists, remove it
        updatedCreatedBy = [
          ...createdByIds.value.slice(0, existingCreatedByIndex),
          ...createdByIds.value.slice(existingCreatedByIndex + 1),
        ];
      } else {
        // If the Created By doesn't exist, add it
        updatedCreatedBy = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedBy,
            applied: updatedCreatedBy.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_DATE_ADDED_UPDATED_LAST_RUN_NEXT_RUN: {
      const { from, to, name } = action.payload;
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.REPORTS_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.REPORTS_UPDATED_DATE: {
      const { from, to } = action.payload;
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          updatedDate: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.REPORTS_LAST_RUN: {
      const { from, to } = action.payload;
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          lastRun: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.REPORTS_NEXT_RUN: {
      const { from, to } = action.payload;
      return {
        ...state,
        reportsFilter: {
          ...state.reportsFilter,
          page: 1,
          nextRun: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.SET_REPORT_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          reportsFilter: {
            ...filtersData.filters,
            status: initialState.reportsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          reportsFilter: initialState.reportsFilter,
        };
      }
    }
    // Run Report filter reducer

    case "RUN_REPORTS_DATE_SELECT" : {
      const { from, to, name } = action.payload;
      return {
        ...state,
        reportsRunReportsFilter: {
          ...state.reportsRunReportsFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case "RUN_REPORT_RESET_FILTER": {
      return {
        ...state,
        reportsRunReportsFilter: {
          ...initialState.reportsRunReportsFilter,
        },
      };
    }
    // Reports processed filter Reducer

    case actionTypes.REPORTS_PROFILE_SORTING: {
      return {
        ...state,
        reportProfileFilter: {
          ...state.reportProfileFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.REPORTS_PROFILE_STATUS: {
      return {
        ...state,
        reportProfileFilter: {
          ...state.reportProfileFilter,
          page: 1,
          status: { value: action.payload, applied: action.payload !== "" },
        },
      };
    }

    case actionTypes.REPORTS_PROFILE_PAGINATION: {
      return {
        ...state,
        reportProfileFilter: {
          ...state.reportProfileFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_PROFILE_ITEMS_LIMIT: {
      return {
        ...state,
        reportProfileFilter: {
          ...state.reportProfileFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.REPORTS_PROFILE_RESET_FILTERS: {
      return {
        ...state,
        reportProfileFilter: {
          ...initialState.reportProfileFilter,
        },
      };
    }
    // Reports Analytics filter Reducer

    case actionTypes.REPORTS_ANALYTICS_SORTING: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_STATUS: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_PAGINATION: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_ITEMS_LIMIT: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_RESET_FILTERS: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...initialState.reportsAnalyticsFilter,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_VERSION: {
      const { versionIds } = state.reportsAnalyticsFilter;

      // Check if the Version with the same id exists
      const existingVersionIndex = versionIds.value.findIndex(
        (item: any) => item?.id === action.payload.id
      );

      let updatedversions;

      if (existingVersionIndex !== -1) {
        // If the Version exists, remove it
        updatedversions = [
          ...versionIds.value.slice(0, existingVersionIndex),
          ...versionIds.value.slice(existingVersionIndex + 1),
        ];
      } else {
        // If the Version doesn't exist, add it
        updatedversions = [...versionIds.value, action.payload];
      }

      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          versionIds: {
            value: updatedversions,
            applied: updatedversions.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_MEMBER: {
      const { memberIds } = state.reportsAnalyticsFilter;

      // Check if the Community with the same id exists
      const existingMemberIndex = memberIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedMembers;

      if (existingMemberIndex !== -1) {
        // If the Community exists, remove it
        updatedMembers = [
          ...memberIds.value.slice(0, existingMemberIndex),
          ...memberIds.value.slice(existingMemberIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedMembers = [...memberIds.value, action.payload];
      }

      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          memberIds: {
            value: updatedMembers,
            applied: updatedMembers.length > 0,
          },
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_ACTIVITY: {
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          activityType: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_ANALYTICS_DATE: {
      const { from, to } = action.payload;
      return {
        ...state,
        reportsAnalyticsFilter: {
          ...state.reportsAnalyticsFilter,
          page: 1,
          date: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.SET_REPORTS_ANALYTICS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          reportsAnalyticsFilter: {
            ...filtersData.filters,
            // activityType: state.reportsAnalyticsFilter.activityType,
          },
        };
      } else {
        return {
          ...state,
          reportsAnalyticsFilter: initialState.reportsAnalyticsFilter,
        };
      }
    }

    case actionTypes.REPORTS_VERSIONS: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          versionIds: {
            value: action.payload.name,
            applied: action.payload !== "",
            id: action.payload.id,
          },
        },
      };
    }
    case actionTypes.REPORTS_VERIONS_ITEMS_LIMIT: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.REPORTS_VERSION_RESET_FILTERS: {
      return {
        ...state,
        reportsVersionFilter: {
          ...initialState.reportsVersionFilter,
        },
      };
    }
    case actionTypes.REPORTS_VERSION_PAGINATION: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.REPORTS_VERIONS_TOTAL_PAGES: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          totalPages: action.payload,
        },
      };
    }
    case actionTypes.REPORTS_ITEMS_PER_PAGE_LIMIT: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          itemsPerPage: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_VETSION_SEARCH: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          page: 1,
          searchString: action.payload,
        },
      };
    }

    case actionTypes.REPORTS_VERSION_RESET_FILTERS: {
      return {
        ...state,
        reportsVersionFilter: {
          ...initialState.reportsVersionFilter,
        },
      };
    }
    case actionTypes.REPORTS_VERSIONS_VIEW_TYPE: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          // page: 1,
          viewType: action.payload,
        },
      };
    }
    case actionTypes.REPORTS_VERSION_LOCATIONS: {
      return {
        ...state,
        reportsVersionFilter: {
          ...state.reportsVersionFilter,
          page: 1,
          locations: action.payload,
        },
      };
    }

    case actionTypes.SET_REPORTS_VERSION_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          reportsVersionFilter: { ...filtersData.filters },
        };
      } else {
        return {
          ...state,
          reportsVersionFilter: initialState.reportsVersionFilter,
        };
      }
    }

    // Notifications filters Reducer

    case actionTypes.NOTIFICATIONS_SORTING: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          sortBy: action.payload.sortBy,
          sortType: action.payload.sortType,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_SEARCH: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_STATUS: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_PAGINATION: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_ITEMS_LIMIT: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_PROGRAMS: {
      const { programIds } = state.notificationsFilter;
      // Check if the Program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the Program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the Program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_FORMS: {
      const { formIds } = state.notificationsFilter;
      // Check if the Form with the same id exists
      const existingFormIndex = formIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedForms;

      if (existingFormIndex !== -1) {
        // If the Form exists, remove it
        updatedForms = [
          ...formIds.value.slice(0, existingFormIndex),
          ...formIds.value.slice(existingFormIndex + 1),
        ];
      } else {
        // If the Form doesn't exist, add it
        updatedForms = [...formIds.value, action.payload];
      }
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          formIds: {
            value: updatedForms,
            applied: updatedForms.length > 0,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_COMMUNITY: {
      const { communityIds } = state.notificationsFilter;

      // Check if the Community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the Community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the Community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_MEMBER: {
      const { memberIds } = state.notificationsFilter;

      // Check if the Member with the same id exists
      const existingMemberIndex = memberIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedMembers;

      if (existingMemberIndex !== -1) {
        // If the Member exists, remove it
        updatedMembers = [
          ...memberIds.value.slice(0, existingMemberIndex),
          ...memberIds.value.slice(existingMemberIndex + 1),
        ];
      } else {
        // If the Member doesn't exist, add it
        updatedMembers = [...memberIds.value, action.payload];
      }

      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          memberIds: {
            value: updatedMembers,
            applied: updatedMembers.length > 0,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_CREATED_BY: {
      const { createdByIds } = state.notificationsFilter;

      // Check if the Created By with the same id exists
      const existingCreatedByIndex = createdByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCreatedBy;

      if (existingCreatedByIndex !== -1) {
        // If the Created By exists, remove it
        updatedCreatedBy = [
          ...createdByIds.value.slice(0, existingCreatedByIndex),
          ...createdByIds.value.slice(existingCreatedByIndex + 1),
        ];
      } else {
        // If the Created By doesn't exist, add it
        updatedCreatedBy = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedBy,
            applied: updatedCreatedBy.length > 0,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_RESET_FILTERS: {
      return {
        ...state,
        notificationsFilter: {
          ...initialState.notificationsFilter,
        },
      };
    }

    case actionTypes.NOTIFICATIONS_SCHEDULED_FOR: {
      const { scheduledFor } = action.payload;
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          page: 1,
          scheduledFor: {
            value: scheduledFor,
            applied: scheduledFor !== "" ? true : false,
          },
        },
      };
    }

    case actionTypes.NOTIFICATIONS_VIEW_TYPE: {
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SET_NOTIFICATIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          notificationsFilter: {
            ...filtersData.filters,
            status: initialState.notificationsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          notificationsFilter: initialState.notificationsFilter,
        };
      }
    }

    // SPECIES DIRECTORY FILTERS REDUCERS
    case actionTypes.SPECIES_SORTING: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }
    case actionTypes.SPECIES_SEARCH: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.SPECIES_STATUS: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.SPECIES_PAGINATION: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.SPECIES_ITEMS_LIMIT: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.SPECIES_RESET_FILTERS: {
      return {
        ...state,
        speciesFilter: {
          ...initialState.speciesFilter,
          viewType: state.speciesFilter.viewType,
        },
      };
    }
    case actionTypes.SPECIES_TYPES: {
      const { typeIds } = state.speciesFilter;
      // Check if the Type with the same id exists
      const existingTypeIndex = typeIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedTypes;
      if (existingTypeIndex !== -1) {
        // If the Type exists, remove it
        updatedTypes = [
          ...typeIds.value.slice(0, existingTypeIndex),
          ...typeIds.value.slice(existingTypeIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedTypes = [...typeIds.value, action.payload];
      }
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          typeIds: {
            value: updatedTypes,
            applied: updatedTypes.length > 0,
          },
        },
      };
    }
    case actionTypes.SPECIES_CATEGORIES: {
      const { categoryIds } = state.speciesFilter;
      // Check if the Type with the same id exists
      const existingCategoriesIndex = categoryIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedCategories;
      if (existingCategoriesIndex !== -1) {
        // If the Type exists, remove it
        updatedCategories = [
          ...categoryIds.value.slice(0, existingCategoriesIndex),
          ...categoryIds.value.slice(existingCategoriesIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedCategories = [...categoryIds.value, action.payload];
      }
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          categoryIds: {
            value: updatedCategories,
            applied: updatedCategories.length > 0,
          },
        },
      };
    }
    case actionTypes.SPECIES_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.SPECIES_VIEW_TYPE: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SPECIES_REDIRECTION_FROM_DASHBOARD: {
      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          formIds: action.payload.formIds,
          programIds: action.payload.programIds,
          organizationIds: action.payload.organizationIds,
          communityIds: action.payload.communityIds,
        },
      };
    }

    case actionTypes.SET_SPECIES_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          speciesFilter: {
            ...filtersData.filters,
            status: initialState.speciesFilter.status,
          },
        };
      } else {
        return {
          ...state,
          speciesFilter: initialState.speciesFilter,
        };
      }
    }

    case actionTypes.SPECIES_COMMUNITY: {
      const { communityIds } = state.speciesFilter;

      // Check if the community with the same id exists
      const existingCommunityIndex = communityIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedCommunities;

      if (existingCommunityIndex !== -1) {
        // If the community exists, remove it
        updatedCommunities = [
          ...communityIds.value.slice(0, existingCommunityIndex),
          ...communityIds.value.slice(existingCommunityIndex + 1),
        ];
      } else {
        // If the community doesn't exist, add it
        updatedCommunities = [...communityIds.value, action.payload];
      }

      return {
        ...state,
        speciesFilter: {
          ...state.speciesFilter,
          communityIds: {
            value: updatedCommunities,
            applied: updatedCommunities.length > 0,
          },
        },
      };
    }

    // OBJECTS DIRECTORY FILTERS REDUCERS
    case actionTypes.OBJECTS_SORTING: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }
    case actionTypes.OBJECTS_SEARCH: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.OBJECTS_STATUS: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }
    case actionTypes.OBJECTS_PAGINATION: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.OBJECTS_ITEMS_LIMIT: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.OBJECTS_RESET_FILTERS: {
      return {
        ...state,
        objectsFilter: {
          ...initialState.objectsFilter,
          viewType: state.objectsFilter.viewType,
        },
      };
    }
    case actionTypes.OBJECTS_TYPES: {
      const { typeIds } = state.objectsFilter;
      // Check if the Type with the same id exists
      const existingTypeIndex = typeIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedTypes;
      if (existingTypeIndex !== -1) {
        // If the Type exists, remove it
        updatedTypes = [
          ...typeIds.value.slice(0, existingTypeIndex),
          ...typeIds.value.slice(existingTypeIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedTypes = [...typeIds.value, action.payload];
      }
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          typeIds: {
            value: updatedTypes,
            applied: updatedTypes.length > 0,
          },
        },
      };
    }
    case actionTypes.OBJECTS_CATEGORIES: {
      const { categoryIds } = state.objectsFilter;
      // Check if the Type with the same id exists
      const existingCategoriesIndex = categoryIds.value.findIndex(
        (item) => item.id === action.payload.id
      );
      let updatedCategories;
      if (existingCategoriesIndex !== -1) {
        // If the Type exists, remove it
        updatedCategories = [
          ...categoryIds.value.slice(0, existingCategoriesIndex),
          ...categoryIds.value.slice(existingCategoriesIndex + 1),
        ];
      } else {
        // If the Type doesn't exist, add it
        updatedCategories = [...categoryIds.value, action.payload];
      }
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          categoryIds: {
            value: updatedCategories,
            applied: updatedCategories.length > 0,
          },
        },
      };
    }
    case actionTypes.OBJECTS_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.OBJECTS_VIEW_TYPE: {
      return {
        ...state,
        objectsFilter: {
          ...state.objectsFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SET_OBJECTS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          objectsFilter: {
            ...filtersData.filters,
            status: initialState.objectsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          objectsFilter: initialState.objectsFilter,
        };
      }
    }

    // TAGS DIRECTORY FILTERS REDUCERS
    case actionTypes.TAGS_SORTING: {
      return {
        ...state,
        tagsFilter: {
          ...state.tagsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.TAGS_SEARCH: {
      return {
        ...state,
        tagsFilter: {
          ...state.tagsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }

    case actionTypes.TAGS_PAGINATION: {
      return {
        ...state,
        tagsFilter: {
          ...state.tagsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.TAGS_ITEMS_LIMIT: {
      return {
        ...state,
        tagsFilter: {
          ...state.tagsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.TAG_RESET_ACTION: {
      return {
        ...state,
        tagsFilter: {
          ...initialState.tagsFilter,
        },
      };
    }

    case actionTypes.SET_TAGS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          tagsFilter: { ...filtersData.filters },
        };
      } else {
        return {
          ...state,
          tagsFilter: initialState.tagsFilter,
        };
      }
    }

    case actionTypes.NOTIFICATIONSRECIPIENTS_SORTING: {
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.NOTIFICATIONSRECIPIENTS_SEARCH: {
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.NOTIFICATIONSRECIPIENTS_PAGINATION: {
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.NOTIFICATIONSRECIPIENTS_ITEMS_LIMIT: {
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.NOTIFICATIONSRECIPIENTS_ACTIONS: {
      const { date } = action.payload;
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          page: 1,
          dateRecieved: { date, applied: date !== "" ? true : false },
        },
      };
    }
    case actionTypes.NOTIFICATIONS_RECIPIENTS_DATE_CREATED_UPDATED_ARCHIVED_DELETED: {
      const { date } = action.payload;
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...state.notificationsRecipientsFilter,
          page: 1,
          dateRecieved: { date, applied: date !== "" ? true : false },
        },
      };
    }
    case actionTypes.NOTIFICATIONS_RECIPIENTS_RESET_EXCEPT_PROGRAM_FILTERS: {
      return {
        ...state,
        notificationsRecipientsFilter: {
          ...initialState.notificationsRecipientsFilter,
        },
      };
    }

    // GEOGRAPHY DIRECTORY FILTERS REDUCERS
    case actionTypes.GEOGRAPHY_SORTING: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.GEOGRAPHY_SEARCH: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }

    case actionTypes.GEOGRAPHY_PAGINATION: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.GEOGRAPHY_ITEMS_LIMIT: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.GEOGRAPHY_RESET_FILTERS: {
      return {
        ...state,
        geographyFilter: {
          ...initialState.geographyFilter,
          viewType: state.geographyFilter.viewType,
        },
      };
    }

    case actionTypes.GEOGRAPHY_CREATED_BY: {
      const { createdByIds } = state.geographyFilter;

      // Check if the createdByIds with the same id exists
      const existingCreatedByIdsIndex = createdByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCreatedByIds;

      if (existingCreatedByIdsIndex !== -1) {
        // If the createdByIds exists, remove it
        updatedCreatedByIds = [
          ...createdByIds.value.slice(0, existingCreatedByIdsIndex),
          ...createdByIds.value.slice(existingCreatedByIdsIndex + 1),
        ];
      } else {
        // If the createdByIds doesn't exist, add it
        updatedCreatedByIds = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedByIds,
            applied: updatedCreatedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.GEOGRAPHY_DATE_ADDED: {
      const { from, to } = action.payload;
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          page: 1,
          dateAdded: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }
    case actionTypes.GEOGRAPHY_TYPE: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          type: action.payload,
        },
      };
    }
    case actionTypes.GEOGRAPHY_VIEW_TYPE: {
      return {
        ...state,
        geographyFilter: {
          ...state.geographyFilter,
          viewType: action.payload,
        },
      };
    }

    case actionTypes.SET_GEOGRAPHY_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          geographyFilter: {
            ...filtersData.filters,
            type: state.geographyFilter.type,
          },
        };
      } else {
        return {
          ...state,
          geographyFilter: initialState.geographyFilter,
        };
      }
    }

    case actionTypes.ROLES_TYPE: {
      return {
        ...state,
        rolesFilter: {
          ...state.rolesFilter,
          page: 1,
          type: action.payload,
        },
      };
    }
    case actionTypes.ROLES_SEARCH: {
      return {
        ...state,
        rolesFilter: {
          ...state.rolesFilter,
          page: 1,
          searchString: payload.searchString,
        },
      };
    }
    case actionTypes.ROLES_ITEMS_LIMIT: {
      return {
        ...state,
        rolesFilter: {
          ...state.rolesFilter,
          page: 1,
          limit: action.payload,
        },
      };
    }

    // IMPORTS DIRECTORY FILTERS REDUCERS
    case actionTypes.IMPORTS_SORTING: {
      return {
        ...state,
        importsFilter: {
          ...state.importsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.IMPORTS_SEARCH: {
      return {
        ...state,
        importsFilter: {
          ...state.importsFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.IMPORTS_PAGINATION: {
      return {
        ...state,
        importsFilter: {
          ...state.importsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.IMPORTS_ITEMS_LIMIT: {
      return {
        ...state,
        importsFilter: {
          ...state.importsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.IMPORTS_STATUS: {
      return {
        ...state,
        importsFilter: {
          ...state.importsFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.IMPORTS_PROPERTY_DROPDOWN_SEARCH: {
      return {
        ...state,
        importsPropertyDropdownsFilter: {
          ...state.importsPropertyDropdownsFilter,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.IMPORTS_FILTER_RESET: {
      return {
        ...state,
        importsFilter: {
          ...initialState.importsFilter,
        },
      };
    }

    case actionTypes.SET_IMPORTS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          importsFilter: {
            ...filtersData.filters,
            status: initialState.importsFilter.status,
          },
        };
      } else {
        return {
          ...state,
          importsFilter: initialState.importsFilter,
        };
      }
    }

    // ROLES PERMISSIONS DIRECTORY FILTERS REDUCERS
    case actionTypes.ROLES_PERMISSIONS_SORTING: {
      return {
        ...state,
        rolesPermissionsFilter: {
          ...state.rolesPermissionsFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.ROLES_PERMISSIONS_SEARCH: {
      return {
        ...state,
        rolesPermissionsFilter: {
          ...state.rolesPermissionsFilter,
          page: 1,
          searchString: action.payload.searchString,
          searchBy: action.payload.searchBy,
        },
      };
    }
    case actionTypes.ROLES_PERMISSIONS_PAGINATION: {
      return {
        ...state,
        rolesPermissionsFilter: {
          ...state.rolesPermissionsFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.ROLES_PERMISSIONS_ITEMS_LIMIT: {
      return {
        ...state,
        rolesPermissionsFilter: {
          ...state.rolesPermissionsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.ROLES_PERMISSIONS_RESET_FILTER: {
      return {
        ...state,
        rolesPermissionsFilter: {
          ...initialState.rolesPermissionsFilter,
        },
      };
    }

    case actionTypes.SET_ROLES_PERMISSIONS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          rolesPermissionsFilter: { ...filtersData.filters },
        };
      } else {
        return {
          ...state,
          rolesPermissionsFilter: initialState.rolesPermissionsFilter,
        };
      }
    }

    // IMPORTS DIRECTORY FILTERS REDUCERS
    case actionTypes.IMPORTS_VIEW_DATA_SORTING: {
      return {
        ...state,
        importsViewDataFilter: {
          ...state.importsViewDataFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.IMPORTS_VIEW_DATA_SEARCH: {
      return {
        ...state,
        importsViewDataFilter: {
          ...state.importsViewDataFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }

    case actionTypes.IMPORTS_VIEW_DATA_PAGINATION: {
      return {
        ...state,
        importsViewDataFilter: {
          ...state.importsViewDataFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.IMPORTS_VIEW_DATA_ITEMS_LIMIT: {
      return {
        ...state,
        importsViewDataFilter: {
          ...state.importsViewDataFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.IMPORTS_VIEW_DATA_STATUS: {
      return {
        ...state,
        importsViewDataFilter: {
          ...state.importsViewDataFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.IMPORTS_VIEW_DATA_RESET_FILTERS: {
      return {
        ...state,
        importsViewDataFilter: {
          ...initialState.importsViewDataFilter,
        },
      };
    }
    // IMPORTS SUMMARY TABLE FILTERS REDUCERS
    case actionTypes.IMPORTS_SUMMARY_DATA_SORTING: {
      return {
        ...state,
        importsSummaryDataFilter: {
          ...state.importsSummaryDataFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.IMPORTS_SUMMARY_DATA_PAGINATION: {
      return {
        ...state,
        importsSummaryDataFilter: {
          ...state.importsSummaryDataFilter,
          page: action.payload,
        },
      };
    }
    case actionTypes.IMPORTS_SUMMARY_DATA_ITEMS_LIMIT: {
      return {
        ...state,
        importsSummaryDataFilter: {
          ...state.importsSummaryDataFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.IMPORTS_SUMMARY_DATA_RESET_FILTERS: {
      return {
        ...state,
        importsSummaryDataFilter: {
          ...initialState.importsSummaryDataFilter,
        },
      };
    }

    // NOTIFICATION CENTER FILTERS REDUCERS

    case actionTypes.NOTIFICATION_CENTER_STATUS: {
      return {
        ...state,
        notificationCenterFilter: {
          ...state.notificationCenterFilter,
          page: 1,
          status: action.payload,
        },
      };
    }

    case actionTypes.NOTIFICATION_CENTER_PAGINATION: {
      return {
        ...state,
        notificationCenterFilter: {
          ...state.notificationCenterFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.NOTIFICATION_CENTER_ITEMS_LIMIT: {
      return {
        ...state,
        notificationCenterFilter: {
          ...state.notificationCenterFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.NOTIFICATION_CENTER_RESET_FILTERS: {
      return {
        ...state,
        notificationCenterFilter: {
          ...initialState.notificationCenterFilter,
        },
      };
    }

    // polls And surveys filter

    case actionTypes.POLLS_AND_SURVEYS_SORTING: {
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          sortBy: payload.sortBy,
          sortType: payload.sortType,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_SEARCH: {
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          searchString: payload.searchString,
          searchBy: payload.searchBy,
        },
      };
    }
    case actionTypes.POLLS_AND_SURVEYS_STATUS: {
      const { dateUpdated, archivedByIds, dateArchived } =
        initialState.pollsAndSurveysFilter;

      if (action.payload === "archived") {
        return {
          ...state,
          pollsAndSurveysFilter: {
            ...state.pollsAndSurveysFilter,
            page: 1,
            dateUpdated,
            status: action.payload,
          },
        };
      } else {
        return {
          ...state,
          pollsAndSurveysFilter: {
            ...state.pollsAndSurveysFilter,
            page: 1,
            dateArchived,
            archivedByIds,
            status: action.payload,
          },
        };
      }
    }
    case actionTypes.POLLS_AND_SURVEYS_PAGINATION: {
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_ITEMS_LIMIT: {
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_PROGRAMS: {
      const { programIds } = state.pollsAndSurveysFilter;

      // Check if the program with the same id exists
      const existingProgramIndex = programIds.value.findIndex(
        (item: any) => item.id === action.payload.id
      );

      let updatedPrograms;

      if (existingProgramIndex !== -1) {
        // If the program exists, remove it
        updatedPrograms = [
          ...programIds.value.slice(0, existingProgramIndex),
          ...programIds.value.slice(existingProgramIndex + 1),
        ];
      } else {
        // If the program doesn't exist, add it
        updatedPrograms = [...programIds.value, action.payload];
      }

      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          programIds: {
            value: updatedPrograms,
            applied: updatedPrograms.length > 0,
          },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_CREATED_BY: {
      const { createdByIds } = state.pollsAndSurveysFilter;

      // Check if the createdByIds with the same id exists
      const existingCreatedByIdsIndex = createdByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedCreatedByIds;

      if (existingCreatedByIdsIndex !== -1) {
        // If the createdByIds exists, remove it
        updatedCreatedByIds = [
          ...createdByIds.value.slice(0, existingCreatedByIdsIndex),
          ...createdByIds.value.slice(existingCreatedByIdsIndex + 1),
        ];
      } else {
        // If the createdByIds doesn't exist, add it
        updatedCreatedByIds = [...createdByIds.value, action.payload];
      }

      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          createdByIds: {
            value: updatedCreatedByIds,
            applied: updatedCreatedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_ARCHIVED_BY: {
      const { archivedByIds } = state.pollsAndSurveysFilter;

      // Check if the archivedByIds with the same id exists
      const existingArchivedByIdsIndex = archivedByIds.value.findIndex(
        (item) => item.id === action.payload.id
      );

      let updatedArchivedByIds;

      if (existingArchivedByIdsIndex !== -1) {
        // If the archivedByIds exists, remove it
        updatedArchivedByIds = [
          ...archivedByIds.value.slice(0, existingArchivedByIdsIndex),
          ...archivedByIds.value.slice(existingArchivedByIdsIndex + 1),
        ];
      } else {
        // If the archivedByIds doesn't exist, add it
        updatedArchivedByIds = [...archivedByIds.value, action.payload];
      }

      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          archivedByIds: {
            value: updatedArchivedByIds,
            applied: updatedArchivedByIds.length > 0,
          },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESET_FILTERS: {
      // const {status} = state.pollsAndSurveysFilter
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...initialState.pollsAndSurveysFilter,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESPONSES: {
      const { name, min, max, applied } = action.payload;
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          [name]: { min, max, applied: applied },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_DATE_CREATED_UPDATED_ARCHIVED: {
      const { from, to, name } = action.payload;
      return {
        ...state,
        pollsAndSurveysFilter: {
          ...state.pollsAndSurveysFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.SET_POLLS_AND_SURVEYS_FILTERS: {
      const filtersData = getFiltersDataFromLocalStorage();
      if (filtersData !== undefined) {
        return {
          ...state,
          pollsAndSurveysFilter: {
            ...filtersData.filters,
            status: initialState.pollsAndSurveysFilter.status,
          },
        };
      } else {
        return {
          ...state,
          pollsAndSurveysFilter: initialState.pollsAndSurveysFilter,
        };
      }
    }

    case actionTypes.POLLS_AND_SURVEYS_RESULTS_SEARCH: {
      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...state.pollAndSurveyResultsFilter,
          page: 1,
          searchString: payload.searchString,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESULTS_PAGINATION: {
      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...state.pollAndSurveyResultsFilter,
          page: action.payload,
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESULTS_ITEMS_LIMIT: {
      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...state.pollAndSurveyResultsFilter,
          limit: action.payload,
          page: 1,
        },
      };
    }
    case actionTypes.POLLS_AND_SURVEYS_RESULTS_RESPONSE_DATE: {
      const { from, to, name } = action.payload;
      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...state.pollAndSurveyResultsFilter,
          page: 1,
          [name]: { from, to, applied: from && to !== "" ? true : false },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESULTS_VERSION: {
      // Check if the Version with the same id exists

      let updatedVersions = [action.payload];

      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...state.pollAndSurveyResultsFilter,
          versionIds: {
            value: updatedVersions,
            applied: updatedVersions.length > 0,
          },
        },
      };
    }

    case actionTypes.POLLS_AND_SURVEYS_RESULTS_RESET_FILTERS: {
      return {
        ...state,
        pollAndSurveyResultsFilter: {
          ...initialState.pollAndSurveyResultsFilter,
          versionIds: state.pollAndSurveyResultsFilter.versionIds,
        },
      };
    }

    default:
      return state;
  }
};

export default Filters;
