import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import Placeholder from "assets/images/Placeholders/programPlaceholderAvatar.png";
import ReviewersDropdown from "../Dropdown";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { fetchObservationProfileAction } from "store/observationProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import useRoles from "hooks/roles";
import { fetchObservationsActivitiesAction } from "store/observationsActivities";
import useDateFormatter from "hooks/formatDateWithPattern";
import usePermissions from "hooks/usePermissions";

interface ReviewerObj {
  name: string | null;
  value: number;
}

const InfoBar = () => {
  const { isExternalReviewer } = useRoles();
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );

  const { daysUntil } = useDateFormatter();
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [reviewers, setReviewers] = useState<ReviewerObj[]>([]);
  const [selectedReviewer, setSelectedReviewer] = useState<ReviewerObj | null>(
    null
  );
  const { observations, programs } = usePermissions();

  const fetchReviewers = async (programId: number) => {
    try {
      const response = await apiLibrary.Observations.getReviewers(programId);
      setReviewers(response.data.reviewers);
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message ??
        error?.message ??
        "Error fetching reviewers";
      Toasts.error(errorMsg);
    }
  };

  const attachReviewer = async (personId: number) => {
    if (personId === observationProfile?.reviewedBy?.id) {
      return;
    }

    try {
      const { data, message } = await apiLibrary.Observations.updateReviewer(
        observationProfile.id,
        { personId }
      );
      Toasts.success(message);
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message ??
        error?.message ??
        "Error updating reviewer";
      Toasts.error(errorMsg);
    } finally {
      dispatch(fetchObservationsActivitiesAction(observationProfile.id));
      dispatch(fetchObservationProfileAction(observationProfile.id));
    }
  };

  useEffect(() => {
    const programId = observationProfile?.program?.id;
    if (programId && programs.canViewReviewers) {
      fetchReviewers(programId);
    }
  }, [observationProfile]);

  useEffect(() => {
    if (selectedReviewer?.value) {
      attachReviewer(selectedReviewer.value);
    }
  }, [selectedReviewer]);

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-5">
      {isExternalReviewer() ? (
        <>
          <div>
            <h5 className="capitalize text-md text-secondaryMidLight dark:text-caption font-Overpass">
              External reviewer
            </h5>
            {observationProfile?.reviewedBy?.id && (
              <div className="flex items-center mt-2">
                <div className="w-6 h-6 overflow-hidden rounded-full">
                  <img
                    src={
                      observationProfile?.reviewedBy?.profileImage?.thumb ??
                      Placeholder
                    }
                    className="w-full h-full"
                    alt="Avatar"
                  />
                </div>
                <p className="ml-2 capitalize dark:text-textMain font-Overpass">
                  {observationProfile?.reviewedBy?.name}
                </p>
              </div>
            )}
          </div>

          <div>
            <h5 className="capitalize text-md text-secondaryMidLight dark:text-caption font-Overpass">
              Access days left
            </h5>
            <div className="flex items-center mt-2">
              <p className="capitalize dark:text-textMain font-Overpass">
                {observationProfile?.externalReviewerAccessUntil &&
                  daysUntil(observationProfile?.externalReviewerAccessUntil)}
              </p>
            </div>
          </div>
          <div>
            <h5 className="capitalize text-md text-secondaryMidLight dark:text-caption font-Overpass">
              assign by
            </h5>
            <div className="flex items-center mt-2">
              {observationProfile.assignedBy === null ? (
                <></>
              ) : (
                <div className="w-6 h-6 overflow-hidden rounded-full">
                  <img
                    src={
                      observationProfile?.assignedBy?.profileImage?.thumb
                      || Placeholder
                    }
                    className="w-full h-full"
                    alt=""
                  />
                </div>
              )}
              <p className="ml-2 capitalize dark:text-textMain font-Overpass">
                {observationProfile?.assignedBy === null
                  ? ""
                  : observationProfile?.assignedBy?.name}
              </p>
            </div>
            {/* )} */}
          </div>
          <div>
            <div className="flex gap-2 w-[80%] 2xl:w-[70%] relative">
              <h5 className="text-md text-secondaryMidLight font-Overpass">
                Observer
              </h5>
            </div>
            {observationProfile?.observer?.id ? (
              <div className="flex items-center mt-2">
                <div className="w-6 h-6 overflow-hidden rounded-full">
                  <img
                    src={
                      observationProfile?.observer?.profileImage?.thumb ??
                      Placeholder
                    }
                    className="w-full h-full"
                    alt="Avatar"
                  />
                </div>
                <p className="ml-2 capitalize dark:text-textMain font-Overpass">
                  {observationProfile?.observer?.name}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          <div>
            <h5 className="text-md font-Overpass text-secondaryMidLight dark:text-caption ">
              Program
            </h5>
            {observationProfile?.program?.id && (
              <div className="flex items-start mt-2">
                <div className="w-[30px] flex-shrink-0">
                  <div className="w-6 h-6 overflow-hidden rounded-full">
                    <img
                      src={
                        observationProfile?.program?.profileImage?.original ??
                        Placeholder
                      }
                      height="100"
                      width="100"
                      className="object-cover w-full h-full"
                      alt="Avatar"
                    />
                  </div>
                </div>
                <p className="w-full ml-1 pr-4 capitalize break-words font-Overpass text-secondary dark:text-textMain">
                  {observationProfile?.program?.name}
                </p>
              </div>
            )}
          </div>
          <div>
            <h5 className="text-md font-Overpass text-secondaryMidLight dark:text-caption ">
              Observer
            </h5>
            {observationProfile?.observer?.id && (
              <div className="flex items-start w-full mt-2">
                <div className="w-[30px] flex-shrink-0">
                  <div className="w-6 h-6 overflow-hidden rounded-full">
                    <img
                      src={
                        observationProfile?.observer?.profileImage?.original ??
                        Placeholder
                      }
                      height="100"
                      width="100"
                      className="object-cover w-full h-full"
                      alt="Avatar"
                    />
                  </div>
                </div>
                <p className="w-full ml-1 pr-4 capitalize break-words font-Overpass text-secondary dark:text-textMain ">
                  {observationProfile?.observer?.name}
                </p>
              </div>
            )}
          </div>
          <div>
            <h5 className="text-md font-Overpass text-secondaryMidLight dark:text-caption ">
              Community Name
            </h5>
            {observationProfile?.community?.communityId && (
              <div className="flex items-start justify-start mt-2">
                <div className="w-[30px] flex-shrink-0">
                  <div className="w-6 h-6 overflow-hidden rounded-full">
                    <img
                      src={
                        observationProfile?.community?.profileImage?.original ??
                        Placeholder
                      }
                      height="100"
                      width="100"
                      className="object-cover w-full h-full"
                      alt="Avatar"
                    />
                  </div>
                </div>
                <p className="w-full ml-1 pr-4 capitalize break-words font-Overpass text-secondary dark:text-textMain">
                  {observationProfile?.community?.name}
                </p>
              </div>
            )}
          </div>
          <div>
            <h5 className="text-md font-Overpass text-secondaryMidLight dark:text-caption ">
              Form
            </h5>
            {observationProfile?.dynamicForm?.id && (
              <div className="flex items-start justify-start mt-2">
                <div className="w-[30px] flex-shrink-0">
                  <div className="w-6 h-6 overflow-hidden rounded-full">
                    <img
                      src={
                        observationProfile?.dynamicForm?.profileImage
                          ?.original ?? Placeholder
                      }
                      height="100"
                      width="100"
                      className="object-cover w-full h-full"
                      alt="Avatar"
                    />
                  </div>
                </div>
                <p className="w-full ml-1 pr-4 capitalize break-words font-Overpass text-secondary dark:text-textMain">
                  {observationProfile?.dynamicForm?.name}
                </p>
              </div>
            )}
          </div>

          {/* {programs.canViewReviewers && !observationProfile.requireQa && ( */}
          <div>
            {!observationProfile.isAnonymous &&
              <div className="flex gap-2 w-[80%] 2xl:w-[70%] relative">
                <h5 className="capitalize text-md font-Overpass text-secondaryMidLight shrink-0 dark:text-caption ">
                  Assign to
                </h5>
                {!isExternalReviewer() &&
                  observations.canEditObservationReviewer &&
                  // observations.canViewObservationManagersDropdown &&
                  programs.canViewReviewers && (
                    <ReviewersDropdown
                      data={reviewers}
                      value={selectedReviewer}
                      handleChange={(e: any) => {
                        if (observations.canEditInviteExternalReviewer) {
                          setSelectedReviewer(e.target);
                        }
                      }}
                    />
                  )}
              </div>
            }
            {!observationProfile.isAnonymous && <div>
              {observationProfile?.reviewedBy?.id ? (
                <div className="flex items-start mt-2">
                  <div className="w-[30px] flex-shrink-0">
                    <div className="w-6 h-6 overflow-hidden rounded-full">
                      <img
                        src={
                          observationProfile?.reviewedBy?.profileImage
                            ?.original ?? Placeholder
                        }
                        height="100"
                        width="100"
                        className="object-cover w-full h-full"
                        alt="Avatar"
                      />
                    </div>
                  </div>
                  <p className="ml-1 pr-4 capitalize dark:text-textMain font-Overpass text-secondary">
                    {observationProfile?.reviewedBy?.name}
                  </p>
                </div>
              ) : (
                <span className="italic dark:text-textMain">Not Assigned</span>
              )}
            </div>}
          </div>
        </>
      )}
    </div>
  );
};

export default InfoBar;
