import InfiniteScroll from "Components/Geography/InfiniteScroll";
import {  CheveronDownIcon, CheveronUpIcon } from "assets/icons/HeroIcons";
import { useCallback, useEffect,  useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import apiLibrary from "services/api";
import { RootState } from "store";
import { updateSelectedLayersItems } from "store/surveyJS";
import splitString from "utils/splitString";
import CustomCheckbox from "view/components/CheckBox";
import { Toasts } from "view/components/Toasts";

interface ReferenceVectorLayersListProps {
  layerIds: string[];
  layers: T_VectorLayersData[];
  heading: string;
  message: string;
  loading: boolean;
  type: LayerType;
  doShowDataList: boolean;
  newItemType: NewItemType;
  layerRenderingMode: LayerRenderingMode;
  handleClickOnTogglerBtn: (layerType: LayerType) => void;
  handleScrollEnd: (layerType: LayerType) => void;
  selectLayersHandler: (e: any, newLayer: any) => void;
}
type T_VectorLayersData = {
  id: number;
  legendFilePath: URL;
  name: string;
};
type LayerType = "vector" | "publicVector";
type NewItemType = "region" | "shape" | "point" | "vector";
type LayerRenderingMode = "draw" | "paint";

const ReferenceVectorLayersList = ({
  selectLayersHandler,
  layerIds,
  layers,
  heading,
  message,
  loading,
  handleScrollEnd,
  type,
  doShowDataList:doShow,
  handleClickOnTogglerBtn,
  newItemType,
  layerRenderingMode
}: ReferenceVectorLayersListProps) => {

  const [doShowDataList,setDoShowDataList] = useState(false);

  useEffect(()=>{
    setDoShowDataList(doShow)
  },[doShow])

  return (
    <div className="flex flex-col flex-wrap w-full py-2 mr-2">
      <button
        type="button"
        className="flex items-center justify-between flex-1 mb-2"
        onClick={() => setDoShowDataList(!doShowDataList)}
      >
       {layers?.length > 0 && <h3 className="text-[17px] dark:text-textMain">
          {heading}
        </h3>}

        {layers && layers?.length > 0 && (
          <div>
            {doShowDataList ? (
              <CheveronUpIcon className="w-[16px] fill-secondaryMid mb-1" />
            ) : (
              <CheveronDownIcon className="w-[16px] fill-secondaryMid mb-1" />
            )}
          </div>
        )}
      </button>
      <div className="mr-1">
        <InfiniteScroll
          height="200px"
          handleOnScrollEnd={() => handleScrollEnd(type)}
          loading={loading}
        >
          <div className="px-2 ">
            {doShowDataList && layers && layers?.length > 0 && (
              <ul className="mb-4">
                {layers?.map((item: any) => (
                  <ListItem
                    item={item}
                    isGlobal={type === "publicVector"}
                    layerIds={layerIds}
                    selectLayersHandler={selectLayersHandler}
                    newItemType={newItemType}
                    layerRenderingMode={layerRenderingMode}
                  />
                ))}
              </ul>
            )}
            {loading && <p className="text-center">Loading...</p>}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

interface ListItem {
  item: any;
  isGlobal: boolean;
  layerIds: string[];
  selectLayersHandler: (e: any, newLayer: any) => void;
  newItemType: NewItemType;
  layerRenderingMode: LayerRenderingMode;
}

const ListItem = ({ item, isGlobal, layerIds, selectLayersHandler, newItemType: itemType, layerRenderingMode: mode }: ListItem) => {
  const [loading, setLoading] = useState(false);
  const [newLayer, setNewLayer] = useState<any>(null);
  const { selectedLayersItems } = useSelector(
    (state: RootState) => state.geography
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (newLayer) {
      dispatch(
        updateSelectedLayersItems([...(selectedLayersItems ?? []), newLayer])
      );
      selectLayersHandler({ target: { value: newLayer.item.id } }, newLayer);
      setNewLayer(null);
    }
  }, [newLayer]);

  // handlers
  const handleClickOnCheckBoxLocally = useCallback(
    async (e: any) => {
      const isChecked = e.target.checked;
      const layersItems = selectedLayersItems ?? [];
      const itemIndex = layersItems.findIndex(
        (itm: any) => itm.item.id === item.id
      );

      const alreadyExistedItem = layersItems[itemIndex];
      let upadatedItems;

      if (isChecked) {
        if (alreadyExistedItem) {
          // update item status to true
          alreadyExistedItem.status = true;

          layersItems[itemIndex] = alreadyExistedItem;
          upadatedItems = layersItems;
          dispatch(updateSelectedLayersItems([...(upadatedItems ?? [])]));
          selectLayersHandler(e, item);
        } else {
          setLoading(true);
          if (item.communityId && !isGlobal) {
            await apiLibrary.geography
              .getSingleLayer(item.communityId, item.id)
              .then((data) => {
                const newItem = {
                  item: data?.data,
                  itemType,
                  status: true,
                  [mode === "draw" ? "draw" : "clickable"]: true,
                };
                // upadatedItems = [...layersItems, newItem];
                setNewLayer(newItem);
              })
              .catch((error) => {
                Toasts.error(error.message);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            await apiLibrary.geography
              .getSingleGlobalLayer(item.id)
              .then((data) => {
                const newItem = {
                  item: data?.data,
                  itemType,
                  status: true,
                  [mode === "draw" ? "draw" : "clickable"]: true,
                };
                // upadatedItems = [...layersItems, newItem];
                setNewLayer(newItem);
              })
              .catch((error) => {
                Toasts.error(error.message);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      } else {
        if (itemIndex === -1) return;
        // update item status to false on unchecking the checkbox
        alreadyExistedItem.status = false;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
        dispatch(updateSelectedLayersItems([...(upadatedItems ?? [])]));
        selectLayersHandler(e, item);
      }
    },
    [selectedLayersItems]
  );
  return (
    <li
      className={`flex items-center py-[6px] ${loading ? "opacity-60 select-none cursor-progress" : ""}`}
      key={item.id}
    >
      <CustomCheckbox
        onChange={(e:any) => {
          handleClickOnCheckBoxLocally(e);
          // selectLayersHandler(e, item);
        }}
        padding="0"
        value={item.id}
        style={{ marginRight: "16px" }}
        checked={layerIds?.includes(item.id)}
        disabled={loading}
      />
      {mode !== "draw" && <span
        className="h-[14px] w-[14px] rounded-full inline-block mr-[14px]"
        style={{ background: item.color ?? "red" }}
      />}
      <p className="text-[15px] leading flex-1 -mb-1 dark:text-textMain ">
        {splitString(item.name, 25)}
      </p>
      <div className="basis-1 flex justify-end items-center">
        {loading && (
          <TailSpin
            height="18"
            width="18"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        )}
      </div>
    </li>
  );
};

export default ReferenceVectorLayersList;
