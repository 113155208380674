import { apiClient } from "../config";

async function getAllOrganizations(searchQuery?:string) {
  const params = {
    searchString:searchQuery
  }
  const res = await apiClient.get(`admin/programs/organizations-dropdown`,{params});
  return res.data;
}

export default getAllOrganizations;
