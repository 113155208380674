import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  closeIniviteDataEndViewerModal,
  closeInviteExternalReviewerQualityAssuranceObservationModalAction,
  openSuccessMessageModalAction,
  openSuccessfullInviteMessageModalAction,
} from "store/modals/reducer.actions";
import { Toasts } from "view/components/Toasts";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import Button from "view/components/Button";
import {
  AddReviewerName,
  Header,
  InviteButton,
  InviteReviewer,
} from "./components";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";
import { fetchManagerAction } from "store/managers";
import { managerTypeAction } from "store/filters";
import { updateColumnsVisibilityOfManagersByStatus } from "store/directoriesOrders/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { filtersInitialState } from "store/filters/initialState";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // width: "35%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#ffff",
};

interface InviteExternalReviewerQualityAssuranceModalProps {}

export const InviteDataEndViewersModal: React.FC<
  InviteExternalReviewerQualityAssuranceModalProps
> = () => {
  const { inviteDataEndViewerModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { managerFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const [typeMessage, setTypeMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { programId } = useParams<{
    programId: any;
  }>();
  const { programs } = usePermissions();
  const registrationValidationSchema = Yup.object().shape({
    reviewerId: Yup.string().required("Reviewer name is required"),
  });
  const invitationValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });

  const initialValues = {
    reviewerId: "",
    email:
      inviteDataEndViewerModal.data?.data?.reviewersDetail?.reviewer?.email ??
      "",
    firstName:
      inviteDataEndViewerModal.data?.data?.reviewersDetail?.reviewer
        ?.firstName ?? "",
    lastName:
      inviteDataEndViewerModal.data?.data?.reviewersDetail?.reviewer
        ?.lastName ?? "",
  };
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [reviewersList, setReviewersList] = useState([]);
  const [inviteExternalReviewer, setInviteExternalReviewer] = useState(true);
  const formikRef = useRef<any>();

  const fetchAllReviewers = useCallback(
    async (searchQuery?: string) => {
      if (!programs.canViewExternalEndDataViewer) {
        return;
      }
      try {
        const res =
          await apiLibrary.Managers.getAllExternalEndDataViewersDropdown(
            searchQuery,
            programId
          );
        setReviewersList(
          res?.data.endDataViewers?.map((reviewer: any) => ({
            label: `${reviewer?.firstName}  ${reviewer?.lastName}`,
            value: reviewer?.id,
          }))
        );
      } catch (error: any) {
        console.error("Error fetching reviewers:", error);
      }
    },
    [inviteDataEndViewerModal]
  );

  useEffect(() => {
    if (inviteDataEndViewerModal.isOpen) {
      fetchAllReviewers();
    }
  }, []);

  const handleAddReviewerSearch = (query: string) => {
    fetchAllReviewers(query);
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      setIsLoading(true);
      const formValues = {
        ...values,
        reviewerId: values.reviewerId?.value,
      };
      if (inviteDataEndViewerModal.data?.isEdit) {
        const res = await apiLibrary.Managers.updateEndDataViewer(
          inviteDataEndViewerModal.data.data.personId,
          values
        );
        Toasts.success(res.message);

        handleClose();
        dispatch(openSuccessfullInviteMessageModalAction(values.email));
        dispatch(managerTypeAction("external_data_end_viewer"));
        dispatch(fetchManagerAction(parseInt(programId)));
      } else if (
        !inviteExternalReviewer &&
        programs.canReinviteExternalEndDataViewer
      ) {
        const res = await apiLibrary.Managers.reinviteDataEndViewer(
          programId,
          values.reviewerId
        );
        Toasts.success(res.message);
        handleClose();
        dispatch(openSuccessfullInviteMessageModalAction(values.email));
        dispatch(fetchManagerAction(parseInt(programId)));
      } else {
        if (!programs.canCreateExternalEndDataViewer) {
          return;
        }
        const res = await apiLibrary.Managers.inviteDataEndViewer(
          programId,
          formValues
        );
        Toasts.success(res.message);
        handleClose();
        if (programId) {
          dispatch(fetchManagerAction(parseInt(programId)));
          dispatch(managerTypeAction("external_data_end_viewer"));
          dispatch(
            updateColumnsVisibilityOfManagersByStatus(
              "external_data_end_viewer"
            )
          );
        }
        dispatch(openSuccessfullInviteMessageModalAction(values.email));
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleInviteExternalReviewer = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm(); // Resetting form directly if current exists
    }
    setInviteExternalReviewer(!inviteExternalReviewer);
  };

  const handleClose = () => {
    dispatch(closeIniviteDataEndViewerModal());
  };

  const StatusMessage = () => {
    switch (managerFilter.type) {
      case "external":
        setTypeMessage(
          "By adding a person as an External reviewer, they won't automatically gain access to any reports. They must be added through the permissions settings of a specific report to view it."
        );
        break;
      case "external_data_end_viewer":
        setTypeMessage(
          "By adding a person as an Data-end viewer, they won't automatically gain access to any reports. They must be added through the permissions settings of a specific report to view it."
        );
        break;
      default:
        setTypeMessage(
          "This internal directory shows all admin users connected to this program."
        );
        break;
    }
  };
  useEffect(() => {
    StatusMessage();
  }, [managerFilter.type]);

  return (
    <Modal
      open={inviteDataEndViewerModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={
            inviteExternalReviewer
              ? invitationValidationSchema
              : registrationValidationSchema
          }
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                <div
                  className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <Header
                    handleClose={handleClose}
                    handleTitleChange={inviteExternalReviewer}
                    inviteDataEndViewerModal={inviteDataEndViewerModal}
                    isSubmitting={isSubmitting}
                  />
                  <InviteButton
                    handleClick={handleInviteExternalReviewer}
                    handleTitleChange={inviteExternalReviewer}
                  />
                  <div>
                    <p className="text-sm font-normal text-secondaryMidLight leading-[18px]">
                      {typeMessage}
                    </p>
                  </div>
                  <div className="flex flex-col w-full px-1 pb-4">
                    <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-4 mb-6">
                      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-2">
                        {inviteExternalReviewer ? (
                          <InviteReviewer
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            isSubmitting={isSubmitting}
                          />
                        ) : (
                          <div className="flex items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
                            <div className="flex w-[296px] flex-col justify-start items-start flex-grow">
                              <AddReviewerName
                                handleAddReviewerNameOnChange={(option: any) =>
                                  setFieldValue("reviewerId", option)
                                }
                                errors={errors}
                                touched={touched}
                                reviewers={reviewersList}
                                values={values}
                                handleAddReviewerSearch={
                                  handleAddReviewerSearch
                                }
                                handleBlur={handleBlur}
                                isSubmitting={isSubmitting}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 mt-auto">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        disabled={isSubmitting}
                        variant="outlined"
                        onClick={handleClose}
                      />
                      <Button
                        type="submit"
                        disabled={
                          isSubmitting ||
                          (!inviteExternalReviewer &&
                            !programs.canReinviteExternalEndDataViewer) ||
                          (inviteExternalReviewer &&
                            !programs.canCreateExternalEndDataViewer)
                        }
                        text="Save"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="w-24 h-11"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
