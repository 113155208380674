import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import { switchToObserverTimeZoneAction } from "store/observationProfile/reducer.actions";
import useDateFormatter from "hooks/formatDateWithPattern";
import moment from "moment";

export const ObserverTimeZoneAccordionToggle = () => {
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const convertToObserverTimeZone = (sumbissionDateTime: string) => {
    const userTimeZone = profileSettings.timezone;
    // Define the current timezone either from the submission date or the system's timezone
    const currentTimeZone = userTimeZone
      ? userTimeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Define the observer's timezone from the observation profile
    const observerTimeZone = observationProfile.observerTimezone;

    // Check if we should switch to the observer's timezone
    const switchToObserverTimeZone =
      observationProfile.switchToObserverTimeZone;

    // Format the date-time according to the specified timezone
    const formattedDateTime = switchToObserverTimeZone
      ? moment
        .tz(sumbissionDateTime, currentTimeZone)
        .tz(observerTimeZone)
        .format("MM/DD/YYYY [at] hh:mm A")
      : moment
        .utc(sumbissionDateTime)
        .tz(currentTimeZone)
        .format("MM/DD/YYYY [at] hh:mm A");

    return formattedDateTime;
  };

  return (
    <div className="flex justify-start flex-col items-start">
      <CustomizedSwitches
        label={observationProfile.observerTimezone ? `Observer’s time zone (${observationProfile.observerTimezone})` : "Observer’s time zone"}
        value={observationProfile.switchToObserverTimeZone}
        onChange={(e: any) => {
          dispatch(switchToObserverTimeZoneAction(e.target.checked));
        }}
        labelClassName="text-secondary !m-0 font-Overpass"
        sx={{
          "&.MuiFormControlLabel-root": {
            margin: 0
          }
        }}
      />

      <p className="font-Overpass text-[15px] text-secondary ml-2">Submission Date/Time: {observationProfile?.submissionLocalDateTime ? convertToObserverTimeZone(observationProfile?.submissionLocalDateTime) : ""}</p>
    </div>
  );
};
