import React, { useEffect, useState } from "react";
import { Communities } from "./components";
import apiLibrary from "services/api";
import { useParams } from "react-router-dom";
import CustomCheckbox from "view/components/CheckBox";
import communityProfilePlaceholder from "assets/images/Placeholders/avatar-communityProfile.png";
import TextInput from "view/components/InputField";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeManageProgramMembersModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Status from "view/components/Statuses";
import { fetchUsersAction } from "store/users";
import User2Icon from "assets/icons/HeroIcons/User2Icon";
import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";

import _ from "lodash";
import usePermissions from "hooks/usePermissions";
interface PersonImage {
  file: {
    original: string;
    thumb: string;
  };
}

interface User {
  firstName: string;
  id: number;
  isConnected: boolean;
  lastName: string;
  observations: number;
  personImage: PersonImage | null;
  status: string;
  communityId: string;
  memberFromOther: boolean;
}

interface UsersResponse {
  totalItems: number;
  totalPages: number;
  users: User[];
}

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
  // width: "60% !important",
};

export const ManageProgramMembersModal = () => {
  const { manageProgramMembersModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [communitiesResponse, setCommunitiesResponse] = useState({});
  const [selectedCommunityId, setSelectedCommunityId] = useState("");
  const [usersResponse, setUsersResponse] = useState<UsersResponse>({
    totalItems: 0,
    totalPages: 0,
    users: [],
  });
  const [hasMoreData, setHasMoreData] = useState(true);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [userSearchString, setUserSearchString] = useState("");
  const [page, setPage] = useState(1);
  const { programs } = usePermissions();

  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const handleClose = () => {
    setCommunitiesResponse({});
    setSelectedCommunityId("");
    setUsersResponse({
      totalItems: 0,
      totalPages: 0,
      users: [],
    });
    setSelectedUsers([]);
    setUserSearchString("");
    dispatch(closeManageProgramMembersModalAction());
  };

  const getProgramUserMembers = async (
    communityId?: number,
    searchString?: string,
    page?: number,
    sortBy?: string,
    sortType?: string
  ) => {
    if (!programs.canViewUserMembers) {
      return;
    }
    try {
      const response = await apiLibrary.Programs.getProgramUserMembers({
        programId: programId ? parseInt(programId) : 0,
        communityId,
        searchString,
        page,
        sortBy,
        sortType,
      });
      const res = { ...response.data };
      res.users = response.data.users.map((user: User) => {
        const User = { ...user, communityId };
        return User;
      });
      setSelectedUsers((prevSelectedUsers: User[]) => {
        const newSelectedUsers = res.users
          .filter((user: User) => user.isConnected)
          .filter(
            (newUser: User) =>
              !prevSelectedUsers.some(
                (selectedUser: User) => selectedUser.id === newUser.id
              )
          );
        return [...prevSelectedUsers, ...newSelectedUsers];
      });

      setUsersResponse(res);
    } catch (error: any) {
      console.error("Error fetching user members:", error);
    }
  };

  useEffect(() => {
    if (selectedCommunityId) {
      getProgramUserMembers(parseInt(selectedCommunityId));
    }
  }, [selectedCommunityId]);

  useEffect(() => {
    if (page < usersResponse.totalPages) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [usersResponse.users, usersResponse.totalPages]);

  const selectUsers = (e: any, user: User) => {
    const { checked } = e.target;

    if (checked) {
      setSelectedUsers((pre: User[]) => {
        const users = [...pre, user];
        return users;
      });
    } else if (!checked) {
      setSelectedUsers((pre: User[]) => {
        const filteredUsers: User[] = pre.filter((item: User) => {
          if (item.id === user.id) {
            return false;
          }
          return true;
        });
        return filteredUsers;
      });
    }
  };

  const addMembersToProgram = async () => {
    if (!programs.canCreateMembers) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await apiLibrary.Programs.addMembersToProgram(
        parseInt(manageProgramMembersModal?.data?.programId),
        selectedUsers.map((item: User) => {
          return { communityId: item.communityId, personId: item.id };
        })
      );
      dispatch(fetchUsersAction(communityId, programId, organizationId));
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAllUsers = () => {
    setSelectedUsers((prevSelectedUsers: User[]) => {
      const newUsers = usersResponse.users.filter(
        (user: User) =>
          !prevSelectedUsers.some(
            (selectedUser) => selectedUser.id === user.id
          ) && !user.memberFromOther
      );

      return [...prevSelectedUsers, ...newUsers];
    });
  };

  const handleSearch = (e: any) => {
    setUserSearchString((prevState: string) => {
      if (programId && selectedCommunityId) {
        getProgramUserMembers(parseInt(selectedCommunityId), e.target.value, 1);
      }
      return e.target.value;
    });
  };

  const selectedUsersCount = () => {
    return selectedUsers.filter(
      (item: User) => item.communityId === selectedCommunityId
    ).length;
  };

  const handleClearAll = () => {
    setSelectedUsers((pre: User[]) => {
      return pre.filter(
        (user: User) => user.communityId !== selectedCommunityId
      );
    });
  };

  function getUserStatus(user: User) {
    const isDisabled = selectedUsers.some(
      (item) => item.communityId !== user.communityId && item.id === user.id
    );

    const isChecked = selectedUsers.some(
      (item) => item.communityId === user.communityId && item.id === user.id
    );

    return { isDisabled, isChecked };
  }
  return (
    <Modal
      open={manageProgramMembersModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight">
        <div className="flex items-center justify-center">
          <div className="flex flex-col items-start justify-start w-full rounded-lg">
            <div
              className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 bg-bgWhite  rounded-lg dark:bg-secondaryLight p-6 w-[100%]"
              style={{
                boxShadow:
                  "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
              }}
            >
              <div className="w-full rounded-lg bg-bgWhite dark:bg-secondaryLight">
                <div className="flex items-center justify-items-center">
                  <div className="flex flex-col items-start self-stretch justify-center w-full">
                    <div className="flex items-center self-stretch justify-start gap-2">
                      <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                        <p className="self-stretch w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                          Search to add members to program
                        </p>
                      </div>
                    </div>
                  </div>
                  <button onClick={handleClose} type="button">
                    <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                  </button>
                </div>
                <div className="w-[80vw] max-w-[1200px] max-h-[60vh] overflow-y-auto">
                  <div className="flex w-full justify-start items-center  gap-4 py-1.5">
                    <div className="rounded-full">
                      <img
                        src={
                          manageProgramMembersModal?.data?.profileImage
                            ? manageProgramMembersModal?.data?.profileImage
                            : PlaceholderAvatar
                        }
                        alt=""
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div className="w-[95%] flex">
                      <p className="max-h-32 break-all w-full text-[15px] text-left font-normal text-secondary dark:text-textMain">
                        {manageProgramMembersModal?.data?.name}
                      </p>
                    </div>
                  </div>
                  <div className="grid w-full grid-cols-2 gap-4 pt-1 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                    <div>
                      <Communities
                        selectedCommunityId={selectedCommunityId}
                        communitiesResponse={communitiesResponse}
                        setCommunitiesResponse={setCommunitiesResponse}
                        setSelectedCommunityId={setSelectedCommunityId}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full rounded-lg">
                      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white border rounded-lg bg-bgWhite border-lineMid dark:bg-secondaryLight ">
                        <TextInput
                          onChange={handleSearch}
                          type="Search"
                          value={userSearchString}
                          placeholder="Search among users"
                        />
                        <p className="dark:text-textMain">
                          Can't find the community you want to add? Create a new
                          one from the communities directory.
                        </p>
                        <div className="w-full px-1 overflow-y-auto">
                          <div className="items-start justify-start w-full">
                            <div className="flex justify-between w-full">
                              <div className="flex justify-between gap-4">
                                <div>
                                  <UsersIcon />
                                </div>
                                <div>
                                  <p className="text-[17px] font-semibold text-secondaryMid dark:text-textMain">
                                    {selectedUsersCount()} of{" "}
                                    {usersResponse.totalItems} Added members
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between gap-4">
                                <div>
                                  <button onClick={handleClearAll}>
                                    <p className="font-semibold text-primary">
                                      Clear All
                                    </p>
                                  </button>
                                </div>
                                <div>
                                  <button onClick={handleSelectAllUsers}>
                                    <p className="font-semibold text-primary">
                                      Add All
                                    </p>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="w-full mt-1 py-2 overflow-y-auto overflow-x-hidden h-[400px] max-h-[31vh]">
                              {usersResponse.users.map((user: User, index) => {
                                const { isDisabled, isChecked } =
                                  getUserStatus(user);
                                return (
                                  <div className="w-full rounded-lg hover:bg-primaryExtraLight dark:hover:bg-primary/[0.2]">
                                    <CustomCheckbox
                                      onChange={(e: any) => {
                                        selectUsers(e, user);
                                      }}
                                      labelPlacement="end"
                                      className="w-full"
                                      checked={isChecked}
                                      disabled={
                                        isDisabled || user.memberFromOther
                                      }
                                      label={
                                        <button
                                          type="button"
                                          key={index}
                                          className="w-full flex  disabled:cursor-not-allowed justify-start items-center relative pl-2 gap-4  py-1.5 rounded-lg"
                                          disabled={
                                            isDisabled || user.memberFromOther
                                          }
                                        >
                                          {user?.personImage?.file?.thumb ? (
                                            <img
                                              src={
                                                user?.personImage?.file?.thumb
                                              }
                                              alt=""
                                              height="100"
                                              width="100"
                                              className="object-cover Img_user_Data"
                                              style={{ flexShrink: 0 }}
                                            />
                                          ) : (
                                            <svg
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="relative flex-grow-0 flex-shrink-0 w-8 h-8"
                                              preserveAspectRatio="xMidYMid meet"
                                            >
                                              <circle
                                                cx={12}
                                                cy={12}
                                                r={12}
                                                fill="#99BED0"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M12.0013 5.33594C10.1604 5.33594 8.66797 6.82832 8.66797 8.66927C8.66797 10.5102 10.1604 12.0026 12.0013 12.0026C13.8423 12.0026 15.3346 10.5102 15.3346 8.66927C15.3346 6.82832 13.8423 5.33594 12.0013 5.33594ZM10.0013 8.66927C10.0013 7.5647 10.8967 6.66927 12.0013 6.66927C13.1059 6.66927 14.0013 7.5647 14.0013 8.66927C14.0013 9.77384 13.1059 10.6693 12.0013 10.6693C10.8967 10.6693 10.0013 9.77384 10.0013 8.66927Z"
                                                fill="white"
                                              />
                                              <path
                                                d="M8.0013 18.0026C8.0013 15.7935 9.79216 14.0026 12.0013 14.0026C14.2104 14.0026 16.0013 15.7935 16.0013 18.0026C16.0013 18.3708 16.2998 18.6693 16.668 18.6693C17.0362 18.6693 17.3346 18.3708 17.3346 18.0026C17.3346 15.0571 14.9468 12.6693 12.0013 12.6693C9.05578 12.6693 6.66797 15.0571 6.66797 18.0026C6.66797 18.3708 6.96645 18.6693 7.33464 18.6693C7.70283 18.6693 8.0013 18.3708 8.0013 18.0026Z"
                                                fill="white"
                                              />
                                            </svg>
                                          )}

                                          <div className="flex items-center justify-start w-full">
                                            <div className="relative flex flex-col items-start justify-center">
                                              <p className="text-left break-words dark:text-textMain">
                                                {user.firstName} {user.lastName}
                                              </p>
                                              <div className="flex flex-col items-center justify-start flex-grow-0 flex-shrink-0">
                                                <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-3 mt-[-4px]">
                                                  <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
                                                    <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                                                      {user.observations}
                                                    </p>
                                                    <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                                                      observations
                                                    </p>
                                                  </div>
                                                  <Status
                                                    status={user.status}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </button>
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 ">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={
                    isLoading ||
                    selectedUsers.length === 0 ||
                    !programs.canCreateMembers
                  }
                  type="button"
                  text="Confirm"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={addMembersToProgram}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
