import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { IAuthResponseData } from "store/auth/initialState";
import { openDeleteAccountRequestModalAction } from "store/modals/reducer.actions";
import { CreateAnAccountBtn } from "../CreateAnAccountBtn";

export const InviteToISN = () => {
  const dispatch = useDispatch();

  const auth = useSelector<RootState, IAuthResponseData>((state) => state.auth);

  return (
    <>
      <div className="flex flex-col self-stretch justify-start flex-grow-0 flex-shrink-0 mt-10 overflow-hidden bg-white border-t border-lineMid dark:border-lineLightitems-start">
        <CreateAnAccountBtn icon={true} helperText={"You can invite this user to also start using the ISN app"} />
      </div>
    </>
  );
};
