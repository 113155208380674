import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import downloadReports from "services/api/reports/downloadReports";
import { RootState } from "store";
import { fetchImportSummaryAction } from "store/importSummary/reducer.actions";
import {
  openAddEditDuplicateProtocolModalAction,
  openAddFormModalAction,
  openAddOrEditCategoryModalAction,
  openAddOrEditObjectModalAction,
  openAddOrEditSpecieModalAction,
  openAddOrEditTagModalAction,
  openAddOrEditTypeModalAction,
  openArchiveOrRestoreCategoryModalAction,
  openArchiveOrRestoreCommunityModalAction,
  openArchiveOrRestoreFormModalAction,
  openArchiveOrRestoreObjectModalAction,
  openArchiveOrRestoreObservationModalAction,
  openArchiveOrRestoreProgramModalAction,
  openArchiveOrRestoreProtocolModalAction,
  openArchiveOrRestoreQualityAssuranceObservationModalAction,
  openArchiveOrRestoreSpecieModalAction,
  openArchiveOrRestoreTypeModalAction,
  openAssignAnAdminToCommunityModalAction,
  openAssignAnAdminToProgramModalAction,
  openCommunityAccessModalAction,
  openCompleteProgramModalAction,
  openDeactivateUserModalAction,
  openDeleteACommunityModalAction,
  openDeleteAccountModalAction,
  openDeleteFormModalAction,
  openDeleteTagsModalAction,
  openInviteExternalReviewerQualityAssuranceObservationModalAction,
  openManageConnectionProtocolModal,
  openNotificationsSummaryModalAction,
  openReinviteUserModalAction,
  openRemoveFromProgramAction,
  openRemoveManagerModalAction,
  openRemoveNotificationRecipientsModalAction,
  openRemovePersonFromCommunityAction,
  openResetPasswordModalAction,
  openRestorePersonModalAction,
  openReviewerDetailsModal,
  openPauseResumeDeleteNotificationModalAction,
  openArchiveOrRestoreGeographyLayerModalAction,
  openArchiveRestoreDeleteReportsModalAction,
  openReportsSummaryModalAction,
  openAddEditDuplicateRoleModalAction,
  openDeleteRoleModalAction,
  openEditPermissionModalAction,
  openRemoveProgramAction,
  openArchiveOrRestorePollsAndSurveyModalAction,
  openDeletePollsAndSurveyModalAction,
  openAddSurveyFormModal,
  openAddOrEditBehaviorsModalAction,
  openArchiveOrRestoreBehaviorsModalAction,
  openAddOrEditConditionsModalAction,
  openArchiveOrRestoreConditionsModalAction,
  openDeleteManagerModalAction,
  openIniviteDataEndViewerModal,
  openAddEditContactInfoModalAction,
  openPauseResumeProgramModalAction,
  openMapSettngsProgramModalAction,
  openConfirmationAssignAsAdminAction,
  openRemoveCommunityFromOrganizationAction,
} from "store/modals/reducer.actions";
import { IfetchResultReports } from "store/reportsResult/initialState";
import { fetchUsersAction } from "store/users";
import { DownloadReportProfile } from "utils/donwloadRePortProfile";
import { Toasts } from "view/components/Toasts";
import usePermissions from "./usePermissions";
import {
  pollAndSurveyResultsVersionAction,
  reportsAnalyticsVersionAction,
  reportsProgramsAction,
} from "store/filters";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { profile } from "console";
import { OPEN_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL } from "store/modals/action-types";
import useRoles from "./roles";
import { IAuthResponseData } from "store/auth/initialState";
import { IfetchNotificationsRecipients } from "store/notificationsRecipients/initialState";
import getReportsAnalyticsFilterDropdown from "services/api/reports/getReportsAnalyticsFilterDropdown";
import { IfetchProfileReports } from "store/reportsProfile/initialState";
import { filtersInitialState } from "store/filters/initialState";

const useActions = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communityId, programId, organizationId } = useParams();
  const auth = useSelector<RootState, IAuthResponseData>((state) => state.auth);

  const { isExternalDataEndReviewer, isInternalReviewer } = useRoles();
  const navigate = useNavigate();
  const {
    users,
    programs,
    observations,
    communities,
    categories,
    reports,
    reminders,
    types,
    species,
    objects,
    protocols,
    imports,
    dynamicForms,
    geographies,
    profile,
    roles,
    permissions,
    surveyAndPolls,
    tags,
    behaviors,
    conditions,
    organizations,
    assignRoles,
  } = usePermissions();
  const reportsProfile = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );

  const isProgramAdminExist = auth.roles.includes("program_admin");
  const isOrganizationAdminExist = auth.roles.includes("organization_admin");
  const userActions = (userData: any) => {
    const { status, virtual, role, openAccess } = userData;
    const isVisible =
      status === "active" || status === "re_invited" || status === "invited";

    const handleSendEmail = () => {
      const mailtoLink = `mailto:${userData.email}`;
      window.location.href = mailtoLink;
    };
    const handleCopyEmail = () => {
      // Use the Clipboard API to copy the email to the clipboard
      navigator.clipboard
        .writeText(userData.email)
        .then(() => {
          // Show a success toast or notification indicating that the email has been copied
          Toasts.copy("Email copied to clipboard", "bottom-center");
        })
        .catch((error) => {
          // Show an error toast or notification if copying fails
          Toasts.error("Failed to copy email to clipboard");
          console.error("Failed to copy email to clipboard:", error);
        });
    };

    const assignAsCommunityAdmin = async () => {
      try {
        const response = await apiLibrary.Communities.editCommunity(
          communityId,
          { admin_id: userData.personId }
        );

        dispatch(fetchUsersAction(communityId, programId, organizationId));
        Toasts.success(response.message);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
        console.error("API call failed:", error);
      } finally {
      }
    };

    const assignAsProgramAdmin = async () => {
      try {
        const response = await apiLibrary.Programs.editProgram(programId, {
          admin_id: userData.personId,
        });

        dispatch(fetchUsersAction(communityId, programId, organizationId));

        Toasts.success(response.message);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
        console.error("API call failed:", error);
      } finally {
      }
    };
    const assignAsOrganizationsAdmin = async () => {
      try {
        const response =
          await apiLibrary.OrganizationDirectory.addAdminsToOrganization(
            organizationId,
            {
              admin_id: userData.personId,
            },
            "PersonId"
          );

        dispatch(fetchUsersAction(communityId, programId, organizationId));

        Toasts.success(response.message);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
        console.error("API call failed:", error);
      } finally {
      }
    };
    return [
      {
        label: "Copy Email",
        onClick: handleCopyEmail,
        divider: false,
        isVisible: isVisible && userData.path !== "userProfile",
      },
      {
        label: "Send Email",
        onClick: handleSendEmail,
        divider: false,
        isVisible,
      },
      {
        label: "Assign as Admin",
        onClick: () => {
          dispatch(openConfirmationAssignAsAdminAction(userData));
        },
        divider: false,
        isVisible:
          !openAccess &&
          shouldShowAssignAsAdminOption(
            status,
            userData,
            communityId,
            programId,
            organizationId
          ) && !role.includes("external_reviewer") &&
          checkUserRoles(auth) &&
          assignRoles.canEditAssignRoles,
      },

      {
        label: "Assign as Admin",
        onClick: () => assignAsCommunityAdmin(),
        divider: false,
        isVisible:
          !openAccess &&
          communities.canEditCommunities &&
          shouldShowAssignAsCommunityAdminOption(
            role,
            status,
            userData,
            communityId
          ) && !role.includes("external_reviewer"),
      },
      {
        label: "Assign as Admin",
        onClick: () => assignAsOrganizationsAdmin(),
        divider: false,
        isVisible:
          !openAccess &&
          organizations.canCreateAdmins &&
          shouldShowAssignAsOrganizationAdminOption(
            role,
            status,
            userData,
            organizationId
          ) && !role.includes("external_reviewer"),
      },
      {
        label: "Assign as Admin",
        onClick: () => assignAsProgramAdmin(),
        divider: false,
        isVisible:
          !openAccess &&
          shouldShowAssignAsProgramAdminOption(
            role,
            status,
            userData,
            programId,
            auth
          ) && !role.includes("external_reviewer") &&
          programs.canEditProgram &&
          checkUserRoles(auth),
      },

      {
        label: "Reset Password",
        onClick: () => {
          dispatch(openResetPasswordModalAction(userData));
        },
        divider: programId ? true : false,
        isVisible:
          shouldShowResetPasswordOption(status, userData) &&
          users.canResetPassword &&
          users.canResetPassword,
      },

      {
        label: "Re-Invite",
        onClick: () => {
          dispatch(openReinviteUserModalAction(userData));
        },
        divider: true,
        isVisible: status === "invited" && users.canInviteUsers,
      },
      {
        label: "Suspend",
        onClick: () => dispatch(openDeactivateUserModalAction(userData)),
        divider: true,
        isVisible:
          isVisible &&
          !communityId &&
          !programId &&
          !openAccess &&
          users.canChangeStatus,
      },
      {
        label: "Remove",
        onClick: () => dispatch(openDeleteAccountModalAction(userData)),
        divider: false,
        isVisible:
          status !== "deleted" &&
          status !== "suspended" &&
          !communityId &&
          !programId &&
          users.canDeletePerson &&
          // profile.canDeleteProfile &&
          !openAccess,
      },

      {
        label: "Remove From Community",
        onClick: () =>
          dispatch(
            openRemovePersonFromCommunityAction({ ...userData, communityId })
          ),
        divider: false,
        isVisible:
          !openAccess && shouldShowRemoveCommunityOption(role, communityId),
        // communities.canDeleteMemberCommunities,
      },
      {
        label: "Remove From Program",
        onClick: () => {
          const data = {
            user: {
              firstName: userData.firstName,
              lastName: userData.lastName,
            },
            id: userData.personId,
            programId,
            entity: "member",
          };
          dispatch(openRemoveFromProgramAction(data));
        },
        divider: false,
        isVisible:
          shouldShowRemoveProgramOption(programs, programId) &&
          // programs.canDeleteMember &&
          !openAccess,
      },
      {
        label: "Restore",
        onClick: () => dispatch(openRestorePersonModalAction(userData)),
        divider: false,
        isVisible:
          status === "deleted" &&
          status !== "suspended" &&
          users.canRestoreUsers,
      },

      {
        label: "Re-Activate",
        onClick: () => dispatch(openRestorePersonModalAction(userData)),
        divider: false,
        isVisible: status === "suspended" && users.canRestoreUsers,
      },
    ];
  };

  const communityActions = (communityData: any) => {
    const {
      status,
      id,
      accessibleForSuperAdmin,
      name,
      communityAdmins,
      profileImage,
      path,
      members,
      contactInfo,
      openAccess,
    } = communityData;
    const data = {
      status,
      id,
      path,
      name,
      profileImage,
      communityAdmins,
      members,
      openAccess,
    };
    return [
      {
        label: "Edit Contact Information",
        onClick: () => {
          dispatch(openAddEditContactInfoModalAction(contactInfo));
        },
        divider: true,
        isVisible:
          status === "active" &&
          path === "profilePage" &&
          communities.canArchiveCommunities,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreCommunityModalAction(data));
        },
        divider: false,
        isVisible:
          accessibleForSuperAdmin &&
          status === "active" &&
          path === "profilePage" &&
          !programId &&
          !openAccess &&
          communities.canArchiveCommunities,
      },
      {
        label: "Exit Community",
        onClick: () => {
          dispatch(openCommunityAccessModalAction(data, "exit"));
        },
        divider: false,
        isVisible:
          accessibleForSuperAdmin &&
          status === "active" &&
          !programId &&
          !openAccess &&
          communities.canEditCommunitiesAccess,
      },

      {
        label: "Assign an Admin",
        onClick: () => {
          dispatch(openAssignAnAdminToCommunityModalAction(data));
        },
        divider: false,
        isVisible:
          accessibleForSuperAdmin &&
          status === "active" &&
          path === "profilePage" &&
          !programId &&
          !openAccess &&
          communities.canViewUserAdminsCommunities,
      },

      {
        label: "Regain Access",
        onClick: () => {
          dispatch(openCommunityAccessModalAction(data, "enter"));
        },
        divider: false,
        isVisible:
          !accessibleForSuperAdmin &&
          !programId &&
          !openAccess &&
          communities.canEditCommunitiesAccess,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreCommunityModalAction(data));
        },
        divider: false,
        isVisible:
          status === "archived" &&
          !openAccess &&
          communities.canArchiveCommunities,
      },
      {
        label: "Delete Forever",
        onClick: () => {
          dispatch(openDeleteACommunityModalAction(data));
        },
        divider: false,
        isVisible:
          status === "archived" &&
          !programId &&
          !openAccess &&
          communities.canDeleteCommunities,
      },

      {
        label: "Remove From Program",
        onClick: () => {
          const dataa = {
            id: data.id,
            members: data.members,
            programId,
            entity: "community",
          };
          dispatch(openRemoveFromProgramAction(dataa));
        },
        divider: false,
        isVisible:
          shouldShowRemoveProgramOption(programs, programId) && !openAccess,
      },
      {
        label: "Remove From Organization",
        onClick: () => {
          const dataa = {
            id: organizationId,
            members: data.members,
            communityId: data.id,
            entity: "community",
            removeCommunity: true,
          };
          dispatch(openRemoveCommunityFromOrganizationAction(dataa));
        },
        divider: false,
        isVisible:
          shouldShowRemoveFromOrganizationOption(
            organizations,
            organizationId
          ) && !openAccess,
      },
    ];
  };

  const programsActions = (programData: any) => {
    const {
      status,
      id,
      name,
      programAdmins,
      profileImage,
      path,
      leadingOrganizationId,
      observations,
      contactInfo,
      openAccess,
      canAddAdmin,
      canEditProgramDetail,
    } = programData;

    const data = {
      status,
      id,
      path,
      name,
      profileImage,
      programAdmins,
      leadingOrganizationId,
      observations,
      openAccess,
      canAddAdmin,
      canEditProgramDetail,
    };

    const handleClickData = (id: any, name: any) => {
      dispatch(reportsProgramsAction({ id: id, name: name }));
      navigate("/reports/list");
    };

    return [
      {
        label: "Report",
        onClick: () => {
          handleClickData(id, name);
        },
        divider: false,
        isVisible:
          (status === "active" || status === "completed") &&
          path === "directory" &&
          reports.canViewReports,
      },
      {
        label: "Remove from organization",
        onClick: () => {
          const dataa = {
            id: organizationId,
            name: data.name,
            entityType: "program",
            entityId: data.id,
          };

          dispatch(openRemoveProgramAction(dataa));
        },
        divider: false,
        isVisible:
          !openAccess && organizations.canDeleteEntity && organizationId
            ? true
            : false,
      },
      {
        label: "Remove from community",
        onClick: () => {
          const dataa = {
            id: data.id,
            name: data.name,
            entity: "community",
            communityId: communityId,
            removeCommunity: true,
          };

          dispatch(openRemoveFromProgramAction(dataa));
        },
        divider: false,
        isVisible: !openAccess && communityId ? true : false,
      },
      {
        label: "Edit Contact Information",
        onClick: () => {
          dispatch(openAddEditContactInfoModalAction(contactInfo));
        },
        divider: true,
        isVisible:
          status === "active" && path === "profilePage" && canEditProgramDetail,
      },
      {
        label: "Map settings",
        onClick: () => {
          dispatch(openMapSettngsProgramModalAction(id));
        },
        divider: false,
        isVisible: programs.canEditProgram && !isOrganizationAdminExist,
      },
      {
        label: "Pause",
        onClick: () => {
          dispatch(openPauseResumeProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "active" &&
          path === "profilePage" &&
          !openAccess &&
          programs.canEditStatus,
      },
      {
        label: "Resume",
        onClick: () => {
          dispatch(openPauseResumeProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "paused" &&
          path === "profilePage" &&
          !openAccess &&
          programs.canEditStatus,
      },
      {
        label: "Assign an Admin",
        onClick: () => {
          dispatch(openAssignAnAdminToProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "active" &&
          path === "profilePage" &&
          !openAccess &&
          programs.canViewAdminsDropdown &&
          checkUserRoles(auth),
      },
      {
        label: "Complete",
        onClick: () => {
          dispatch(openCompleteProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "active" &&
          path === "profilePage" &&
          !openAccess &&
          programs.canEditStatus,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "active" &&
          path === "profilePage" &&
          !openAccess &&
          programs.canArchiveProgram,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreProgramModalAction(data));
        },
        divider: false,
        isVisible:
          status === "archived" && !openAccess && programs.canArchiveProgram,
      },
    ];
  };

  const dynamicFormsActions = (data: any) => {
    // const changeFormStatus = async (status: any) => {
    //   try {
    //     const res = await apiLibrary.Forms.changeFormStatus(
    //       data.formName.id,
    //       status
    //     );

    //     setActiveLink(changeCase.capitalCase(status));
    //     Toasts.success(res.message);
    //   } catch (error: any) {
    //     const errorMsg = error.response.data.message ?? error.message
    //     Toasts.error(errorMsg)
    //     }
    // };

    const editFormOrUseAsTemplate = (
      editMode: any = false,
      templateMode: any = false
    ) => {
      dispatch(openAddFormModalAction(data, editMode, templateMode));
    };

    return [
      {
        label: "Edit Form",
        onClick: () => editFormOrUseAsTemplate(true),
        divider: false,
        isVisible:
          (data?.status === "draft" || data?.status === "published") &&
          data.path === "directory" &&
          dynamicForms.canEditDynamicForm &&
          data.programStatus !== "completed",
      },
      {
        label: "Use as Template",
        onClick: () => editFormOrUseAsTemplate(false, true),
        divider: false,
        isVisible:
          (data?.status === "draft" || data?.status === "published") &&
          dynamicForms.canCreateDynamicFormTemplate,
      },
      {
        label: "Archive",
        onClick: () => dispatch(openArchiveOrRestoreFormModalAction(data)),
        divider: false,
        isVisible:
          (data?.status === "published" || data?.status === "draft") &&
          dynamicForms.canArchiveDynamicForm &&
          data.programStatus !== "completed",
      },

      // {
      //   label: "Delete",
      //   onClick: () => dispatch(openDeleteFormModalAction(data)),
      //   divider: false,
      //   isVisible: data?.status === "archived" && dynamicForms.canDeleteDynamicForm,
      // },

      {
        label: "Restore",
        onClick: () => dispatch(openArchiveOrRestoreFormModalAction(data)),
        divider: false,
        isVisible:
          data?.status === "archived" &&
          dynamicForms.canArchiveDynamicForm &&
          data.programStatus !== "completed",
      },
    ];
  };
  const userDirectoryActions = (columns: any, rowsData: any) => {
    const fileDownload = async () => {
      if (rowsData.length === 0) {
        Toasts.error("Please select at least one person from the table");
        return;
      }

      try {
        const res = await apiLibrary.file.fileDownload(
          "users",
          columns,
          rowsData?.map((item: any) => {
            return item.personId;
          })
        );

        // Convert the response to a Blob
        const blob = new Blob([res], { type: "application/octet-stream" });

        // Create a link element
        const link = document.createElement("a");

        // Set the href attribute to a URL created from the Blob
        link.href = window.URL.createObjectURL(blob);

        // Set the download attribute to specify the file name
        link.download = `persons_${new Date().toISOString().slice(0, 10)}.csv`;

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);

        Toasts.success("Download complete");
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      }
    };

    return [
      {
        label: "Export Details",
        onClick: () => fileDownload(),
        divider: false,
        isVisible: true,
      },
    ];
  };
  const protocolsActions = (data: any) => {
    const editOrDuplicateProtocol = async (
      editMode: boolean = false,
      duplicateMode: boolean = false
    ) => {
      try {
        const res = await apiLibrary.Protocols.getProtocolProfile(data.id);
        const protocolInitialState = {
          id: res.data?.protocolId,
          title: duplicateMode
            ? `Copy of ${res?.data?.protocolTitle}`
            : res.data?.protocolTitle,
          description: res.data?.protocolDescription,
          programId: {
            value: duplicateMode
              ? ""
              : res.data?.program
                ? res.data?.program?.id
                : "",
            label: duplicateMode
              ? ""
              : res.data?.program
                ? res.data?.program?.name
                : "",
          },
          formId: {
            value: duplicateMode
              ? ""
              : res.data?.form
                ? res.data?.form?.id
                : "",
            label: duplicateMode
              ? ""
              : res.data?.form
                ? res.data?.form?.name
                : "",
          },

          files: res.data?.files?.length > 0 ? res.data?.files : [],
          path: data?.path,
        };
        dispatch(
          openAddEditDuplicateProtocolModalAction(
            protocolInitialState,
            editMode,
            duplicateMode
          )
        );
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching programs:", error);
      }
    };

    const manageConnectionProtocol = async () => {
      try {
        const res = await apiLibrary.Protocols.getProtocolProfile(data.id);
        const protocolInitialState = {
          id: res.data?.protocolId,
          programId: {
            value: res.data?.program ? res.data?.program?.id : "",
            label: res.data?.program ? res.data?.program?.name : "",
          },
          formId: {
            value: res.data?.form ? res.data?.form?.id : "",
            label: res.data?.form ? res.data?.form?.name : "",
          },
          path: data?.path,
        };
        dispatch(openManageConnectionProtocolModal(protocolInitialState));
      } catch (error: any) {
        // Handle API errors
        console.error("Error fetching manage connection", error);
      }
    };

    return [
      {
        label: "Edit",
        onClick: () => editOrDuplicateProtocol(true),
        divider: false,
        isVisible:
          data?.status === "active" &&
          protocols.canEditProtocol &&
          data.programStatus !== "completed",
      },
      {
        label: "Archive",
        onClick: () => dispatch(openArchiveOrRestoreProtocolModalAction(data)),
        divider: false,
        isVisible:
          data?.status === "active" &&
          protocols.canArchiveProtocol &&
          data.programStatus !== "completed",
      },
      {
        label: "Manage Connection",
        onClick: () => manageConnectionProtocol(),
        divider: false,
        isVisible:
          data?.status === "active" &&
          data.programStatus !== "completed" &&
          isInternalReviewer(),
      },
      {
        label: "Duplicate Protocol",
        onClick: () => editOrDuplicateProtocol(false, true),
        divider: false,
        isVisible: isInternalReviewer(),
      },

      {
        label: "Restore",
        onClick: () => dispatch(openArchiveOrRestoreProtocolModalAction(data)),
        divider: false,
        isVisible:
          data?.status === "archived" &&
          data.programStatus !== "completed" &&
          protocols.canArchiveProtocol,
      },
    ];
  };
  const observationsActions = (observationData: any) => {
    const { status, id, submissionDate, path, protocolId, programStatus } =
      observationData;
    // console.log(status,'slkfkldsfdskfsdlkfjdslfjkldsfjlks');

    const data = {
      status,
      id,
      submissionDate,
      path,
      protocolId,
      programStatus,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          navigate(`/observations/${data?.id}/profile`, {
            state: { editMode: true },
          });
        },
        divider: false,
        isVisible: shouldShowEditObservation(
          data?.status,
          data.submissionDate,
          observations
        ),
      },

      {
        label: "Archive",
        onClick: () => {
          const dataa = {
            ...data,
            isArchive: true,
          };
          dispatch(openArchiveOrRestoreObservationModalAction(dataa));
        },
        divider: false,
        isVisible:
          (data?.status === "in_review" || data?.status === "completed") &&
          observations.canArchiveObservation &&
          data.programStatus !== "completed",
      },

      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreObservationModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && data.programStatus !== "completed",
      },
      {
        label: "View Protocol",
        onClick: () => {
          navigate(`/protocols/${protocolId}/profile`);
        },
        divider: false,
        isVisible:
          (status === "completed" ||
            status === "in_review" ||
            status === "archived") &&
            programId
            ? true
            : false,
      },
    ];
  };
  const reportsProfileActions = (reportsProfileData: any) => {
    const { status, id, versionId, processedReports } = reportsProfileData;
    const data = {
      status,
      id,
      versionId,
      processedReports,
    };

    const handleClick = async (id: number, versionId: number) => {
      DownloadReportProfile(id, versionId);
    };
    return [
      {
        label: "Download Details",
        onClick: () => {
          handleClick(id, versionId);
        },
        divider: false,
        isVisible:
          data?.status === "ready" && reports.canViewDownloadResultReports && reportsProfile.data.status !== "archived",
      },

      {
        label: "Analytics",
        onClick: () => {
          dispatch(reportsAnalyticsVersionAction({ id: versionId, name: `${versionId}-${processedReports}` }));
          navigate(`/reports/${id}/analytics`, {
            state: { editMode: true },
          });
        },
        divider: !isExternalDataEndReviewer(),
        isVisible: reports.canViewAnalyticsReports && reportsProfile.data.status !== "archived",
      },

      {
        label: "Delete",
        onClick: () => {
          dispatch(
            openArchiveRestoreDeleteReportsModalAction({
              ...data,
              status: "deletedProfile",
              path: "profile",
            })
          );
        },
        divider: false,
        isVisible:
          !isExternalDataEndReviewer() &&
          reports.canDeleteVersionReports &&
          reportsProfile.data.status !== "archived",
      },
    ];
  };
  const managerActions = (managerData: any) => {
    const { type, personId, reviewersDetail } = managerData;
    const data = {
      type,
      personId,
      reviewersDetail,
    };

    return [
      {
        label: "Remove role",
        onClick: () => {
          dispatch(openRemoveManagerModalAction(data));
        },
        divider: false,
        isVisible:
          shouldShowRemoveOption(programs, data.type) &&
          programs.canDeleteManager,
      },
      {
        label: "Delete user permanently",
        onClick: () => {
          dispatch(openDeleteManagerModalAction(data));
        },
        divider: false,
        isVisible: data.type === "external" && programs.canDeleteManager,
      },

      {
        label: "Edit user details",
        onClick: () => {
          const dataa = {
            data: data,
            isEdit: true,
          };
          dispatch(openIniviteDataEndViewerModal(dataa));
        },
        divider: false,
        isVisible:
          data?.type === "external_data_end_viewer" && users.canEditUsers,
      },
    ];
  };
  const notificationsRecipientsActions = (getData: any) => {
    const { data, stateIs } = useSelector<
      RootState,
      IfetchNotificationsRecipients
    >((state) => state?.notificationRecipients);
    const { id, recipientName } = getData;
    const dataa = {
      id,
      recipientName,
    };
    return [
      {
        label: "Remove",
        onClick: () => {
          dispatch(openRemoveNotificationRecipientsModalAction(dataa));
        },
        divider: false,
        isVisible:
          reminders.canDeletePersonReminders && data.status !== "completed",
      },
    ];
  };
  const qualityAssuranceObservationsActions = (observationData: any) => {
    const { status, id, programId, requireQa } = observationData;
    const data = {
      status,
      id,
      programId,
      requireQa,
    };
    return [
      {
        label: "Invite External Reviewer",
        onClick: () => {
          dispatch(
            openInviteExternalReviewerQualityAssuranceObservationModalAction(
              data
            )
          );
        },
        divider: false,
        isVisible: data?.status === "in_review" && !requireQa,
      },

      {
        label: "Archive",
        onClick: () => {
          dispatch(
            openArchiveOrRestoreQualityAssuranceObservationModalAction(data)
          );
        },
        divider: false,
        isVisible:
          data?.status === "completed" || data?.status === "incompleted",
      },

      {
        label: "Restore",
        onClick: () => {
          dispatch(
            openArchiveOrRestoreQualityAssuranceObservationModalAction(data)
          );
        },
        divider: false,
        isVisible: status === "archived",
      },
    ];
  };
  const categoriesActions = (categoryData: any) => {
    const { status, id, categoryName, categoryCode } = categoryData;
    const data = {
      status,
      id,
      categoryName,
      categoryCode,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddOrEditCategoryModalAction(data, true));
        },
        divider: false,
        isVisible:
          (status === "active" || status === "archived") &&
          categories.canEditCategories,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreCategoryModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && categories.canArchiveCategories,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreCategoryModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && categories.canArchiveCategories,
      },
    ];
  };
  const behaviorsActions = (behaviorData: any) => {
    const { status, id, behaviorName, behaviorCode, types } = behaviorData;
    const data = {
      status,
      id,
      behaviorName,
      behaviorCode,
      types,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddOrEditBehaviorsModalAction(data, true));
        },
        divider: false,
        isVisible: status === "active" && behaviors.canEditBehaviors,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreBehaviorsModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && behaviors.canArchiveBehaviors,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreBehaviorsModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && behaviors.canArchiveBehaviors,
      },
    ];
  };
  const conditionsActions = (conditionData: any) => {
    const { status, id, conditionName, types } = conditionData;
    const data = {
      status,
      id,
      conditionName,
      types,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddOrEditConditionsModalAction(data, true));
        },
        divider: false,
        isVisible: conditions.canEditConditions,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreConditionsModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && conditions.canArchiveConditions,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreConditionsModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && conditions.canArchiveConditions,
      },
    ];
  };
  const importActions = (importData: any) => {
    const { importsFilter } = useSelector<RootState, filtersInitialState>(
      (state) => state.Filters
    );

    return [
      {
        label: "Imports Summary",
        onClick: () => {
          dispatch(
            fetchImportSummaryAction({
              id: importData?.id,
              path: "directory",
            })
          );
        },
        divider: false,
        isVisible: true && imports.canViewSummaryImports && importsFilter.status !== "permission",
      },
      {
        label: "View Data",
        onClick: () => {
          navigate(`/imports/view-data/${importData?.id}`);
        },
        divider: false,
        isVisible: imports.canViewViewDataImports && importsFilter.status !== "permission",
      },
    ];
  };
  const typesActions = (typeData: any) => {
    const { status, id, typeName, categoryId } = typeData;
    const data = {
      status,
      id,
      typeName,
      categoryId,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddOrEditTypeModalAction(data, true));
        },
        divider: false,
        isVisible:
          (status === "active" || status === "archived") && types.canEditType,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreTypeModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && types.canArchive,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreTypeModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && types.canArchive,
      },
    ];
  };
  const specieActions = (specieData: any) => {
    const { status, id, path } = specieData;

    const data = {
      status,
      id,
      path,
    };

    const editSpecie = async (species: any) => {
      const { data, message } =
        await apiLibrary.SpeciesAndObjects.getSpecieProfile(species.id);

      const specieData = {
        alternativeName: data.alternativeName,
        commonName: data.commonName,
        description: data.description,
        images:
          data?.attachments?.map((attachment: any) => {
            const image = {
              file: attachment.file.thumb,
              isStar: attachment.isStar,
              id: attachment.id,
              name: attachment.title,
            };
            return image;
          }) ?? [],
        isStar: data?.attachments?.find((image: any) => image.isStar)?.id,
        scientificName: data.scientificName,
        preferredName: data.preferredName,
        typeId: { label: data.type.typeName, value: data.type.typeId },
        id: data.id,
        path: path,
      };
      dispatch(openAddOrEditSpecieModalAction(specieData, true));
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          editSpecie(data);
        },
        divider: false,
        isVisible:
          (status === "active" || status === "archived") &&
          species.canEditSpecies,
        // &&
        // communities.canEditSpeciesCommunities,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreSpecieModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && species.canArchiveSpecies,
        // &&
        // communities.canEditStatusCommunities,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreSpecieModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && species.canArchiveSpecies,
      },
    ];
  };

  const notificationsActions = (notificationsData: any) => {
    const { status, id, programId } = notificationsData;
    const data = {
      status,
      id,
    };
    return [
      {
        label: "Summary",
        onClick: () => {
          dispatch(openNotificationsSummaryModalAction(data.id));
        },
        divider: false,
        isVisible: true,
      },

      {
        label: "See Recipients",
        onClick: () => {
          navigate(`/custom-notifications/${data?.id}/recipients`);
        },
        divider: false,
        isVisible: reminders.canViewMembersReminders,
      },

      {
        label: "Pause",
        onClick: () => {
          dispatch(openPauseResumeDeleteNotificationModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && reminders.canEditReminders,
      },

      {
        label: "Resume",
        onClick: () => {
          dispatch(openPauseResumeDeleteNotificationModalAction(data));
        },
        divider: false,
        isVisible: status === "paused" && reminders.canEditReminders,
      },

      {
        label: "Delete",
        onClick: () => {
          dispatch(
            openPauseResumeDeleteNotificationModalAction({
              ...data,
              status: "deleted",
            })
          );
        },
        divider: false,
        isVisible:
          (status === "active" ||
            status === "paused" ||
            status === "completed") &&
          reminders.canDeleteReminders,
      },
    ];
  };

  const objectActions = (specieData: any) => {
    const { status, id } = specieData;
    const data = {
      status,
      id,
    };

    const editObject = async (object: any) => {
      const { data, message } =
        await apiLibrary.SpeciesAndObjects.getObjectProfile(object.id);
      const objectData = {
        name: data.objectName,
        description: data.description,
        images:
          data?.attachments?.map((attachment: any) => {
            const image = {
              file: attachment.file.thumb,
              isStar: attachment.isStar,
              id: attachment.id,
              name: attachment.title,
            };
            return image;
          }) ?? [],
        isStar: object?.attachments?.find((image: any) => image.isStar)?.id,
        typeId: {
          label: data.typeName,
          value: data.typeId,
        },
        id: object.id,
        path: "directory",
      };
      dispatch(openAddOrEditObjectModalAction(objectData, true));
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          editObject(data);
        },
        divider: false,
        isVisible:
          (status === "active" || status === "archived") &&
          objects.canEditObjects,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreObjectModalAction(data));
        },
        divider: false,
        isVisible: status === "active" && objects.canArchiveObjects,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreObjectModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && objects.canArchiveObjects,
      },
    ];
  };

  const tagsActions = (tagsData: any) => {
    const { description, id, tagName, taggedEntities } = tagsData;
    const data = {
      id,
      description,
      name: tagName,
      taggedEntities,
    };
    return [
      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddOrEditTagModalAction(data, true));
        },
        divider: false,
        isVisible: tags.canEditTag,
      },
      {
        label: "Delete",
        onClick: () => {
          dispatch(openDeleteTagsModalAction(data));
        },
        divider: false,
        isVisible: tags.canDeleteTag,
      },
    ];
  };

  const geographyActions = (categoryData: any) => {
    const { status, id, name } = categoryData;
    const data = {
      status,
      id,
      name,
    };
    return [
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestoreGeographyLayerModalAction(data));
        },
        divider: false,
        isVisible: status !== "archived" && geographies.canArchiveGeographies,
      },
      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestoreGeographyLayerModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && geographies.canArchiveGeographies,
      },
    ];
  };

  const reportsAction = (reportsData: any) => {
    const { status, id, path } = reportsData;
    const data = {
      status,
      id,
    };
    return [
      {
        label: "Report Summary",
        onClick: () => {
          dispatch(openReportsSummaryModalAction(data.id));
        },
        divider: false,
        isVisible: reports.canViewSummaryReports,
      },

      {
        label: "Go to Profile",
        onClick: () => {
          navigate(`/reports/${data?.id}/profile`);
        },
        divider: false,
        isVisible: true,
      },

      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveRestoreDeleteReportsModalAction(data));
        },
        divider: false,
        isVisible:
          (status === "current" || status === "past") &&
          reports.canArchiveReports &&
          !isExternalDataEndReviewer(),
      },

      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveRestoreDeleteReportsModalAction(data));
        },
        divider: false,
        isVisible: status === "archived" && !isExternalDataEndReviewer(),
      },

      {
        label: "Delete",
        onClick: () => {
          dispatch(
            openArchiveRestoreDeleteReportsModalAction({
              ...data,
              status: "deleted",
            })
          );
        },
        divider: false,
        isVisible: !isExternalDataEndReviewer() && reports.canDeleteReports,
        // status === "active" || status === "past" || status === "archived" ,
      },
    ];
  };

  const rolesPermissionsAction = (reportsData: any) => {
    const { id, users, roleName, description, editable } = reportsData;
    const data = {
      id,
      users,
      roleName,
      description,
      editable,
    };
    return [
      {
        label: "Edit Role",
        onClick: () => {
          dispatch(
            openAddEditDuplicateRoleModalAction({
              ...data,
              roleType: "edit",
            })
          );
        },
        divider: false,
        isVisible: editable === true && roles.canEditRole,
      },

      {
        label: "Edit Permissions",
        onClick: () => {
          dispatch(openEditPermissionModalAction(data));
        },
        divider: false,
        isVisible: permissions.canViewPermissions,
      },

      {
        label: "Duplicate Role",
        onClick: () => {
          dispatch(
            openAddEditDuplicateRoleModalAction({
              ...data,
              roleType: "duplicate",
            })
          );
        },
        divider: true,
        isVisible: roles.canCreateDuplicateRole,
      },

      {
        label: "Delete Role",
        onClick: () => {
          dispatch(openDeleteRoleModalAction(data));
        },
        divider: true,
        isVisible: roles.canDeleteRole,
      },
    ];
  };

  const pollsAndSurveysActions = (reportsData: any) => {
    const {
      name,
      pictureId,
      imageUrl,
      id,
      status,
      description,
      path,
      token,
      program,
      surveyAndPollVersionId,
    } = reportsData;

    const data = {
      name,
      pictureId,
      imageUrl,
      id,
      status,
      description,
      path,
      token,
      program,
    };

    const currentOrigin = window.location.origin;

    const copySurveyLink = () => {
      navigator.clipboard
        .writeText(`${currentOrigin}/polls-and-surveys/${token}`)
        .then(() => {
          Toasts.copy("Survey Link Copied Successfully", "bottom-center");
        })
        .catch((error) => {
          // Show an error toast or notification if copying fails
          Toasts.error("Failed to copy email to clipboard");
          console.error("Failed to copy email to clipboard:", error);
        });
    };
    return [
      {
        label: "Copy Survey Link ",
        onClick: () => {
          copySurveyLink();
        },
        divider: false,
        isVisible: status === "published",
      },

      {
        label: "Edit",
        onClick: () => {
          dispatch(openAddSurveyFormModal(data, true));
        },
        divider: false,
        isVisible:
          status !== "archived" && surveyAndPolls.canEditSurveyAndPolls,
      },
      {
        label: "View Results",
        onClick: () => {
          dispatch(pollAndSurveyResultsVersionAction(surveyAndPollVersionId));
          navigate(`/polls-and-surveys/${id}/view-results`);
        },
        divider: false,
        isVisible: surveyAndPolls.canViewResultsSurveyAndPolls,
      },
      {
        label: "Archive",
        onClick: () => {
          dispatch(openArchiveOrRestorePollsAndSurveyModalAction(data));
        },
        divider: false,
        isVisible:
          status !== "archived" && surveyAndPolls.canEditStatusSurveyAndPolls,
      },

      {
        label: "Restore",
        onClick: () => {
          dispatch(openArchiveOrRestorePollsAndSurveyModalAction(data));
        },
        divider: false,
        isVisible:
          status === "archived" && surveyAndPolls.canEditStatusSurveyAndPolls,
      },

      {
        label: "Delete",
        onClick: () => {
          dispatch(openDeletePollsAndSurveyModalAction(data));
        },
        divider: false,
        isVisible: surveyAndPolls.canDeleteSurveyAndPolls,
      },
    ];
  };

  return {
    userActions,
    communityActions,
    dynamicFormsActions,
    userDirectoryActions,
    programsActions,
    protocolsActions,
    observationsActions,
    managerActions,
    qualityAssuranceObservationsActions,
    categoriesActions,
    typesActions,
    notificationsActions,
    specieActions,
    objectActions,
    tagsActions,
    notificationsRecipientsActions,
    geographyActions,
    reportsProfileActions,
    reportsAction,
    importActions,
    rolesPermissionsAction,
    pollsAndSurveysActions,
    behaviorsActions,
    conditionsActions,
    // programFilesActions,
  };
};

export default useActions;

function shouldShowAssignAsAdminOption(
  status: any,
  userData: any,
  communityId: any,
  programId: any,
  organizationId: any
) {
  const isUserAssigned = userData.userId !== null;
  const isAlreadyAdmin = userData.role?.includes("admin");
  const isCommunityOrProgramAbsent =
    !communityId && !programId && !organizationId;
  const isStatusActiveReInvitedAndInvited = [
    "active",
    "re_invited",
    "invited",
  ].includes(status);

  const canAssignAsAdmin =
    isStatusActiveReInvitedAndInvited &&
    isUserAssigned &&
    isCommunityOrProgramAbsent &&
    !isAlreadyAdmin;
  return canAssignAsAdmin;
}

function shouldShowAssignAsCommunityAdminOption(
  role: any,
  status: any,
  userData: any,
  communityId: any
) {
  const isUserAssigned = userData.userId !== null;
  const isCommunityPresent = communityId;
  const alreadyAssigned = role?.includes("community_admin");

  const isStatusActiveReInvitedAndInvited = [
    "active",
    "re_invited",
    "invited",
  ].includes(status);

  const canAssignAsAdmin =
    isStatusActiveReInvitedAndInvited &&
    isUserAssigned &&
    isCommunityPresent &&
    !alreadyAssigned;
  return canAssignAsAdmin;
}
function shouldShowAssignAsOrganizationAdminOption(
  role: any,
  status: any,
  userData: any,
  organizationId: any
) {
  const isUserAssigned = userData.userId !== null;
  const isOrganizationPresent = organizationId;
  const alreadyAssigned = role?.includes("organization_admin");

  const isStatusActiveReInvitedAndInvited = [
    "active",
    "re_invited",
    "invited",
  ].includes(status);

  const canAssignAsAdmin =
    isStatusActiveReInvitedAndInvited &&
    isUserAssigned &&
    isOrganizationPresent &&
    !alreadyAssigned;
  return canAssignAsAdmin;
}

function shouldShowResetPasswordOption(status: any, userData: any) {
  const isUserAssigned = userData.userId !== null;
  const isStatusActiveReInvitedAndInvited = [
    "active",
    "re_invited",
    "invited",
  ].includes(status);
  const canAssignAsAdmin =
    isStatusActiveReInvitedAndInvited &&
    isUserAssigned &&
    (userData.email !== null || userData.phoneNumber !== null);

  return canAssignAsAdmin;
}

function shouldShowAssignAsProgramAdminOption(
  role: any,
  status: any,
  userData: any,
  programId: any,
  auth: any
) {
  const isUserAssigned = userData.userId !== null;
  const isProgramPresent = programId;
  const alreadyAssigned = role?.includes("program_admin");
  const isProgramAdmin = checkUserRoles(auth);
  const canAssignAsAdmin =
    (status === "active" || status === "re_invited" || status === "invited") &&
    isUserAssigned &&
    isProgramPresent &&
    !alreadyAssigned &&
    isProgramAdmin;
  return canAssignAsAdmin;
}

function shouldShowRemoveProgramOption(programs: any, programId: any) {
  const isProgramPresent = programId ? true : false;
  const isProgramAdminRoleExist = programs.canDeleteEntity;
  const canAssignAsAdmin = isProgramPresent && isProgramAdminRoleExist;
  return canAssignAsAdmin;
}
function shouldShowRemoveFromOrganizationOption(
  organizations: any,
  organizationId: any
) {
  const isOrganizationPresent = organizationId ? true : false;
  const isOrganizationAdminRoleExist = organizations.canDeleteEntity;
  const canAssignAsAdmin =
    isOrganizationAdminRoleExist && isOrganizationPresent;
  return canAssignAsAdmin;
}

function shouldShowRemoveOption(programs: any, data: any) {
  const isProgramPresent = data ? true : false;
  // const isProgramAdminRoleExist = programs.canDeleteEntity;
  const canAssignAsAdmin = isProgramPresent;
  return canAssignAsAdmin;
}

function shouldShowRemoveCommunityOption(role: any[] = [], communityId: any) {
  const isCommunityPresent = communityId;
  const isCommunityAdminRoleExist = !role?.includes("community_admin");
  const canAssignAsAdmin = isCommunityAdminRoleExist && isCommunityPresent;
  return canAssignAsAdmin;
}
function shouldShowEditObservation(
  status: string,
  date: string,
  observations: {
    canViewActivities: any;
    canArchiveObservation: any;
    canEditObservationReviewer: any;
    canEditObservationComment: any;
    canEditObservationPropertyCopy: any;
    // canCreateObservation: any;
    // canDeleteObservation: any;
    canEditObservation: any;
    canCreateExternalReviewer: any;
    canViewImportInfo: any;
    canEditInviteExternalReviewer: any;
    // canDeleteObservationManager: any;
    // canCreateObservationManagers: any;
    // canViewObservationManagersDropdown: any;
    canCreateReinviteReviewer: any;
    canEditRequestConsent: any;
    canEditReviewerAccessFor: any;
    canEditSeenActivities: any;
    canViewObservations: any;
  }
) {
  const { programId } = useParams();
  const givenDate = moment(date);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(givenDate, "days");
  if (
    (status === "in_review" && observations.canEditObservation) ||
    (status === "archived" && !programId && observations.canEditObservation)
  ) {
    return true;
  } else if (
    (status === "completed" || programId) &&
    observations.canEditObservation
  ) {
    return true;
  } else {
    return false;
  }
}

function checkUserRoles(auth: IAuthResponseData) {
  const isUserSuperAdmin = auth?.roles?.includes("super_admin");
  const isAdmin = auth?.roles?.includes("admin");
  const isUserProgramAdmin = auth?.roles?.includes("program_admin");
  const isUserOrganizationAdmin = auth?.roles?.includes("organization_admin");

  if (isUserSuperAdmin || isUserOrganizationAdmin || isAdmin) {
    return true;
  } else if (
    isUserProgramAdmin &&
    !isUserSuperAdmin &&
    !isUserOrganizationAdmin
  ) {
    return false;
  }
  return false;
}
