import React from "react";
import PlaceholderAvatar from "assets/images/Placeholders/PlaceholderAvatar.png";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { useDispatch } from "react-redux";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
  programId,
  id,
}) => {
  const dispatch = useDispatch();
  function isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }
  function getFileNameFromUrl(fileUrl: string) {
    const lastSlashIndex = fileUrl.lastIndexOf("original/");
    if (lastSlashIndex !== -1) {
      return fileUrl.substring(lastSlashIndex + "original/".length);
    }
    return fileUrl;
  }

  function handleDownload(fileUrl: string) {
    const fileName = getFileNameFromUrl(fileUrl);
    if (fileName === null) return;
    dispatch(setLoadingProgress(getRandomProgress()));
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const tempAnchor = document.createElement("a");
        tempAnchor.href = URL.createObjectURL(blob);
        tempAnchor.download = fileName;
        tempAnchor.click();
        URL.revokeObjectURL(tempAnchor.href);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      })
      .finally(() => {
        dispatch(setLoadingProgress(100));
      });
  }

  if (cellValue !== null && Array.isArray(cellValue)) {
    if (cellValue === undefined) {
      return <></>;
    }

    return (
      <div className={`relative flex items-start py-1 px-3`}>
        {cellValue.map((link, index, array) => (
          <React.Fragment key={index}>
            {isValidUrl(link) ? (
              <button
                onClick={() => {
                  handleDownload(link);
                }}
                className={`text-sm text-left text-primary whitespace-nowrap dark:text-caption dark:group-hover:text-secondary mx-1`}
              >
                {getFileNameFromUrl(link)}
              </button>
            ) : (
              <p
                className={`text-sm text-left text-textMid whitespace-nowrap dark:text-caption dark:group-hover:text-secondary mx-1`}
              >
                {link}
              </p>
            )}
            {index !== array.length - 1 && ", "}{" "}
          </React.Fragment>
        ))}
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p
          className={`text-sm text-left text-textMid whitespace-nowrap dark:text-caption dark:group-hover:text-secondary`}
        >
          {ellipsize(`${cellValue?.toString() ?? ""}`)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
