import { useEffect } from "react";
import { Geography, SurveyComponent } from "./Components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "store";
import { IfetchSurveyJsFormDatails } from "store/formDetailsSurveyJsFields/initialState";
import { TailSpin } from "react-loader-spinner";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { fetchSurveyJSFormDetails } from "store/formDetailsSurveyJsFields/reducer.actions";

export const EditFormDetails = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchSurveyJsFormDatails
  >((state) => state.formSurveyJsDetails);
  const { displayView } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );

  const { formId } = useParams();

  useEffect(() => {
    if (formId) {
      dispatch(fetchSurveyJSFormDetails({ id: formId }));
    }
  }, [formId]);

  if (stateIs === "Pending") {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <TailSpin
          height="50"
          width="50"
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
        />
      </div>
    );
  } else if (stateIs === "Idle") {
    return (
      <>
        <SurveyComponent
          formDetails={formDetails}
          doHide={displayView === "geography"}
        />
        {displayView === "geography" ? <Geography /> : null}
      </>
    );
  }
};
