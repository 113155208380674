import { useEffect, useState } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "../../../mapHandlers";

const usePaintAllLayersOnMap = (
  currentQuestionEle: any,
  map: any,
  isMapFullyLoaded: any,
  LAYER_SELECTED_COLOR: any,
  LAYER_DEFAULT_COLOR: any
) => {
  const [clickedLayer, setClickedLayer] = useState<any>(null);

  useEffect(() => {
    if (
      currentQuestionEle &&
      currentQuestionEle.allCommunityLayers &&
      currentQuestionEle.allCommunityLayers.length > 0 &&
      map &&
      isMapFullyLoaded
    ) {
      currentQuestionEle.allCommunityLayers.forEach((layer: any) => {
        const item = { item: layer, itemType: "shapepicker", status: true };

        const isGeojsonUrl =  item.item.geoFilePath ? true : false;

        const isItemHighlighted =
          currentQuestionEle.selectedLayersIdToHighlight?.find(
            (layerId: any) => layerId == item.item.id
          );

        const layerColor = isGeojsonUrl ? "purple" : isItemHighlighted ? LAYER_SELECTED_COLOR : LAYER_DEFAULT_COLOR;

        // paint layers on map
        handlePaintingOfVectorRegionShapeLayersOnMap(
          item,
          map,
          {},
          layerColor,
          (layerItem: any) => setClickedLayer(layerItem)
        );
      });
    }
  }, [map, isMapFullyLoaded, currentQuestionEle]);

  return [clickedLayer, setClickedLayer];
};

export { usePaintAllLayersOnMap };
