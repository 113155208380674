import React, { useEffect, useLayoutEffect, useState } from "react";
import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import Button from "view/components/Button";
import TextInput from "view/components/InputField";
import apiLibrary from "services/api";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { Box, Modal } from "@mui/material";
import { Toasts } from "view/components/Toasts";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeAssignAnAdminToProgramModalAction } from "store/modals/reducer.actions";
import Placeholder from "assets/images/Placeholders/programPlaceholderAvatar.png";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import { Users } from "./Users";
import Admins from "./Admins";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import _ from "lodash";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

export const AssignAnAdminForProgram = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { assignAnAdminToProgramModal } = useSelector(
    (state: RootState) => state.modals
  );

  const [searchString, setSearchString] = useState("");
  const [entitiesList, setEntitiesList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [connectedEntitiesList, setConnectedEntitiesList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const { programs } = usePermissions();

  const customSort = (entities: any): any => {
    return entities.sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const addExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) =>
      prevEntitiesList.filter((item) => item?.id !== entity.id)
    );

    setConnectedEntitiesList((prevconnectedEntitiesList) => {
      const isEntityAlreadyConnected = prevconnectedEntitiesList.some(
        (item: any) => item.id === entity.id
      );

      if (!isEntityAlreadyConnected) {
        const updatedList = [...prevconnectedEntitiesList, entity];
        return customSort(updatedList);
      }
      if (searchString !== "") {
        searchUserWhichCanBeAddAsAdmin(1, "", prevconnectedEntitiesList);
      }
      return prevconnectedEntitiesList;
    });
  };

  const removeExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) => {
      const isEntityPresent = prevEntitiesList.some(
        (item) => item?.id === entity.id
      );

      if (!isEntityPresent) {
        const updatedEntitiesList = [...prevEntitiesList, entity];
        return customSort(updatedEntitiesList);
      } else {
        return prevEntitiesList;
      }
    });

    setConnectedEntitiesList((prevconnectedEntitiesList: any) => {
      const updatedConnectedEntitiesList = prevconnectedEntitiesList.filter(
        (item: any) => item?.id !== entity.id
      );
      if (searchString !== "") {
        searchUserWhichCanBeAddAsAdmin(1, "", updatedConnectedEntitiesList);
      }

      return customSort(updatedConnectedEntitiesList);
    });
  };
  const removeAllEntities = (): void => {
    setEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...connectedEntitiesList.filter(
          (connectedEntity: any) =>
            !prevEntitiesList.some((entity) => entity.id === connectedEntity.id)
        ),
      ];
      return customSort(updatedEntitiesList);
    });

    setConnectedEntitiesList([]);
  };

  const addAllEntities = (): void => {
    setConnectedEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...entitiesList.filter(
          (newEntity: any) =>
            !prevEntitiesList.some(
              (prevEntity: any) => prevEntity?.id === newEntity.id
            )
        ),
      ];

      if (searchString !== "") {
        searchUserWhichCanBeAddAsAdmin(1, "", updatedEntitiesList);
      }
      return customSort(updatedEntitiesList);
    });

    setEntitiesList([]);
  };

  const handleClose = () => {
    dispatch(closeAssignAnAdminToProgramModalAction());
  };

  const getAllUserWhichCanBeAddAsAdmin = async (
    isLoadMore: boolean,
    page: number,
    searchString: string
  ) => {
    if (!isLoadMore) {
      setIsLoading(true);
    }

    try {
      const { data } = await apiLibrary.Programs.getAllUserWhichCanBeAddAsAdmin(
        assignAnAdminToProgramModal.data?.leadingOrganizationId,
        searchString,
        page
      );

      const programAdmins =
        assignAnAdminToProgramModal?.data?.programAdmins.map((user: any) => {
          const { ...rest } = user;
          const updatedUser = {
            ...rest,
            name: `${user.firstName} ${user.lastName}`,
            personImage: user?.personImage?.thumb || user?.profileImage?.thumb,
            observations: user.observations,
          };
          return updatedUser;
        }) ?? [];

      const entitieslist = getSortedDifference(
        data.users,
        isLoadMore ? connectedEntitiesList : programAdmins
      );
      setEntitiesList(
        isLoadMore ? (prevList) => [...prevList, ...entitieslist] : entitieslist
      );

      const reStructuredConnectedEntitiesList =
        reStructureTheEntitiesList(programAdmins);

      setConnectedEntitiesList((pre: any[]) => {
        if (isLoadMore || searchString !== "") {
          return pre;
        } else {
          return reStructuredConnectedEntitiesList;
        }
      });

      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserWhichCanBeAddAsAdmin(false, 1, searchString);
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getAllUserWhichCanBeAddAsAdmin(true, page, searchString);
      return page;
    });
  };

  const searchUserWhichCanBeAddAsAdmin = async (
    page: number,
    searchString: string,
    connectedEntitiesList: any[]
  ) => {
    setIsLoading(true);

    try {
      const { data } = await apiLibrary.Programs.getAllUserWhichCanBeAddAsAdmin(
        assignAnAdminToProgramModal.data?.leadingOrganizationId,
        searchString,
        page
      );

      const entitieslist = getSortedDifference(
        data.users,
        connectedEntitiesList
      );

      setEntitiesList(entitieslist);
      setTotalPages(data.to);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchUsersWhichCanBeAddAsAdmin = (
    searchString: string
  ): void => {
    setPage((pre: number) => {
      searchUserWhichCanBeAddAsAdmin(1, searchString, connectedEntitiesList);
      return 1;
    });
  };

  const debouncedSearch = _.debounce(
    handleSearchUsersWhichCanBeAddAsAdmin,
    100
  );
  const addExistingMembersAsAnAdmin = async () => {
    if(!programs.canEditProgram){
      return;
    }
    setIsLoading(true);
    try {
      const response = await apiLibrary.Programs.editProgram(
        assignAnAdminToProgramModal.data?.id,
        {
          admin_ids: connectedEntitiesList.map((admin: any) => {
            return admin.id;
          }),
        }
      );
      dispatch(fetchProgramProfileAction(assignAnAdminToProgramModal.data?.id));
      handleClose();
      Toasts.success(response.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={assignAnAdminToProgramModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="bg-bgWhite w-[700px] max-h-[95vh] rounded-lg dark:bg-secondaryLight">
          <div className="flex flex-col items-start self-stretch justify-start p-4 bg-white rounded-lg">
            <div className="w-full">
              <div className="flex items-center justify-items-center">
                <div className="flex flex-col items-start self-stretch justify-center w-full">
                  <div className="flex items-center self-stretch justify-start gap-2">
                    <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                      <p className="self-stretch w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                        Search For An Admin To Add To
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  title="close"
                  type="button"
                  onClick={handleClose}
                  className="ml-8 cursor-pointer"
                >
                  <XCloseIcon />
                </button>
              </div>
              <div className="flex w-full justify-start items-center gap-4 py-1.5">
                <div className="rounded-full">
                  <img
                    className="object-cover rounded-full w-9 h-9"
                    src={
                      assignAnAdminToProgramModal?.data?.profileImage
                        ? assignAnAdminToProgramModal?.data?.profileImage
                        : Placeholder
                    }
                  />
                </div>
                <div className="w-[95%] flex">
                  <p className="max-h-32 break-all w-full text-[16px] text-left font-medium text-secondary dark:text-textMain">
                    {assignAnAdminToProgramModal?.data?.name}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-start self-stretch justify-start bg-white">
                <div className="flex flex-col items-start self-stretch justify-start gap-3 pt-4 pb-2">
                  <div className="w-full">
                    <TextInput
                      type="Search"
                      placeholder="Search Name"
                      value={searchString}
                      onChange={(e: any) => {
                        setSearchString(e.target.value);
                        debouncedSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[5px] rounded-lg">
                  <div className="flex-grow h-px bg-lineMid" />
                </div>
                {isLoading ? (
                  <div className="loader min-h-[44vh]  justify-center items-center flex w-full">
                    <TailSpin
                      height="50"
                      width="50"
                      color="#005C89"
                      ariaLabel="tail-spin-loading"
                      radius="2"
                      wrapperStyle={{}}
                      wrapperClass="tailspin-loader"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="w-full overflow-hidden px-1 pb-4 h-[50vh] flex flex-col">
                    <>
                      {connectedEntitiesList?.length > 0 &&
                        searchString === "" && (
                          <div className="w-full">
                            <div className="flex justify-between p-3 space-x-4">
                              <div className="flex gap-2">
                                <UsersIcon />
                                <p className="font-medium text-md text-textMid dark:text-textMain">
                                  {connectedEntitiesList?.length} Assigned
                                  Admins
                                </p>
                              </div>
                              <button
                                type="button"
                                onClick={() => removeAllEntities()}
                              >
                                <p className="text-primary">Clear All</p>
                              </button>
                            </div>

                            <div className="max-h-[100px] overflow-y-auto">
                              {connectedEntitiesList?.map(
                                (admin: any, index: number) => (
                                  <Admins
                                    key={index}
                                    userData={admin}
                                    unAssignAdmin={() =>
                                      removeExistingEntity(admin)
                                    }
                                  />
                                )
                              )}
                            </div>
                            <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[8px] rounded-lg">
                              <div className="flex-grow h-px bg-lineMid" />
                            </div>
                          </div>
                        )}

                      <div className="w-full overflow-hidden flex flex-col flex-1">
                        <div className="flex justify-between p-3">
                          <div className="flex gap-2">
                            <div>
                              <UsersIcon />
                            </div>
                            <div className="flex w-full gap-1">
                              <p className="font-medium text-md text-textMid dark:text-textMain">
                                {entitiesList?.length}
                              </p>
                              <p className="font-medium text-md text-textMid dark:text-textMain">
                                {searchString !== ""
                                  ? "Search results"
                                  : "Users"}
                              </p>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              addAllEntities();
                              if (searchString !== "") {
                                setSearchString("");
                              }
                            }}
                          >
                            <p className="text-primary">Add All</p>
                          </button>
                        </div>
                        <div id="usersDiv" className={`overflow-auto`}>
                          <InfiniteScroll
                            dataLength={entitiesList.length}
                            next={handleLoadMore}
                            hasMore={hasMoreData}
                            // height={170}
                            scrollableTarget="usersDiv"
                            loader={
                              <div className="flex items-center justify-center w-full loader">
                                <TailSpin
                                  height="30"
                                  width="30"
                                  color="#005C89"
                                  ariaLabel="tail-spin-loading"
                                  radius="2"
                                  wrapperStyle={{}}
                                  wrapperClass="tailspin-loader"
                                  visible={true}
                                />
                              </div>
                            }
                            pullDownToRefresh={true}
                            refreshFunction={() => { }}
                            pullDownToRefreshContent={
                              <h3 style={{ textAlign: "center" }}>
                                &#8595; Pull down to refresh &#8595;
                              </h3>
                            }
                            releaseToRefreshContent={
                              <h3 style={{ textAlign: "center" }}>
                                &#8593; Release to refresh &#8593;
                              </h3>
                            }
                          >
                            {entitiesList?.map((user, index) => (
                              <Users
                                key={index}
                                userData={user}
                                addAdmin={() => {
                                  addExistingEntity(user);
                                  if (searchString !== "") {
                                    setSearchString("");
                                  }
                                }}
                              />
                            ))}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 pt-4">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                disabled={isLoading}
                onClick={handleClose}
              />

              <Button
                type="button"
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                disabled={isLoading || !programs.canEditProgram}
                onClick={addExistingMembersAsAnAdmin}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const reStructuredEntitiesList = reStructureTheEntitiesList(entitiesList);
  const reStructuredConnectedEntitiesList = reStructureTheEntitiesList(
    connectedEntitiesList
  );

  const difference = _.differenceBy(
    reStructuredEntitiesList,
    reStructuredConnectedEntitiesList,
    "id"
  );
  return difference;
}

function getSortedEntities(entitiesList: any) {
  return _.sortBy(entitiesList, "name");
}

function reStructureTheEntitiesList(entitiesList: any): any[] {
  const reStructuredList = entitiesList.map((user: any) => {
    const { ...rest } = user;
    return {
      ...rest,
      name: `${user.firstName} ${user.lastName}`,
      profileImage: user.personImage,
    };
  });
  const sortedList = _.sortBy(reStructuredList, [
    (user) => user.name.toLowerCase(),
  ]);

  return sortedList;
}
