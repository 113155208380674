import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import NotificationNoData from "../imgs/NotificationNoData";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
export default function QANotifications() {
  const {
    dashboard: { qaNotifications },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  React.useEffect(() => {
    dispatch(dashboardActions.fetchQANotifications());
  }, [dashboardFilter]);
  return (
    <Box height={"100%"}>
      <DashboardCardHeading
        text="QA Notifications"
        widgetId="qa_notifications"
        viewAllLInk={"/custom-notifications/list"}
      />

      {qaNotifications.stateIs == "Idle" &&
      qaNotifications.data.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <NotificationNoData />
        </div>
      ) : qaNotifications.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <List sx={{ width: "100%", py: 0 }}>
          <List className="w-full py-0">
            {qaNotifications.data.map((item, index) => {
              return (
                <Link key={index} to={"/custom-notifications/list"}>
                  <ListItem alignItems="flex-start" sx={{ p: 0, mb: 3 }}>
                    <ListItemAvatar
                      sx={{ mt: 0, minWidth: "36px" }}
                      className="mr-2.5"
                    >
                      <Avatar
                        alt={"NA"}
                        src={item.image?.original}
                        sx={{
                          width: "36px",
                          height: "36px",
                        }}
                        className="shadow"
                      />
                    </ListItemAvatar>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      className="mr-1 w-[320px] text-textNeutralGray text-sm font-normal font-Overpass dark:text-textMain leading-5 overflow-hidden overflow-ellipsis line-clamp-2"
                    ></p>

                    <p className="ml-auto min-w-fit  text-textNeutralGray text-sm font-normal font-['Inter'] dark:text-caption text-right leading-4">
                      {item.duration}
                    </p>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </List>
      )}
    </Box>
  );
}
