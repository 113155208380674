import React, { useState } from "react";
/************ hooks ************/
import useDateFormatter from "hooks/formatDateWithPattern";
import { CalenderIcon, EyeIcon, EyeOffIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Field } from "formik";
import moment from "moment-timezone";
import CustomDatePicker from "view/components/DatePicker";
import CustomDateTimePicker from "view/components/DateTimePicker";
import CustomTimePicker from "view/components/TimePicker";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import { IMask, IMaskInput } from 'react-imask';

interface TextFieldProps {
  label?: string;
  type:
  | "text"
  | "number"
  | "password"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "date"
  | "datetime-local"
  | "month"
  | "range"
  | "search"
  | "tel"
  | "time"
  | "url"
  | "week"
  | "color";
  error?: any;
  name: string;
  editMode?: boolean;
  value?: any;
  data?: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  setFieldValue?: any;
  placeholder?: any;
  rows?: number;
  multiple?: any;
  disabled?: any;
  max?: any;
  ref?: any;
  id?: any;
  inputClassName?: string;
  className?: string;
  accept?: string;
  min?: any;
  step?: number | string;
  required?: boolean;
  pattern?: string;
  size?: number;
  maxLength?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  fieldAs?: "text" | "select" | "date" | "password" | "textarea";
  setFieldWidth?: any;
  visibleIf?: any;
  enableIf?: any;
  setValueIf?: any;
  setValueExpression?: any;
  requiredIf?: any;
  answers?: any;
  labelSize?: string;
  airTemperatureUnit?: any;
  waterTemperatureUnit?: any;
  observerTimeZone?: any;
  isUpdated?: boolean;
  maskType?: string | undefined;
  maskSettings?: any;
  panel?: any;
  element?: any;
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  type = "text",
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  rows = 4,
  setFieldValue,
  placeholder,
  multiple,
  disabled = false,
  max,
  ref,
  id,
  inputClassName,
  className,
  accept,
  min,
  step,
  required,
  pattern,
  size,
  maxLength,
  readOnly = false,
  autoFocus = false,
  fieldAs,
  setFieldWidth,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  labelSize,
  airTemperatureUnit = null,
  waterTemperatureUnit = null,
  isUpdated = false,
  maskType = "",
  maskSettings,
  panel,
  element,
  ...restProps
}) => {
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );

  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { formatDate, FormatDateTime, formatTime } = useDateFormatter();

  const isPasswordInput = type === "password";

  return (
    <div
      className={`flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight ${labelSize ? "p-0" : "p-4"}`} id={`${element?.name}${panel?.sectionId}`}
    >
      <div className="relative flex flex-col py-2">
        <p
          className={
            `w-full pb-1 text-left capitalize font-Overpass ${editMode
              ? "text-primary dark:text-caption font-medium "
              : "text-primary dark:text-caption font-regular "
            }` + (labelSize ? `text-${labelSize}` : "text-md")
          }
        >
          {label || name} {required && <span>*</span>}
        </p>

        {editMode ? (
          <>
            <div
              className={`flex justify-start items-center focus:outline-none gap-1.5 ${isFieldIsDateDateTimeOrTime(type) ? "" : " border px-3 py-2"}  rounded bg-bgWhite dark:bg-secondaryLight ${setFieldWidth ? " w-[50%]" : "w-full"
                } ${touched && error
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
                }`}
            >
              <RenderInput
                label={label}
                type={type}
                error={error}
                name={name}
                editMode={editMode}
                value={value}
                data={data}
                handleChange={(value: any) => {
                  handleChange(value);
                }}
                handleBlur={handleBlur}
                touched={touched}
                rows={rows}
                setFieldValue={setFieldValue}
                placeholder={placeholder}
                multiple={multiple}
                disabled={disabled}
                max={max}
                ref={ref}
                id={id}
                inputClassName={inputClassName}
                className={className}
                accept={accept}
                min={min}
                step={step}
                required={required}
                pattern={pattern}
                size={size}
                maxLength={maxLength}
                readOnly={readOnly}
                autoFocus={autoFocus}
                fieldAs={fieldAs}
                setFieldWidth={setFieldWidth}
                visibleIf={visibleIf}
                enableIf={enableIf}
                setValueIf={setValueIf}
                setValueExpression={setValueExpression}
                requiredIf={requiredIf}
                answers={requiredIf}
                labelSize={requiredIf}
                airTemperatureUnit={airTemperatureUnit}
                waterTemperatureUnit={waterTemperatureUnit}
                observerTimeZone={observationProfile.observerTimezone}
                isUpdated={isUpdated}
                maskType={maskType}
                maskSettings={maskSettings}
              />
              {isPasswordInput && (
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="focus:outline-none"
                >
                  {showPassword ? (
                    <EyeIcon className="w-6 h-6" />
                  ) : (
                    <EyeOffIcon className="w-6 h-6" />
                  )}
                </button>
              )}
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              {value !== null && value !== undefined && value !== "" ? (
                <p className="overflow-hidden text-overflow-ellipsis text-[15px] text-left font-normal text-textAnswer font-Overpass dark:text-textMain">
                  {formatDisplayValue({
                    type,
                    value,
                    name,
                    waterTemperatureUnit,
                    airTemperatureUnit,
                    formatDate,
                    FormatDateTime,
                    formatTime,
                    observerTimeZone: observationProfile.observerTimezone,
                    switchToObserverTimeZone:
                      observationProfile.switchToObserverTimeZone,
                    userTimezone: profileSettings.timezone,
                    maskType
                  })}
                </p>
              ) : (
                <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
                  (No Selection)
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default TextField;

const RenderInput: React.FC<TextFieldProps> = ({
  label,
  type = "text",
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  rows = 4,
  setFieldValue,
  placeholder,
  multiple,
  disabled = false,
  max,
  ref,
  id,
  inputClassName,
  className,
  accept,
  min,
  step,
  required,
  pattern,
  size,
  maxLength,
  readOnly = false,
  autoFocus = false,
  fieldAs,
  setFieldWidth,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  labelSize,
  airTemperatureUnit = null,
  waterTemperatureUnit = null,
  observerTimeZone,
  isUpdated,
  maskType,
  maskSettings,
  ...restProps
}) => {
  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const updatedValue = isFieldIsDateDateTimeOrTime(type)
    ? moment.tz(value, observerTimeZone).tz(currentTimeZone)
    : value;

  const updateDateValue = (updatedValue: any, observerTimeZone: any) => {
    return moment
      .tz(updatedValue, currentTimeZone)
      .tz(observerTimeZone)
      .format("YYYY-MM-DD HH:mm:ss.SSSS");
  };

  switch (type) {
    case "date":
      return (
        <CustomDatePicker
          {...restProps}
          max={max}
          min={min ?? ""}
          initialValue={updatedValue}
          className={`w-full ${inputClassName}`}
          touched={touched}
          disabled={disabled}
          // sx={{border:"none"}}
          handleChange={(value: any) => {
            const datePart = moment(value).format("YYYY-MM-DD");
            const timePart = moment().format("HH:mm:ss.SSS");
            const updatedValue = updateDateValue(
              `${datePart} ${timePart}`,
              observerTimeZone
            );
            handleChange(updatedValue);
          }}
        />
      );
    case "datetime-local":
      return (
        <CustomDateTimePicker
          {...restProps}
          max={max}
          min={min ?? ""}
          initialValue={updatedValue}
          className={`w-full ${inputClassName}`}
          touched={touched}
          disabled={disabled}
          // sx={{border:"none"}}
          handleChange={(value: any) => {
            const updatedValue = updateDateValue(value, observerTimeZone);
            handleChange(updatedValue);
          }}
        />
      );

    case "time":
      return (
        <CustomTimePicker
          {...restProps}
          max={max}
          min={min ?? ""}
          initialValue={updatedValue}
          className={`w-full ${inputClassName}`}
          touched={touched}
          disabled={disabled}
          // sx={{border:"none"}}
          handleChange={(value: any) => {
            const datePart = moment().format("YYYY-MM-DD");
            const timePart = moment(value, "HH:mm A").format("HH:mm:ss.SSS");

            const updatedValue = updateDateValue(
              `${datePart} ${timePart}`,
              observerTimeZone
            );
            handleChange(updatedValue);
          }}
        />
      );
    default:
      if (maskType) {
        const maskProps = getMaskProps(maskType, maskSettings);
        if (maskType === "datetime") {

          const formatMap: { [key: string]: string } = {
            'dd': 'DD',
            'd': 'D',
            'mm': 'MM',
            'm': 'MM',
            'yyyy': 'YYYY',
            'yy': 'YY',
            'HH': 'HH',
            'H': 'H',
            'hh': 'hh',
            'h': 'h',
            'MM': 'mm', // minutes
            'ss': 'ss', // seconds
            'tt': 'a',
            'TT': 'A'
          };

          const minDate = maskSettings.min ? new Date(maskSettings.min).toString() : "";
          const maxDate = maskSettings.max ? new Date(maskSettings.max).toString() : "";
          const defaultFormat = "DD/MM/YYYY hh:MM:ss A";

          const convertToDateFnsFormat = (backendFormat: string) => {
            // Remove 'tt' or 'TT' if 'H', 'HH' is present
            if (/H|HH/.test(backendFormat)) {
              backendFormat = backendFormat.replace(/tt|TT/g, '');
            }

            // Replace format tokens
            const modifiedFormat = backendFormat?.replace(/dd|d|mm|m|yyyy|yy|HH|H|hh|h|MM|ss|tt|TT/g, (match) => formatMap[match]);

            return modifiedFormat;
          };

          return (
            <CustomDateTimePicker
              {...restProps}
              max={maxDate}
              min={minDate}
              initialValue={updatedValue}
              className={`w-full`}
              touched={touched}
              disabled={disabled}
              format={maskSettings?.pattern ? convertToDateFnsFormat(maskSettings?.pattern) : defaultFormat}
              handleChange={(value: any) => {
                const updatedValue = updateDateValue(value, observerTimeZone);
                handleChange(updatedValue);
              }}
              ampm={maskSettings?.pattern?.includes('tt') || maskSettings.pattern?.includes('TT')}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },
                "& .MuiInputBase-input": {
                  padding: "0 !important"
                }
              }}

            />
          )
        }

        else {
          return (
            <IMaskInput
              placeholder={placeholder}
              name={name}
              onChange={(e: any) => handleChange(e.target.value)}
              onBlur={handleBlur}
              value={value}
              className={
                "w-full h-full focus:outline-none resize-none text-textMid placeholder:text-secondaryLight text-[14px] dark:bg-secondaryLight dark:text-caption dark:placeholder:text-caption border-lineLight" +
                (inputClassName ?? "")
              }
              placeholderChar="_"  // Use underscores as placeholders for unfilled positions
              lazy={false}  // Show the full mask pattern when the input is focused
              {...maskProps}
            />
          )
        }

      }
      else {
        return (
          <Field
            disabled={isEnabled ? isEnabled : disabled}
            as={fieldAs}
            rows={rows}
            type={type}
            className={
              "w-full h-full focus:outline-none resize-none text-textMid placeholder:text-secondaryLight text-[14px] dark:bg-secondaryLight dark:text-caption dark:placeholder:text-caption border-lineLight" +
              (inputClassName ?? "")
            }
            name={name}
            max={max}
            onChange={(e: any) => handleChange(e.target.value)}
            placeholder={placeholder}
            ref={ref}
            id={id}
            onBlur={handleBlur}
            value={value}
            {...restProps}
          />
        );
      }

  }
};

function convertTemperature(value: any, unit: any) {
  let result;

  if (unit === "Celcius") {
    const fahrenheit = (value * 9) / 5 + 32;
    result = `${fahrenheit.toFixed(1)}°F / ${value}°C`;
  } else if (unit === "Fahrenheit") {
    const celsius = ((value - 32) * 5) / 9;
    result = `${value}°F / ${celsius.toFixed(1)}°C`;
  } else {
    return value;
  }

  return result;
}

type FormatDisplayValueType = {
  type: "date" | "datetime-local" | "time" | "text" | any;
  value: string | null;
  name: string;
  waterTemperatureUnit?: any;
  airTemperatureUnit?: any;
  formatDate?: any;
  FormatDateTime?: any;
  formatTime?: any;
  observerTimeZone: string;
  switchToObserverTimeZone: boolean;
  userTimezone?: any;
  maskType?: string;
};

function formatDisplayValue({
  type,
  value,
  name,
  waterTemperatureUnit,
  airTemperatureUnit,
  formatDate,
  FormatDateTime,
  formatTime,
  observerTimeZone,
  switchToObserverTimeZone,
  userTimezone,
  maskType,
}: FormatDisplayValueType): string {
  if (value === null || value === undefined || value === "") return "";
  if (name === "AirTemperature") {
    return convertTemperature(value, airTemperatureUnit);
  } else if (name === "WaterTemperature") {
    return convertTemperature(value, waterTemperatureUnit);
  } else {
    const updatedValue: any = convertToObserverTimeZone(
      value,
      observerTimeZone,
      switchToObserverTimeZone,
      type,
      userTimezone
    );

    if (maskType === "datetime") {
      return FormatDateTime(updatedValue);
    }

    switch (type) {
      case "date":
        return formatDate(updatedValue);
      case "datetime-local":
        return FormatDateTime(updatedValue);
      case "time":
        return updatedValue;
      case "text":
        if (
          !isNaN(Number(updatedValue)) &&
          !moment(updatedValue, moment.ISO_8601, true).isValid()
        ) {
          return updatedValue.toString();
        }
        break;
      default:
        break;
    }
    return value.toString();
  }
}

function isFieldIsDateDateTimeOrTime(type: string): boolean {
  return ["date", "datetime-local", "time"].includes(type);
}

function handleInputFielChange(event: any, type: string) {
  const { value } = event.target;

  let updatedValue;

  if (type === "date") {
    console.log(moment(value, "YYYY-MM-DD").utc().format("YYYY-MM-DD"));
    updatedValue = moment(value, "YYYY-MM-DD").utc().format("YYYY-MM-DD");
  } else if (type === "datetime-local") {
    updatedValue = moment(value, "YYYY-MM-DDTHH:mm").utc().toISOString();
  } else if (type === "time") {
    const fullDateTime = moment().format("YYYY-MM-DD") + "T" + value;
    updatedValue = moment(fullDateTime, "YYYY-MM-DDTHH:mm")
      .utc()
      .format("HH:mm");
  } else {
    updatedValue = value;
  }
  return updatedValue;
}

function convertToObserverTimeZone(
  value: string | null,
  timeZone: string | null,
  switchToObserverTimeZone: boolean,
  type: any,
  userTimezone: any
) {
  if (isFieldIsDateDateTimeOrTime(type) && value && timeZone) {
    const currentTimeZone = userTimezone
      ? userTimezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formatedDateTime = switchToObserverTimeZone
      ? moment(value)
      : moment.tz(value, timeZone).tz(currentTimeZone);
    switch (type) {
      case "date":
        return formatedDateTime.format("YYYY-MM-DD");
      case "datetime-local":
        return formatedDateTime.format("DD MMM YYYY, hh:mm A");
      case "time":
        return formatedDateTime.format("hh:mm A");

      default:
        break;
    }
    return formatedDateTime;
  }
  return value;
}

function updatedDefaultValue(
  value: any,
  isUpdated: boolean,
  editMode: boolean
) {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (isUpdated || editMode) {
    value = moment.tz(value, currentTimeZone).tz(currentTimeZone);
  }
  return value;
}

// GET MASKING PROPS
const getMaskProps = (type: string | undefined, maskSettings: any) => {

  const { pattern } = maskSettings;

  switch (type) {
    case 'currency':
      return {
        mask: `${maskSettings?.prefix || ""} num ${maskSettings?.suffix || ""}`,
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: maskSettings?.thousandsSeparator || ',',
            radix: maskSettings?.decimalSeparator || '.',
            scale: maskSettings?.precision || 2,  // digits after point
            min: maskSettings?.min || 0,
            max: maskSettings?.max || 0,
            prefix: maskSettings?.prefix || "",
            suffix: maskSettings?.suffix || "",
            signed: maskSettings?.allowNegativeValues,  // allows negative values
          },
        }
      }
    case 'datetime':
      const minDate = maskSettings.min ? new Date(maskSettings.min) : null;
      const maxDate = maskSettings.max ? new Date(maskSettings.max) : null;

      return {
        mask: pattern,
        blocks: {
          dd: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          mm: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          yy: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2999,
            maxLength: 2,
          },
          yyyy: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2999,
            maxLength: 4,
          },
          H: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
            maxLength: 2,
            optional: true,
          },
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
            maxLength: 2,
            optional: true,
          },
          h: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            optional: true,
          },
          hh: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            optional: true,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            maxLength: 2,
            optional: true,
          },
          ss: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            maxLength: 2,
            optional: true,
          },
          tt: {
            mask: IMask.MaskedEnum,
            enum: ['am', 'pm'],
          },
          TT: {
            mask: IMask.MaskedEnum,
            enum: ['AM', 'PM'],
          },
        },
        format: function (date: Date) {
          let hours = date.getHours();
          let tt = hours >= 12 ? 'pm' : 'am';
          if (pattern.includes('TT')) {
            tt = tt.toUpperCase();
          }
          if (hours > 12) {
            hours -= 12;
          } else if (hours === 0) {
            hours = 12;
          }
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} ${tt}`;
        },
        parse: function (str: string) {
          let [datePart, timePart, tt] = str.split(' ');
          let [year, month, day] = datePart.split('-').map(Number);
          let [hours, minutes] = timePart.split(':').map(Number);

          if (tt.toLowerCase() === 'pm' && hours < 12) {
            hours += 12;
          } else if (tt.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
          }

          let parsedDate = new Date(year, month - 1, day, hours, minutes);

          // Check if parsedDate is within the min and max dates
          if (minDate && parsedDate < minDate) {
            parsedDate = minDate;
          } else if (maxDate && parsedDate > maxDate) {
            parsedDate = maxDate;
          }

          return parsedDate;
        },
        autofix: true,  // enables autofixing date if some parts are out of range, like 32nd day
      };

    case 'numeric':
      return {
        mask: Number,
        thousandsSeparator: ',',
        scale: 2,  // digits after point
        min: maskSettings?.min || 0,
        max: maskSettings?.max || 0,
      };
    case 'pattern':
      return {
        mask: pattern,
        definitions: {
          '9': {  // Redefine '9' to be a digit placeholder
            mask: IMask.MaskedRange,
            from: 0,
            to: 9
          },
          '#': {  // Redefine '#' to be a placeholder for digits or letters
            mask: IMask.MaskedRegExp,
            pattern: '[0-9a-zA-Z]'  // Allows digits and letters
          },

        },
      }; // Custom pattern passed via props
    default:
      return {}; // No mask for other types
  }
};

