import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const PhoneSchema = Yup.string().test({
  name: "isValidPhone",
  exclusive: true,
  message: "Invalid phone number",
  test: function (value) {
    if (value) {
      const phoneNumber = isValidPhoneNumber(value);
      return phoneNumber;
    } else {
      return true;
    }
  },
});

export const loginValidationSchema = Yup.object().shape({
  phoneOrEmail: Yup.string()
    .matches(
      /^(?:(?:\+|0{0,2})[1-9]\d{0,3}[-\s]?)?(?:\d{1,4}[-\s]?){1,5}(?:[xX]{1,2}[ ]?\d{1,4})?$|^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/,
      "Invalid email or phone number"
    )
    .required("Email or phone number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const forgetPasswordValidationSchema = Yup.object().shape({
  emailOrPhone: Yup.string()
    .matches(
      /^(?:(?:\+|0{0,2})[1-9]\d{0,3}[-\s]?)?(?:\d{1,4}[-\s]?){1,5}(?:[xX]{1,2}[ ]?\d{1,4})?$|^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/,
      "Invalid email or phone number"
    )
    .required("Email Or Phone Number Is Required"),
});

export const updatePersonProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(256, "First name must be at most 256 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .max(256, "Last name must be at most 256 characters"),
  dateOfBirth: Yup.date().nullable().optional(),
  // .min(moment().subtract(10, 'years').toDate(), "Date of birth can't be less than 10 years ago"),
  gender: Yup.string()
    .nullable()
    .max(30, "Gender must be at most 30 characters")
    .optional(),
  // secondaryEmail: Yup.array().of(
  //   Yup.object().shape({
  //     email: Yup.string().email("Invalid email"),
  //     isPrimary: Yup.boolean(),
  //     isVerified: Yup.boolean(),
  //   })
  // ),
  // secondaryPhoneNumber: Yup.array().of(
  //   Yup.object().shape({
  //     phoneNumber: PhoneSchema,
  //     isPrimary: Yup.boolean(),
  //     isVerified: Yup.boolean(),
  //   })
  // ),

  secondaryEmail: Yup.array().of(
    Yup.object()
      .shape({
        email: Yup.string().email("Invalid email"),
        isPrimary: Yup.boolean(),
        isVerified: Yup.boolean(),
      })
      .test("unique-email", "Email already exists", function (value) {
        const emails =
          (this.options &&
            this.options.context &&
            this.options.context.emails) ||
          [];
        const email = value && value.email;
        return (
          !email ||
          !emails.some((existingEmail: any) => existingEmail.email === email)
        );
      })
  ),

  secondaryPhoneNumber: Yup.array().of(
    Yup.object()
      .shape({
        phoneNumber: PhoneSchema,
        isPrimary: Yup.boolean(),
        isVerified: Yup.boolean(),
      })
      .test("unique-phone", "Phone number already exists", function (value) {
        const phoneNumbers =
          (this.options &&
            this.options.context &&
            this.options.context.phoneNumbers) ||
          [];
        const phoneNumber = value && value.phoneNumber;
        return (
          !phoneNumber ||
          !phoneNumbers.some(
            (existingNumber: any) => existingNumber.phoneNumber === phoneNumber
          )
        );
      })
  ),
});
export const addPersonSchema = Yup.object().shape({
  contactPreference: Yup.string().optional(),
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  // firstName: Yup.string()
  //   .required("First name is required")
  //   .max(256, "First name must be at most 256 characters"),
  gender: Yup.string().max(10).optional(),
  // lastName: Yup.string()
  //   .required("Last name is required")
  //   .max(256, "Last name must be at most 256 characters"),
  personImageId: Yup.number().optional(),
  prefix: Yup.string().max(10).optional(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: PhoneSchema.required("Phone is required"),
  suffix: Yup.string().max(10).optional(),
  address: Yup.string().optional(),
  dateOfBirth: Yup.date().nullable(),
  // .optional()
  // .min(
  //   moment().subtract(10, "years").toDate(),
  //   "Date of birth can't be less than 10 years ago"
  // ),
});

export const addFormSchema = Yup.object().shape({
  description: Yup.string()
    .required("Description is required")
    .max(6000, "6000 max characters"),
  name: Yup.string()
    .required("Form Name is required")
    .max(255, "Form Name should be maximum 255 characters"),
  pictureId: Yup.number().nullable(),
  programId: Yup.object().shape({
    value: Yup.string().required("Program Name Is required"),
  }),
});

export const addSurveyFormScema = Yup.object().shape({
  description: Yup.string()
    .required("Description is required")
    .max(6000, "6000 max characters"),
  name: Yup.string()
    .required("Name is required")
    .max(255, "Name should be maximum 255 characters"),
  pictureId: Yup.number().nullable(),
  // programId: Yup.object().required("Program is required"),
});

export const createCommunityValidationSchema = [
  Yup.object().shape({
    address: Yup.string(),
    backgroundImageId: Yup.number(),
    location: Yup.object().shape({
      lat: Yup.string(),
      long: Yup.string(),
    }),
    missionStatement: Yup.string(),
    name: Yup.string().required("Name is required"),
    profileImageId: Yup.string(),
    type: Yup.string().required("Type is required"),
  }),
  Yup.object().shape({
    adminIds: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required("Admin ID is required"),
        })
      )
      .required("Admin IDs are required"),
  }),
  Yup.object().shape({
    organizationIds: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required("Organization ID is required"),
        })
      )
      .required("Organization IDs are required"),
  }),
];

export const createPasswordModalValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, "Password must be at least 8 characters long"),
  // .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  // .matches(/[0-9]/, "Password must contain at least one number")
  // .matches(
  //   /[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]/,
  //   "Password must contain at least one special character"
  // ),
});


export const CreateNewPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]/,
      "Password must contain at least one special character"
    ),

  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords does not match")
    .required("Confirm password is required"),
});
export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, "Password must be at least 8 characters long"),
  // .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  // .matches(/[0-9]/, "Password must contain at least one number")
  // .matches(
  //   /[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]/,
  //   "Password must contain at least one special character"
  // ),
});

export const profileChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]/,
      "Password must contain at least one special character"
    )
    .test(
      "no-trailing-whitespace",
      "Password cannot end with a whitespace",
      (value) => !/\s+$/.test(value)
    )
    .test(
      "no-leading-whitespace",
      "Password cannot start with a whitespace",
      (value) => !/^\s/.test(value)
    )
    .test(
      "passwords-should-not-match",
      "New password cannot be the same as the old password",
      function (value) {
        return value !== this.parent.oldPassword;
      }
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const programIdSchema = Yup.object().shape({
  value: Yup.string().required("Program Is A Required Field"),
  label: Yup.string(),
});

export const protocolSchema = Yup.object().shape({
  programId: programIdSchema.required("Program Is A Required Field"),
  description: Yup.string().required("Description Is A Required Field"),
  title: Yup.string()
    .required("Title Is A Required field")
    .max(255, "Description Can Be At Most 255 Characters Long."),
});

export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Category Name Is Required")
    .max(60, "Category Name Should Be Maximum 60 characters"),
  categoryCode: Yup.string()
    .required("Category code is required")
    .max(255, "Category code should be maximum 255 characters"),
});

export const addTypeSchema = Yup.object().shape({
  typeName: Yup.string()
    .required("Type name is required")
    .max(60, "Type name should be maximum 60 characters"),
  categoryId: Yup.mixed().required("Category name is required"),
});

export const addBehaviorSchema = Yup.object().shape({
  behaviorName: Yup.string()
    .required("Behavior name is required")
    .max(60, "Behavior name should be maximum 60 characters"),
  typeIds: Yup.array()
    .min(1, "Please select at least one type")
    .required("Types are required"),
});

export const addConditionSchema = Yup.object().shape({
  conditionName: Yup.string()
    .required("Condition name is required")
    .max(60, "Condition name should be maximum 60 characters"),
  typeIds: Yup.array()
    .min(1, "Please select at least one type")
    .required("Types are required"),
});

const typeIdSchema = Yup.object().shape({
  value: Yup.string().required("Type  is a required"),
  label: Yup.string(),
});

export const specieSchema = Yup.object().shape({
  typeId: typeIdSchema.required("Type ID is a required field"),
  description: Yup.string()
    .max(6000, "Description can be at most 6000 characters long.")
    .nullable(),
  commonName: Yup.string()
    .required("Common name is a required field")
    .max(255, "Common name can be at most 255 characters long."),
  scientificName: Yup.string()
    .max(255, "Sceintific name can be at most 255 characters long.")
    .nullable(),
  alternativeName: Yup.string()
    .max(255, "Alternative name can be at most 255 characters long.")
    .nullable(),
  images: Yup.array().max(3, "You can select a maximum of three images"),
  // .test(
  //   "array-length",
  //   "You can select a maximum of three images",
  //   (array: any) => array.length > 2
  // ),
  // .required("Please select images")
});

export const objectSchema = Yup.object().shape({
  typeId: typeIdSchema.required("Type ID Is A Required Field"),
  description: Yup.string()
    .max(6000, "Description can be at most 6000 characters long.")
    .nullable(),
  name: Yup.string()
    .required("Object name is a required field")
    .max(255, "Object name can be at most 255 characters long."),

  images: Yup.array()
    .max(3, "You can select a maximum of three images")
    // .required("Please select images")
    // .max(3, "You can select a maximum of three images"),
  // .test("array-length", "Images Are Required", (array) => array.length > 0),
});

export const addTagSchema = Yup.object().shape({
  name: Yup.string()
    .required("Tag name is required")
    .max(60, "Tag name should be maximum 60 characters"),
  description: Yup.string()
    .required("Description is required")
    .max(6000, "Description should be maximum 6000 characters"),
});

export const reUseSpeciesAndObjectsSchema = Yup.object().shape({
  communityId: Yup.array()
    .required("Please select communities")
    .test(
      "array-length",
      "Communities  are required",
      (array) => array.length > 0
    ),
});

const tagIdSchema = Yup.object().shape({
  value: Yup.string().required("Program  is a required"),
  label: Yup.string(),
});

export const editCommunityTagsSchema = Yup.object().shape({
  tagIds: Yup.array()
    .of(tagIdSchema)
    .max(15, "You can add a maximum of 15 tags"),
});

export const editProgramTagsSchema = Yup.object().shape({
  tagIds: Yup.array()
    .of(tagIdSchema)
    .max(15, "You can add a maximum of 15 tags"),
});

export const editOrganizationTagsSchema = Yup.object().shape({
  tagIds: Yup.array()
    .of(tagIdSchema)
    .max(15, "You can add a maximum of 15 tags"),
});
