import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Button from "view/components/Button";
import XCloseSmallIcon from "assets/icons/HeroIcons/XCloseSmallIcon";
import apiLibrary from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  closeAddExistingCommunitiesOrOrganizationsToProgramModalAction,
  openCreateCommunityModalAction,
  openCreateOrganizationModalAction,
} from "store/modals/reducer.actions";
import TextInput from "view/components/InputField";
import { Toasts } from "view/components/Toasts";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import CommunityPlaceholderAvatar from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";
import OrganizationPlaceholderAvatar from "assets/images/Placeholders/organizaitons_placeholder.png";

import _ from "lodash";
import { fetchCommunitiesAction } from "store/communities";
import { fetchOrganizationsAction } from "store/oranizations";
import { useNavigate, useParams } from "react-router-dom";
import { OfficeBuildingIcon, UserGroupIcon } from "assets/icons/HeroIcons";
import { setLoadingProgress } from "store/loadingBar";
import usePermissions from "hooks/usePermissions";
// Define the interface for SearchToAddExistingOrganizationProps (if required)
interface SearchToAddExistingEntitiesOrOrganizationsToProgramProps {}

// Styles for the Modal component
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

// SearchToAddExistingEntitiesOrOrganizationsToProgram component
export const SearchToAddExistingEntitiesOrOrganizationsToProgram: React.FC<
  SearchToAddExistingEntitiesOrOrganizationsToProgramProps
> = () => {
  // Redux hooks
  const navigate = useNavigate();

  // Redux hooks
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { addExistingCommunitiesOrOrganizationsToProgramModal } = useSelector(
    (state: RootState) => state.modals
  );

  // State hooks
  const [entitiesList, setEntitiesList] = useState<any[]>([]);
  const [connectedEntitiesList, setConnectedEntitiesList] = useState<any[]>([]);
  const [isMemberRemoved, setIsMemberRemoved] = useState<boolean>(false);
  const [searchString, setSearchString] = useState("");

  const [totalEntities, setTotalEntities] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { programs, communities, organizations } = usePermissions();

  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();

  const addExistingEntities = async () => {
    setIsLoading(true);
    try {
      if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
        "community"
      ) {
        const response = await apiLibrary.Programs.connectCommunitiesToProgram(
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
          {
            communityIds: connectedEntitiesList.map((entity: any) => {
              return entity.id;
            }),
          }
        );
        Toasts.success(response.message);

        handleClose();
        dispatch(fetchCommunitiesAction(organizationId, programId));
      } else if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
          "organization" &&
        programs.canEditProgram
      ) {
        const response = await apiLibrary.Programs.editProgram(
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
          {
            organizationIds: connectedEntitiesList.map((entity: any) => {
              return entity.id;
            }),
          }
        );
        Toasts.success(response.message);

        handleClose();

        dispatch(fetchOrganizationsAction(communityId, programId));
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getEntities = async (
    isLoadMore: boolean,
    id: number,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedEntities?: any[] | null
  ) => {
    setIsLoading(true);

    try {
      const { data } =
        await apiLibrary.Programs.getCommunitiesAndOrganizationsByProgram(
          id,
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity,
          isConnected,
          searchString,
          page
        );
      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.entities,
        isConnected
      );
      if (isConnected) {
        setConnectedEntitiesList(reStructuredEntitiesList);
      } else {
        if (!isLoadMore) {
          if (connectedEntities && connectedEntities?.length > 0) {
            const EntitiesList = getSortedDifference(
              reStructuredEntitiesList,
              connectedEntities
            );
            setEntitiesList(EntitiesList);
          } else {
            setEntitiesList(reStructuredEntitiesList);
          }
        } else {
          if (connectedEntities && connectedEntities?.length > 0) {
            const entitiesList = getSortedDifference(
              reStructuredEntitiesList,
              connectedEntities
            );

            setEntitiesList((prevList) => [...prevList, ...entitiesList]);
          } else {
            setEntitiesList((prevList) => [
              ...prevList,
              ...reStructuredEntitiesList,
            ]);
          }
        }

        setTotalPages(data.totalPages);
        setHasMoreData(data.totalPages > page);
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConnectedAndExistingEntities = async () => {
    await getEntities(
      false,
      parseInt(addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id),
      false,
      page,
      searchString
    );
    await getEntities(
      false,
      parseInt(addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id),
      true,
      page,
      searchString
    );
  };
  useEffect(() => {
    if (addExistingCommunitiesOrOrganizationsToProgramModal.isOpen) {
      setEntitiesList([]);
      setPage(1);
      fetchConnectedAndExistingEntities();
    }
  }, [
    addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
    addExistingCommunitiesOrOrganizationsToProgramModal.isOpen,
  ]);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getEntities(
        true,
        parseInt(addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id),
        false,
        page,
        searchString,
        connectedEntitiesList
      );
      return page;
    });
  };

  const searchEntities = async (
    id: any,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedEntities: any[]
  ) => {
    setIsLoading(true);
    try {
      const { data } =
        await apiLibrary.Programs.getCommunitiesAndOrganizationsByProgram(
          id,
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity,
          isConnected,
          searchString,
          page
        );

      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.entities,
        isConnected
      );

      const searchedEntitiesList = getSortedDifference(
        reStructuredEntitiesList,
        connectedEntities
      );

      setEntitiesList(searchedEntitiesList);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchEntities = async (searchString: string) => {
    setPage(1);
    await searchEntities(
      parseInt(addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id),
      false,
      1,
      searchString,
      connectedEntitiesList
    );
  };

  const debouncedSearch = _.debounce(handleSearchEntities, 1000);

  const removeEntityFromProgram = async (entity: any) => {
    setIsMemberRemoved(true);
    dispatch(setLoadingProgress(100));
    setIsLoading(true);
    try {
      const res = await apiLibrary.Programs.removeFromProgram(
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity,
        parseInt(entity.id)
      );

      Toasts.success(res.message);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const addExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) => {
      const filteredEntities = prevEntitiesList.filter(
        (item) => item.id !== entity.id
      );

      if (filteredEntities.length === 0) {
        callApiIfEntitiesLengthIsZero();
      }
      return filteredEntities;
    });

    setConnectedEntitiesList((prevconnectedEntitiesList) => {
      const isCommunityAlreadyConnected = prevconnectedEntitiesList.some(
        (item) => item.id === entity.id
      );

      if (!isCommunityAlreadyConnected) {
        const updatedList = [...prevconnectedEntitiesList, entity];
        return updatedList;
      }

      return prevconnectedEntitiesList;
    });
  };

  const removeExistingEntity = async (entity: any) => {
    if (entity.isConnected) {
      await removeEntityFromProgram(entity);
      await getEntities(
        false,
        parseInt(addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id),
        false,
        1,
        searchString
      );
      setConnectedEntitiesList((prevconnectedEntitiesList) =>
        prevconnectedEntitiesList.filter((item) => item.id !== entity.id)
      );
    }

    setEntitiesList((prevEntitiesList) => {
      const isCommunityPresent = prevEntitiesList.some(
        (item) => item.id === entity.id
      );

      if (!isCommunityPresent) {
        const updatedEntitiesList = [...prevEntitiesList, entity];
        return updatedEntitiesList;
      } else {
        return prevEntitiesList;
      }
    });

    setConnectedEntitiesList((prevconnectedEntitiesList) => {
      const updatedConnectedEntitiesList = prevconnectedEntitiesList.filter(
        (item) => item.id !== entity.id
      );
      return updatedConnectedEntitiesList;
    });
  };

  const removeExistingEntities = async () => {
    setIsLoading(true);
    try {
      if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
        "community"
      ) {
        const response = await apiLibrary.Programs.connectCommunitiesToProgram(
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
          {
            communityIds: connectedEntitiesList
              .filter((item: any) => !item.isConnected)
              .map((entity: any) => {
                return entity.id;
              }),
          }
        );
        Toasts.success("All Communities Have Been Removed");

        // handleClose();
        // dispatch(fetchCommunitiesAction(organizationId, programId));
      } else if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
          "organization" &&
        programs.canEditProgram
      ) {
        const response = await apiLibrary.Programs.editProgram(
          addExistingCommunitiesOrOrganizationsToProgramModal?.data?.id,
          {
            organizationIds: connectedEntitiesList
              .filter((item: any) => !item.isConnected)
              .map((entity: any) => {
                return entity.id;
              }),
          }
        );
        Toasts.success("All Organization Have Been Removed");

        // handleClose();

        // dispatch(fetchOrganizationsAction(communityId, programId));
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAllEntities = (): void => {
    const isAllEntityConnected = connectedEntitiesList.every(
      (entity: any) => entity.isConnected
    );

    if (isAllEntityConnected) {
      removeAllConnectedEntity();
    } else if (searchString !== "") {
      setEntitiesList((prevEntitiesList) => {
        const updatedEntitiesList = [
          ...prevEntitiesList,
          ...connectedEntitiesList.filter(
            (connectedMember) =>
              !connectedMember.isConnected &&
              connectedMember.name
                .toLowerCase()
                .includes(searchString.toLowerCase())
          ),
        ];
        return updatedEntitiesList;
      });

      setConnectedEntitiesList((pre: any[]) => {
        const updatedConnectedEntitiesList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedEntitiesList;
      });
    } else {
      setEntitiesList((prevEntitiesList) => {
        const filteredConnectedEntitiesList = connectedEntitiesList.filter(
          (connectedMember) => !connectedMember.isConnected
        );

        const updatedEntitiesList = [
          ...prevEntitiesList,
          ...filteredConnectedEntitiesList,
        ];

        return updatedEntitiesList;
      });

      setConnectedEntitiesList((pre: any[]) => {
        const updatedConnectedEntitiesList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedEntitiesList;
      });
    }
  };

  const removeAllConnectedEntity = async () => {
    await removeExistingEntities();
    await fetchConnectedAndExistingEntities();
  };

  const addAllEntities = (): void => {
    setEntitiesList(() => {
      return [];
    });
    setConnectedEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...entitiesList.filter(
          (newMember) =>
            !prevEntitiesList.some(
              (prevMember) => prevMember.id === newMember.id
            )
        ),
      ];
      return updatedEntitiesList;
    });
  };

  // Function to handle modal close
  const handleClose = () => {
    setEntitiesList([]);
    setConnectedEntitiesList([]);
    setTotalEntities(0);
    setTotalPages(1);
    setSearchString("");
    dispatch(closeAddExistingCommunitiesOrOrganizationsToProgramModalAction());

    if (isMemberRemoved) {
      if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
        "community"
      ) {
        dispatch(fetchCommunitiesAction(organizationId, programId));
      } else if (
        addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
        "organization"
      ) {
        dispatch(fetchOrganizationsAction(communityId, programId));
      }
    }
  };

  const redirectToTheDirectory = () => {
    if (
      addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
      "community"
    ) {
      if (communities.canViewViewCommunities) {
        navigate("/community/list");
      }
      handleClose();
      if (communities.canCreateCommunities) {
        setTimeout(() => {
          dispatch(openCreateCommunityModalAction());
        }, 1000);
      }
    } else if (
      addExistingCommunitiesOrOrganizationsToProgramModal?.data?.entity ===
      "organization"
    ) {
      navigate("/organizations/list");
      handleClose();
      setTimeout(() => {
        dispatch(
          openCreateOrganizationModalAction({
            communityIds: [],
            missionStatement: "",
            name: "",
            organizationImageId: "",
            tagIds: [],
            adminIds: [],
            profileImage: "",
          })
        );
      }, 1000);
    }
  };
  const callApiIfEntitiesLengthIsZero = () => {
    if (hasMoreData) {
      handleLoadMore();
    }
  };

  return (
    <Modal
      open={addExistingCommunitiesOrOrganizationsToProgramModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[850px] rounded-lg overflow-y-auto">
          <div
            className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg bg-bgWhite dark:bg-secondaryLight"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                  Search to add{" "}
                  {addExistingCommunitiesOrOrganizationsToProgramModal?.data
                    ?.entity === "community"
                    ? "Communities"
                    : "Organizations"}{" "}
                  To The Program
                </p>
              </div>
              <button type="button" onClick={handleClose}>
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <div className="flex items-center w-full">
              <div className="w-12">
                <img
                  src={
                    addExistingCommunitiesOrOrganizationsToProgramModal.data
                      .breadcrumbData?.profileImage
                  }
                  alt=""
                  className="rounded-full h-9 w-9"
                />
              </div>
              <div className="flex flex-wrap w-full">
                <p className="dark:text-textMain">
                  {
                    addExistingCommunitiesOrOrganizationsToProgramModal.data
                      .breadcrumbData?.name
                  }
                </p>
              </div>
            </div>
            <TextInput
              onChange={(e: any) => {
                setSearchString(e.target.value);
                debouncedSearch(e.target.value);
              }}
              value={searchString}
              type="Search"
              placeholder={`Search among existing ${
                addExistingCommunitiesOrOrganizationsToProgramModal?.data
                  ?.entity === "community"
                  ? "communities"
                  : "organizations"
              } `}
            />
            <div className="w-full px-1">
              <div className="flex self-stretch justify-between flex-grow-0 flex-shrink-0 w-full gap-4 items-between">
                <div className="flex flex-col justify-start items-start w-[50%]">
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
                    <div className="flex items-center justify-start flex-grow gap-4 px-2 py-2 rounded-lg">
                      <div className="flex justify-start items-center flex-grow relative py-0.5">
                        {addExistingCommunitiesOrOrganizationsToProgramModal
                          .data.entity === "community" ? (
                          <UserGroupIcon />
                        ) : (
                          <OfficeBuildingIcon fill="#000000" />
                        )}
                        <span className="ml-2 dark:text-textMain">
                          {" "}
                          {entitiesList.length} Existing{" "}
                        </span>
                        <span className="ml-1 dark:text-textMain">
                          {addExistingCommunitiesOrOrganizationsToProgramModal
                            ?.data?.entity === "community"
                            ? "Communities"
                            : "Organizations"}
                        </span>
                      </div>
                      <button
                        onClick={() => {
                          addAllEntities();
                          callApiIfEntitiesLengthIsZero();
                        }}
                        className="font-semibold text-primary"
                      >
                        Add All
                      </button>
                    </div>
                  </div>
                  <div className="w-full border border-lineMid">
                    <InfiniteScroll
                      dataLength={entitiesList.length}
                      next={handleLoadMore}
                      hasMore={hasMoreData}
                      height={350}
                      className="w-full"
                      style={{ width: "100%" }}
                      loader={
                        <div className="flex items-center justify-center w-full loader">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#005C89"
                            ariaLabel="tail-spin-loading"
                            radius="2"
                            wrapperStyle={{}}
                            wrapperClass="tailspin-loader"
                            visible={true}
                          />
                        </div>
                      }
                    >
                      <div className="flex flex-col items-start justify-start w-full px-2 py-3 overflow-y-auto rounded-lg">
                        {entitiesList.map((entity: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer group hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2] "
                              onClick={() => addExistingEntity(entity)}
                            >
                              <img
                                src={
                                  entity?.profileImage?.thumb ||
                                  entity?.image?.thumb
                                    ? entity?.profileImage?.thumb ||
                                      entity?.image?.thumb
                                    : addExistingCommunitiesOrOrganizationsToProgramModal
                                          ?.data?.entity === "community"
                                      ? CommunityPlaceholderAvatar
                                      : OrganizationPlaceholderAvatar
                                }
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_user_Data"
                                style={{ flexShrink: 0 }}
                              />
                              <div className="flex items-center justify-start flex-grow">
                                <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                                  <p className="w-[260px] text-left break-words dark:text-textMain">{`${entity.name}`}</p>
                                </div>
                              </div>
                              <svg
                                width={14}
                                height={12}
                                viewBox="0 0 14 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.19526 0.861888C7.93491 1.12224 7.93491 1.54435 8.19526 1.8047L11.7239 5.33329L1.33333 5.33329C0.965143 5.33329 0.666666 5.63177 0.666666 5.99996C0.666666 6.36815 0.965143 6.66663 1.33333 6.66663H11.7239L8.19526 10.1952C7.93491 10.4556 7.93491 10.8777 8.19526 11.138C8.45561 11.3984 8.87772 11.3984 9.13807 11.138L13.8047 6.47136C14.0651 6.21101 14.0651 5.78891 13.8047 5.52856L9.13807 0.861888C8.87772 0.601539 8.45561 0.601539 8.19526 0.861888Z"
                                  className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                                />
                              </svg>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
                <ConnectedEntitiesList
                  connectedEntities={connectedEntitiesList}
                  removeEntity={removeExistingEntity}
                  entity={
                    addExistingCommunitiesOrOrganizationsToProgramModal?.data
                      ?.entity
                  }
                  removeAllEntities={removeAllEntities}
                  isLoading={isLoading}
                />
              </div>
              {organizations.canCreateOrganization && (
                <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                  <p className="text-left break-words dark:text-textMain">
                    You can’t find the{" "}
                    {
                      addExistingCommunitiesOrOrganizationsToProgramModal?.data
                        ?.entity
                    }{" "}
                    you’re looking for,
                    <span
                      className="px-2 font-semibold cursor-pointer text-primaryDark"
                      onClick={redirectToTheDirectory}
                    >
                      Add It Here
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />
              <Button
                type="button"
                disabled={isLoading || !programs.canEditProgram}
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={addExistingEntities}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

interface ConnectedEntitiesListProps {
  connectedEntities: any[];
  removeEntity: (entity: any) => void;
  entity: string;
  removeAllEntities: () => void;
  isLoading: boolean;
}

const ConnectedEntitiesList: React.FC<ConnectedEntitiesListProps> = ({
  connectedEntities,
  removeEntity,
  entity,
  removeAllEntities,
  isLoading,
}) => {
  // console.log(connectedEntities, "entities =>",entity,'testing the communties');
  const { addExistingCommunitiesOrOrganizationsToProgramModal } = useSelector(
    (state: RootState) => state.modals
  );
  return (
    <div className="flex flex-col justify-start items-start w-[50%] relative">
      <div className="flex flex-col items-start justify-start flex-grow w-full ">
        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
          <div className="flex items-center justify-start flex-grow gap-4 px-2 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow relative py-0.5">
              {addExistingCommunitiesOrOrganizationsToProgramModal.data
                .entity === "community" ? (
                <UserGroupIcon />
              ) : (
                <OfficeBuildingIcon fill="#000000" />
              )}{" "}
              <span className="ml-2 dark:text-textMain">
                {" "}
                {connectedEntities.length} Added
              </span>
            </div>
            <button
              disabled={isLoading || connectedEntities.length === 0}
              className="font-semibold text-primary disabled:cursor-not-allowed"
              onClick={removeAllEntities}
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow w-full h-[353px] px-2 py-3 overflow-y-auto border rounded-lg border-lineMid">
          {connectedEntities.map((entity: any, index: number) => {
            return (
              <div
                key={index}
                className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer group hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2] "
              >
                <img
                  src={
                    entity?.profileImage?.thumb || entity?.image?.thumb
                      ? entity?.profileImage?.thumb || entity?.image?.thumb
                      : entity === "community"
                        ? CommunityPlaceholderAvatar
                        : OrganizationPlaceholderAvatar
                  }
                  alt=""
                  height="100"
                  width="100"
                  className="object-cover Img_user_Data"
                  style={{ flexShrink: 0 }}
                />
                <div className="flex-col items-center justify-start flex-grow fle">
                  <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                    <p className="w-[260px] text-left break-words dark:text-textMain">{`${entity.name}`}</p>
                  </div>
                  {entity?.isLeading ? (
                    <span className="text-xs text-tertiary ml-2 bg-tertiaryLight px-1 py-[2px] rounded dark:bg-primary_50 dark:text-textMain font-Overpass font-normal leading-4">
                      Leading
                    </span>
                  ) : (
                    " "
                  )}
                </div>
                {!entity?.isLeading && (
                  <button
                    disabled={isLoading}
                    onClick={() => removeEntity(entity)}
                  >
                    <XCloseSmallIcon
                      width={24}
                      height={24}
                      className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                    />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConnectedEntitiesList;

function reStructureTheEntitiesList(
  entitiesList: any,
  isConnected: boolean
): any[] {
  const reStructuredList = entitiesList.map((entity: any) => {
    const { ...rest } = entity;
    return {
      ...rest,
      isConnected: isConnected,
    };
  });

  return reStructuredList;
}

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  return difference;
}
