import { useRef, useState } from "react";
import {
  useCustomizeColorOfHigilightedPoint,
  useHandleClickOnPoint,
  useInitializeCustomLayersListControl,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
  usePaintAllPointsOnMap,
} from "../../hooks";

const LAYER_DEFAULT_COLOR = "red";
const LAYER_SELECTED_COLOR ="#FFA500";

const Map = ({element,answer,eleName,setFieldValue,editMode}:any) => {
  const mapContainer = useRef<any>(null);
  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);

  const customControlId = "pointpicker" + element.id;

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
    customControlId,
    "",
    "",
    editMode
  );

  // initializing custom control for global layers listing to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    element.selectedLayers,
    customControlId
  );

  // painting the all global layers on the map
  usePaintAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    LAYER_DEFAULT_COLOR
  );

  // paint all points on the map
  const [clickedPoint, setClickedPoint] = usePaintAllPointsOnMap(
    element,
    answer,
    map,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers
  );

  // handle click on point
  useHandleClickOnPoint(
    clickedPoint,
    setClickedPoint,
    element,
    eleName,
    setFieldValue,
    editMode,
    answer,
    element.selectedPoints
  );

  // change the color of highlighted point
  useCustomizeColorOfHigilightedPoint(
    map,
    isMapFullyLoaded,
    element,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers,
    setClickedPoint,
    answer
  );
  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
