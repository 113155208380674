import { sentenceCase } from "change-case";
import { ERROR_LOADING_MANAGERS, GET_ALL_MANAGERS, RESET_DATA, START_LOADING_MANAGERS, STOP_LOADING_MANAGERS } from "./action-types";
import initialState, { IManager } from "./initialState";
export const managerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_MANAGERS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_MANAGERS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_MANAGERS:
      return {
        ...state,
        stateIs: "Idle",
      };

    case GET_ALL_MANAGERS:
      const managers = action.payload.managers.map((item: IManager) => {
        const {
          reviewer,
          personId,
          lastActive,
          community,
          roles,
          observationsReviewed,
          reviewerSince,
          activeObservations,
          dataEndViewerSince,
          activeReportAccess,
          status,
          endOfAccess,
        } = item;

        const data = {
          id: personId,
          reviewer: { ...reviewer, personId },
          lastActive,
          personId,
          role: roles,
          community,
          observationsReviewed,
          reviewerSince,
          activeObservations,
          dataEndViewerSince,
          activeReportAccess,
          status,
          endOfAccess,
        };

        return data;
      });

      return {
        ...state,
        data: {
          managers,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
