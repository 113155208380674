import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import ellipsize from "ellipsize";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface LinkItem {
  path: string;
  label: string;
  icon?: React.ComponentType<{ height: number; width: number }>; // Icon component with height and width props
}

interface BreadcrumbProps {
  links: LinkItem[];
}

const DirectoryBreadCrumb: React.FC<BreadcrumbProps> = ({ links }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-start">
      <button
        onClick={() => navigate(-1)}
        className="w-auto h-auto p-0 mr-2 border-none outline-none hover:outline-none"
      >
        <ArrowLeftIcon height={22} width={22} />
      </button>
      {links.map((link, index) => (
        <React.Fragment key={index}>
          {index > 0 && <CheveronRightIcon height={22} width={22} />}
          <Link
            to={link.path}
            className="flex items-center space-x-2 text-textMid dark:text-inputText hover:underline"
          >
            {link.icon && <link.icon height={22} width={22} />}{" "}
            {/* If you have icons for each link */}
            <p className="text-sm capitalize">{ellipsize(link.label, 100)}</p>
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DirectoryBreadCrumb;
