import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { useSelector } from "react-redux";
import { RootState } from "store";
import { formatTheDates } from "utils/formatTheDates";
// interface CustomDatePickerProps {
//   initialValue: string;
//   handleChange: (value: any) => void;
//   min: string;
//   max: string;
//   className?: any;
//   touched?: any;
//   disabled?: any;
// }

// const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
//   initialValue,
//   handleChange,
//   max,
//   min,
//   className,
//   touched,
//   disabled,
//   ...restProps
// }) => {
//   const { profileSettings } = useSelector(
//     (state: RootState) => state.userProfileSettings
//   );
//   const [selectedDate, setSelectedDate] = useState<any>(null);
//   const handleDateChange = (date: any) => {

//     setSelectedDate(date);
//     handleChange(date);
//   };

//   useEffect(() => {
//     setSelectedDate(dayjs(initialValue).isValid() ? dayjs(initialValue) : null);
//   }, [initialValue]);
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         {...restProps}
//         value={selectedDate}
//         onChange={handleDateChange}
//         format={profileSettings.dateFormat}
//         maxDate={max ? dayjs(max) : undefined}
//         minDate={min ? dayjs(min) : undefined}
//         disabled={disabled}
//         className={className}
//         sx={{
//           "& .MuiInputBase-root": {
//             border: "none", // Removes border
//             "& fieldset": {
//               border: "none", // Removes border from fieldset if used
//             },
//           },
//         }}
//       />
//     </LocalizationProvider>
//   );
// };

// export default CustomDatePicker;

interface CustomDatePickerProps {
  initialValue: string;
  handleChange: (value: any) => void;
  min: string;
  max: string;
  className?: any;
  touched?: any;
  disabled?: any;
  isForFilters?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  initialValue,
  handleChange,
  max,
  min,
  className,
  touched,
  disabled,
  isForFilters = false,
  ...restProps
}) => {
  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const [selectedDate, setSelectedDate] = useState<any>(null);

  // Function to handle the date change
  const handleDateChange = (date: any) => {
    if (date && moment(date).isValid()) {
      const utcDate = isForFilters
        ? moment(date).toISOString()
        : moment(date).toISOString();
      const formattedDate = formatTheDates(utcDate);
      setSelectedDate(date);
      handleChange(formattedDate);
    } else {
      setSelectedDate(null);
      handleChange(null);
    }
  };

  // Effect to set the initial value
  useEffect(() => {
    const date = moment(initialValue, moment.ISO_8601);
    if (date.isValid()) {
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  }, [initialValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...restProps}
        value={selectedDate}
        onChange={handleDateChange}
        format={profileSettings.dateFormat}
        maxDate={max ? moment(max) : undefined}
        minDate={min ? moment(min) : undefined}
        disabled={disabled}
        className={className}
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
              border: "none"
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
