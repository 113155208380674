import React, { useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { List, ListItem, Popover } from "@mui/material";
import { fetchFormDetailsAction } from "store/formDetails/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useDateFormatter from "hooks/formatDateWithPattern";

interface FormType {
  value: string;
  label: string;
  image: string;
}

interface FormsDropdownProps {
  forms: any;
  id: number;
  handleClose: (event: any) => void;
  anchorEl: any;
}

const FormsDropdown: React.FC<FormsDropdownProps> = ({
  forms,
  id,
  handleClose,
  anchorEl,
}) => {
  const [selectedOption, setSelectedOption] = useState<FormType | null>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { formatDate } = useDateFormatter();
  const navigate = useNavigate();

  const formattedOptions = useMemo(() => {
    const options = forms?.map((form: any) => {
      console.log(form, "form has date for not");

      return {
        value: form.id,
        label: form.name,
        image: form?.image?.thumb,
        protocolConnectedAt: form?.protocolConnectedAt,
      };
    });

    return options;
  }, [forms]);

  const handleSelect = (form: SingleValue<FormType>) => {
    if (form?.value) {
      navigate("/forms/list");
      dispatch(
        fetchFormDetailsAction({
          id: form?.value,
          path: "directory",
        })
      );
      const currentPath = window.location.pathname;
      const queryParam = `formId=${encodeURIComponent(form.value)}`;
      const newPath = `${currentPath}?${queryParam}`;
      navigate(newPath);
    }
  };

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        id="form-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "25vh",
            overflowY: "auto",
            width: "203px",
            marginTop: "30px",
          },
        }}
      >
        {/* <TextField
          autoFocus
          margin="dense"
          id="search"
          type="text"
          fullWidth
          placeholder="Search By Member Name"
          value={searchTerm}
          className="font-Overpass text-sm dark:placeolder:text-caption"
          onChange={(e) => {
            e.stopPropagation(); // Prevent the event from propagating to the menu
            setSearchTerm(e.target.value);
          }}
          onKeyDown={(e) => {
            // Prevents the menu from closing when pressing "Enter"
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
          sx={{
            "& .MuiInputBase-root": {
              fontSize: "14px",
              fontFamily: "Overpass",
            },

            "& .MuiInputBase-input": {
              padding: "10px 0px",
              fontSize: "14px",
              height: "auto",
              fontFamily: "Overpass",
              color: "#00000",
              boxShadow: "none",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              margin: "0px 10px",
              border: "1px solid #BDC0C4",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="ml-2">
                <MagnifyingGlassIcon className="w-6 h-6 stroke-textLightExtra" />
              </InputAdornment>
            ),
            classes: {
              input: "MuiInputBase-input", // Ensure this class name matches your theme's class name for input
            },
          }}
        /> */}
        <p className="text-sm font-semibold text-secondaryMid px-3 pt-3">
          {formattedOptions?.length} Forms
        </p>
        <List component="nav" aria-label="search results">
          {formattedOptions?.length > 0 ? (
            formattedOptions.map((form: any, index: number) => {
              return (
                <div
                  onClick={(event: any) => {
                    handleSelect(form);
                    event.stopPropagation();
                  }}
                  className={`px-3 py-2 flex items-center gap-2 cursor-pointer text-sm font-Overpass`}
                >
                  <p className="text-primaryLight71 text-sm w-1/2">
                    {form?.label}
                  </p>
                  <p className="text-sm w-1/2">
                    {formatDate(form?.protocolConnectedAt)}
                  </p>
                </div>
              );
            })
          ) : (
            <ListItem className="font-Overpass text-sm dark:text-caption !justify-center">
              No Options Found
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
};

export default FormsDropdown;
