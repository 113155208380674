import { apiClient } from "../config";

async function getAllUserWhichCanBeAddAsAdmin(
  organizationId: string,
  searchString: string,
  page: number
) {
  const params = {
    organizationId,
    search_string: searchString.trim() !== "" ? searchString : undefined,
    page,
  };

  const response = await apiClient.get("/admin/programs/admins-dropdown", {
    params,
  });

  return response.data;
}

export default getAllUserWhichCanBeAddAsAdmin;