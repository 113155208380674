import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import Placeholderavatar from "assets/images/Placeholders/avatar-circle.png";

interface AdminProps {
  userData: any;
  unAssignManager: any;
}
const Admins: React.FC<AdminProps> = ({ userData, unAssignManager }) => {
  return (
    <div>
      <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">
        {" "}
        <div
          onClick={() => unAssignManager(userData)}
          className="flex items-center flex-grow w-full cursor-pointer"
        >
          <div className="relative flex items-center justify-between cursor-pointer">
            <img
              src={
                userData.profileImage || userData?.image
                  ? userData.profileImage || userData?.image?.file?.thumb
                  : Placeholderavatar
              }
              alt=""
              height="32px"
              width="32px"
              className="object-cover mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
            <p className="ml-3 break-words w-[500px] dark:text-textMain">{`${userData.name} `}</p>{" "}
          </div>
        </div>
        <button type="button" className="flex-shrink-0">
          <XCloseSmallControlIcon onClick={() => unAssignManager(userData)} />
        </button>
      </div>
    </div>
  );
};

export default Admins;
