import { RenderCellContentProps } from '../../types';
import ellipsize from 'ellipsize';
import useDateFormatter from 'hooks/formatDateWithPattern';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  if (columnDef.id === 'image') {
    return (
      <div className='flex justify-start  items-center relative'>
        {cellValue !== null ? (
          <img
            src={cellValue}
            alt=''
            height='48px'
            width='64px'
            className='object-cover'
            style={{ flexShrink: 0 }}
          />
        ) : (
          <svg
            width={64}
            height={48}
            viewBox='0 0 64 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='flex-grow-0 flex-shrink-0 w-16 h-16 relative'
            preserveAspectRatio='none'
          >
            <g clip-path='url(#clip0_5705_17812)'>
              <rect width={64} height={48} fill='#DCDEE0' />
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M18 10L28 23H18V25H28L18 38H46L36 25H46V23H36L46 10H18ZM36 23V25H28V23H36ZM22.0617 12L28.9848 21H35.0152L41.9383 12H22.0617ZM28.9848 27L22.0617 36H41.9383L35.0152 27H28.9848Z'
                fill='#202123'
                fill-opacity='0.1'
              />
            </g>
            <defs>
              <clipPath id='clip0_5705_17812'>
                <rect width={64} height={48} fill='white' />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'dateAdded') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'objectName') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'type') {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue.name}`, 50)}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === 'address' ? 'justify-start' : 'justify-start'
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
