import React, { useEffect, useLayoutEffect, useState } from "react";
import UsersIcon from "assets/icons/HeroIcons/UsersIcon";
import Button from "view/components/Button";
import TextInput from "view/components/InputField";
import apiLibrary from "services/api";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { Box, Modal } from "@mui/material";
import { Toasts } from "view/components/Toasts";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeAddManagerModal } from "store/modals/reducer.actions";
import Placeholder from "assets/images/Placeholders/programPlaceholderAvatar.png";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";

import { Users } from "./Users";
import Managers from "./Managers";
import { fetchManagerAction } from "store/managers";
import _ from "lodash";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

export const AssignManagersToProgram = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { addManagerModal } = useSelector((state: RootState) => state.modals);

  const [searchString, setSearchString] = useState("");
  const [entitiesList, setEntitiesList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [connectedEntitiesList, setConnectedEntitiesList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);

  const customSort = (entities: any): any => {
    return entities.sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const addExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) =>
      prevEntitiesList.filter((item) => item?.id !== entity.id)
    );

    setConnectedEntitiesList((prevconnectedEntitiesList) => {
      const isEntityAlreadyConnected = prevconnectedEntitiesList.some(
        (item: any) => item.id === entity.id
      );

      if (!isEntityAlreadyConnected) {
        const updatedList = [...prevconnectedEntitiesList, entity];
        return customSort(updatedList);
      }
      if (searchString !== "") {
        searchUserWhichCanBeAddedAsManager(1, "", prevconnectedEntitiesList);
      }
      return prevconnectedEntitiesList;
    });
  };

  const removeExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) => {
      const isEntityPresent = prevEntitiesList.some(
        (item) => item?.id === entity.id
      );

      if (!isEntityPresent) {
        const updatedEntitiesList = [...prevEntitiesList, entity];
        return customSort(updatedEntitiesList);
      } else {
        return prevEntitiesList;
      }
    });

    setConnectedEntitiesList((prevconnectedEntitiesList: any) => {
      const updatedConnectedEntitiesList = prevconnectedEntitiesList.filter(
        (item: any) => item?.id !== entity.id
      );
      if (searchString !== "") {
        searchUserWhichCanBeAddedAsManager(1, "", updatedConnectedEntitiesList);
      }

      return customSort(updatedConnectedEntitiesList);
    });
  };
  const removeAllEntities = (): void => {
    setEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...connectedEntitiesList.filter(
          (connectedEntity: any) =>
            !prevEntitiesList.some((entity) => entity.id === connectedEntity.id)
        ),
      ];
      return customSort(updatedEntitiesList);
    });

    setConnectedEntitiesList([]);
  };

  const addAllEntities = (): void => {
    setConnectedEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...entitiesList.filter(
          (newEntity: any) =>
            !prevEntitiesList.some(
              (prevEntity: any) => prevEntity?.id === newEntity.id
            )
        ),
      ];

      if (searchString !== "") {
        searchUserWhichCanBeAddedAsManager(1, "", updatedEntitiesList);
      }
      return customSort(updatedEntitiesList);
    });

    setEntitiesList([]);
  };

  const handleClose = () => {
    dispatch(closeAddManagerModal());
  };

  const getAllUserWhichCanBeAddedAsManager = async (
    isLoadMore: boolean,
    page: number,
    searchString: string
  ) => {
    if (!isLoadMore) {
      setIsLoading(true);
    }

    try {
      const { data } = await apiLibrary.Managers.getManagersDropdown(
        searchString,
        addManagerModal.data?.programId,
        page
      );

      const programManagers =
        addManagerModal?.data?.programManagers.map((user: any) => {
          const { ...rest } = user;
          const updatedUser = {
            ...rest,
            personImage: user?.personImage?.thumb ?? null,
          };
          return updatedUser;
        }) ?? [];

      const entitieslist = getSortedDifference(
        data.users,
        isLoadMore ? connectedEntitiesList : programManagers
      );
      // console.log(entitieslist,'entitieslistentitieslist');

      setEntitiesList(
        isLoadMore ? (prevList) => [...prevList, ...entitieslist] : entitieslist
      );

      const reStructuredConnectedEntitiesList =
        reStructureTheEntitiesList(programManagers);

      setConnectedEntitiesList((pre: any[]) => {
        if (isLoadMore || searchString !== "") {
          return pre;
        } else {
          return reStructuredConnectedEntitiesList;
        }
      });

      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserWhichCanBeAddedAsManager(false, 1, searchString);
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getAllUserWhichCanBeAddedAsManager(true, page, searchString);
      return page;
    });
  };

  const searchUserWhichCanBeAddedAsManager = async (
    page: number,
    searchString: string,
    connectedEntitiesList: any[]
  ) => {
    setIsLoading(true);

    try {
      const { data } = await apiLibrary.Managers.getManagersDropdown(
        searchString,
        addManagerModal.data?.programId,
        page
      );

      const entitieslist = getSortedDifference(
        data.users,
        connectedEntitiesList
      );
      setEntitiesList(entitieslist);
      setTotalPages(data.to);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchUsersWhichCanBeAddedAsManager = (
    searchString: string
  ): void => {
    setPage((pre: number) => {
      searchUserWhichCanBeAddedAsManager(
        1,
        searchString,
        connectedEntitiesList
      );
      return 1;
    });
  };

  const debouncedSearch = _.debounce(
    handleSearchUsersWhichCanBeAddedAsManager,
    100
  );

  const addExistingMembersAsAnManager = async () => {
    setIsLoading(true);
    try {
      const response = await apiLibrary.Managers.addManagersToProgram(
        addManagerModal.data?.programId,
        {
          person_ids: connectedEntitiesList.map((manager) => {
            return manager.id;
          }),
        }
      );
      dispatch(fetchManagerAction(parseInt(addManagerModal.data?.programId)));

      handleClose();
      Toasts.success(response.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={addManagerModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="bg-bgWhite w-[700px] max-h-[95vh] rounded-lg dark:bg-secondaryLight">
          <div className="flex flex-col items-start self-stretch justify-start p-4 bg-white rounded-lg">
            <div className="w-full">
              <div className="flex items-center justify-items-center">
                <div className="flex flex-col items-start self-stretch justify-center w-full">
                  <div className="flex items-center self-stretch justify-start gap-2">
                    <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                      <p className="self-stretch w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                        Search For An Manager To Add To
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  title="close"
                  type="button"
                  onClick={handleClose}
                  className="ml-8 cursor-pointer"
                >
                  <XCloseIcon />
                </button>
              </div>
              <div className="flex w-full justify-start items-center gap-4 py-1.5">
                <div className="rounded-full">
                  <img
                    className="object-cover rounded-full w-9 h-9"
                    src={
                      addManagerModal?.data?.profileImage
                        ? addManagerModal?.data?.profileImage
                        : Placeholder
                    }
                  />
                </div>
                <div className="w-[95%] flex">
                  <p className="max-h-32 break-all w-full text-[16px] text-left font-medium text-secondary dark:text-textMain">
                    {addManagerModal?.data?.name}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-start self-stretch justify-start bg-white">
                <div className="flex flex-col items-start self-stretch justify-start gap-1 pt-4 pb-2">
                  <div className="w-full">
                    <TextInput
                      type="Search"
                      placeholder="Search Name"
                      value={searchString}
                      onChange={(e: any) => {
                        setSearchString(e.target.value);
                        debouncedSearch(e.target.value);
                      }}
                    />
                  </div>
                  <p className="text-sm text-textMidLight dark:text-caption ">
                    Please note that you can only select users from the
                    connected communities.
                  </p>
                </div>
                <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[5px] rounded-lg">
                  <div className="flex-grow h-px bg-lineMid" />
                </div>
                {isLoading ? (
                  <div className="loader min-h-[25vh] justify-center items-center flex w-full">
                    <TailSpin
                      height="50"
                      width="50"
                      color="#005C89"
                      ariaLabel="tail-spin-loading"
                      radius="2"
                      wrapperStyle={{}}
                      wrapperClass="tailspin-loader"
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="w-full overflow-hidden px-1 pb-4 max-h-[50vh]">
                    <>
                      {connectedEntitiesList?.length > 0 &&
                        searchString === "" && (
                          <div className="w-full">
                            <div className="flex justify-between p-3 space-x-4">
                              <div className="flex gap-2">
                                <UsersIcon />
                                <p className="font-medium text-md text-textMid dark:text-textMain">
                                  {connectedEntitiesList?.length} Assigned
                                  Managers
                                </p>
                              </div>
                              <button
                                type="button"
                                onClick={() => removeAllEntities()}
                              >
                                <p className="text-primary">Clear All</p>
                              </button>
                            </div>

                            <div className="max-h-[100px] overflow-y-auto">
                              {connectedEntitiesList?.map(
                                (manager: any, index: number) => (
                                  <Managers
                                    key={index}
                                    userData={manager}
                                    unAssignManager={() =>
                                      removeExistingEntity(manager)
                                    }
                                  />
                                )
                              )}
                            </div>
                            <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[8px] rounded-lg">
                              <div className="flex-grow h-px bg-lineMid" />
                            </div>
                          </div>
                        )}

                      <div className="w-full">
                        <div className="flex justify-between p-3">
                          <div className="flex gap-2">
                            <div>
                              <UsersIcon />
                            </div>
                            <div className="flex w-full gap-1">
                              <p className="font-medium text-md text-textMid dark:text-textMain">
                                {entitiesList?.length}
                              </p>
                              <p className="font-medium text-md text-textMid dark:text-textMain">
                                {searchString !== ""
                                  ? "Search results"
                                  : "Users"}
                              </p>
                            </div>
                          </div>
                          <button
                            type="button"
                            disabled={entitiesList?.length > 0 ? false : true}
                            onClick={() => {
                              addAllEntities();
                              if (searchString !== "") {
                                setSearchString("");
                              }
                            }}
                          >
                            <p className="text-primary">Add All</p>
                          </button>
                        </div>

                        <InfiniteScroll
                          dataLength={entitiesList.length}
                          next={handleLoadMore}
                          hasMore={hasMoreData}
                          height={170}
                          loader={
                            <div className="flex items-center justify-center w-full loader">
                              <TailSpin
                                height="30"
                                width="30"
                                color="#005C89"
                                ariaLabel="tail-spin-loading"
                                radius="2"
                                wrapperStyle={{}}
                                wrapperClass="tailspin-loader"
                                visible={true}
                              />
                            </div>
                          }
                          pullDownToRefresh={true}
                          refreshFunction={() => {}}
                          pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8595; Pull down to refresh &#8595;
                            </h3>
                          }
                          releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8593; Release to refresh &#8593;
                            </h3>
                          }
                        >
                          {entitiesList?.map((user, index) => (
                            <Users
                              key={index}
                              userData={user}
                              addManager={() => {
                                addExistingEntity(user);
                                if (searchString !== "") {
                                  setSearchString("");
                                }
                              }}
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 pt-4">
              <Button
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                disabled={isLoading}
                onClick={handleClose}
              />

              <Button
                type="button"
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="w-24 h-11"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                disabled={isLoading}
                onClick={addExistingMembersAsAnManager}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  return difference;
}

function getSortedEntities(entitiesList: any) {
  return _.sortBy(entitiesList, "name");
}

function reStructureTheEntitiesList(entitiesList: any): any[] {
  const reStructuredList = entitiesList.map((user: any) => {
    const { ...rest } = user;

    if (user.image) {
      return {
        ...rest,
        profileImage: user?.image?.file?.thumb ?? null,
      };
    }

    return user;
  });

  return reStructuredList;
}
