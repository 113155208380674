import {  useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  setFormAllSelectedShapes,
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateShapePickers,
} from "store/surveyJS";
import { panelsName } from "../../../panelsTitle";
import { HARVEST_QUESTIONS_NAME } from "../../../panelsQuestionsName";

const useHandleClickOnLayer = (
  clickedLayer: any,
  setClickedLayer: any,
  currentQuestionEle: any,
  questionName: any
) => {
  const {
    shapePickers,
    selectedQuestionElement,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
    surveyActiveTab,
    formAllSelectedShapes,
  } = useSelector((state: RootState) => state.surveyJS);
  const dispatch = useDispatch();

  useMemo(() => {
    if (!clickedLayer || surveyActiveTab === "test") return;

    const layerId = clickedLayer.item.id;
    
    const alreadyExistedLayerIndex =
      currentQuestionEle.selectedLayersIdToHighlight.findIndex(
        (l: any) => l === layerId
      );

    if (alreadyExistedLayerIndex < 0) {
      const updatedSelectedLayers = [
        ...currentQuestionEle.selectedLayersIdToHighlight,
        layerId,
      ];
      const updatedQuestion = {
        ...currentQuestionEle,
        selectedLayersIdToHighlight: updatedSelectedLayers,
      };

      const selectedQuestionTypes = selectedQuestionElement?.isPanel
        ? selectedQuestionElement.name
        : selectedQuestionElement?.getType();
      if (selectedQuestionTypes === panelsName.HARVEST) {
        if (questionName === HARVEST_QUESTIONS_NAME.RetrievalLocation) {
          dispatch(
            updateAddedHarvestPanelMapQuestions({
              ...harvestPanelMapQuestions,
              retrievalShapePicker: updatedQuestion,
            })
          );
        } else if (
          questionName === HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion
        ) {
          dispatch(
            updateAddedHarvestPanelMapQuestions({
              ...harvestPanelMapQuestions,
              struckShapePicker: updatedQuestion,
            })
          );
        }
      } else if (
        selectedQuestionTypes === panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        if (questionName === "Region") {
          dispatch(
            updateAddedGeographicalPanelMapQuestions({
              ...geographicalPanelMapQuestions,
              regionTypeShapePicker: updatedQuestion,
            })
          );
        } else if (questionName === "View") {
          dispatch(
            updateAddedGeographicalPanelMapQuestions({
              ...geographicalPanelMapQuestions,
              viewTypeShapePicker: updatedQuestion,
            })
          );
        }
      } else {
        const currentQuestionIndex = shapePickers.findIndex(
          (s: any) => s.id === updatedQuestion.id
        );

        const updatedAllShapePickers = [...shapePickers];
        updatedAllShapePickers[currentQuestionIndex] = updatedQuestion;

        dispatch(updateShapePickers(updatedAllShapePickers));
      }
    }

    setClickedLayer(null);
  }, [clickedLayer]);

  return null;
};

export { useHandleClickOnLayer };


