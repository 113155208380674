import { useMemo } from "react";

const useHandleClickOnLayer = (
  clickedLayer: any,
  setClickedLayer: any,
  selectedGeographies: any[],
  updateSelectedShapeRegions: any,
  editMode: boolean
) => {
  useMemo(() => {
    if (!clickedLayer || !editMode) {
      setClickedLayer(null);
      return;
    }

    const layerId = clickedLayer.item.id;
    const isLayerSelected = selectedGeographies.some(
      (layer) => layer.id === layerId
    );

    if (!isLayerSelected) {
      updateSelectedShapeRegions(clickedLayer.item);
    }
    // else {
    //   updateSelectedShapeRegions(
    //     selectedGeographies.filter((layer) => layer.id !== layerId)
    //   );
    // }

    setClickedLayer(null);
  }, [
    clickedLayer,
    editMode,
    selectedGeographies,
    setClickedLayer,
    updateSelectedShapeRegions,
  ]);
};

export { useHandleClickOnLayer };
