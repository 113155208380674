import {
  GET_OBSERVATION_PROFILE,
  RESET_OBSERVATION_PROFILE,
  START_LOADING_OBSERVATION,
  STOP_LOADING_OBSERVATION,
  SWITCH_TO_OBSERVER_TIME_ZONE,
} from "./action-types";

import { initialState } from "./initialState";

export const observationProfileReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    // Loading states
    case START_LOADING_OBSERVATION:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING_OBSERVATION:
      return {
        ...state,
        isLoading: false,
      };

    case GET_OBSERVATION_PROFILE:
      const data = action.payload;

      data.sections.forEach((section: any) => {
        if (section.templateElements) {
          section.templateElements.forEach(
            (element: any) => (element.isUpdated = false)
          );
        }

        if (section.elements) {
          section.elements.forEach(
            (element: any) => (element.isUpdated = false)
          );
        }

        // Remove 'isUpdated' key from the section itself
        section.isUpdated = false;
      });

  
      return {
        ...data,
        isLoading: false,
      };
    case RESET_OBSERVATION_PROFILE:
      return initialState;

    case SWITCH_TO_OBSERVER_TIME_ZONE:
      return {
        ...state,
        switchToObserverTimeZone: action.payload,
      };
    default:
      return state;
  }
};
